import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetSuppRefNumbersResp,
	GetSuppRefNumbersPath,
	GetBolDetailsResp,
	GetBolDetailsQuery,
	CreateEdiBillOfLadingPickupRequestRqst,
	CreateEdiBillOfLadingPickupRequestResp,
	CreateEdiBillOfLadingPickupRequestQuery,
	CancelEdiBillOfLadingPickupRequestRqst,
	CreateOcrBillOfLadingRqst,
	CreateOcrBillOfLadingResp,
	ListSubmittedBillsOfLadingResp,
	ListSubmittedBillsOfLadingQuery,
	GetBillOfLadingResp,
	GetBillOfLadingQuery,
	GetBillOfLadingPath,
	CreateBillOfLadingRqst,
	CreateBillOfLadingResp,
	CreateBillOfLadingQuery,
	UpdateBillOfLadingRqst,
	UpdateBillOfLadingResp,
	UpdateBillOfLadingQuery,
	CancelBillOfLadingRqst,
	CancelBillOfLadingQuery,
	GetBillOfLadingPdfResp,
	GetBillOfLadingPdfQuery,
	GetBillOfLadingPdfPath,
	EmailBillOfLadingPdfRqst,
	EmailBillOfLadingPdfResp,
	EmailBillOfLadingPdfQuery,
	ListBillOfLadingResp,
	ListBillOfLadingQuery,
	GenerateBillOfLadingPdfResp,
	GenerateBillOfLadingPdfQuery,
	GenerateBillOfLadingCsvQuery,
	GetBillOfLadingTemplateResp,
	GetBillOfLadingTemplateQuery,
	GetBillOfLadingTemplatePath,
	ListBillOfLadingTemplatesResp,
	ListBillOfLadingTemplatesQuery,
	CreateBillOfLadingTemplateRqst,
	CreateBillOfLadingTemplateResp,
	CreateBillOfLadingTemplateQuery,
	UpdateBillOfLadingTemplateRqst,
	UpdateBillOfLadingTemplateResp,
	UpdateBillOfLadingTemplateQuery,
	DeleteBillOfLadingTemplateQuery,
	DeleteBillOfLadingTemplatePath,
	CopyBillOfLadingTemplatesRqst,
	CopyBillOfLadingTemplatesResp,
	UploadBillOfLadingTemplatesRqst,
	UploadBillOfLadingTemplatesResp,
	UploadBillOfLadingTemplatesQuery,
	GetBillOfLadingDraftResp,
	GetBillOfLadingDraftQuery,
	GetBillOfLadingDraftPath,
	CreateBillOfLadingDraftRqst,
	CreateBillOfLadingDraftResp,
	CreateBillOfLadingDraftQuery,
	UpdateBillOfLadingDraftRqst,
	UpdateBillOfLadingDraftResp,
	UpdateBillOfLadingDraftQuery,
	DeleteBillOfLadingDraftQuery,
	DeleteBillOfLadingDraftPath,
	ListBOLCommodityPreferencesResp,
	ListBOLCommodityPreferencesQuery,
	CreateBOLCommodityPreferenceRqst,
	CreateBOLCommodityPreferenceResp,
	CreateBOLCommodityPreferenceQuery,
	UpdateBOLCommodityPreferenceRqst,
	UpdateBOLCommodityPreferenceResp,
	UpdateBOLCommodityPreferenceQuery,
	DeleteBOLCommodityPreferenceQuery,
	DeleteBOLCommodityPreferencePath,
	UploadBOLCommodityPreferencesRqst,
	UploadBOLCommodityPreferencesResp,
	UploadBOLCommodityPreferencesQuery,
	CreatePapsLabelPdfRqst,
	CreatePapsLabelPdfResp,
	CreatePapsLabelPdfQuery,
	CreateShippingLabelPdfRqst,
	CreateShippingLabelPdfResp,
	CreateShippingLabelPdfQuery,
	EmailPapsLabelPdfRqst,
	EmailPapsLabelPdfResp,
	EmailPapsLabelPdfQuery,
	EmailShippingLabelPdfRqst,
	EmailShippingLabelPdfResp,
	EmailShippingLabelPdfQuery,
	GenerateShippingLabelPdfResp,
	GenerateShippingLabelPdfQuery,
	GeneratePapsLabelPdfResp,
	GeneratePapsLabelPdfQuery
} from './api-billoflading';

import {
	GetHealthCheckResp,
	FileContents,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class BillOfLadingApiService extends BaseService {
	private static BillOfLadingApiEndpoint = 'billofladingApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the list of all supplemenatal reference numbers for the bol.
	* <br/>	
	* <br/>Pre-condition: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If successful, a bill of lading, in CSV format, is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getSuppRefNumbers(
							   pathParams: GetSuppRefNumbersPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSuppRefNumbersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/bills-of-lading/{bolInstId}/supplemental-refs'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns details for a bill of lading.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. Returns a bill of lading based on the information provided.
	*/
	public getBolDetails(
							   queryParams: GetBolDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetBolDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Maintain  Bill of Lading and Pickup Request for EDI customer request. Based on the inbound 204 and/or 211 message either the Bill of Lading record or Pickup Request or both will be created and corresponding identifiers are sent back in the response.
	* <br/>	Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. If pickup request information is provided then both Bill of Lading and Pickup Request is persisted.
	* <br/>Post-conditions: 
	* <br/>1. Bill of Lading and Pickup Request data is persisted and bill of lading identifier and pickup request identifier is returned.
	* <br/>
	*/
	public createEdiBillOfLadingPickupRequest(request: CreateEdiBillOfLadingPickupRequestRqst,
							   queryParams: CreateEdiBillOfLadingPickupRequestQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateEdiBillOfLadingPickupRequestResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/edi-bol-pureqs'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Cancels a Bill of Lading for an EDI customer request. Pickup request associated to BOL are also cancelled.
	*/
	public cancelEdiBillOfLadingPickupRequest(request: CancelEdiBillOfLadingPickupRequestRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/edi-bol-pureqs/cancel'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Creates a bill of lading for OCR source
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading is created based on the information provided.
	*/
	public createOcrBillOfLading(request: CreateOcrBillOfLadingRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateOcrBillOfLadingResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/ocr'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Lists bills of lading that have been submitted.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. Lists bills of lading that have been submitted based on the requester ID provided.
	* <br/>
	*/
	public listSubmittedBillsOfLading(
							   queryParams: ListSubmittedBillsOfLadingQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSubmittedBillsOfLadingResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/requester'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a bill of lading.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. Returns a bill of lading based on the information provided.
	*/
	public getBillOfLading(
							   pathParams: GetBillOfLadingPath,
							   queryParams: GetBillOfLadingQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetBillOfLadingResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/{bolInstId}/requester'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates a bill of lading.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading is created based on the information provided.
	*/
	public createBillOfLading(request: CreateBillOfLadingRqst,
							   queryParams: CreateBillOfLadingQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateBillOfLadingResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Updates a bill of lading.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. Updates a bill of lading based on the BOL instance ID provided.
	*/
	public updateBillOfLading(request: UpdateBillOfLadingRqst,
							   queryParams: UpdateBillOfLadingQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateBillOfLadingResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/{bolInstId}'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Cancels a bill of lading.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. The bill of lading, that corresponds with the inputted information, is cancelled.
	*/
	public cancelBillOfLading(request: CancelBillOfLadingRqst,
							   queryParams: CancelBillOfLadingQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/{bolInstId}/cancel'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Returns a bill of lading in PDF format.
	* <br/>	
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading, in PDF format, is returned.
	*/
	public getBillOfLadingPdf(
							   pathParams: GetBillOfLadingPdfPath,
							   queryParams: GetBillOfLadingPdfQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetBillOfLadingPdfResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/{bolInstId}/pdf'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Emails a bill of lading in PDF format.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. Emails a bill of lading in PDF format.
	*/
	public emailBillOfLadingPdf(request: EmailBillOfLadingPdfRqst,
							   queryParams: EmailBillOfLadingPdfQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<EmailBillOfLadingPdfResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/{bolInstId}/email'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Lists bill of lading templates.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. Lists bill of lading templates based on the requester ID provided.
	*/
	public listBillOfLading(
							   queryParams: ListBillOfLadingQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListBillOfLadingResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billoflading/list'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a bill of lading in PDF format.
	* <br/>	
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading, in PDF format, is returned.
	*/
	public generateBillOfLadingPdf(
							   queryParams: GenerateBillOfLadingPdfQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GenerateBillOfLadingPdfResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/pdf'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the list of all BOLs that belong to the user in CSV format.
	* <br/>	
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading, in CSV format, is returned.
	*/
	public generateBillOfLadingCsv(
							   queryParams: GenerateBillOfLadingCsvQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/csv'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a bill of lading template.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. Returns a bill of lading template based on the information provided.
	*/
	public getBillOfLadingTemplate(
							   pathParams: GetBillOfLadingTemplatePath,
							   queryParams: GetBillOfLadingTemplateQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetBillOfLadingTemplateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingtemplate/{bolInstId}/requester'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists bill of lading templates.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. Lists bill of lading templates based on the requester ID provided.
	*/
	public listBillOfLadingTemplates(
							   queryParams: ListBillOfLadingTemplatesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListBillOfLadingTemplatesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingtemplate/requester'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates a bill of lading template.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading template is created based on the information provided.
	*/
	public createBillOfLadingTemplate(request: CreateBillOfLadingTemplateRqst,
							   queryParams: CreateBillOfLadingTemplateQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateBillOfLadingTemplateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingtemplate'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Updates a bill of lading template.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading template is updated based on the information provided.
	*/
	public updateBillOfLadingTemplate(request: UpdateBillOfLadingTemplateRqst,
							   queryParams: UpdateBillOfLadingTemplateQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateBillOfLadingTemplateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingtemplate/{bolInstId}'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Deletes a bill of lading.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading is deleted based on the information provided.
	*/
	public deleteBillOfLadingTemplate(
							   pathParams: DeleteBillOfLadingTemplatePath,
							   queryParams: DeleteBillOfLadingTemplateQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingtemplate/{bolInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Copies bill of lading templates from one user to another.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading template is created based on the information provided.
	*/
	public copyBillOfLadingTemplates(request: CopyBillOfLadingTemplatesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CopyBillOfLadingTemplatesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingtemplate/replicate'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Uploads a bill of lading templates.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. BOL templates are created based on the information provided.
	*/
	public uploadBillOfLadingTemplates(request: UploadBillOfLadingTemplatesRqst,
							   queryParams: UploadBillOfLadingTemplatesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UploadBillOfLadingTemplatesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingtemplate/upload'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Returns a bill of lading Draft.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. Returns a bill of lading Draft based on the information provided.
	*/
	public getBillOfLadingDraft(
							   pathParams: GetBillOfLadingDraftPath,
							   queryParams: GetBillOfLadingDraftQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetBillOfLadingDraftResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingdraft/{bolInstId}/requester'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates a bill of lading Draft.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading Draft is created based on the information provided.
	*/
	public createBillOfLadingDraft(request: CreateBillOfLadingDraftRqst,
							   queryParams: CreateBillOfLadingDraftQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateBillOfLadingDraftResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingdraft'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Updates a bill of lading Draft.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading Draft is updated based on the information provided.
	*/
	public updateBillOfLadingDraft(request: UpdateBillOfLadingDraftRqst,
							   queryParams: UpdateBillOfLadingDraftQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateBillOfLadingDraftResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingdraft/{bolInstId}'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Deletes a bill of lading.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading is deleted based on the information provided.
	*/
	public deleteBillOfLadingDraft(
							   pathParams: DeleteBillOfLadingDraftPath,
							   queryParams: DeleteBillOfLadingDraftQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsofladingdraft/{bolInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* List Bill of lading commodity preference.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. List of all the commodity preference for the user is returned.
	*/
	public listBOLCommodityPreferences(
							   queryParams: ListBOLCommodityPreferencesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListBOLCommodityPreferencesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/bol-commodities'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates Bill of lading commodity preference.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading commodity is created based on the information provided.
	*/
	public createBOLCommodityPreference(request: CreateBOLCommodityPreferenceRqst,
							   queryParams: CreateBOLCommodityPreferenceQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateBOLCommodityPreferenceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/bol-commodity'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Updates Bill of lading commodity preference.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading commodity is updated based on the information provided.
	*/
	public updateBOLCommodityPreference(request: UpdateBOLCommodityPreferenceRqst,
							   queryParams: UpdateBOLCommodityPreferenceQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateBOLCommodityPreferenceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/bol-commodities/{sequenceId}'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Deletes Bill of lading commodity preference.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A bill of lading commodity is deleted based on the information provided.
	*/
	public deleteBOLCommodityPreference(
							   pathParams: DeleteBOLCommodityPreferencePath,
							   queryParams: DeleteBOLCommodityPreferenceQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/bol-commodities/{sequenceId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Uploads a bill of lading commodity preference.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid. 
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. BOL commodites are created based on the information provided.
	*/
	public uploadBOLCommodityPreferences(request: UploadBOLCommodityPreferencesRqst,
							   queryParams: UploadBOLCommodityPreferencesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UploadBOLCommodityPreferencesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/bol-commodities/upload'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Returns a PAPS label in PDF format.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. The PAPS label, in PDF format, is returned based on the information provided.
	*/
	public createPapsLabelPdf(request: CreatePapsLabelPdfRqst,
							   queryParams: CreatePapsLabelPdfQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreatePapsLabelPdfResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/{bolInstId}/papslabels/pdf'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Returns a shipping label in PDF format.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. The shipping label, in PDF format, is returned based on the information provided.
	*/
	public createShippingLabelPdf(request: CreateShippingLabelPdfRqst,
							   queryParams: CreateShippingLabelPdfQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateShippingLabelPdfResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/{bolInstId}/shippinglabels/pdf'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Email a PAPS label in PDF format.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. The PAPS label, in PDF format, is emailed based on the information provided.
	*/
	public emailPapsLabelPdf(request: EmailPapsLabelPdfRqst,
							   queryParams: EmailPapsLabelPdfQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<EmailPapsLabelPdfResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/{bolInstId}/papslabels/email'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Email a Shipping label in PDF format.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. The Shipping label, in PDF format, is emailed based on the information provided.
	*/
	public emailShippingLabelPdf(request: EmailShippingLabelPdfRqst,
							   queryParams: EmailShippingLabelPdfQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<EmailShippingLabelPdfResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/{bolInstId}/shippinglabels/email'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Returns a shipping label in PDF format.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. The shipping label, in PDF format, is returned based on the information provided.
	*/
	public generateShippingLabelPdf(
							   queryParams: GenerateShippingLabelPdfQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GenerateShippingLabelPdfResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/shippinglabels/pdf'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a shipping label in PDF format.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Mandatory data is provided and valid.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. The paps label, in PDF format, is returned based on the information provided.
	*/
	public generatePapsLabelPdf(
							   queryParams: GeneratePapsLabelPdfQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GeneratePapsLabelPdfResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billsoflading/papslabels/pdf'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(BillOfLadingApiService.BillOfLadingApiEndpoint);
	}
}
