/**
 * ShipmentTracking API
 * Version: 1.0
 * Build: Manual
 */

import {
	ListInfo,
	ReferenceNumber,
	EventHdr,
	FreightExceptions,
	ServiceCenterLocation,
	Status,
	Address,
	Weight,
	MonetaryAmount,
	EquipmentId,
	Appointment,
	ManifestReportTypeCd,
	PartyRoleCd
} from '@xpo-ltl/sdk-common';

/**
 * Input data to retrieve all the reference numbers associated with the shipment
 */
export class ListShipmentReferenceNumbersRqst {
	/**
	 * Business Id of the Shipment
	 * Required: true
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * The pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Output data to retrieve all the reference numbers associated with the shipment
 */
export class ListShipmentReferenceNumbersResp {
	/**
	 * Business Id of the Shipment
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	references:ReferenceNumber[] = undefined;
	/**
	 * The pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Input data to retrieve the shipment tracking history
 */
export class ListShipmentTrackingEventsRqst {
	/**
	 * Business Id of the Shipment
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	levelOfDetail:string = undefined;
	/**
	 * The pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Output data detailing the shipment tracking history
 */
export class ListShipmentTrackingEventsResp {
	/**
	 * First part of the unique business identifier to a shipment
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * List of historical events for a shipment
	 * Required: false
	 * Array: true
	 */
	shipmentTrackingEvent:ShipmentTrackingEvent[] = undefined;
	/**
	 * The pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Input data to retrieve the shipment status
 */
export class GetShipmentStatusRqst {
	/**
	 * Either a Pro number or any reference number used to identify the shipment
	 * Required: false
	 * Array: true
	 */
	referenceNumbers:string[] = undefined;
}
/**
 * Ouput data to retrieve the shipment status
 */
export class GetShipmentStatusResp {
	/**
	 * Required: false
	 * Array: true
	 */
	shipmentStatusDtls:ShipmentStatusDetail[] = undefined;
	/**
	 * Any reference numbers that found a match
	 * Required: false
	 * Array: true
	 */
	matchedSearchReferences:string[] = undefined;
	/**
	 * Any reference numbers that did not find a match
	 * Required: false
	 * Array: true
	 */
	unmatchedSearchReferences:string[] = undefined;
}
/**
 * Request to retrieve the manifest report for the execution ID provided.
 */
export class GetManifestReportRqst {
	/**
	 * Execution ID for the manifest report.
	 * Required: true
	 * Array: false
	 */
	executionId:string = undefined;
}
/**
 * Response to the request to retrieve the manifest report for the execution ID provided.
 */
export class GetManifestReportResp {
	/**
	 * Execution ID for the manifest report.
	 * Required: false
	 * Array: false
	 */
	executionId:string = undefined;
	/**
	 * The unique identifier for the account.
	 * Required: true
	 * Array: false
	 */
	accountNbr:string = undefined;
	/**
	 * The unique identifier for the party role.
	 * Required: true
	 * Array: false
	 */
	partyRoleCd:PartyRoleCd = undefined;
	/**
	 * The type of the manifest report that needs to be executed.
	 * Required: true
	 * Array: false
	 */
	reportTypeCd:ManifestReportTypeCd = undefined;
	/**
	 * Details about the shipment status.
	 * Required: false
	 * Array: true
	 */
	shipmentStatusDtls:ShipmentStatusDetail[] = undefined;
}
/**
 * Request to list PRO numbers and their shipment status details for the account number provided.
 */
export class ListShipmentsByAccountRqst {
	/**
	 * The unique identifier for the account.
	 * Required: true
	 * Array: false
	 */
	accountNbr:string = undefined;
	/**
	 * The unique identifier for the party role.
	 * Required: true
	 * Array: false
	 */
	partyRoleCd:PartyRoleCd = undefined;
	/**
	 * The pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list PRO numbers and their shipment status details for the account number provided.
 */
export class ListShipmentsByAccountResp {
	/**
	 * The unique identifier for the account.
	 * Required: true
	 * Array: false
	 */
	accountNbr:string = undefined;
	/**
	 * Details about the shipment status.
	 * Required: false
	 * Array: true
	 */
	shipmentStatusDtls:ShipmentStatusDetail[] = undefined;
	/**
	 * The pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to create a manifest report and return an execution ID.
 */
export class CreateManifestReportRqst {
	/**
	 * The unique identifier for the account.
	 * Required: true
	 * Array: false
	 */
	accountNbr:string = undefined;
	/**
	 * The unique identifier for the party role.
	 * Required: true
	 * Array: false
	 */
	partyRoleCd:PartyRoleCd = undefined;
	/**
	 * The type of the manifest report that needs to be executed.
	 * Required: true
	 * Array: false
	 */
	reportTypeCd:ManifestReportTypeCd = undefined;
}
/**
 * Response to the request to create a manifest report and return an execution ID.
 */
export class CreateManifestReportResp {
	/**
	 * Execution ID for the manifest report.
	 * Required: false
	 * Array: false
	 */
	executionId:string = undefined;
}
/**
 * Shipment information for an event in that shipment's history.
 */
export class ShipmentTrackingEvent {
	/**
	 * Required: false
	 * Array: false
	 */
	eventHdr:EventHdr = undefined;
	/**
	 * The freight exception information including osd pieces, reason codes if avaliable and description.
	 * Required: false
	 * Array: false
	 */
	exceptionInfo:FreightExceptions = undefined;
	/**
	 * Company location where the history event occurred.
	 * Required: false
	 * Array: false
	 */
	eventOccrdLoc:ServiceCenterLocation = undefined;
}
/**
 * All necessary details associated with a Shipment's status.
 * 			
 */
export class ShipmentStatusDetail {
	/**
	 * Internal identifier to the shipment
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:string = undefined;
	/**
	 * First part of the unique business identifier to a shipment
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Second part of the unique identifier to a shipment. This is the date that the shipment was picked up.
	 * Required: false
	 * Array: false
	 */
	pkupDate:Date = undefined;
	/**
	 * Basic event information about the most recent event that occurred for this shipment.
	 * Required: false
	 * Array: false
	 */
	lastShipmentEvent:EventHdr = undefined;
	/**
	 * Information about the status of the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentStatus:Status = undefined;
	/**
	 * This is a code that identifies that ltl.xpo service center where the shipment is currently located
	 * Required: false
	 * Array: false
	 */
	currSic:ServiceCenterLocation = undefined;
	/**
	 * This is a code that identifies that ltl.xpo service center that is responsible for obtaining this shipment from the shipper.  This could be from an actual pickup or from the customer dropping the shipment off at our dock.
	 * Required: false
	 * Array: false
	 */
	origSic:ServiceCenterLocation = undefined;
	/**
	 * This is a code that identifies that ltl.xpo service center that is responsible for getting this shipment to the consignee.  This could be from an actual delivery or from the customer picking it up from our dock.
	 * Required: false
	 * Array: false
	 */
	destSic:ServiceCenterLocation = undefined;
	/**
	 * Name and address information for the shipper for this shipment.
	 * Required: false
	 * Array: false
	 */
	shipperAddr:Address = undefined;
	/**
	 * Name and address information for the consignee for this shipment.
	 * Required: false
	 * Array: false
	 */
	consigneeAddr:Address = undefined;
	/**
	 * Name and address information for a billing party that is acting on behalf of the debtor.
	 * Required: false
	 * Array: false
	 */
	billToAddr:Address = undefined;
	/**
	 * These are numbers supplied by the Customer that are of value to them.  i.e. SR#; PO#; etc.
	 * Required: false
	 * Array: true
	 */
	suppRef:ReferenceNumber[] = undefined;
	/**
	 * Indicator to represent additional reference numbers. Tto retrieve entire list, use listShipmentReferenceNumbers operation
	 * Required: false
	 * Array: false
	 */
	additionalSuppRefInd:boolean = undefined;
	/**
	 * The start of the time window for the RqstDlvrDate
	 * Required: false
	 * Array: false
	 */
	startDlvrTime:Date = undefined;
	/**
	 * The end of the time window for the RqstDlvrDate
	 * Required: false
	 * Array: false
	 */
	endDlvrTime:Date = undefined;
	/**
	 * name of the consignee's representative who signed for the delivery
	 * Required: false
	 * Array: false
	 */
	dlvrSigNameTxt:string = undefined;
	/**
	 * time when the consignee signed for the delivery
	 * Required: false
	 * Array: false
	 */
	dlvrSigNameTmst:Date = undefined;
	/**
	 * Conditions agreed upon between parties to a transportation transaction regarding the type and payment of freight.
	 * Required: false
	 * Array: false
	 */
	chargeToCd:string = undefined;
	/**
	 * Total number of pieces included in the shipment
	 * Required: false
	 * Array: false
	 */
	totPiecesCnt:number = undefined;
	/**
	 * Describes the type of packaging. Skid, Box, Pallet etc.
	 * Required: false
	 * Array: false
	 */
	packageCd:string = undefined;
	/**
	 * Total weight of the shipment
	 * Required: false
	 * Array: false
	 */
	totWeight:Weight = undefined;
	/**
	 * Total charges for the shipment. Includes all charges that contribute to the CTS
	 * revenue minus discount in US dollars.  Includes advance+beyond carrier charges and COD amount.
	 * Required: false
	 * Array: false
	 */
	totChargeAmt:MonetaryAmount = undefined;
	/**
	 * Date the shipment was  estimated to be delivered.
	 * Required: false
	 * Array: false
	 */
	estdDlvrDate:Date = undefined;
	/**
	 * Date the shipment was delivered
	 * Required: false
	 * Array: false
	 */
	finalDlvrDate:Date = undefined;
	/**
	 * indicates the shipment was tendered late which affects the service date
	 * Required: false
	 * Array: false
	 */
	lateTenderInd:boolean = undefined;
	/**
	 * Indicates whether this is a COD shipment or not. XPO LTL collects payment during delivery on behalf of the shipper for COD shipments.
	 * Required: false
	 * Array: false
	 */
	codInd:boolean = undefined;
	/**
	 * This indicates if cash was collected at the time
	 * of pick up or should be collected at the time of     
	 * delivery.
	 * Required: false
	 * Array: false
	 */
	cshInd:boolean = undefined;
	/**
	 * Expanded name:  Service type code.                                           
	 * Description:  Defines the specific type of service    
	 * provided for a shipment. Deferred is no longer used                                 
	 * Values:  1 - Normal LTL, 2 - ltl.xpo Deferred Express
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Hazardous Materials indicator -  indicates whether or not a shipment includes any commodities with hazardous materials.
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * indicates whether the shipment would feeze under colder temparatures
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * Indicates if the shipment is under the ltl.xpo Guaranteed Service Program.
	 * Required: false
	 * Array: false
	 */
	garntInd:boolean = undefined;
	/**
	 * Indicates whether the shipment is eligible for the money-back guarantee if moving between direct points under Tariff CNWY-599.
	 * Required: false
	 * Array: false
	 */
	warrantyEligibleInd:boolean = undefined;
	/**
	 * Estimated time of arrival at next leg of schedule.
	 * Required: false
	 * Array: false
	 */
	eta:Date = undefined;
	/**
	 * Name of the driver transporting the shipment on next leg
	 * Required: false
	 * Array: false
	 */
	driverName:string = undefined;
	/**
	 * Tractor information for the shipment to next leg
	 * Required: false
	 * Array: false
	 */
	tractor:EquipmentId = undefined;
	/**
	 * Trailer information for the shipment to the next leg
	 * Required: false
	 * Array: false
	 */
	trailer:EquipmentId = undefined;
	/**
	 * Idetifies the parent shipment for split or co-op shipments.
	 * Required: false
	 * Array: false
	 */
	parentPro:string = undefined;
	/**
	 * Indicates if an appointment is set, for the shipment
	 * Required: false
	 * Array: false
	 */
	appointmentInd:boolean = undefined;
	/**
	 * Scheduled appointment for delivery
	 * Required: false
	 * Array: false
	 */
	dlvrAppt:Appointment = undefined;
	/**
	 * Address information for the US Broker
	 * Required: false
	 * Array: false
	 */
	usBrokerAddr:Address = undefined;
	/**
	 * Address information for the MX Broker
	 * Required: false
	 * Array: false
	 */
	mxBrokerAddr:Address = undefined;
	/**
	 * Carrier information for the shipment
	 * Required: false
	 * Array: false
	 */
	carrierName:string = undefined;
	/**
	 * The Standard Carrier Alpha Codeï¿½ (SCACï¿½) is an US code used to identify road transport companiesassigned by the National Motor Freight Traffic Association
	 * Required: false
	 * Array: false
	 */
	carrierScac:string = undefined;
	/**
	 * Service exception information for the shipment
	 * Required: false
	 * Array: true
	 */
	serviceException:FreightExceptions[] = undefined;
}

/**
 * Query parameters for getShipmentStatus
 */
export class GetShipmentStatusQuery {
	referenceNumbers: string[] = undefined;
}

/**
 * Query parameters for listShipmentReferenceNumbers
 */
export class ListShipmentReferenceNumbersQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listShipmentTrackingEvents
 */
export class ListShipmentTrackingEventsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listShipmentsByAccount
 */
export class ListShipmentsByAccountQuery {
	partyRoleCd: PartyRoleCd = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}


/**
 * Path parameters for listShipmentReferenceNumbers
 */
export class ListShipmentReferenceNumbersPath {
	proNbr: string = undefined;
}

/**
 * Path parameters for listShipmentTrackingEvents
 */
export class ListShipmentTrackingEventsPath {
	proNbr: string = undefined;
}

/**
 * Path parameters for listShipmentsByAccount
 */
export class ListShipmentsByAccountPath {
	accountNbr: string = undefined;
}

/**
 * Path parameters for getManifestReport
 */
export class GetManifestReportPath {
	executionId: string = undefined;
}

