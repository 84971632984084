/**
 * Analytics API
 * Version: 2.0
 * Build: 2.0.0.4
 */

import {
	LookerResultFormatCd
} from '@xpo-ltl/sdk-common';

/**
 * 
 */
export class GetLookerLooksQuery {
	/**
	 * Required: true
	 * Array: false
	 */
	title:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	spaceId:number = undefined;
}
/**
 * 
 */
export class GetLookerLooksResp {
	/**
	 * Required: true
	 * Array: false
	 */
	lookId:string = undefined;
}
/**
 * 
 */
export class GetLookerLooksHeader {
	/**
	 * Required: true
	 * Array: false
	 */
	lookerToken:string = undefined;
}
/**
 * 
 */
export class GetLookerLooksDetailPath {
	/**
	 * Required: true
	 * Array: false
	 */
	lookId:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	resultFormat:LookerResultFormatCd = undefined;
}
/**
 * 
 */
export class GetLookerLooksDetailResp {
	/**
	 * Required: true
	 * Array: false
	 */
	results:string = undefined;
}
/**
 * 
 */
export class GetLookerLooksDetailHeader {
	/**
	 * Required: true
	 * Array: false
	 */
	lookerToken:string = undefined;
}
/**
 * Query parameters used to retrieve the Looker SSO URL.
 */
export class GetLookerSSOUrlRqst {
	/**
	 * Each dashboard is given a unique number, and the embed URL must be provided for that dashboard.
	 * Required: true
	 * Array: false
	 */
	target_url:string = undefined;
	/**
	 * This should be configured longer than the application session length.
	 * Required: true
	 * Array: false
	 */
	session_length:number = undefined;
	/**
	 * This only applies if the user has an active Looker session.  When they do, that session will be forced to logout when this new SSO URL is 'logged' in when it is embedded.  Each dashboard is given a unique number, in this case 588 is the dashboard for which the application is seeking a SSO URL.
	 * Required: true
	 * Array: false
	 */
	force_logout_login:boolean = undefined;
	/**
	 * This contains the bearer token provided by the getLookerAccessToken.
	 * Required: true
	 * Array: false
	 */
	looker_auth:string = undefined;
}
/**
 * Response to the request to retrieve the Looker SSO URL.
 */
export class GetLookerSSOUrlResp {
	/**
	 * The SSL URL for the target_url in the request.
	 * Required: true
	 * Array: false
	 */
	url:string = undefined;
}
/**
 * Request to retrieve a Looker access token.
 */
export class GetLookerAccessTokenRqst {
	/**
	 * The amount of time for which the access token is valid.
	 * Required: true
	 * Array: false
	 */
	payload:string = undefined;
}
/**
 * Response to the request to retrieve a Looker access token.
 */
export class GetLookerAccessTokenResp {
	/**
	 * The access token from Looker.
	 * Required: true
	 * Array: false
	 */
	access_token:string = undefined;
	/**
	 * The token type from Looker.
	 * Required: true
	 * Array: false
	 */
	token_type:string = undefined;
	/**
	 * The amount of time for which the access token is valid.
	 * Required: true
	 * Array: false
	 */
	expires_in:number = undefined;
}


