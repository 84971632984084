import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetAcctBasicDetailsResp,
	GetAcctBasicDetailsPath,
	GetAcctBillToLocationDetailResp,
	GetAcctBillToLocationDetailQuery,
	GetCisCustomerHierarchyResp,
	GetCisCustomerHierarchyQuery,
	GetCustomerContactsResp,
	GetCustomerContactsPath,
	ListAcctBasicDetailsResp,
	ListAcctBasicDetailsQuery,
	ListCorpCustAcctHierarchyChildrenResp,
	ListCorpCustAcctHierarchyChildrenQuery,
	ListCorpCustAcctHierarchyChildrenPath,
	MaintainCustProfileRqst,
	ListCustProfileCodeByTypeResp,
	ListCustProfileCodeByTypePath,
	GetCustomerProfileResp,
	GetCustomerProfileQuery,
	GetCustomerProfilePath,
	GetShipperDimensionProfileResp,
	GetShipperDimensionProfileQuery,
	GetShipperDimensionProfilePath,
	GetCustLocationResp,
	GetCustomerLocationPath,
	MatchCustomerRqst,
	MatchCustomerResp,
	DetermineRestrictedBillToResp,
	DetermineRestrictedBillToPath,
	GetRelatedBillToForCustomerResp,
	GetRelatedBillToForCustomerQuery,
	GetSendToPartyPaymentTermResp,
	GetSendToPartyPaymentTermQuery,
	MatchCustomerBillingRqst,
	MatchCustomerBillingResp,
	ListCustomerGroupsForLocationResp,
	ListCustomerGroupsForLocationQuery,
	ListCustomerGroupsForLocationPath,
	GetCustomerGroupDetailsResp,
	GetCustomerGroupDetailsQuery,
	ListCustomerInvcTypeAddressesResp,
	ListCustomerInvcTypeAddressesPath,
	UpdateGeoCoordinatesForCustRqst,
	UpdateGeoCoordinatesForCustResp,
	UpdateGeoCoordinatesForCustQuery,
	GetCustomerLocationOperationsProfileResp,
	GetCustomerLocationOperationsProfilePath,
	GetCustomerStopDetailResp,
	GetCustomerStopDetailPath,
	ListCustCommonBolsResp,
	ListCustCommonBolsQuery,
	CreateSRNRulesRqst,
	CreateSRNRulesResp,
	UpdateSRNRulesRqst,
	UpdateSRNRulesResp,
	DeleteSRNRulesResp,
	DeleteSRNRulesPath,
	ListSRNRulesResp,
	ListSRNRulesQuery,
	GetSRNRuleResp,
	GetSRNRulePath,
	CreateBillingRemarksRqst,
	CreateBillingRemarksResp,
	UpdateBillingRemarksRqst,
	UpdateBillingRemarksResp,
	DeleteBillingRemarksPath,
	ListBillingRemarksResp,
	ListBillingRemarksQuery,
	ListBillingRemarksPath,
	GetBillingRemarkResp,
	GetBillingRemarkPath,
	UpsertMultipleBillingRemarksRqst,
	UpsertMultipleBillingRemarksResp
} from './api-customer';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class CustomerApiService extends BaseService {
	private static CustomerApiEndpoint = 'customerApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation gets basic details for the requested customer account.
	* <br/>Pre-condition:
	* <br/>A valid customer account ID is provided.
	* <br/>
	* <br/>Post-Condition:
	* <br/>Customer account MAD Code, account type, name, address, credit information is returned.
	* <br/>
	*/
	public getAcctBasicDetails(
							   pathParams: GetAcctBasicDetailsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetAcctBasicDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accounts/{acctInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation gets bill to locations details for the requested customer account instance id or mad code.
	* <br/>Pre-condition:
	* <br/>A valid customer account ID or MAD code is provided.
	* <br/>
	* <br/>Post-Condition:
	* <br/>The bill to party details of the customer is returned.
	* <br/>
	*/
	public getAcctBillToLocationDetail(
							   queryParams: GetAcctBillToLocationDetailQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetAcctBillToLocationDetailResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accounts/billtolocation'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will be used to either return all the offspring or all the parents for a customer MAD code or legacy CIS customer number.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A customer MAD code or a legacy CIS customer number is provider.
	* <br/>2. The customer hierarchy type is provided.
	* <br/>		
	* <br/>Post-condition:
	* <br/>1. If the input is found, then a status code of 200 is returned along with all of the offspring or parents for the requested customer.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getCisCustomerHierarchy(
							   queryParams: GetCisCustomerHierarchyQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCisCustomerHierarchyResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/hierarchy'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns a customer's contacts based on the customer number provided.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. A valid customer account number is provided.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If the input is valid, then all the details of the customer contacts in the Customer system are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getCustomerContacts(
							   pathParams: GetCustomerContactsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCustomerContactsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{custAcctNbr}/contacts'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* The operation gets basic details for the requested account (customer location).
	* <br/>Pre-condition:
	* <br/>A valid list of customer account instance IDs is provided.
	* <br/>
	* <br/>Post-Condition:
	* <br/>List of customer account basic details (account id, Customer account MAD Code, location type, name, address, credit information) are returned.
	* <br/>
	*/
	public listAcctBasicDetails(
							   queryParams: ListAcctBasicDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAcctBasicDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accounts'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Given a unique customer instance ID for a corporate account, retrieve all of the subsidiary accounts within its corporate stucture.
	*/
	public listCorpCustAcctHierarchyChildren(
							   pathParams: ListCorpCustAcctHierarchyChildrenPath,
							   queryParams: ListCorpCustAcctHierarchyChildrenQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListCorpCustAcctHierarchyChildrenResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accounts/{acctInstId}/hierarchy'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Maintain Customer profile will be used to update the profile codes related to a customer location. For example - physical location type (like post office, airport) and physical location details (does the customer have dock and forklift)
	* <br/>
	* <br/>pre-condition
	* <br/> - Customer ID, futureCustInd and 1 or more customer profile is provided including the profile code ID, profile code type code, profile value and action code
	* <br/>post-condition
	* <br/> - profile is updated for the customer
	* <br/>
	* <br/>pre-condition
	* <br/> - customer id is missing
	* <br/>post-condition 
	* <br/> - profile change failed 
	*/
	public maintainCustProfile(request: MaintainCustProfileRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{acctInstId}/profiles'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation will be used to retrieve all of the codes associated with a particular profile code type.
	* <br/>Pre-condition
	* <br/> - Type code provided
	* <br/>Post-condition
	* <br/> - List of codes associated with that type is returned
	*/
	public listCustProfileCodeByType(
							   pathParams: ListCustProfileCodeByTypePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListCustProfileCodeByTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/profile-codes/{profileTypeCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns all details for a customer profile using a customer number and an optional filter of profile type code.
	* <br/>
	* <br/>Pre-condition: A valid customer id is provided.
	* <br/>
	* <br/>Post-condition: All the details for the customer's profile in the Customer system are returned.
	*/
	public getCustomerProfile(
							   pathParams: GetCustomerProfilePath,
							   queryParams: GetCustomerProfileQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCustomerProfileResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customer/{custAcctNbr}/profile'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will be used to retrieve the shipper dimension profile from CIS.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid shipper ID is provided.
	* <br/>2. Additional criteria may be supplied.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input is valid, then a status code of 200, with all relevant details, is returned.
	* <br/>2. If the contact person ID is invalid or doesn't exist, then a status code of 404 (not found) is returned.
	* <br/>3. Otherwise an appropriate error message is returned.
	*/
	public getShipperDimensionProfile(
							   pathParams: GetShipperDimensionProfilePath,
							   queryParams: GetShipperDimensionProfileQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetShipperDimensionProfileResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipper-dimension-profiles/{shipperId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service returns the location details for a customer including the geo area and the latitude and logitude.  The pick up and delivery service area of a service center is divided into mutiple geo areas and each customer belongs to one of these geo areas based on their location. 
	* <br/>
	* <br/>Pre Condition: A valid customer id is provided.
	* <br/>
	* <br/>Post Condition: The location details including address, the geo area the customer belongs to and the lat/long are returned. 
	*/
	public getCustomerLocation(
							   pathParams: GetCustomerLocationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCustLocationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customer-locations/{custAcctId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Matches Bill of Lading/shipment customer information (shipper, consignee, bill to) to existing customers. Returns matched customer numbers, validated version of the address and other lcoation information or error codes for invalid address fields. Creates future customer depending on input flag.
	* <br/>
	* <br/>Pre Condition: Shipper Address and optionally Consignee Address and BillTo Address are provided. Flags to indicate whether future customers should be created or not are provided for Shipper and Consignee. 
	* <br/>
	* <br/>Post Condition: 
	* <br/>The shipper, consignee and bill to addresses are validated and either validated address or error codes are provided for each of the addresses.
	* <br/>
	* <br/>if any matching customers exist the customer Ids are returned for the matched customers. Geo area information is returned only for Shipper.
	* <br/>
	* <br/>If no matching customers are found for the shipper and consignee, future customers are created and their Ids are returned as indicated by the input flags.
	*/
	public matchCustomer(request: MatchCustomerRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<MatchCustomerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customer-locations/match'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation determines whether the input Bill-To customer number is designated as a Restricted Bill-To, based on data in the Customer Information System.
	* <br/>
	* <br/>Pre-condition:
	* <br/>Bill-To customer number is supplied and valid.
	* <br/>
	* <br/>Post-Condition:
	* <br/>If input Bill-To customer number is designated as a Restricted Bill-To, the output restricted bill-to flag is set to 'Y', otherwise it is set to 'N'.
	*/
	public determineRestrictedBillTo(
							   pathParams: DetermineRestrictedBillToPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DetermineRestrictedBillToResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accounts/{billToCustNbr}/determine-restricted'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the Bill-to information for a shipment based on the matched pickup delivery location (Shipper for Prepaid bills, Consignee for Collect bills), the charge to cd, the invoice type cd and the bill date.                                                       
	* <br/>
	* <br/>IMPORT:                                                    -- Expects a P/D customer ID or MAD code, Charge To Cd, Invoice Type Cd, and a bill date. 
	* <br/>       EXPORT:                                                     - The operation will return the Pickup and Delivery customer name and address information if there is no Bill-to for the input customer.
	* <br/>
	* <br/>- The Bill-to location name, address, MAD code and customer number information will be returned if the cutomer has a Bill-to set up in CIS.
	* <br/>
	* <br/>The operation will also return an Invoice Address Code which indicates the Invoice Type set up for the imported Pickup and Delivery customer.
	*/
	public getRelatedBillToForCustomer(
							   queryParams: GetRelatedBillToForCustomerQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetRelatedBillToForCustomerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customer-locations/related-bill-tos'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* 
	* <br/>		If the Input Customer Type Code is Bill-TO then this operation retrieves the Bill-to information for a shipment based on the matched pickup delivery location (Shipper for Prepaid bills, Consignee for Collect bills), the charge to cd, the invoice type cd and the bill date.
	* <br/>		If the related bill to party is found then retrieves the Payment term for the related bill to party.
	* <br/>		If the related bill to party is not found then retrieves the Payment term for the input bill to party.
	* <br/>		If the input customer type code is not Bill-TO then return the input customer number as the bill to party and the payment term for the Input customer.
	* <br/>		IMPORT:                                                    -- Expects a P/D customer ID or MAD code, Charge To Cd, Invoice Type Cd, and a bill date. 
	* <br/>       EXPORT:                                                     - The operation will return the Pickup and Delivery customer name and address information if there is no Bill-to for the input customer.
	* <br/>
	* <br/>- The Bill-to location name, address, MAD code and customer number information will be returned if the cutomer has a Bill-to set up in CIS.
	* <br/>
	* <br/>The operation will also return an Invoice Address Code which indicates the Invoice Type set up for the imported Pickup and Delivery customer.
	*/
	public getSendToPartyPaymentTerm(
							   queryParams: GetSendToPartyPaymentTermQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSendToPartyPaymentTermResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customer-locations/send-to-party'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Searches by Name or Address for up to 3 Customers: Shipper, Consignee, Bill-To. 
	* <br/>Exports the customer matching results for the customers supplied as input (Shipper, Consignee, Bill-To).  For each input customer, if a full match is found in the customer database, that match is returned in a single set of fields corresponding to each input customer. If no full match is found and there are one or more partial matches, OR if there are multiple full matches found, those are returned in an export group corresponding to each input customer. If there are validation errors they will be returned in an export group corresponding to each input customer.  Input Charge To Cd is a required field and is used to retrieve the Bill-To related to the debtor party, if that party has a full match AND the input Bill-To customer is blank.  Values for the input Charge To Cd are Prepaid, Collect or Both.  If input Charge To Cd is Prepaid or Both and the Shipper is fully matched and the input Bill-To is blank, the Bill-To customer related to the matched Shipper is returned.  If input Charge To Cd is Collect and the Consignee is fully matched and the input Bill-To is blank, the Bill-To customer related to the matched Consignee is returned.
	*/
	public matchCustomerBilling(request: MatchCustomerBillingRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<MatchCustomerBillingResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customer-locations/matchbilling'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation will be used to retrieve the list of customer groups that are associated with a customer location. The parameter of customerTypeCd is optional.
	* <br/>
	* <br/>Pre-condition
	* <br/> - CIS MAD key or MAD code is provided
	* <br/> - groupType code is provided
	* <br/>Post-condition
	* <br/> - List of groups associated with input group type is returned
	*/
	public listCustomerGroupsForLocation(
							   pathParams: ListCustomerGroupsForLocationPath,
							   queryParams: ListCustomerGroupsForLocationQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListCustomerGroupsForLocationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerNbr}/groups'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will be used to retrieve details for a single customer group. Either customer group inst id or group mad cd must be supplied.  If eff date is not supplied, it will be defaulted to current date.
	* <br/>Pre-condition
	* <br/> - Customer group inst id or group mad cd is supplied and valid.
	* <br/>Post-condition
	* <br/> - Details for the matching group are returned.
	*/
	public getCustomerGroupDetails(
							   queryParams: GetCustomerGroupDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCustomerGroupDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/groups'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves ACTIVE invoice types from CIS for the input Pickup Delivery or Bill-To location customer number.
	* <br/>Pre-condition: Input customer number is supplied and valid.
	* <br/>Post-condition: CIS Invoice Type details are returned for the input customer.
	*/
	public listCustomerInvcTypeAddresses(
							   pathParams: ListCustomerInvcTypeAddressesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListCustomerInvcTypeAddressesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accounts/{customerNbr}/invoice-types'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates the geo latitude/longitude coordinates for a CIS/Future customer using a customer ID.
	* <br/> 
	* <br/>Pre-condition:
	* <br/>1. A valid customer ID is provided and the ID is valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the update is successful, a success boolean is returned.
	* <br/>2. Otherwise an apprpriate error message is returned.
	*/
	public updateGeoCoordinatesForCust(request: UpdateGeoCoordinatesForCustRqst,
							   queryParams: UpdateGeoCoordinatesForCustQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateGeoCoordinatesForCustResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerId}/coordinates'),
			{
		    	pathParams: request
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Returns all details for a customer location operations profile using the customer number.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. A valid customer ID is provided.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If the input is valid, then all the details for a customer location operations profile in the Customer system are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getCustomerLocationOperationsProfile(
							   pathParams: GetCustomerLocationOperationsProfilePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCustomerLocationOperationsProfileResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customer-locations/{custAcctNbr}/operations-profile'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will be used to retrieve the customer stop detail from CIS. 
	* <br/>		The customer can either be a pickup and delivery location, or a future customer. 
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid customer number is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input is valid, then a status code of 200, with all relevant details, is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getCustomerStopDetail(
							   pathParams: GetCustomerStopDetailPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCustomerStopDetailResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customer-stops/{customerNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists all common bill of lading details for the shipper, consignee and bill-to customer numbers provided as input. If the shipper customer number is supplied, the  commonly shipped commodities, shipper billing instructions, Hazmat and Operational billing remarks and Supp Ref Number (SRN) Rules for that customer number will be returned. If the consignee customer number is supplied, consignee billing instructions, Hazmat and Operational billing remarks and Supp Ref Number (SRN) Rules for that customer number will be returned. If the bill-to customer number is supplied, bill-to billing instructions and Supp Ref Number (SRN) Rules for that customer number will be returned.
	* <br/>
	* <br/>Pre-condition:
	* <br/>Shipper customer number is supplied and valid.
	* <br/>
	* <br/>Post-Condition:
	* <br/>List of all common commodities for the shipper customer number as well as all billing remark records for the shipper customer number where the remarkTypeCd is 'S'(Instructions to Biller for Shipper) OR 'H'(Instruction to Operations when Hazmat is transported) OR 'O'(Instruction for Pickup and Delivery) are returned. All SRN Rules for the Shipper customer number are also returned.
	* <br/>
	* <br/>Pre-condition:
	* <br/>Consignee customer number is supplied and valid.
	* <br/>
	* <br/>Post-Condition:
	* <br/>List of all billing remark records for the consignee customer number where the remarkTypeCd is 'C' OR 'H'(Instruction to Operations when Hazmat is transported) OR 'O'(Instruction for Pickup and Delivery) are returned. All SRN Rules for the Consignee customer number are also returned.
	* <br/>
	* <br/>Pre-condition:
	* <br/>Bill-To customer number is supplied and valid.
	* <br/>
	* <br/>Post-Condition:
	* <br/>List of all billing remark records for the bill-to customer number where the remarkTypeCd is 'B' are returned.  All SRN Rules for the bill-to customer number are also returned.
	*/
	public listCustCommonBols(
							   queryParams: ListCustCommonBolsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListCustCommonBolsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accounts/common-bols'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation creates an SRN rule for a given customer number.
	* <br/>
	* <br/>Pre-condition(s):
	* <br/>  1. Customer number given is valid
	* <br/>
	* <br/>Post-condition(s):
	* <br/>  1. An SRN rule has been created for the given customer number
	* <br/>
	*/
	public createSRNRules(request: CreateSRNRulesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateSRNRulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/srn-rules'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation updates an SRN rule for a given SRN Rule Instance ID.
	* <br/>
	* <br/>Pre-condition(s):
	* <br/>  1. SRN Rule Instance ID given is valid
	* <br/>
	* <br/>Post-condition(s):
	* <br/>  1. An SRN rule has been updated for the given SRN Rule Instance ID
	* <br/>
	*/
	public updateSRNRules(request: UpdateSRNRulesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateSRNRulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/srn-rules/{srnRuleInstId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation deletes an SRN rule for a given SRN Rule Instance ID.
	* <br/>
	* <br/>Pre-condition(s):
	* <br/>  1. SRN Rule Instance ID given is valid
	* <br/>
	* <br/>Post-condition(s):
	* <br/>  1. An SRN rule has been deleted for the given SRN Rule Instance ID
	* <br/>
	*/
	public deleteSRNRules(
							   pathParams: DeleteSRNRulesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteSRNRulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/srn-rules/{srnRuleInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Lists SRN rules for a given customer or group.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>None
	* <br/>   
	*/
	public listSRNRules(
							   queryParams: ListSRNRulesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSRNRulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/srn-rules'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns details for one SRN Rule for a given srnRuleInstId.
	* <br/>Pre-Condition:  srnRuleInstId is supplied and is valid.
	* <br/>Post-Condition:  Matching SRN Rule details are returned for the input Id.
	*/
	public getSRNRule(
							   pathParams: GetSRNRulePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSRNRuleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/srn-rules/{srnRuleInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation creates a billing remark record for a given customer number and remark type cd.
	* <br/>
	* <br/>Pre-condition(s):
	* <br/>1. Customer number and remark type cd given are valid.
	* <br/>
	* <br/>Post-condition(s):
	* <br/>1. If the customer number and remark type cd are valid, a billing remarks record is created for the given customer number.
	* <br/>2. If not valid, an appropriate error message is returned.
	*/
	public createBillingRemarks(request: CreateBillingRemarksRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateBillingRemarksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billing-remarks'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation updates a billing remarks record for a given customer number and remark type cd.
	* <br/>
	* <br/>Pre-condition(s):
	* <br/>1. Customer number and Remark type cd are supplied and valid.
	* <br/>
	* <br/>Post-condition(s):
	* <br/>1. If the customer number and remark type cd are valid and the billing remark exists, the record is updated.
	* <br/>2. If not valid, an appropriate error message is returned.
	*/
	public updateBillingRemarks(request: UpdateBillingRemarksRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateBillingRemarksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billing-remarks/{custNbr}/{remarkTypeCd}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation deletes a billing remark record for a given customer number and remark type cd.
	* <br/>
	* <br/>Pre-condition(s):
	* <br/>1. The customer number and remark type code provided are valid and the billing remark record exists.
	* <br/>
	* <br/>Post-condition(s):
	* <br/>1. If the customer number and the remark type cd are valid and the billing remark record exists, it is deleted.
	* <br/>2. If not valid, an appropriate error message is returned.
	*/
	public deleteBillingRemarks(
							   pathParams: DeleteBillingRemarksPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billing-remarks/{custNbr}/{remarkTypeCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation lists billing remarks records for a given customer number.
	* <br/>
	* <br/>Pre-condition(s):
	* <br/>1. The customer number provided is valid.
	* <br/>
	* <br/>Post-condition(s):
	* <br/>1. If the customer number is valid, a list of billing remarks records will be returned.
	* <br/>2. If not valid, an appropriate error message is returned.
	*/
	public listBillingRemarks(
							   pathParams: ListBillingRemarksPath,
							   queryParams: ListBillingRemarksQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListBillingRemarksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billing-remarks/{custNbr}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a billing remark record for a given customer number and remark type cd.
	* <br/>
	* <br/>Pre-condition(s):
	* <br/>1. Customer number and Remark type cd are supplied and valid.
	* <br/>
	* <br/>Post-condition(s):
	* <br/>1. If the customer number and remark type cd are valid and the billing remark exists, the record is returned.
	* <br/>2. If not valid, an appropriate error message is returned.
	*/
	public getBillingRemark(
							   pathParams: GetBillingRemarkPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetBillingRemarkResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/billing-remarks/{custNbr}/{remarkTypeCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation upserts multiple billing remarks through a batch job.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The customer number and remark type code provided are valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the customer number and remark type codes provided are valid and the billing remark does not exist, a billing remarks record is created for the given customer number.
	* <br/>2. If the customer number and remark type code provided are valid and the billing remark exists, the record is updated.
	* <br/>3. If they are not valid, an appropriate error message is returned.
	*/
	public upsertMultipleBillingRemarks(request: UpsertMultipleBillingRemarksRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertMultipleBillingRemarksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/multiple-billing-remarks'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(CustomerApiService.CustomerApiEndpoint);
	}
}
