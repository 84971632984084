import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	XrtSearchRqst,
	XrtSearchResp,
	XrtBolSearchRqst,
	XrtBolSearchResp,
	XrtClaimSearchRqst,
	XrtClaimSearchResp,
	XrtClaimExternalSearchRqst,
	XrtClaimExternalSearchResp,
	XrtImageViewerSearchRqst,
	XrtImageViewerSearchResp,
	XrtClaimPartySearchRqst,
	XrtClaimPartySearchResp,
	XrtCustomerSearchRqst,
	XrtCustomerSearchResp,
	XrtDisputePartySearchRqst,
	XrtDisputePartySearchResp,
	XrtDisputeSearchRqst,
	XrtDisputeSearchResp,
	XrtHazmatRefSearchRqst,
	XrtHazmatRefSearchResp,
	XrtNmfcItemSearchRqst,
	XrtNmfcItemSearchResp,
	XrtOverageImageSearchRqst,
	XrtOverageImageSearchResp,
	XrtPackagingBulkCdSearchRqst,
	XrtPackagingBulkCdSearchResp,
	XrtShipmentSearchRqst,
	XrtShipmentSearchResp
} from './api-elasticsearch';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class ElasticSearchApiService extends BaseService {
	private static ElasticSearchApiEndpoint = 'elasticsearchApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Search for a record from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - Search for the record in Elastic Search and return the result.
	*/
	public xrtSearch(request: XrtSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search BOL records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of BOL that met the search criteria
	*/
	public xrtBolSearch(request: XrtBolSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtBolSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/bols'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search Claim records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of Claims that met the search criteria
	*/
	public xrtClaimSearch(request: XrtClaimSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtClaimSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/claims'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search External Claim records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of External Claims that met the search criteria
	*/
	public xrtClaimExternalSearch(request: XrtClaimExternalSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtClaimExternalSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/claims/external'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search ImageViewer records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of ImageViewer records that met the search criteria
	*/
	public xrtImageViewerSearch(request: XrtImageViewerSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtImageViewerSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/image-viewers'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search Claim Party records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of Claim Parties that met the search criteria
	*/
	public xrtClaimPartySearch(request: XrtClaimPartySearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtClaimPartySearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/claims/parties'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search Customer records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of Customers that met the search criteria
	*/
	public xrtCustomerSearch(request: XrtCustomerSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtCustomerSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/customers'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search Dispute Parties records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of Dispute Parties that met the search criteria
	*/
	public xrtDisputePartySearch(request: XrtDisputePartySearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtDisputePartySearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/dispute/parties'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search Dispute records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of Disputes that met the search criteria
	*/
	public xrtDisputeSearch(request: XrtDisputeSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtDisputeSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/disputes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search HazmatRef records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of HazmatRefs that met the search criteria
	*/
	public xrtHazmatRefSearch(request: XrtHazmatRefSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtHazmatRefSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/hazmat-refs'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search NmfcItem records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of NmfcItems that met the search criteria
	*/
	public xrtNmfcItemSearch(request: XrtNmfcItemSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtNmfcItemSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/nmfc-items'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search Overage Image tag records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of Overage Images that met the search criteria
	*/
	public xrtOverageImageSearch(request: XrtOverageImageSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtOverageImageSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/overage-images/tags'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search PackagingBulkCd records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of PackagingBulkCds that met the search criteria
	*/
	public xrtPackagingBulkCdSearch(request: XrtPackagingBulkCdSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtPackagingBulkCdSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/packaging-bulk-codes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search Shipment records from Elastic Search using XRT framework
	* <br/>Pre-condition - provide search criteria.
	* <br/>Post-condition - List of Shipments that met the search criteria
	*/
	public xrtShipmentSearch(request: XrtShipmentSearchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<XrtShipmentSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/xrt-search/shipments'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(ElasticSearchApiService.ElasticSearchApiEndpoint);
	}
}
