/**
 * Invoice API
 * Version: 1.0
 * Build: 1.0-1.0.0.6
 */

import {
	ListInfo,
	DataValidationError,
	AuditInfo,
	MonetaryAmount,
	SupplementalReference,
	ReferenceNumber,
	Address,
	AccessorialUnitOfMeasureCd,
	ActionCd,
	AdvanceBeyondTypeCd,
	BillClassCd,
	BillStatusCd,
	BillToRelationCd,
	BillTypeCd,
	ChargeToCd,
	CommodityClassCd,
	CommodityPackageCd,
	CurrencyCd,
	CustomerTypeCd,
	DeliveryQualifierCd,
	EdiSlashPoundCd,
	HazmatSourceCd,
	InvPaymentChrgToCd,
	InvoiceAccuracyConstraintCd,
	InvoiceCurrencyCd,
	InvoiceDetailDataTypeCd,
	InvoiceEvalOperCd,
	InvoiceHoldReasonCd,
	InvoiceModeCd,
	InvoicePostDeliveryStatusCd,
	InvoicePreferenceCd,
	InvoicePreferenceConstraintCd,
	InvoicePresentationFormatCd,
	InvoiceRequirementCategoryCd,
	InvoiceRequirementTypeCd,
	InvoiceTransmitStatusCd,
	InvoiceTypeCd,
	LineItemChargeToCd,
	MatchedPartySourceCd,
	MatchedPartyStatusCd,
	MatchedPartyTypeCd,
	MiscLineItemCd,
	MiscLineItemPaymentMethodCd,
	MovementStatusCd,
	NetExplosiveMassUomCd,
	RadioactivityUomCd,
	RatingCurrencyCd,
	ServiceStatusCd,
	ShipmentCreditStatusCd,
	ShipmentRemarkTypeCd
} from '@xpo-ltl/sdk-common';

/**
 * This is a request to create new invoice accuracy requirement and constraints.
 */
export class CreateInvoiceAccuracyRequirementRqst {
	/**
	 * The invoice accuracy requirement.
	 * Required: true
	 * Array: false
	 */
	accuracyReq:AccuracyReq = undefined;
}
export class CreateInvoiceAccuracyRequirementResp {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceAccuracyReqInstId:number = undefined;
}
/**
 * Path parameter used to delete invoice accuracy requirement and constraints.
 */
export class DeleteInvoiceAccuracyRequirementPath {
	/**
	 * The unique identifier for the invoice accuracy requirement.
	 * Required: true
	 * Array: false
	 */
	invoiceAccuracyReqInstId:number = undefined;
}
/**
 * Path parameter used to retrieve invoice accuracy requirement and constraints.
 */
export class GetInvoiceAccuracyRequirementPath {
	/**
	 * Unique identifier for the invoice accuracty requirement.
	 * Required: true
	 * Array: false
	 */
	invoiceAccuracyReqInstId:number = undefined;
}
/**
 * This is a response to retrieve invoice accuracy requirement and constraints.
 */
export class GetInvoiceAccuracyRequirementResp {
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceAccuracyRequirement:AccuracyReq = undefined;
}
/**
 * Path parameter used to update an invoice accuracy requirement and constraints.
 */
export class UpdateInvoiceAccuracyRequirementPath {
	/**
	 * The invoice accuracy requirement.
	 * Required: true
	 * Array: false
	 */
	accuracyReq:AccuracyReq = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	invoiceAccuracyReqInstId:number = undefined;
}
/**
 * This is a request to update an invoice accuracy requirement and constraints.
 */
export class UpdateInvoiceAccuracyRequirementRqst {
	/**
	 * The invoice accuracy requirement.
	 * Required: true
	 * Array: false
	 */
	accuracyReq:AccuracyReq = undefined;
}
export class UpdateInvoiceAccuracyRequirementResp {
	/**
	 * The invoice accuracy requirement.
	 * Required: false
	 * Array: false
	 */
	accuracyReq:AccuracyReq = undefined;
}
/**
 * Query parameters used to list invoice accuracy requirements and constraints.
 */
export class ListInvoiceAccuracyRequirementsQuery {
	/**
	 * Unique identifier for the customer for which we are retrieving invoice accuracy requirements.
	 * Required: false
	 * Array: false
	 */
	custNbr:number = undefined;
	/**
	 * The customer group to which the invoice accuracy requirement belongs.
	 * Required: false
	 * Array: false
	 */
	customerGroupInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requirementCategoryCd:InvoiceRequirementCategoryCd = undefined;
	/**
	 * The customer group to which the invoice accuracy requirement belongs.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * This is a request to update an invoice accuracy requirement and constraints.
 */
export class ListInvoiceAccuracyRequirementsResp {
	/**
	 * The invoice accuracy requirement.
	 * Required: false
	 * Array: true
	 */
	accuracyReq:AccuracyReq[] = undefined;
	/**
	 * The invoice accuracy requirement.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * This is a request to update an invoice accuracy requirement and constraints.
 */
export class ListInvoiceAccuracyConstraintCodesRqst {
}
/**
 * This is a request to update an invoice accuracy requirement and constraints.
 */
export class ListInvoiceAccuracyConstraintCodesResp {
	/**
	 * The list of invoice accuracy constraint codes.
	 * Required: false
	 * Array: true
	 */
	accuracyReqConstraintReqRef:AccuracyReqConstraintRef[] = undefined;
	/**
	 * The list of invoice accuracy constraint codes.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * This is a request to perform First Invoice Accuracy rules.
 */
export class ExecutePreRatingInvoiceAccuracyRulesRqst {
	/**
	 * Information about Shipment Rating Information to execute Invoice Accuracy Rules.
	 * Required: false
	 * Array: false
	 */
	shipmentRating:ShipmentRating = undefined;
}
/**
 * This is a request to update an invoice accuracy requirement and constraints.
 */
export class ExecutePreRatingInvoiceAccuracyRulesResp {
	/**
	 * Details about the results of the rules performed for a customer.
	 * Required: false
	 * Array: true
	 */
	ruleResults:CustomerRuleResult[] = undefined;
}
/**
 * This is a request to perform First Invoice Accuracy rules.
 */
export class ExecutePostRatingInvoiceAccuracyRulesRqst {
	/**
	 * Information about Shipment Rating Information to execute Invoice Accuracy Rules.
	 * Required: false
	 * Array: false
	 */
	shipmentRating:ShipmentRating = undefined;
}
/**
 * This is a request to update an invoice accuracy requirement and constraints.
 */
export class ExecutePostRatingInvoiceAccuracyRulesResp {
	/**
	 * Details about the results of the rules performed for a customer.
	 * Required: false
	 * Array: true
	 */
	ruleResults:CustomerRuleResult[] = undefined;
}
/**
 * Query paraameters used to validate that the Shipment has all the SRNs created based on the parties on the shipment.
 */
export class ValidateSRNQuery {
	/**
	 * business key for shipment
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * pkupDate with proNbr uniquely identifies a shipment
	 * Required: false
	 * Array: false
	 */
	pkupDate:Date = undefined;
	/**
	 * database key of shipment
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:string = undefined;
}
export class ValidateSRNResp {
	/**
	 * true - if SRNs are valid.
	 * false - if SRN not found or invalid
	 * Required: false
	 * Array: false
	 */
	isValid:boolean = undefined;
	/**
	 * Response message: SRN Validated or Missing SRNs
	 * Required: false
	 * Array: false
	 */
	message:string = undefined;
	/**
	 * Response message: SRN Validated or Missing SRNs
	 * Required: false
	 * Array: true
	 */
	validationError:DataValidationError[] = undefined;
}
/**
 * Request message to generate the Invoice PDF and archive to DMS
 */
export class ArchiveInvoicePdfRqst {
	/**
	 * Surrogate key. Surrogate key is required because rows that are for non-both bill can be identified by SHP_INST_ID+VERSION_NBR, while rows that are for Both bill are be identified by SHP_INST_ID+charge_code+VERSION_NBR
	 * Required: true
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
	/**
	 * Business identifier for the shipment.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * The unique identifier for the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The person who made the request.
	 * Required: false
	 * Array: false
	 */
	requestor:string = undefined;
	/**
	 * The pickup date associated with the invoice.
	 * Required: false
	 * Array: false
	 */
	pickupDt:Date = undefined;
}
export class ArchiveInvoicePdfResp {
	/**
	 * DMS Document Id
	 * Required: false
	 * Array: false
	 */
	dmsInvoiceImageUrl:string = undefined;
}
/**
 * Request message to insert Invoice Hold record
 */
export class CreateInvoiceHoldRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	invoiceHold:InvoiceHold = undefined;
}
export class CreateInvoiceHoldResp {
	/**
	 * Required: true
	 * Array: false
	 */
	holdInvoicing:HoldInvoicing = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	dataValidationError:DataValidationError[] = undefined;
}
export class ExecutePrefChangeCreateInvoiceRqst {
	/**
	 * This is mad_cd corresponding to sent_to_party_cis_nbr.
	 * Required: false
	 * Array: false
	 */
	sentToPartyCisMadCd:string = undefined;
	/**
	 * Customer that received the invoice. 
	 * If billto is not recorded in the SHM_AS_ENTD_CUST by bill entry/correction, then the SENT TO PARTY is Shipper CIS# for prepaid and Consignee CIS# for Collect
	 * In many cases Billto CIS# is recorded in SHM_AS_ENTD_CUST and is the SENT_TO_PARTY. However, if the Bill2 in the SHM has CIS invc instr (CIS_INVC_TYPE) then it is bill2 to bill2 situation and the bill2 for the bill2 is used as the Sent to Party.
	 * Required: false
	 * Array: false
	 */
	sentToPartyCisNbr:number = undefined;
}
export class ExecutePrefChangeCreateInvoiceResp {
	/**
	 * Count of Invoices that were created
	 * Required: false
	 * Array: false
	 */
	invoiceCount:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	invoiceShipment:InvoiceShipmentBasic[] = undefined;
}
/**
 * Request message to create Invoice data
 */
export class CreateInvoiceRqst {
	/**
	 * Set to True if there is a Pending Correction on the Shipment
	 * Required: false
	 * Array: false
	 */
	isPendingCorrection:boolean = undefined;
	/**
	 * Set to True by consuming process if the PendingCorrections check was done.
	 * Required: false
	 * Array: false
	 */
	pendingCorrChecked:boolean = undefined;
	/**
	 * Identifies the shipment that is invoiced. The value comes from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * set to true when the request is to resend the invoice
	 * Required: false
	 * Array: false
	 */
	resendInd:boolean = undefined;
	/**
	 * Valid Values - O - Original Invoice, B - Balance Due Invoice, C - Corrected Invoice
	 * Required: false
	 * Array: false
	 */
	invoiceTypeCd:InvoiceTypeCd = undefined;
	/**
	 * The Charge Code on applied payment from customer
	 * Required: false
	 * Array: false
	 */
	appliedChargeToCd:ChargeToCd = undefined;
	/**
	 * Applied payment from customer.
	 * Required: false
	 * Array: false
	 */
	appliedPaymentAmt:number = undefined;
	/**
	 * If Customer has made partial payment, then this is set to Invoice Amount minus Amount Credited. Generally Amount Credited is same as Amount Paid by the customer.
	 * Required: false
	 * Array: false
	 */
	invoiceBalanceAmount:number = undefined;
	/**
	 * Set to true if the user slashed to EDI. Else set to false
	 * Required: false
	 * Array: false
	 */
	isSlashToEdi:boolean = undefined;
	/**
	 * Set to true if the user pounded to Paper. Else false
	 * Required: false
	 * Array: false
	 */
	isPoundToPaper:boolean = undefined;
	/**
	 * Depending on the delivery mode, this can have one of the following.
	 * EMAIL= Email Address 
	 * FTP = ftp_routing_id given by ETS
	 * FAX = fax#
	 * EDI = Sent_to_party_cis_mad_Cd (mad cd is used by EDI not CIS#) If not matched then Name/addr
	 * Paper=Sent_to_party_cis_mad_Cd  (users query by specifying mad code). If not matched then Name/addr
	 * Required: false
	 * Array: true
	 */
	sendTo:string[] = undefined;
	/**
	 * Valid Values
	 * INDIVIDUAL   Single invoice. applies to Paper, EDI
	 * STATEMENT    Presentation application combinies multiple invoices, applies to Paper
	 * MANIFEST     Presentation application combinies multiple invoices, applies to EDI
	 * following apply to EMAIL, FTP 
	 * CSV            
	 * CSV-ACC      CSV with Accessorial
	 * NO-ATTM      No attachments
	 * PDF          Invoice image as PDF
	 * PDF-IMAGE    Invoice image as PDF with attachment images
	 * XML          Invoice data required with XML doc attachment
	 * Required: false
	 * Array: false
	 */
	presentationFormatCd:InvoicePresentationFormatCd = undefined;
	/**
	 * PAPER
	 * EDI
	 * EMAI
	 * FTP
	 * SELF-PAID
	 * Required: false
	 * Array: false
	 */
	invoiceModeCd:InvoiceModeCd = undefined;
	/**
	 * Should be populated if resending one of the both bill Invoice
	 * Valid values are: Collect,
	 * Prepaid
	 * Required: false
	 * Array: false
	 */
	resendChargeToCd:ChargeToCd = undefined;
	/**
	 * The ID of the employee who made the resend invoice request.
	 * Required: false
	 * Array: false
	 */
	resendRequestorId:string = undefined;
}
export class CreateInvoiceResp {
	/**
	 * Required: false
	 * Array: true
	 */
	invoiceHdr:InvoiceHdrTransmitStatus[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	message:string = undefined;
}
/**
 * Path parameter used to get the latest invoice data for a given shipment.
 */
export class GetInvoiceDetailPath {
	/**
	 * Identifies the shipment that is invoiced. The value comes from SHM_SHIPMENT
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:number = undefined;
}
/**
 * Query parameters used to get the latest invoice data for a given shipment.
 */
export class GetInvoiceDetailQuery {
	/**
	 * If type = clob then only returns the clob data depending upon the input format. If no format specified then returns JSON payload.
	 * If type is not input then returns the Invoice details from INV tables
	 * Required: false
	 * Array: false
	 */
	dataType:string = undefined;
	/**
	 * If charge to Cd is Ppd then only the Ppd Invoice for Shipment is returned. If charge to cd is Coll then only the Coll Invoice for Shipment is returned.
	 * Required: true
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
}
export class GetInvoiceDetailResp {
	/**
	 * Invocie Details
	 * Required: false
	 * Array: false
	 */
	invoice:Invoice = undefined;
	/**
	 * Invoice CLOB data
	 * Required: false
	 * Array: false
	 */
	invoiceClobData:InvoiceDetailData = undefined;
}
/**
 * Request message to get the latest Invoice data for a given shipment
 */
export class ListInvoiceDetailByProPath {
	/**
	 * PRO# of the shipment invoiced.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
}
export class ListInvoiceDetailByProResp {
	/**
	 * Invocie Details
	 * Required: false
	 * Array: true
	 */
	invoice:Invoice[] = undefined;
}
/**
 * Request message to update the Invoice Delivery Status
 */
export class UpdateInvoiceDeliveryRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceDelivery:InvoiceDelivery = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Should be populated if resending one of the both bill Invoice
	 * Valid values are: Collect,
	 * Prepaid
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
}
export class UpdateInvoiceDeliveryResp {
	/**
	 * Required: false
	 * Array: true
	 */
	invoiceDelivery:InvoiceDelivery[] = undefined;
}
export class UpdateInvoicePostDeliveryStatusLogPath {
	/**
	 * Key of the parent Invoice Delivery table
	 * Required: true
	 * Array: false
	 */
	invoiceDeliveryId:number = undefined;
}
export class UpdateInvoicePostDeliveryStatusLogRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	invoicePostDlvryStatusLog:InvoiceDeliveryStatus = undefined;
	/**
	 * Value denormed from B2B_TRS table that identifies whether the invoice went through the parallel stream (Y), or through the actual production stream (N).
	 * Required: false
	 * Array: false
	 */
	deliveryModeParallelInd:boolean = undefined;
}
export class UpdateInvoiceTransmissionDateRqst {
	/**
	 * Unique identifier of the shipment to update the invoice delivery status
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Date when Invoice should be sent. Applies only if Invoice status is T-ready for Transmission. In most cases, it is the same date when status changes to T. In thoses cases where customer wants invoices on certain days, such as Tuesday, this can be a future date.
	 * Required: false
	 * Array: false
	 */
	scheduledTransmissionDate:Date = undefined;
}
export class UpdateInvoiceTransmissionDateResp {
	/**
	 * Required: true
	 * Array: false
	 */
	invoiceHeader:InvoiceDataHeader = undefined;
}
/**
 * Request message to update the Invoice delivery status
 */
export class UpdateInvoiceTransmitStatusRqst {
	/**
	 * Unique identifier of the shipment to update the invoice delivery status
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Final Delivery date of the Shipment
	 * Required: false
	 * Array: false
	 */
	finalDeliveryDate:Date = undefined;
	/**
	 * Set to True when the DR Scanned event was received. Else False
	 * Required: false
	 * Array: false
	 */
	isDRScanned:boolean = undefined;
	/**
	 * Set to True if there is a Pending Correction on the Shipment
	 * Required: false
	 * Array: false
	 */
	isPendingCorrection:boolean = undefined;
	/**
	 * Set to True by consuming process if the PendingCorrections check was done.
	 * Required: false
	 * Array: false
	 */
	pendingCorrChecked:boolean = undefined;
	/**
	 * Set to true when the shipment is delivered AllShort
	 * Required: false
	 * Array: false
	 */
	isAllShort:boolean = undefined;
	/**
	 * Set to true when the shipment is delivered Part Short
	 * Required: false
	 * Array: false
	 */
	isPartShort:boolean = undefined;
	/**
	 * PRO# of the shipment invoiced.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Guaranteed Indicator
	 * 
	 * Desc : Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 * 
	 * Values : Y/N
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
}
export class UpdateInvoiceTransmitStatusResp {
	/**
	 * Required: false
	 * Array: true
	 */
	invoiceHdr:InvoiceHdrTransmitStatus[] = undefined;
}
/**
 * Invoked by the Flux Scheduler to update the Invoice transmit status of Invoice data where transmit status code = N
 */
export class BulkUpdateInvoiceTransmitStatusRqst {
}
export class BulkUpdateInvoiceTransmitStatusResp {
	/**
	 * Required: false
	 * Array: true
	 */
	invoiceTransmitStatus:InvoiceHdrTransmitStatus[] = undefined;
}
/**
 * Invoked by the Flux Scheduler to update the Invoice transmit status of Invoice data where transmit status code = N
 */
export class ExecuteReadyForTransmissionRulesPath {
	/**
	 * Surrogate key. Surrogate key is required because rows that are for non-both bill can be identified by SHP_INST_ID+VERSION_NBR, while rows that are for Both bill are be identified by SHP_INST_ID+charge_code+VERSION_NBR
	 * Required: true
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
}
/**
 * Invoked by the Flux Scheduler to update the Invoice transmit status of Invoice data where transmit status code = N
 */
export class ExecuteReadyForTransmissionRulesRqst {
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
export class ExecuteReadyForTransmissionRulesResp {
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceTransmitStatus:InvoiceHdrTransmitStatus = undefined;
}
export class ReleaseHeldInvoicesRqst {
	/**
	 * list of shipments
	 * Required: true
	 * Array: true
	 */
	shipment:InvoiceHdrIdBasic[] = undefined;
	/**
	 * Person(manual release), App ID (systematically released, e.g. 7day rule) who released the hold. 
	 * This is in lieu of the field LST_UPDT_BY_ID
	 * Required: false
	 * Array: false
	 */
	releaseById:string = undefined;
}
export class ReleaseHeldInvoicesResp {
	/**
	 * Required: false
	 * Array: true
	 */
	shipment:InvoiceHdrTransmitStatus[] = undefined;
}
/**
 * Request message to create the invoice data in pdf, csv, or xml format
 */
export class CreateInvoiceDataExtensionRqst {
	/**
	 * Unique identifier of the Invoice data header record
	 * Required: true
	 * Array: false
	 */
	invoiceDataHdrId:string = undefined;
	/**
	 * Various
	 * types are:
	 * INV = Extension data stores PDF
	 * image applies where Delivery mode is
	 * PDF, PDF with Images, and for Reprint
	 * request
	 * CSV = Extension data stores CSV,
	 * applies where Delivery mode is CSV
	 * and CSV with Accessorial
	 * EIPXML = Extension data stores XML,
	 * applies where Delivery mode is EIP
	 * (Email,Fax,FTP) w presentation=XML
	 * E-P-XML=Extension data stores XML,
	 * applies where Delivery mode is either
	 * EDI or PAPER. This XML format is
	 * slightly different from the one used with
	 * EMAIL/XML
	 * Required: true
	 * Array: false
	 */
	extensionTypeCd:string = undefined;
	/**
	 * binary image data for pdf
	 * Required: false
	 * Array: false
	 */
	binaryImageData:string = undefined;
	/**
	 * text image data for CSV and XML
	 * Required: false
	 * Array: false
	 */
	textImageData:string = undefined;
}
export class CreateInvoiceDataExtensionResp {
	/**
	 * Unique identifier of the Invoice data header record
	 * Required: false
	 * Array: false
	 */
	invoiceDataHdrId:string = undefined;
	/**
	 * Unique identifier of the Invoice data Extension seq nbr
	 * Required: false
	 * Array: false
	 */
	invoiceDataExtnSeqNbr:number = undefined;
}
/**
 * Request to resend invoices.
 */
export class ResendInvoicesRqst {
	/**
	 * Set to true if the user slashed to EDI. Else set to false
	 * Required: false
	 * Array: false
	 */
	isSlashToEdi:boolean = undefined;
	/**
	 * Set to true if the user pounded to Paper. Else false
	 * Required: false
	 * Array: false
	 */
	isPoundToPaper:boolean = undefined;
	/**
	 * ID from SHM_SHIPMENT
	 * Required: true
	 * Array: true
	 */
	shipment:ShipmentBasic[] = undefined;
	/**
	 * Depending on the delivery mode, this can have one of the following.
	 * EMAIL= Email Address 
	 * FTP = ftp_routing_id given by ETS
	 * FAX = fax#
	 * EDI = Sent_to_party_cis_mad_Cd (mad cd is used by EDI not CIS#) If not matched then Name/addr
	 * Paper=Sent_to_party_cis_mad_Cd  (users query by specifying mad code). If not matched then Name/addr
	 * Required: false
	 * Array: true
	 */
	sendTo:string[] = undefined;
	/**
	 * PAPER
	 * EDI
	 * EMAI
	 * FTP
	 * SELF-PAID
	 * Required: false
	 * Array: false
	 */
	invoiceModeCd:InvoiceModeCd = undefined;
	/**
	 * Valid Values
	 * INDIVIDUAL   Single invoice. applies to Paper, EDI
	 * STATEMENT    Presentation application combinies multiple invoices, applies to Paper
	 * MANIFEST     Presentation application combinies multiple invoices, applies to EDI
	 * following apply to EMAIL, FTP 
	 * CSV            
	 * CSV-ACC      CSV with Accessorial
	 * NO-ATTM      No attachments
	 * PDF          Invoice image as PDF
	 * PDF-IMAGE    Invoice image as PDF with attachment images
	 * XML          Invoice data required with XML doc attachment
	 * Required: false
	 * Array: false
	 */
	presentationFormatCd:InvoicePresentationFormatCd = undefined;
	/**
	 * The ID of the employee who made the request to resend the invoice(s).
	 * Required: false
	 * Array: false
	 */
	resendRequestorId:string = undefined;
}
/**
 * Response to the request to resend invoices.
 */
export class ResendInvoicesResp {
	/**
	 * List of Invoice Header records created for Reprint request
	 * Required: false
	 * Array: true
	 */
	invoiceHdrList:InvoiceHdrTransmitStatus[] = undefined;
}
export class SubmitResendInvoicesRqst {
	/**
	 * Set to true if the user slashed to EDI. Else set to false
	 * Required: false
	 * Array: false
	 */
	isSlashToEdi:boolean = undefined;
	/**
	 * Set to true if the user pounded to Paper. Else false
	 * Required: false
	 * Array: false
	 */
	isPoundToPaper:boolean = undefined;
	/**
	 * List of Shipments
	 * Required: true
	 * Array: true
	 */
	shipment:ShipmentBasic[] = undefined;
	/**
	 * Depending on the delivery mode, this can have one of the following.
	 * EMAIL= Email Address 
	 * FTP = ftp_routing_id given by ETS
	 * FAX = fax#
	 * EDI = Sent_to_party_cis_mad_Cd (mad cd is used by EDI not CIS#) If not matched then Name/addr
	 * Paper=Sent_to_party_cis_mad_Cd  (users query by specifying mad code). If not matched then Name/addr
	 * Required: false
	 * Array: true
	 */
	sendTo:string[] = undefined;
	/**
	 * PAPER
	 * EDI
	 * EMAI
	 * FTP
	 * SELF-PAID
	 * Required: false
	 * Array: false
	 */
	invoiceModeCd:InvoiceModeCd = undefined;
	/**
	 * Valid Values
	 * INDIVIDUAL   Single invoice. applies to Paper, EDI
	 * STATEMENT    Presentation application combinies multiple invoices, applies to Paper
	 * MANIFEST     Presentation application combinies multiple invoices, applies to EDI
	 * following apply to EMAIL, FTP 
	 * CSV            
	 * CSV-ACC      CSV with Accessorial
	 * NO-ATTM      No attachments
	 * PDF          Invoice image as PDF
	 * PDF-IMAGE    Invoice image as PDF with attachment images
	 * XML          Invoice data required with XML doc attachment
	 * Required: false
	 * Array: false
	 */
	presentationFormatCd:InvoicePresentationFormatCd = undefined;
	/**
	 * If invoiceModeCd = FAX then this will capture the Attention line
	 * Required: false
	 * Array: false
	 */
	faxAttentionLine:string = undefined;
	/**
	 * mailing Address Override on Resend
	 * Required: false
	 * Array: false
	 */
	mailingAddress:InvoiceShipmentParty = undefined;
	/**
	 * Email Id of the requester
	 * Required: false
	 * Array: false
	 */
	requesterEmailId:string = undefined;
	/**
	 * Employee ID of the person who made the invoice resend request
	 * Required: false
	 * Array: false
	 */
	requesterEmployeeId:string = undefined;
}
export class SubmitResendInvoicesResp {
	/**
	 * This is the correlation id of the Request submitted
	 * Required: false
	 * Array: false
	 */
	requestId:string = undefined;
}
/**
 * Path parameter used to update or insert Applied Payments to a Shipment Invoice.
 */
export class UpsertInvoiceAppliedPaymentPath {
	/**
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:number = undefined;
}
/**
 * Request message to update or insert Applied Payments to a Shipment Invoice.
 */
export class UpsertInvoiceAppliedPaymentRqst {
	/**
	 * If Shipment is not Both Bill then this is the Payment amount for the Invoice.
	 * If Shipment is both bill then this is the Payment amount for the Ppd Invoice of the Shipment
	 * Required: false
	 * Array: false
	 */
	payment:MonetaryAmount = undefined;
	/**
	 * Prepaid
	 * Collect
	 * Both Prepaid
	 * Both Collect
	 * Required: false
	 * Array: false
	 */
	chargeToCd:InvPaymentChrgToCd = undefined;
	/**
	 * Set to true if the Invoice is Open in NS. Else false
	 * Required: false
	 * Array: false
	 */
	nsOpenInvoiceInd:boolean = undefined;
}
export class UpsertInvoiceAppliedPaymentResp {
	/**
	 * Required: false
	 * Array: true
	 */
	invoiceHdr:InvoiceHdrTransmitStatus[] = undefined;
}
/**
 * Query parameters used to retrieve a list of invoices that are ready to be transmitted.
 */
export class ListReadyForTransmitInvoicesQuery {
	/**
	 * Delivery Mode is one of the following
	 * EDI, 
	 * PAPER, 
	 * EMAIL (EIP), 
	 * FAX (EIP),
	 * FTP (EIP), 
	 * SELF-PAID(not sent).
	 * Required: false
	 * Array: false
	 */
	deliveryModeCd:InvoiceModeCd = undefined;
	/**
	 * Depending on the delivery mode, this can have one of the following.
	 * EMAIL= Email Address 
	 * FTP = ftp_routing_id given by ETS
	 * FAX = fax#
	 * EDI = Sent_to_party_cis_mad_Cd (mad cd is used by EDI not CIS#) If not matched then Name/addr
	 * Paper=Sent_to_party_cis_mad_Cd  (users query by specifying mad code). If not matched then Name/addr
	 * Required: false
	 * Array: false
	 */
	sendTo:string = undefined;
	/**
	 * Pagination information to retrieve list
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
	/**
	 * PRO Last digit number can be between 0 and 6
	 * Required: false
	 * Array: false
	 */
	proLastDigit:number = undefined;
	/**
	 * '
	 * Valid Values
	 * INDIVIDUAL   Single invoice. applies to Paper, EDI
	 * STATEMENT    Presentation application combinies multiple invoices, applies to Paper
	 * MANIFEST     Presentation application combinies multiple invoices, applies to EDI
	 * following apply to EMAIL, FTP 
	 * CSV            
	 * CSV-ACC      CSV with Accessorial
	 * NO-ATTM      No attachments
	 * PDF          Invoice image as PDF
	 * PDF-IMAGE    Invoice image as PDF with attachment images
	 * XML          Invoice data required with XML doc attachment'
	 * Required: false
	 * Array: false
	 */
	presentationFormatCd:InvoicePresentationFormatCd = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * Required: false
	 * Array: false
	 */
	batchId:string = undefined;
	/**
	 * start at row number for ID
	 * Required: false
	 * Array: false
	 */
	startAtId:number = undefined;
}
export class ListReadyForTransmitInvoicesResp {
	/**
	 * Required: false
	 * Array: true
	 */
	invoices:Invoice[] = undefined;
	/**
	 * Pagination Information to retrieve list
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve a list of Invoice Delivery Send To address by Delivery Mode cd
 */
export class ListInvoiceDeliverySendToByDeliveryModeQuery {
	/**
	 * Delivery Mode is one of the following
	 * EDI, 
	 * PAPER, 
	 * EMAIL (EIP), 
	 * FAX (EIP),
	 * FTP (EIP), 
	 * SELF-PAID(not sent).
	 * Required: false
	 * Array: false
	 */
	deliveryModeCd:InvoiceModeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListInvoiceDeliverySendToByDeliveryModeResp {
	/**
	 * Required: false
	 * Array: true
	 */
	invoiceSendTo:InvoiceSendTo[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Path parameter used to retrieve the Invoice CLOB details by the Invoice Header Id.
 */
export class GetInvoiceDataDetailByHeaderIdPath {
	/**
	 * Surrogate key. Surrogate key is required because rows that are for non-both bill can be identified by SHP_INST_ID+VERSION_NBR, while rows that are for Both bill are be identified by SHP_INST_ID+charge_code+VERSION_NBR
	 * Required: true
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
}
export class GetInvoiceDataDetailByHeaderIdResp {
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceDataDetail:InvoiceDataDetail = undefined;
}
export class ListInvoiceSummaryQuery {
	/**
	 * Required: true
	 * Array: true
	 */
	proNbr:string[] = undefined;
}
export class ListInvoiceSummaryResp {
	/**
	 * Required: false
	 * Array: true
	 */
	invoiceSummary:InvoiceSummary[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	messages:DataValidationError[] = undefined;
}
/**
 * Request message to download the Invoice Summary information as a CSV file
 */
export class DownloadInvoiceSummaryRqst {
	/**
	 * Identifies the shipment that is invoiced. The value comes from SHM_SHIPMENT
	 * Required: true
	 * Array: true
	 */
	shipmentInstId:number[] = undefined;
}
export class DownloadInvoiceSummaryResp {
	/**
	 * Required: false
	 * Array: true
	 */
	invoiceSummary:InvoiceSummary[] = undefined;
}
/**
 * Path parameter used to retrieve a list of invoices on hold for a given send to party MAD code.
 */
export class ListOnHoldInvoicesByCustomerPath {
	/**
	 * This is mad_cd corresponding to sent_to_party_cis_nbr.
	 * Required: true
	 * Array: false
	 */
	sentToPartyCisMadCd:string = undefined;
}
/**
 * Query parameter used to retrieve a list of invoices on hold for a given send to party MAD code.
 */
export class ListOnHoldInvoicesByCustomerQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListOnHoldInvoicesByCustomerResp {
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	invoices:InvoiceBasic[] = undefined;
}
/**
 * Path parameter used to retrieve a list of invoices previously transmitted for a given PRO number.
 */
export class ListInvoiceTransmissionHistoryPath {
	/**
	 * Required: true
	 * Array: false
	 */
	proNbr:string = undefined;
}
/**
 * Query parameter used to retrieve a list of invoices previously transmitted for a given PRO number.
 */
export class ListInvoiceTransmissionHistoryQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListInvoiceTransmissionHistoryResp {
	/**
	 * Required: false
	 * Array: true
	 */
	invoices:Invoice[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to determine the Invoice preferences for a given Shipment Data
 */
export class DetermineInvoicePreferenceRqst {
	/**
	 * Unique identifier of the Shipment for which the Invoice needs to be generated
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	invoiceInfo:InvoiceInfo = undefined;
}
export class DetermineInvoicePreferenceResp {
	/**
	 * Required: false
	 * Array: false
	 */
	invoicePreference:Preference = undefined;
}
/**
 * Request message to create an invoice preference.
 */
export class CreateInvoicePreferenceRqst {
	/**
	 * The invoice preference.
	 * Required: true
	 * Array: false
	 */
	preference:Preference = undefined;
}
export class CreateInvoicePreferenceResp {
	/**
	 * Surrogate key
	 * Required: false
	 * Array: false
	 */
	invoicePreferenceId:number = undefined;
}
/**
 * Request message to delete the invoice preference by the ID.
 */
export class DeleteInvoicePreferencePath {
	/**
	 * The unique identifier for the invoice preference.
	 * Required: true
	 * Array: false
	 */
	invoicePreferenceId:string = undefined;
}
/**
 * Query parameters used to list invoice preferences for a customer account number.
 */
export class ListInvoicePreferencesQuery {
	/**
	 * The customer to whom the invoice preference belongs.
	 * Required: true
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * Pagination information to retievet the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list invoice preferences for a customer account number.
 */
export class ListInvoicePreferencesResp {
	/**
	 * The invoice preference.
	 * Required: false
	 * Array: true
	 */
	preference:Preference[] = undefined;
}
/**
 * Path parameter used to list invoice preferences and their invoice address for a customer account number.
 */
export class ListInvoicePreferencesWithAddressPath {
	/**
	 * The customer to whom the invoice preference belongs.
	 * Required: true
	 * Array: false
	 */
	customerNbr:number = undefined;
}
/**
 * Response to the request to list invoice preferences and their invoice address for a customer account number.
 */
export class ListInvoicePreferencesWithAddressResp {
	/**
	 * List of invoice preferences and corresponding invoice address.
	 * Required: false
	 * Array: true
	 */
	invoicePreferenceAddressDetails:InvoicePreferenceAddressDetail[] = undefined;
}
/**
 * Path parameter used to update an invoice preference.
 */
export class UpdateInvoicePreferencePath {
	/**
	 * Surrogate key
	 * Required: true
	 * Array: false
	 */
	invoicePreferenceId:number = undefined;
}
/**
 * Request to update an invoice preference.
 */
export class UpdateInvoicePreferenceRqst {
	/**
	 * The invoice preference.
	 * Required: true
	 * Array: false
	 */
	preference:Preference = undefined;
}
export class UpdateInvoicePreferenceResp {
	/**
	 * The invoice preference.
	 * Required: false
	 * Array: false
	 */
	preference:Preference = undefined;
}
/**
 * Path parameter used to get invoice preference details.
 */
export class GetInvoicePreferencePath {
	/**
	 * Surrogate key
	 * Required: true
	 * Array: false
	 */
	invoicePreferenceId:number = undefined;
}
/**
 * Response to the request to retrieve invoice preference details.
 */
export class GetInvoicePreferenceResp {
	/**
	 * Invoice Preference details.
	 * Required: false
	 * Array: false
	 */
	preference:Preference = undefined;
}
/**
 * Path parameter used to retrieve invoice preference details that apply at the customer level.
 */
export class GetCustomerInvoicePreferencePath {
	/**
	 * Unique identifier for the customer
	 * Required: false
	 * Array: false
	 */
	cisCustomerNumber:number = undefined;
}
/**
 * Response to the request for Invoice preference details that apply at the customer level.
 */
export class GetCustomerInvoicePreferenceResp {
	/**
	 * Invoice preference details that apply at the customer level.
	 * Required: false
	 * Array: false
	 */
	customerInvoicePreference:CustomerInvoicePreference = undefined;
}
/**
 * Request to add/update Invoice preference details that apply at the customer level.
 */
export class UpsertCustomerInvoicePreferenceRqst {
	/**
	 * Invoice preference details that apply at the customer level.
	 * Required: false
	 * Array: false
	 */
	customerInvoicePreference:CustomerInvoicePreference = undefined;
}
/**
 * Path parameter used to delete an invoice based on the shipment instance ID provided.
 */
export class DeleteInvoicePath {
	/**
	 * The unique identifier for the shipment.
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:number = undefined;
}
/**
 * Contains information about Shipment Rating Information to execute Invoice Accuracy Rules
 */
export class ShipmentRating {
	/**
	 * Determines if the Shipment rated with Absolute Min Charge. True or False
	 * Required: false
	 * Array: false
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * CIS Account Number for the Bill To 1st party
	 * Required: false
	 * Array: false
	 */
	bill2CustNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	commodities:ShipmentCommodity[] = undefined;
	/**
	 * CIS Account Number for the Consignee party
	 * Required: false
	 * Array: false
	 */
	consigneeCustNbr:string = undefined;
	/**
	 * Discount Coupon Code that the shipment was rated with
	 * Required: false
	 * Array: false
	 */
	couponCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	eventCrteTmst:string = undefined;
	/**
	 * Rating Tariff Name used to rate the shipment
	 * Required: false
	 * Array: false
	 */
	ratingTariffId:string = undefined;
	/**
	 * CIS Account Number for the Shipper party
	 * Required: false
	 * Array: false
	 */
	shipperCustNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalPalletCnt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trueDebtorCustNbr:number = undefined;
	/**
	 * Total charges for the shipment. Includes all charges that contribute to the CTS revenue minus discount in US dollars.  Includes advance+beyond carrier charges and COD amount.
	 * Required: false
	 * Array: false
	 */
	totChargeAmt:number = undefined;
	/**
	 * Pricing Agreement Instance Id
	 * Required: false
	 * Array: false
	 */
	pricingAgreementId:number = undefined;
}
/**
 * Contains Information about the Shipment Commodity
 */
export class ShipmentCommodity {
	/**
	 * Description about the commodity being shipped
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Package code for the commodity: eg. PLT or SKD
	 * Required: false
	 * Array: false
	 */
	packageCode:CommodityPackageCd = undefined;
	/**
	 * Sequence Number of the commodity
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
}
/**
 * This records the rules that are applied to Supplementary Reference# (SR#) when Bill is entered. SR# are supplied by the customer. These are important because unless they are put on the customer Invoice (typically EDI), the customer does not pay us.
 * The rules are specified for Customer and the SR# Type. 
 * The format mask allows program to edit check the format of the entered SR# is as required by the customer.
 */
export class SuppRefRule {
	/**
	 * CIS Cust# for which the rule has been defined.
	 * Required: false
	 * Array: false
	 */
	custNbr:number = undefined;
	/**
	 * SR NBR Type is the one that goes into SHM_SR_NBR. TYP_CD. Example of most common types are: SN#,  PO# BL# BOL etc
	 * Required: false
	 * Array: false
	 */
	suppRefNbrTypeCd:string = undefined;
	/**
	 * This is the edit mask that can be used programatically to check the entered SR# on Bill entry screen. In some cases, the rule could be complex and could not be specified as an edit mask. In that case, this field will be empty. The biller will have to read the Instruction field and make sure the entered SR# is correct.
	 * @ - LETTER
	 * # - NUMBER
	 * N - ALPHANUMERIC
	 * if N is part of the string then specify it in lower case. The alpha in SR# are always to stored in uppercase. e.g. CnW## will be stored CNW01, CNW02 etc.
	 * Required: false
	 * Array: false
	 */
	format1:string = undefined;
	/**
	 * This is second optional edit mask. This is specified only when another edit mask as given in Format_1 is present. This is used when there are multiple edit masks and any one of them can be satisfied.
	 * Required: false
	 * Array: false
	 */
	format2:string = undefined;
	/**
	 * This is second optional edit mask. This is specified only when another edit mask as given in Format_2 is present. This is used when there are multiple edit masks and any one of them can be satisfied.
	 * Required: false
	 * Array: false
	 */
	format3:string = undefined;
	/**
	 * Y- Rule is mandatory, if not satisfied, the Order (bill) cannot be submitted.
	 * N- Just recommendation for user to re-check against the paper BOL
	 * Required: false
	 * Array: false
	 */
	requiredInd:boolean = undefined;
	/**
	 * Textual instructions to the bill entry person. It is displayed on the bill entry screen for the person to make sure the SR Nbr are entered correctly.
	 * Required: false
	 * Array: false
	 */
	instruction:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Unique Id for the Srn Rule.
	 * Required: false
	 * Array: false
	 */
	srnRuleInstId:number = undefined;
}
export class InvoiceInfo {
	/**
	 * Prepaid or Collect or
	 * Third Party
	 * Indicates if this applies to
	 * a prepaid or collect bill.
	 * Required: false
	 * Array: false
	 */
	billTypeCd:BillTypeCd = undefined;
	/**
	 * Type of Invoice: Original
	 * Past Due Bill
	 * Past Due Statement
	 * Corrected
	 * Balance Due
	 * Required: false
	 * Array: false
	 */
	invoiceTypeCd:InvoiceTypeCd = undefined;
	/**
	 * Customer Account Number from CIS
	 * Required: false
	 * Array: false
	 */
	cisCustomerNbr:number = undefined;
	/**
	 * Pickup and Delivery
	 * Location(P),
	 * Bill to Location (B),
	 * Corporate Customer (C),
	 * Future Customer (F),
	 * Service Recipient (R)
	 * Required: false
	 * Array: false
	 */
	customerTypeCd:CustomerTypeCd = undefined;
	/**
	 * Total weight of the Shipment in LBs
	 * Required: false
	 * Array: false
	 */
	shipmentWeight:number = undefined;
	/**
	 * Invoice Currency Code
	 * Required: false
	 * Array: false
	 */
	invoiceCurrency:string = undefined;
	/**
	 * Total Invoice Amount
	 * Required: false
	 * Array: false
	 */
	invoiceAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	isSvcRecipientInd:boolean = undefined;
}
/**
 * Contains Information about the Invoice Accuracy Rule
 */
export class InvoiceAccuracyReq {
	/**
	 * CIS Customer Type Code: Corporate(C), Pickup and Delivery (P) or Bill To (B)
	 * Required: false
	 * Array: false
	 */
	custTypeCode:string = undefined;
	/**
	 * CIS Account Instance Id
	 * Required: false
	 * Array: false
	 */
	cisAccountId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	reqName:string = undefined;
}
/**
 * Invoice preference details that apply at the customer level.
 */
export class CustomerInvoicePreference {
	/**
	 * Identifier of the appropriate customer in CIS, determined by the matching sub-routine.
	 * Required: false
	 * Array: false
	 */
	cisCustNbr:number = undefined;
	/**
	 * Specifies the nature of relationship of the customer with respect to the Shipment in question. For Shipments, a Customer can be the shipper, consignee, bill-to, requestor or contact.
	 * Required: false
	 * Array: false
	 */
	custTypeCd:string = undefined;
	/**
	 * Y - Invoice will remain in N-Not ready for transmit status. This is a permanent hold that has to be manually released.  Typically this is applied only to handful of customers.
	 * 					When this is set, HOLD_FOR_DAYS is set to 0.  N - Invoice will not be held such that it can be only manually released .
	 * Required: false
	 * Array: false
	 */
	holdInd:boolean = undefined;
	/**
	 * At the time invoice is ready for transmission the schedule date will be set to today+these many days. 
	 * When this is set, HOLD_IND (which is for manual release only) should be set to N
	 * Required: false
	 * Array: false
	 */
	holdForDays:string = undefined;
}
/**
 * Details about the results of the rules performed for a customer.
 */
export class CustomerRuleResult {
	/**
	 * Specifies the nature of relationship of the customer with respect to the Shipment in question. For Shipments, a Customer can be the shipper, consignee, bill-to, requestor or contact.
	 * Required: false
	 * Array: false
	 */
	custTypeCd:string = undefined;
	/**
	 * Identifier of the appropriate customer in CIS, determined by the matching sub-routine.
	 * Required: false
	 * Array: false
	 */
	cisCustNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requirementTypeCd:InvoiceRequirementTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requirementDescription:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Stores the business rules that can be applied to selected customers shipments
 */
export class Rule {
	/**
	 * The name of the service, or operation, to be used when executing this rule.
	 * Required: false
	 * Array: false
	 */
	serviceNm:string = undefined;
	/**
	 * A parameter that can be passed into a rule service to identify specifically the value for which the rule will search. This allows the behavior of a rule to be changed by altering the stored parameter value for the rule. It also allows the same rule service to be used for all rules of a certain type, like SR Type rules.
	 * Required: false
	 * Array: false
	 */
	ruleParm:string = undefined;
	/**
	 * The text of the message to be used when the execution of a rule results in a failure. The message text may be combined with other variable text that indicates the value of data associated with the failure.
	 * Required: false
	 * Array: false
	 */
	failedMessage:string = undefined;
	/**
	 * An additional parameter that can be passed into a rule service to be used to determine how the rule will behave. The use and behavior can be unique to every rule that uses this parameter and can contain 1 to n values as needed by the rule, as long as all the values and any delimiters can fit into the alloted length of the attribute. The behavior of a rule can be changed by altering the stored parameter value for the rule. It also allows the same rule service to be used for all rules of a certain type, like SR Type rules.
	 * Required: false
	 * Array: false
	 */
	extendedParm:string = undefined;
	/**
	 * Indicates whether the rule is classified as one which will attempt to automatically fix, or correct, data on a shipment via a direct update or a correction that is automatically applied.
	 * Required: false
	 * Array: false
	 */
	autoFixInd:boolean = undefined;
	/**
	 * Identifies the group to which a rule belongs. The groups of rules applicable to any shipment are processed in the order shown below.
	 * Required: false
	 * Array: false
	 */
	ruleGroup:string = undefined;
	/**
	 * Indicates whether subsequent rules should not be executed if this rule fails.
	 * Required: false
	 * Array: false
	 */
	stopIfRuleFailedInd:boolean = undefined;
}
/**
 * Stores the conditions under which business rules are applied to selected customers shipments.
 */
export class RuleContext {
	/**
	 * System generated unique identifier.
	 * Required: false
	 * Array: false
	 */
	instId:number = undefined;
	/**
	 * Sequential number assigned to designate the processing order for every rule context for the business rules associated with same customer group ID.
	 * Required: false
	 * Array: false
	 */
	seqNbr:number = undefined;
	/**
	 * Identifies, or filters, rule contexts to be selected for execution of rules for a shipment.
	 * 
	 * Valid Values : 
	 * 'P ' - Prepaid - the rule will be processed only for shipments with a 'prepaid' charge code
	 * 'C ' - Collect - the rule will be processed only for shipments with a 'collect' charge code
	 * 'B' - Both - the rule will be processed only for shipments with a 'both' charge code
	 * ' ' - the rule will be processed for all shipments, regardless of charge code
	 * Required: false
	 * Array: false
	 */
	applicableChargeCd:string = undefined;
	/**
	 * Indicates whether the rule execution should only record rule results for this rule context or also allow further processing based on rule results.
	 * 
	 * Values : Y - create rule results only / N - allow further processing based on rule results (e.g. create a correction request for failed rule results)
	 * Required: false
	 * Array: false
	 */
	resultOnlyInd:boolean = undefined;
}
/**
 * Stores the results of the execution of business rules that are applied to selected customer's shipments.
 */
export class RuleResult {
	/**
	 * A short description of the outcome of running a business rule for a shipment.
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
	/**
	 * System generated unique identifier (as per CBD standard) used to associate this rule result to a related external object. The value of REF_TYPE_CD indicates the related external object.
	 * Required: false
	 * Array: false
	 */
	refInstId:number = undefined;
	/**
	 * Designates the component source for the REF_INST_ID.
	 * Required: false
	 * Array: false
	 */
	refTypeCd:string = undefined;
	/**
	 * Indicates whether the rule execution should only record rule results for this rule context or also allow further processing based on rule results.
	 * 
	 * Values : Y - create rule results only / N - allow further processing based on rule results (e.g. create a correction request for failed rule results)
	 * Required: false
	 * Array: false
	 */
	ruleResultInstId:number = undefined;
}
/**
 * Info about Shipment Rating Info
 */
export class RatingInfo {
	/**
	 * Required: false
	 * Array: false
	 */
	linehaulChgAmt:MonetaryAmount = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalDiscAmt:MonetaryAmount = undefined;
	/**
	 * Density Linehaul Charge Amount
	 * Required: false
	 * Array: false
	 */
	densityLnhChgAmt:MonetaryAmount = undefined;
	/**
	 * Density Calculation Class Code
	 * Required: false
	 * Array: false
	 */
	densityCalcClassCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ratingTariffId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	defaultTariffId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	spotQuoteId:string = undefined;
}
/**
 * 
 */
export class MiscLineItem {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Line type code
	 * 
	 * Desc : Indicates the type of  line item. 
	 * 
	 * Source : MBF Variable Segment - MBWEIGHT
	 * 
	 * Valid Values : 
	 * 
	 * 1 - As weight 
	 * 2 - Deficit weight 
	 * 3 - COD Amount
	 * 4 - Discount line (Reduced charge)
	 * 5 - Part PPD Line
	 * 6 - Part Collect Line
	 * 7- Cash Prepaid Line
	 * 8 - Cash Collected line
	 * 
	 * Note: As weight - Often the commodities are rated at a higher weight bracket than their net weight,  to get lower freight charges. In these cases the 'as weight'  is the weight used in calculating the freight charges. Most of the time this is sytem generated.
	 * Required: false
	 * Array: false
	 */
	lineTypeCd:MiscLineItemCd = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the  charges for the line item. Not all line items have a charge associated with them. The rate when present is multiplied by the unit of measure  to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE, MBRATES
	 * Required: false
	 * Array: false
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name : Unit of Measure
	 * 
	 * Desc : A code to identify the units in terms of which the quantity is measured for calculating charges. For eg. weight (in pounds) is the unit of measure for the AS WEIGHT and  DEFICIT WEIGHT line items. Not all line items have an UOM. 
	 * 
	 * Valid Values :
	 * space - Where UOM is n/a to the Charge. 
	 * 1 - Weight (in pounds)
	 * 2 - Yardage (for carpets)
	 * 3 - Count
	 * Required: false
	 * Array: false
	 */
	unitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantity
	 * 
	 * Desc : Quantity (measured in terms of UOM) to be used in calculating charges
	 * 
	 * It represents As Weight on an AS WEIGHT line. Often the commodities are rated at a higher weight bracket than their net weight,  to get lower freight charges. In these cases the 'as weight'  is the weight used in calculating the freight charges. Most of the time this is sytem generated. 
	 * 
	 * For some Charge lines where it does not make sense to have a QTY, such as a COD, zeros may be formatted; UOM must be a space in this case.
	 * 
	 * Source: MBF Variable Segment - MBWEIGHT
	 * Required: false
	 * Array: false
	 */
	quantity:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge associated with the line item.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * 
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 * Required: false
	 * Array: false
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the line item. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name :  Description
	 * 
	 * Desc : A textual description for the line item.
	 * 
	 * Source : MBF  Variable Segment :
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * 
	 * Desc : Sequential number assigned to uniquely identify  each miscelleneous charge for a Shipment. This entity stores all the differnt types of charge lines that appear on the invoice for the shipment. Not all of these lines contribute to the CTS revenue.Some examples are COD, As Weight, Deficit Weight lines etc. 
	 * 
	 * Source : Generated
	 * 
	 * Design Considerations :
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	paymentMethodCd:MiscLineItemPaymentMethodCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	checkNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chrgToCd:LineItemChargeToCd = undefined;
}
/**
 * 
 */
export class Shipment {
	/**
	 * Expanded Name : Instance identifier
	 * 
	 * Desc : System generated unique identifier (as per CBD standard).
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Type code 
	 * 
	 * Desc : Identifies the type of shipment.
	 * 
	 * 
	 * Valid Values:
	 * A = Normal Movement(NORM)
	 * B = Master Shipment(MSTR) 
	 * C = Segment of a Master shipment (MSEG)
	 * D = Revenue Bill for Split segments (PTLT)
	 * E = Partial Shipment Segment (PSEG)
	 * G = Claims overage Reporting Bill (OREP)
	 * H = Genral Claims Business Shipment (GCBZ)
	 * I =  Astray Freight Segment (MOVR)
	 * J = Company Business Shipment (COBZ) 
	 * K = Expedite
	 * 
	 * 
	 * Note:
	 * Overage Reporting Bill (OREP) is entered into the system just  to indicate to the claims system there is an overage shipment on dock. 
	 * 
	 * Astray freight(MOVR) is used to move freight to it s correct destination after it accidentally ended up somewhere else and its original PRO number is known.
	 * Required: false
	 * Array: false
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This number identifies a shipment. This is the identifier given to the customer as a reference.
	 * 
	 * The numbers are printed on labels for each service center.  Labels are put on the Bill-Of-Lading and on the individual pieces of freight for identification.
	 * 
	 * Internal PRO number format is Oppp-sssss-c where 'ppp' is the pro-prefix, 'ssss' is the pro-suffix and 'c' is the check digit (modulus 7). It is usually displayed in the format 'ppp-sssss-c'
	 * 
	 * An expedited shipment may not always have a real PRO number. It may be given a dummy number in the same format and should not be written out to MBF.
	 * 
	 * MBF: root MBPRO
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Bill Status Code
	 * 
	 * Desc : Current status of the Shipment from billing point of view. 
	 * 
	 * Valid Values:
	 * 1 - Unbilled 
	 * 2 - In FBES Suspense Queue
	 * 3 - Billed
	 * 4 - Rated
	 * 5 - Paid
	 * 6 - Cancelled
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	billStatusCd:BillStatusCd = undefined;
	/**
	 * Expanded Name :  Charge to code
	 * 
	 * Desc : Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. For BOTH bills, the split for each line item will be stored along with the line item in the commodity, ac_svc or the misc_line_item table in future. Currently the total PPD and total COLLECT charges are currently stored as separate line items in the misc_line_item entity.
	 * 
	 * Valid Values : 
	 * 	P    -  Prepaid - shipper responsible for all charges
	 * 	C    -  Collect - consignee responsible for all charges
	 * 	B    -  Both - shipper and consignee responsible 
	 * 								-  Valid only for Non-Revenue bills.
	 * 			
	 * Source: MBF : MBPRTPPD, MBPRTCOL
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Expanded Name : Original terminal SIC
	 * 
	 * Desc : SIC where the Shipment originates.  Usually associated with PRO prefix and can be overriden.
	 * 
	 * Source : MBF: root MBORGIN
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Destination terminal SIC
	 * 
	 * Desc : SIC that is scheduled to deliver the Shipment. 
	 * 
	 * Source : MBF: root MBDEST
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Pickup Date                       
	 * 																																																		
	 * Desc : Earliest available date that Shipment can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * Expanded Name : Total pieces count
	 * 
	 * Desc : Total pieces from all commodities in the shipment.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTPCS
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * Expanded Name : Total weight (in pounds)
	 * 
	 * Desc : Total weight of all the freight being moved as a unit.  If this is a  Mother  bill then this is the total of all of the  Daughter  bills. 
	 * 
	 * Any rating weight allowances or variations (AW, DW, RW, NW) are not included.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTWT
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Expanded Name : Default Tariff
	 * 
	 * Desc : This identifies the default qualifying tariff for the shipment. If the rating process does not identify any other tariffs for the customer from rate aids, this is used as the rating tariff. 
	 * 	
	 * Source : MBF root - MBTARIFF
	 * Required: false
	 * Array: false
	 */
	defaultTariffsId:string = undefined;
	/**
	 * Expanded Name : Total charges
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * Required: false
	 * Array: false
	 */
	totalChargeAmount:number = undefined;
	/**
	 * Expanded Name : Total charges in US dollars
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount in US dollars.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * 
	 * Design Notes: This field was added as a denorm to keep the total charges in USD. Normally this field is same as the tot charges field. However this field is differnt when the rating currncy is not USD.
	 * Required: false
	 * Array: false
	 */
	totalUsdAmount:number = undefined;
	/**
	 * Expanded Name: Split Indicator
	 * 
	 * Desc: Indicates whether the shipment has been split or not. 
	 * 
	 * Valid values: Y/N
	 * 
	 * Source: MBF MBCLAS2 -  This actually replaces the BILL CLASS 2 field in the MBF.
	 * Required: false
	 * Array: false
	 */
	splitInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous Materials indicator
	 * 	
	 * Desc : Indicates whether or not a shipment includes  any commodities with hazardous materials. It is a denormalized attribute for performance puposes. 
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Expanded Name : Revenue Bill Indicator
	 * 
	 * Desc : Indicates whether the shipment will generate revenue for Con-way or not.
	 * 
	 * Valid Values : 
	 * 
	 * Y - generates revenue
	 * N - does not generate revenue
	 * Required: false
	 * Array: false
	 */
	revenueBillInd:boolean = undefined;
	/**
	 * Expanded Name : Cash indicator
	 * 
	 * Desc : This indicates if cash was collected at the tim eof pick up or should be collected at the time of delivery.
	 * 
	 * This information is also printed on the delivery set to inform the driver to collect cash.
	 * 
	 * Source : MBF: root: MBCASH (x 10  of MBFLAG2)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	cashInd:boolean = undefined;
	/**
	 * Expanded Name : Cash collected code                    
	 * 																																																							
	 * Desc : A code indicating whether any cash was          
	 * collected at the time of pick up or delivery or not. Cash may be collected  regardless of whether it was required or not and if  so, the invoice may need to be stopped.                
	 * 																																																							
	 * It has been modelled as a code instead of an indicator 
	 * since it needs to be initialized to a value other than 
	 * NO.                                                    
	 * 																																																							
	 * Source : New                                           
	 * 																																																							
	 * Valid Values :                                         
	 * blank - Initial value                                  
	 * Y - Yes, cash was collected                            
	 * N - No cash collected on delivery
	 * Required: false
	 * Array: false
	 */
	cashCollectInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Indicator
	 * 
	 * Desc : Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 * 
	 * Values : Y/N
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Coupon Indicator
	 * 
	 * Desc : Indicates that a Coupon has been used to allow the shipment to participate in the Con-Way Guaranteed Service Program for free.
	 * 
	 * Values : Y/N
	 * 
	 * Source : BASE
	 * Required: false
	 * Array: false
	 */
	guaranteedCouponInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Code 
	 * 
	 * Desc: This is the discount code that was applied to the shipment when it was rated. 
	 * 
	 * Source: MBF - MBRCCODE
	 * Required: false
	 * Array: false
	 */
	discountCd:string = undefined;
	/**
	 * Expanded Name : Delivery qualifier
	 * 
	 * Desc : Code representing results of a specific delivery activity. The values are the same as what used to be  Event that stopped service).
	 * 
	 * This has been denormalized from Movement for performance reasons and should always be kept in sync. 
	 * 
	 * Source : MBF: root: MBD1FLG / MBD2FLG
	 * 
	 * Valid Values :
	 * A= Over Shipment
	 * B=Trapped shipment/MBD1TRAP
	 * C=Hold for customs/MBD1CSTM
	 * D=Undelivered/MBD1UNDL
	 * E=Hold for appointment/MBD1APPT
	 * F=On spotted trailer/MBD1SPOT
	 * G=Cartage
	 * H=Attempted delivery/MBD1ATMP
	 * J=All short
	 * K=Partial short/MBD1PSRT
	 * L=Refused delivery/MBD1RFSD
	 * M=Damaged shipment/MBD1RDMG
	 * N=Suspense/MBD1SUSP     - Not Used
	 * O=Wait/MBD1WAIT              - Not Used
	 * P=Purge Blocked/MBD2PBLK
	 * R=Return Status/MBD2RTRN
	 * T=Transfer/MBD2TSFR
	 * Z=Final delivery/MBD1FINL
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * Expanded Name : Rating Currency Code
	 * 
	 * Desc: The rates and charges for the shipment were calculated using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	ratingCurrencyCd:RatingCurrencyCd = undefined;
	/**
	 * Expanded Name : Invoicing Currency Code
	 * 
	 * Desc:  PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * The total charges for the shipment should be displayed using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	invoicingCurrencyCd:InvoiceCurrencyCd = undefined;
	/**
	 * Expanded Name: Currency Converstion Factor
	 * 
	 * Desc: PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * Provides the currency conversion factor on the day of shipment pick up between the rating currency and invoicing currency, if they are different.
	 * Required: false
	 * Array: false
	 */
	currencyConversionFctr:number = undefined;
	/**
	 * Expanded Name : Required Delivery Date
	 * 
	 * Desc : Date the shipment is required to be delivered
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	requiredDeliveryDate:Date = undefined;
	/**
	 * Expanded Name :  Rating tariff
	 * 
	 * Desc : This identifies the actual tariff used to rate the shipment. 
	 * 
	 * Source : MBF root - MBRATTAR
	 * Required: false
	 * Array: false
	 */
	ratingTariffsId:string = undefined;
	/**
	 * Expanded name:  Service type code.
	 * 
	 * Description:  Defines the specific type of service provided for a shipment.
	 * 
	 * Values:  1 - Normal LTL, 2 - Con-Way Deferred Express.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Expanded name: Estimated Delivery Date
	 * 
	 * Description: Date that the shipment should be delivered based on standard lane days.
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:Date = undefined;
	/**
	 * Expanded Name: Absolute minimum charge indicator
	 * 
	 * Desc: This inidcates if the charge for this shipment is the absolute minimum charge.
	 * Required: false
	 * Array: false
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Percentage
	 * 
	 * Desc: This is the percentage discount applied to the freigt chrages and discountable accessorial charges of the shipment.
	 * Required: false
	 * Array: false
	 */
	discountPercentage:number = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * 
	 * Desc : The volume of the freight; derived from the dimensions captured by driver, or entered by a CSR or dock worker for an SL  (Shipper Load and Count), or from one of a variety of profiles. For details, see the description of CFT_PRFL_MTHD_CD.
	 * 
	 * 06/21/2016 Cathy Doucet modified description as per Ariel/Sunya.
	 * Required: false
	 * Array: false
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total Pallet Count
	 * 
	 * Desc: Total Number of Pallets in the Shipment
	 * Required: false
	 * Array: false
	 */
	totalPalletsCount:number = undefined;
	/**
	 * Expanded Name: Area instance Id
	 * 
	 * Desc: Each service center is divided into multiple geographic areas for delivery. This identifies the delivery area of destination service center in which this shipment s consignee is located.
	 * 
	 * Source: SMART 4 Requirement
	 * 
	 * == NOTE ==  Ties back to the geo-area stored in Oracle spatial database for each service center.
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * Expanded Name : Shipment Service Status Code
	 * 
	 * Desc : Indicates whether shipment met service standards.
	 * 
	 * Values: Fail, Success, Exempt, Unknown
	 * 
	 * Source : Calculated
	 * Required: false
	 * Array: false
	 */
	shipmentServiceStatusCd:ServiceStatusCd = undefined;
	/**
	 * Expanded Name: Spot Quote ID
	 * 
	 * Desc: The Spot Quote ID that was entered during Billing. This may be different from the one that was used for rating.  For the latest Spot Quote ID that was used for raing, please look at the rating info record.
	 * Required: false
	 * Array: false
	 */
	spotQuoteId:number = undefined;
	/**
	 * Expanded Name: Lineal Foot Total Number
	 * 
	 * Desc: This is the length in feet that a shipment will take up in a trailer.  This dimension is typically used when the freight is long and narrow and cannot have anything stacked on top of it (e.g. carpet rolls).  For this reason it takes a larger portion of the trailer volume than a shipment of the same weight that is packaged on pallets.  This value can then be used in the planning of trailer loads and to rate shipments that have these characteristics.
	 * Required: false
	 * Array: false
	 */
	linealFootTotalNbr:string = undefined;
	/**
	 * Expanded Name : Loose pieces count
	 * 
	 * Desc : Total loose pieces from all commodities in the shipment.
	 * 
	 * Count of non Motor Move pieces associated with a shipment. This count plus Motor Moves defines the total number of pieces moving within the Con-way system for Operations productivity.  This does not necessarily balance with the total pieces or commodity pieces entered during bill entry.
	 * Required: false
	 * Array: false
	 */
	loosePiecesCount:number = undefined;
}
export class InvoiceShipmentDetail {
	/**
	 * Required: false
	 * Array: true
	 */
	accessorial:AccessorialService[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	advBeyondCarrier:AdvanceBeyondCarrier[] = undefined;
	/**
	 * Expanded Name : Type code 
	 * 
	 * Desc : Identifies the type of shipment.
	 * 
	 * 
	 * Valid Values:
	 * A = Normal Movement(NORM)
	 * B = Master Shipment(MSTR) 
	 * C = Segment of a Master shipment (MSEG)
	 * D = Revenue Bill for Split segments (PTLT)
	 * E = Partial Shipment Segment (PSEG)
	 * G = Claims overage Reporting Bill (OREP)
	 * H = Genral Claims Business Shipment (GCBZ)
	 * I =  Astray Freight Segment (MOVR)
	 * J = Company Business Shipment (COBZ) 
	 * K = Expedite
	 * 
	 * 
	 * Note:
	 * Overage Reporting Bill (OREP) is entered into the system just  to indicate to the claims system there is an overage shipment on dock. 
	 * 
	 * Astray freight(MOVR) is used to move freight to it s correct destination after it accidentally ended up somewhere else and its original PRO number is known.
	 * Required: false
	 * Array: false
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * Expanded Name : Bill Status Code
	 * 
	 * Desc : Current status of the Shipment from billing point of view. 
	 * 
	 * Valid Values:
	 * 1 - Unbilled 
	 * 2 - In FBES Suspense Queue
	 * 3 - Billed
	 * 4 - Rated
	 * 5 - Paid
	 * 6 - Cancelled
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	billStatusCd:BillStatusCd = undefined;
	/**
	 * Expanded Name :  Charge to code
	 * 
	 * Desc : Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. For BOTH bills, the split for each line item will be stored along with the line item in the commodity, ac_svc or the misc_line_item table in future. Currently the total PPD and total COLLECT charges are currently stored as separate line items in the misc_line_item entity.
	 * 
	 * Valid Values : 
	 * 	P    -  Prepaid - shipper responsible for all charges
	 * 	C    -  Collect - consignee responsible for all charges
	 * 	B    -  Both - shipper and consignee responsible 
	 * 								-  Valid only for Non-Revenue bills.
	 * 			
	 * Source: MBF : MBPRTPPD, MBPRTCOL
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	commodity:Commodity[] = undefined;
	/**
	 * Expanded Name: Currency Converstion Factor
	 * 
	 * Desc: PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * Provides the currency conversion factor on the day of shipment pick up between the rating currency and invoicing currency, if they are different.
	 * Required: false
	 * Array: false
	 */
	currencyConversionFctr:number = undefined;
	/**
	 * Expanded Name : Delivery qualifier
	 * 
	 * Desc : Code representing results of a specific delivery activity. The values are the same as what used to be  Event that stopped service).
	 * 
	 * This has been denormalized from Movement for performance reasons and should always be kept in sync. 
	 * 
	 * Source : MBF: root: MBD1FLG / MBD2FLG
	 * 
	 * Valid Values :
	 * A= Over Shipment
	 * B=Trapped shipment/MBD1TRAP
	 * C=Hold for customs/MBD1CSTM
	 * D=Undelivered/MBD1UNDL
	 * E=Hold for appointment/MBD1APPT
	 * F=On spotted trailer/MBD1SPOT
	 * G=Cartage
	 * H=Attempted delivery/MBD1ATMP
	 * J=All short
	 * K=Partial short/MBD1PSRT
	 * L=Refused delivery/MBD1RFSD
	 * M=Damaged shipment/MBD1RDMG
	 * N=Suspense/MBD1SUSP     - Not Used
	 * O=Wait/MBD1WAIT              - Not Used
	 * P=Purge Blocked/MBD2PBLK
	 * R=Return Status/MBD2RTRN
	 * T=Transfer/MBD2TSFR
	 * Z=Final delivery/MBD1FINL
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * Expanded Name : Destination terminal SIC
	 * 
	 * Desc : SIC that is scheduled to deliver the Shipment. 
	 * 
	 * Source : MBF: root MBDEST
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * Estimated delivery date of the shipment
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:Date = undefined;
	/**
	 * Expanded Name : Guaranteed Indicator
	 * 
	 * Desc : Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 * 
	 * Values : Y/N
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Expanded Name : Invoicing Currency Code
	 * 
	 * Desc:  PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * The total charges for the shipment should be displayed using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	invoicingCurrencyCd:InvoiceCurrencyCd = undefined;
	/**
	 * set to True if Finaly Delivery Date is present. Otherwise False
	 * Required: false
	 * Array: false
	 */
	isFinaDlvrd:boolean = undefined;
	/**
	 * Expanded Name : Movement Timestamp
	 * 
	 * Desc : Date and time, when the last movement for this shipment  occurred. This is a denormalized attribute from the MOVEMENT entity. 
	 * 
	 * Source: MBF - MBMVTM
	 * Required: false
	 * Array: false
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	miscLineItem:MiscLineItem[] = undefined;
	/**
	 * Expanded Name : Movement status
	 * 
	 * Desc : The status of the latest movement for a Shipment from an operations point-of-view. This is a denormalized atribute from the MOVEMENT entity.
	 * 
	 * Source: MBF
	 * Name: Various
	 * 
	 * Valid Values : 
	 * Spaces - Not applicable
	 * 1 - On dock
	 * 2 - On trailer
	 * 3 - Out for delivery
	 * 4-  Interim Delivery
	 * 5 - Final Delivered
	 * 6 - Not Applicable
	 * 6 - No movements allowed
	 * 7 - Cancelled
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * Timestamp of when the order was entered. SHM_EVENT_LOG. OCCURRED_TMST
	 * Where SUB_TYP_CD='21'
	 * Required: false
	 * Array: false
	 */
	orderEntryDate:Date = undefined;
	/**
	 * Expanded Name : Original terminal SIC
	 * 
	 * Desc : SIC where the Shipment originates.  Usually associated with PRO prefix and can be overriden.
	 * 
	 * Source : MBF: root MBORGIN
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * Parties associated with the shipment
	 * Required: false
	 * Array: true
	 */
	party:ShipmentParty[] = undefined;
	/**
	 * Pickup date of the shipment invoiced.
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * PRO# of the shipment invoiced.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Rating Currency Code
	 * 
	 * Desc: The rates and charges for the shipment were calculated using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	ratingCurrencyCd:RatingCurrencyCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ratingInfo:RatingInfo = undefined;
	/**
	 * Identifies the shipment that is invoiced. The value comes from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * List of SuppRefs associated with Shipment: SN#, PO#, SpotQuote
	 * Required: false
	 * Array: true
	 */
	suppRef:SupplementalReference[] = undefined;
	/**
	 * Expanded Name : Total charges
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * Required: false
	 * Array: false
	 */
	totalChargeAmount:number = undefined;
	/**
	 * Expanded Name: Total Pallet Count
	 * 
	 * Desc: Total Number of Pallets in the Shipment
	 * Required: false
	 * Array: false
	 */
	totalPalletsCount:number = undefined;
	/**
	 * Desc: Total Number Pieces in the Shipment
	 * Required: false
	 * Array: false
	 */
	totalPieceCount:number = undefined;
	/**
	 * Expanded Name : Total charges in US dollars
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount in US dollars.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * 
	 * Design Notes: This field was added as a denorm to keep the total charges in USD. Normally this field is same as the tot charges field. However this field is differnt when the rating currncy is not USD.
	 * Required: false
	 * Array: false
	 */
	totalUsdAmount:number = undefined;
	/**
	 * Expanded Name : Total weight (in pounds)
	 * 
	 * Desc : Total weight of all the freight being moved as a unit.
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Expanded name:  Service type code.
	 * 
	 * Description:  Defines the specific type of service provided for a shipment.
	 * 
	 * Values:  1 - Normal LTL, 2 - Con-Way Deferred Express.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
}
/**
 * Invoice details
 */
export class InvoiceDetail {
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInfo:InvoiceShipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	deliveryInfo:InvoiceDelivery[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	notReadyReason:InvoiceNotRdyTransportReason[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appliedPayment:AppliedPayment = undefined;
}
/**
 * 
 */
export class ShipmentParty {
	/**
	 * Type of Party:
	 * Shipper
	 * Consignee
	 * Bill To
	 * SendTo
	 * Required: false
	 * Array: false
	 */
	typeCd:MatchedPartyTypeCd = undefined;
	/**
	 * Expanded Name : Debtor indicator
	 * 
	 * Desc : Specifies whether the customer is also responsible for paying some or all of the charges for a Service Item. Invoices, allocations (if any) are created only for debtors. 
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	debtorInd:boolean = undefined;
	/**
	 * Expanded Name : Name Line 1 (text format)
	 * 
	 * Desc : Line 1 of the customer name
	 * 
	 * Source : MBF variable segments
	 * 													AA  Shipper segment
	 * 													AB  Consignee segment
	 * 													AC  Bill to segment
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * Expanded Name : Name Line 2 (text format)
	 * 
	 * Desc : Second line (if any) of customer name
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Expanded Line : Address 
	 * 
	 * Desc : Street address of the customer location.
	 * 
	 * Source: MBF variable segments
	 * 	AA  Shipper segment
	 * 	AB  Consignee segment
	 * 	AC  Bill to segment
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * Expanded Name : City
	 * 
	 * Desc : City where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State (code)
	 * 
	 * Desc : State where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * Expanded Name : Country Code
	 * 
	 * Desc : Specifies the code of the country where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Expanded Name : Zip (code) first 5 (characters) (6 characters for Canadian zips)
	 * 
	 * Desc : First 5 characters of the zip code for a US address or first 6 characters of the Canadian zip code of the address where the customer is located. 
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * Expanded Name : Zip (Code) last 4 (characters)
	 * 
	 * Desc : Last 4 characters of the zip code for a US address
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * Expanded Name : CIS Customer Number
	 * 
	 * Desc : Identifier of the appropriate customer in CIS, determined by the matching sub-routine
	 * Required: false
	 * Array: false
	 */
	cisCustNbr:number = undefined;
	/**
	 * Expanded Name : As-Matched MAD code
	 * 
	 * Desc : Customer Account code.  This is the MAD code that came from CIS at the time of Customer Matching.
	 * 
	 * Source : Customer Information System
	 * Required: false
	 * Array: false
	 */
	asMatchedMadCd:string = undefined;
}
/**
 * 
 */
export class Commodity {
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge for the commodity.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Expanded Name : (Commodity) class
	 * 
	 * Desc : Identifies the type (class) of commodity being shipped.
	 * 
	 * Examples:  Carpet, Matresses, Computer paper
	 * 
	 * Valid Values : 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 350, 400, 500  
	 * 
	 * Source: MBF Variable Segment : MBVDESC
	 * Required: false
	 * Array: false
	 */
	classType:CommodityClassCd = undefined;
	/**
	 * Expanded Name : Description
	 * 
	 * Desc : A textual description of the commodity
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name : Hazardous material indicator
	 * 
	 * Desc : Indicates whether the commodity is a hazardous material. 
	 * 
	 * Source : MBF Variable Segment : MBNMHMX
	 * 
	 * Valid Values :
	 * Y - Has hazardous materials
	 * N - No hazardous materials
	 * Required: false
	 * Array: false
	 */
	hazardousMtInd:boolean = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the commodity. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name : NMFC item code
	 * 
	 * Desc : This is the NMFC (National Motor Freight Classification) code for the commodity.
	 * 
	 * Source: MBF Variable Segment - MBNMFC
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Expanded Name: Packaging code
	 * 
	 * Desc : Type of packaging the commodity was originally picked up in. 
	 * 
	 * Examples: Box, Skid, Case, Roll etc.
	 * 
	 * Source: MBF Variable Segment - MBVDESC
	 * Required: false
	 * Array: false
	 */
	packageCd:CommodityPackageCd = undefined;
	/**
	 * Expanded Name : Pieces count
	 * 
	 * Desc : Number of pieces of the commodity in the shipment.
	 * 
	 * Source : MBF Variable Segment - MBPIECES
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * Per Unit Rate Amount
	 * Required: false
	 * Array: false
	 */
	rateAmt:number = undefined;
	/**
	 * Rate Qualifier.
	 * Legacy EDI Reqr: If MinChrgInd = true then MN else PH
	 * Required: false
	 * Array: false
	 */
	rateQlfr:string = undefined;
	/**
	 * Legacy EDI Reqr: Multiiply amount*100 and then round
	 * Required: false
	 * Array: false
	 */
	roundedChrgAmt:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	suppRef:SupplementalReference[] = undefined;
	/**
	 * Expanded Name: Total weight (in pounds)
	 * 
	 * Desc : Total weight of the commdity in pounds. 
	 * 
	 * Source: MBF Root - MBTOTWT
	 * Required: false
	 * Array: false
	 */
	weightLbs:number = undefined;
}
/**
 * 
 */
export class CommodityDimension {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	commoditySequenceNbr:string = undefined;
	/**
	 * Expanded Name: Sequence Number
	 * 
	 * Desc: A system generated sequential number to uniquely identify the recorded dimensions for a shipment.
	 * Required: false
	 * Array: false
	 */
	dimSequenceNbr:string = undefined;
	/**
	 * Expanded Name: Pieces Count
	 * 
	 * Desc: This is the number of pieces the recorded dimensions apply to.
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name: Length                               
	 * 																																																				
	 * Description: This is the length of the whole or part
	 * of the shipment. The number of pieces attribute     
	 * indicates how many pieces of the shipment the       
	 * dimensions apply to.
	 * Required: false
	 * Array: false
	 */
	lengthNbr:number = undefined;
	/**
	 * Expanded Name: Width                                   
	 * 																																																							
	 * Description: This is the width of the whole or part of 
	 * the shipment. The number of pieces attribute indicates 
	 * how many pieces of the shipment the dimensions apply   
	 * to.
	 * Required: false
	 * Array: false
	 */
	widthNbr:number = undefined;
	/**
	 * Expanded Name: Height                                
	 * 																																																								
	 * Description: This is the height of the whole or part of  
	 * the shipment. The number of pieces attribute indicates  
	 * how many pieces of the shipment the dimensions apply    
	 * to.
	 * Required: false
	 * Array: false
	 */
	heightNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class AccessorialService {
	/**
	 * Expanded Name : Accessorial  code
	 * 
	 * Desc : Indicates the type of accessorial service requested or performed
	 * Required: false
	 * Array: false
	 */
	accessorialCd:string = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge for the accessorial service.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Expanded Name : Description
	 * 
	 * Desc : A textual description of the service.
	 * 
	 * Source : MBF  Variable Segment :
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Specifies the units for the quantity for which the accessorial fee is charged.
	 * 
	 * Valid Values
	 * 1- Labour Hour
	 * 2- 1/4 hour
	 * 3- 1/2 hour
	 * 4- CWT
	 * 5- CWT/Day
	 * 6- Check
	 * 7- Correction
	 * 8- Day
	 * 9- Document
	 * A- Package
	 * B- Shipment
	 * C- Vehicle
	 * D- Mile
	 * E- Pound
	 * ' ' - Not applicable
	 * Required: false
	 * Array: false
	 */
	accessorialUnitOfMeasure:AccessorialUnitOfMeasureCd = undefined;
	/**
	 * Specifies the quantity of units (in AC_UOM) for which the accessorial fee is charged.
	 * Required: false
	 * Array: false
	 */
	accessorialQuantity:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoicingRemarks:string = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the accessorial charges for an accessorial service . The rate is multiplied by the unit of measure to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE
	 * Required: false
	 * Array: false
	 */
	tariffsRate:number = undefined;
}
/**
 * 
 */
export class AdvanceBeyondCarrier {
	/**
	 * Valid Values:
	 * Advance
	 * Beyond
	 * Required: false
	 * Array: false
	 */
	typeCd:AdvanceBeyondTypeCd = undefined;
	/**
	 * Expanded Name: Standard Carrier Abbreviation Code
	 * 
	 * Desc : Other Carrier s identification code for Advance and Beyond movements for the shipment.
	 * 
	 * This is the identifying code for the carrier from whom CTS picked up (or transfers to) the freight. If the code is unknown, NONE is entered by the bill entry personnel. 
	 * 
	 * Source : MBF root  -  MBADSCAC
	 * Required: false
	 * Array: false
	 */
	carrierScacCd:string = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This is  the other carrier s PRO number that identifies the shipment for the carrier in their systems.
	 * Required: false
	 * Array: false
	 */
	carrierProNbr:string = undefined;
	/**
	 * Expanded Name: Carrier pick up date
	 * 
	 * Desc: This is the date the carrier picked up the shipment. 
	 * 
	 * In case of an advance carrier this is the date the carrier picked it up from the customer.
	 * 
	 * 	In case of beyond carriers, it is the date the carrier picked up the shipment from CTS.
	 * Required: false
	 * Array: false
	 */
	carrierPickupDate:Date = undefined;
	/**
	 * Expanded Name : From terminal SIC
	 * 
	 * Desc : SIC where the shipment originates for the carrrier.  
	 * 
	 * For advance carriers it is the sic that services the shipper and for beyond carrier this is CTS service center that delivers teh PRO to the beyond carrier. 
	 * 
	 * This is mostly stored for Beyond carriers.
	 * 
	 * Source :
	 * Required: false
	 * Array: false
	 */
	fromTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : To terminal SIC
	 * 
	 * Desc : This is CTS SIC where an advance carrier dropped the shipment for further movement. For beyond carrier this is the SIC code that services the consignee. 
	 * 
	 * Source :
	 * Required: false
	 * Array: false
	 */
	toTerminalSicCd:string = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the advance or beyond charge.
	 * Required: false
	 * Array: false
	 */
	chargeAmount:number = undefined;
}
/**
 * 
 */
export class AsMatchedParty {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence Number
	 * Desc : A system assigned sequential number to uniquely identify each As Entered Customer for a Shipment.
	 * There are always at least two customers for a shipment and atmost 6 customers for a shipment.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name :  Type code
	 * Desc : Specifies the nature of relationship of the customer with respect to the Service Item in question. For Shipments, a Customer can be the shipper, consignee, bill-to, requestor or contact. 
	 * Permitted Values:
	 * 										1=Shipper address
	 * 										2=Consignee address 
	 * 										3=Inbound bill-to address 
	 * 										4=Outbound bill-to address
	 * 										5=Requestor address
	 * 										6=Contact address 
	 * Source : Internal
	 * Required: false
	 * Array: false
	 */
	typeCd:MatchedPartyTypeCd = undefined;
	/**
	 * Expanded Name : Debtor indicator
	 * Desc : Specifies whether the customer is also responsible for paying some or all of the charges for a Service Item. Invoices, allocations (if any) are created only for debtors. 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	debtorInd:boolean = undefined;
	/**
	 * Expanded Name : Name Line 1 (text format)
	 * Desc : Line 1 of the customer name
	 * Source : MBF variable segments
	 * 													AA  Shipper segment
	 * 													AB  Consignee segment
	 * 													AC  Bill to segment
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * Expanded Name : Name Line 2 (text format)
	 * Desc : Second line (if any) of customer name
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Expanded Line : Address 
	 * Desc : Street address of the customer location.
	 * Source: MBF variable segments
	 * 	AA  Shipper segment
	 * 	AB  Consignee segment
	 * 	AC  Bill to segment
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * Expanded Name : City
	 * Desc : City where the customer is located
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State (code)
	 * Desc : State where the customer is located
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * Expanded Name : Country Code
	 * Desc : Specifies the code of the country where the customer is located
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Expanded Name : Zip (code) first 5 (characters) (6 characters for Canadian zips)
	 * Desc : First 5 characters of the zip code for a US address or first 6 characters of the Canadian zip code of the address where the customer is located. 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * Expanded Name : Zip (Code) last 4 (characters)
	 * Desc : Last 4 characters of the zip code for a US address
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * Expanded Name : Matching Status
	 * Desc : Match status from CIS matching.
	 * Valid Values :
	 * The following values ARE ACTIVE for Forward Sync:
	 * Spaces - Initial value, matching activity has not yet occurred.  
	 * 	70  - Full Match 
	 * 	60  - Un-matched (valid name / address but does not exist in CIS)
	 * The following values ARE NOT ACTIVE for Forward Sync:
	 * 	15  - Possible matches found
	 * 	30  - Invalid address (Bad US postal address as per Code-1)
	 * 	50  - Blank bill2 - no bill-to was entered
	 * The following was added by C.Doucet 10/12/2001:
	 * 	CM  - CIS Customer Merge correction changed the CIS cust nbr value
	 * Required: false
	 * Array: false
	 */
	matchedStatusCd:MatchedPartyStatusCd = undefined;
	/**
	 * Expanded Name : Matcher s Initials 
	 * Desc : Contains the initials of the Review Clerk who performed matching on a pro, or forced the MAD code on a pro to a different MAD using a Pro Override.  
	 * Source : Extended Master Bill File. These initials are the same as those stored on the EMB (JABCOB).
	 * Required: false
	 * Array: false
	 */
	matchedInitials:string = undefined;
	/**
	 * Expanded Name : CIS Customer Number
	 * Desc : Identifier of the appropriate customer in CIS, determined by the matching sub-routine
	 * Required: false
	 * Array: false
	 */
	cisCustNbr:number = undefined;
	/**
	 * Expanded Name : As-Matched MAD code
	 * Desc : Customer Account code.  This is the MAD code that came from CIS at the time of Customer Matching.
	 * Source : Customer Information System
	 * Required: false
	 * Array: false
	 */
	asMatchedMadCd:string = undefined;
	/**
	 * Expanded Name : Direct code
	 * Desc : Used to group customer oriented service reports and manifests for direct customer access via the online system.
	 * This is a de-normalisation as direct code is available only for CIS customers and it should be picked up from appropriate CIS table. 
	 * Source : MBF: root: MBSCUST
	 * Required: false
	 * Array: false
	 */
	dirCd:string = undefined;
	/**
	 * Expanded Name : Credit Status Code
	 * Desc : Specifies the credit rating / status for a Customer, if not set up in CIS. If the customer exists in CIS, it is copied here for performance reasons.
	 * Source : MBF: root: MBONOST (Out-bound)
	 * 														MBF: root: MBINOST (In-bound)
	 * Valid Values : 
	 * B=Bad debt/MBOBAD
	 * C=Credit/MBOCRED
	 * N=Non-credit/MBONOCRD
	 * P=PCO Control/MBOPCO
	 * V=Voluntary/MBOVOLON
	 * $=Cash only/MBOCASH
	 * Required: false
	 * Array: false
	 */
	creditedStatusCd:ShipmentCreditStatusCd = undefined;
	/**
	 * Expanded Name:  Bill to Relationship Code
	 * Desc: Indicates who the Bill to customer is related to (Shipper or Consignee) in the CIS system. This field applies only to BILL TO type of customers. 
	 * Valid Values:
	 * 				- Not Applicable
	 * S - Bill To is related to Shipper
	 * C - Bill To is related to Consignee
	 * B - Bill To is related to Both
	 * N - Bill To is related to None
	 * Required: false
	 * Array: false
	 */
	billToRelationshipCd:BillToRelationCd = undefined;
	/**
	 * Expanded Name : Use as entered indicator
	 * Desc : 
	 * Source : E-commerce
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	useAsEntrdInd:boolean = undefined;
	/**
	 * Expanded Name : All shipments (must be) prepaid indicator
	 * Desc : Specifies whether all shipments from the customer have to be pre-paid
	 * Source : MBF: root: MBR4PPDA (x 02  of MBRGFLG4)
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	allShipmentPrepaidInd:boolean = undefined;
	/**
	 * Expanded Name :   Proof Of Delivery  required indicator
	 * Desc : Indicates whether the customer (usually shipper and / or consignee) requires a Proof Of Delivery.
	 * Source : MBF: root: MBSPD (x 80  of MBFLAGD)
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	proofOfDeliveryRequiredInd:boolean = undefined;
	/**
	 * Expanded Name :  Proof Of Delivery  image indicator
	 * Desc : Specifies that an image of the delivery receipt will suffice, instead of an original 
	 * Source : MBF: root: MBSMG (x 40  of MBFLAGD)
	 * Valid values : Y/N
	 * Required: false
	 * Array: false
	 */
	proofOfDeliveryImageInd:boolean = undefined;
	/**
	 * Expanded Name : Broker customer key number
	 * Desc : For international shipments, identifies the CIS customer number of any broker for the shipper or consignee. 
	 * Source : MBF: root: MBBRKKEY
	 * Required: false
	 * Array: false
	 */
	brokerCustKeyNbr:number = undefined;
	/**
	 * Expanded Name :  Customer E-mail Id
	 * Desc : E-mail address of the Customer on a bill, used as supplemental information in case that customer needs to be contacted via e-mail.
	 * Required: false
	 * Array: false
	 */
	eMailId:string = undefined;
	/**
	 * Expanded Name : Phone Country Code 
	 * Desc : Country code for the phone number of the customer (if any) for a Shipment. 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneCountryCdNbr:string = undefined;
	/**
	 * Expanded Name :  Phone Area Code 
	 * Desc : Area Code for phone number of the customer (if any) for a Shipment. 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneAreaCdNbr:string = undefined;
	/**
	 * Expanded Name : Customer Phone 
	 * Desc : Phone number of the customer (if any) for a Shipment. 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneNbr:string = undefined;
	/**
	 * Expanded Name : Customer Phone Extension Number
	 * Desc : Phone Extension of the customer (if any) for a Shipment. 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneExtensionNbr:string = undefined;
	/**
	 * Expanded Name : Last Match Timestamp
	 * Desc : Identifies the last time Customer Matching was performed against this particular customer for this Service Item.
	 * Source : MBF root: MBTMDTE
	 * Required: false
	 * Array: false
	 */
	lastMatchedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded Name: Preferred Payment Currency Code
	 * Desc: The customer prefers to make payments in this currecny.
	 * Valid Values:
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	preferredPaymentCurrencyCd:CurrencyCd = undefined;
	/**
	 * Expanded Name: Alternate Customer Number
	 * Desc: Contains the customer key to an uncoded customer in the customer component.  These are pickup and delivery locations that have been a part of at least one shipment in the Con-way system.  (Note that they are not currently stored in the P  table in the customer component.) They do not have an assigned MAD code (uncoded) and are therefore identified and matched separately so that we know when we service the same physical location.
	 * OR
	 * Contains the non-debtor P location where there were multiple full matches (89% and higher) at the same perentage.  (Changed meaning of attribute 3/1/2010)
	 * Added 2/2/2010 Cathy Doucet (for Hand Held Scorecard project)
	 * Required: false
	 * Array: false
	 */
	alternateCustNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Identifies whether the system full matched the party, or if the Biller chose from a list of possibles. 
	 * Valid Values 
	 * S=System Matched
	 * U=User Selected
	 * F=User Selected from Search
	 * N=Not matched
	 * Required: false
	 * Array: false
	 */
	matchedSourceCd:MatchedPartySourceCd = undefined;
	/**
	 * Identifies if the invoice address should be read from the PICKUP_DELIVERY_LOCATION(self) versus from an associated Bill To.
	 * Required: false
	 * Array: false
	 */
	selfInvoiceInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HazMat {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceCd:HazmatSourceCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatUnna:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatBulkQuantityCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatResidueInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zoneCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packingGroupCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideMethodNm:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dotSpecialPermit:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	limitedQuantityInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportedQuantityInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	explosiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	netExplosiveMassNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	netExplosiveMassUnitOfMeasure:NetExplosiveMassUomCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	marinePollutantInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	marinePollutantChemical:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactiveChemicalName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactivityNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactivityUnitOfMeasure:RadioactivityUomCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	transportIndex:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fissileExceptedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyContactName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyContactPhoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	technicalName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unnaInstId:number = undefined;
}
/**
 * 
 */
export class CustomsBond {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : System generated sequence number assigned to each instance to uniquely identify each customs bond information for a shipment
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Bond number text
	 * 
	 * Desc : This is the IT, TE or WDTE number (7,8 or a 9 digit number) on the in-bond document. It is used to identify the Shipment while it is in the customs warehouse.
	 * 
	 * Source: MBF  Variable Segment - MBITNUM
	 * Required: false
	 * Array: false
	 */
	bondNbr:string = undefined;
	/**
	 * Expanded Name : City
	 * 
	 * Desc : Name of the City where the customs inspection is to be made.
	 * 
	 * Source: MBF Variable Segment - MBINBOND
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State
	 * 
	 * Desc : State of the city where the customs inspection is to be made.
	 * 
	 * Source: MBF Variable Segment - MBINBOND
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
}
/**
 * Information about Shipment Charges
 */
export class ChargeInfo {
	/**
	 * Required: false
	 * Array: false
	 */
	totalChargeAmt:MonetaryAmount = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalRevAmt:MonetaryAmount = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ctsRevAmt:MonetaryAmount = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalUsdAmt:MonetaryAmount = undefined;
	/**
	 * ISO 3 Letter Currency codes
	 * Required: false
	 * Array: false
	 */
	ratingCurrencyCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currencyConvFactor:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	minChrgInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cashInd:boolean = undefined;
	/**
	 * ISO 3 Letter Currency codes
	 * Required: false
	 * Array: false
	 */
	cashPrepaidText:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cashPrepaidAmt:MonetaryAmount = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cashCollInd:boolean = undefined;
	/**
	 * ISO 3 Letter Currency codes
	 * Required: false
	 * Array: false
	 */
	cashCollText:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cashCollAmt:MonetaryAmount = undefined;
}
/**
 * 
 */
export class Remark {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name :  Type code
	 * 
	 * Desc : This code can be used to categorize the remarks.  
	 * 
	 * Valid Values : 
	 * 1 - Adhoc Hazardous Material Remarks
	 * 2 - Shipping Remarks
	 * 3 - Authority Line text Remarks
	 * 4 - Delivery Attachment Remarks
	 * 5 - Operational Freight Handling Remarks
	 * 6 - Final Delivery Date Correction Remarks
	 * 
	 * Source: New/Entered
	 * 
	 * Design Considerations : This can be used for subsetting purposes and for programatically pulling certain remarks to display and/or print.
	 * Required: false
	 * Array: false
	 */
	typeCd:ShipmentRemarkTypeCd = undefined;
	/**
	 * Expanded Name: Remark Text
	 * 
	 * Desc: Remark Text
	 * Required: false
	 * Array: false
	 */
	remark:string = undefined;
}
export class InvoiceBasic {
	/**
	 * Header information about an invoice
	 * Required: false
	 * Array: false
	 */
	invoiceHeader:InvoiceDataHeader = undefined;
	/**
	 * Detail information about the invoice
	 * Required: false
	 * Array: false
	 */
	invoiceDetail:InvoiceDetail = undefined;
}
export class Invoice {
	/**
	 * Header information about an invoice
	 * Required: false
	 * Array: false
	 */
	invoiceHeader:InvoiceDataHeader = undefined;
	/**
	 * Detail information about the invoice
	 * Required: false
	 * Array: false
	 */
	invoiceDetail:InvoiceDetail = undefined;
	/**
	 * Invoice CLOB data
	 * Required: false
	 * Array: false
	 */
	invoiceClobData:InvoiceDetailData = undefined;
}
/**
 * Contains all information about the Invoice that will be saved as XML CLOB 
 */
export class InvoiceDetailData {
	/**
	 * Total number of accessorial lines in the Invoice
	 * Required: false
	 * Array: false
	 */
	accessorialCount:number = undefined;
	/**
	 * Information about the Advance Carrier
	 * Required: false
	 * Array: false
	 */
	advanceCarrier:InvoiceShipmentCarrier = undefined;
	/**
	 * Set to Y if the Shipment was delivered AllShort. Else N
	 * Required: false
	 * Array: false
	 */
	allShortInd:string = undefined;
	/**
	 * Total Amount paid for the Shipment
	 * Required: false
	 * Array: false
	 */
	amountPaid:MonetaryAmount = undefined;
	/**
	 * Authorization Remarks added during the Bill Entry time
	 * Required: false
	 * Array: false
	 */
	authRemark:string = undefined;
	/**
	 * Balance due amount on the Invoice
	 * Required: false
	 * Array: false
	 */
	balanceDueAmount:MonetaryAmount = undefined;
	/**
	 * Information about the Beyond Carrier
	 * Required: false
	 * Array: false
	 */
	beyondCarrier:InvoiceShipmentCarrier = undefined;
	/**
	 * Billing Class Code of the Shipment
	 * Required: false
	 * Array: false
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo:InvoiceDetailData_InvoiceShipmentParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	blindShipper:InvoiceShipmentParty = undefined;
	/**
	 * Set to Y if the shipment is both Bill
	 * Required: false
	 * Array: false
	 */
	bothBillInd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cashCollReqdInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	codAmount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	codCurrencyCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	commodityCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee:InvoiceShipmentParty = undefined;
	/**
	 * Mapped from COR_CORRECTION_RQST.AUTHRZ_TXT
	 * Required: false
	 * Array: false
	 */
	correctionDescription:string = undefined;
	/**
	 * Mapped from COR_CORRECTION_RQST. RQSTR_CMNT_TXT
	 * Required: false
	 * Array: false
	 */
	correctionRqstrComment:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ctsRevAmt:MonetaryAmount = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	debtorType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	driverCashCollInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	emailAddress:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileFormat:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hazmatRemark:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	inbondCity:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	inbondNumber:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	inbondState:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceAmount:MonetaryAmount = undefined;
	/**
	 * Invoice Create Date
	 * Required: false
	 * Array: false
	 */
	invoiceDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceDueDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceInternalId:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	invoiceLines:InvoiceLine[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceType:string = undefined;
	/**
	 * If Invoice Shipment BL Attachment Reqd Ind = Y then set isBolReqd= Y
	 * Else 
	 * If Correction reason of NCIC or WI exists and Invoice delivery presentation format = INDIVIDUAL then set isBolReqd = Y
	 * Required: false
	 * Array: false
	 */
	isBolReqd:boolean = undefined;
	/**
	 * If Invoice Shipment DR Attachment Reqd Ind = Y then set isDRReqd = Y
	 * Required: false
	 * Array: false
	 */
	isDrReqd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	isLoaReqd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	isNcicReqd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	isWiReqd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	obiRemark:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	orderEntryDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	otherSuppRefNbr:ReferenceNumber[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	partnerId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	partShortInd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	poundToPaper:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ratingTariffId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refNbrPO:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	refNbrSN:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sendTo:InvoiceShipmentParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper:InvoiceShipmentParty = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperRemark:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	slashToEdi:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	spotQuoteNbr:string = undefined;
	/**
	 * Total Lineal Feet of the Shipment
	 * Required: false
	 * Array: false
	 */
	totalLinealFt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalPallets:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalPieces:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalRevAmt:MonetaryAmount = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalWeight:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalWeightUom:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	transmission:InvoiceTransmission[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	transmissionDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	arDebtorParty:InvoiceShipmentParty = undefined;
	/**
	 * If Invoice Failed Guaranteed then set to True else False
	 * Required: false
	 * Array: false
	 */
	isFailedGuarantee:boolean = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customsBondInfo:CustomsBondInfo[] = undefined;
}
export class InvoiceLine {
	/**
	 * Required: false
	 * Array: false
	 */
	accessorialCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	accessorialCurrencyCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	accessorialQty:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	rateAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	accessorialUom:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	asRatedClassCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	class:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currencyCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	discountPct:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hazmatInd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoicingRemarks:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lineNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	nmfcItem:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	packaging:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pieces:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	suppRef:ReferenceNumber[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weightUom:string = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the commodity. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * 
	 * Desc : Sequential number assigned to uniquely identify  each miscelleneous charge for a Shipment. This entity stores all the differnt types of charge lines that appear on the invoice for the shipment. Not all of these lines contribute to the CTS revenue.Some examples are COD, As Weight, Deficit Weight lines etc. 
	 * 
	 * Source : Generated
	 * 
	 * Design Considerations :
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
}
export class InvoiceHdrTransmitStatus {
	/**
	 * (N)New, (T) OK to transmit, (P)No need to send
	 * Required: false
	 * Array: false
	 */
	invoiceTransmitStatus:InvoiceTransmitStatusCd = undefined;
	/**
	 * Surrogate key. Surrogate key is required because rows that are for non-both bill can be identified by SHP_INST_ID+VERSION_NBR, while rows that are for Both bill are be identified by SHP_INST_ID+charge_code+VERSION_NBR
	 * Required: false
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
	/**
	 * ID from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
}
/**
 * Basic Information about Invoice data header 
 */
export class InvoiceHdrIdBasic {
	/**
	 * Surrogate key. Surrogate key is required because rows that are for non-both bill can be identified by SHP_INST_ID+VERSION_NBR, while rows that are for Both bill are be identified by SHP_INST_ID+charge_code+VERSION_NBR
	 * Required: false
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
	/**
	 * ID from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
}
/**
 * 
 */
export class ShipmentCommodityInfo {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * Desc : A system assigned number to uniquely identify each commodity for a shipment.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : (Commodity) class
	 * Desc : Identifies the type (class) of commodity being shipped.
	 * Examples:  Carpet, Matresses, Computer paper
	 * Valid Values : 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 350, 400, 500  
	 * Source: MBF Variable Segment : MBVDESC
	 * Required: false
	 * Array: false
	 */
	classType:CommodityClassCd = undefined;
	/**
	 * Expanded Name : NMFC item code
	 * Desc : This is the NMFC (National Motor Freight Classification) code for the commodity.
	 * Source: MBF Variable Segment - MBNMFC
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Expanded Name : Source Code
	 * Desc : Code indicating the source of a particular Commodity Line.  Provides a way to distinguish between a Commodity Line on a bill that originated from the Common Shipper Bill of Lading (CSBOL) profile or a new Commodity Line that is added onto a bill where CSBOL Commodity Lines already exist.
	 * Source : FBES E-Commerce
	 * Valid Values :
	 * 1 - from CSBOL
	 * 2 - Not from CSBOL
	 * Required: false
	 * Array: false
	 */
	sourceCd:string = undefined;
	/**
	 * Expanded Name: Packaging code
	 * Desc : Type of packaging the commodity was originally picked up in. 
	 * Examples: Box, Skid, Case, Roll etc.
	 * Source: MBF Variable Segment - MBVDESC
	 * Required: false
	 * Array: false
	 */
	packageCd:CommodityPackageCd = undefined;
	/**
	 * Expanded Name : Pieces count
	 * Desc : Number of pieces of the commodity in the shipment.
	 * Source : MBF Variable Segment - MBPIECES
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * Desc : The volume of the freight; the height multiplied by the width multiplied by the depth
	 * Source :
	 * Required: false
	 * Array: false
	 */
	volumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total weight (in pounds)
	 * Desc : Total weight of the commdity in pounds. 
	 * Source: MBF Root - MBTOTWT
	 * Required: false
	 * Array: false
	 */
	weightLbs:number = undefined;
	/**
	 * Expanded Name : Reduced weight
	 * Desc : This is the weight deducted from the actual weight of the commodity to get the net weight. This weight is normally the pallet or the container weight. This part of the weight gets a free ride.
	 * Source : MBF Variable Segment - MBWT
	 * Required: false
	 * Array: false
	 */
	reducedWeight:number = undefined;
	/**
	 * Expanded Name : Rating Unit of Measure
	 * Desc : A code to identify the units in terms of which the quantity is measured for calculating charges. For eg. weight (in pounds) is the most common unit of measure for commodities.
	 * Valid Values : 
	 * 1 - Weight (in pounds)
	 * 2 - Yardage (for carpets)
	 * 3 - Count
	 * Required: false
	 * Array: false
	 */
	ratingUnitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantity
	 * Desc : Quantity (measured in terms of UOM) to be used when calculating charges for the commodity.
	 * Required: false
	 * Array: false
	 */
	ratingQuantity:number = undefined;
	/**
	 * Expanded Name : Freezable Indicator 
	 * Desc : Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * Valid Values : Y/N
	 * Y - Can be damaged by freezing
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous material indicator
	 * Desc : Indicates whether the commodity is a hazardous material. 
	 * Source : MBF Variable Segment : MBNMHMX
	 * Valid Values :
	 * Y - Has hazardous materials
	 * N - No hazardous materials
	 * Required: false
	 * Array: false
	 */
	hazardousMtInd:boolean = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * Desc : This is the rate used to calculate the total freight charges for a commodity. The rate is multiplied by the unit of measure (weight in most cases) to calculate the total charges. 
	 * Source : MBF Variable Segment -  MBCRATE, MBRATES
	 * Required: false
	 * Array: false
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * Desc : The dollar amount of the charge for the commodity.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 * Required: false
	 * Array: false
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the commodity. 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name : Mixed class commodity exists indicator
	 * Desc :
	 * Source : MBF: root: MBMXCLS (x 01  of MBFLAGA)
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	mixClassCommodityInd:boolean = undefined;
	/**
	 * Expanded Name : Description
	 * Desc : A textual description of the commodity
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	asRatedClassCd:CommodityClassCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chrgToCd:LineItemChargeToCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	commodityDimension:CommodityDimension[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazMat:HazMat = undefined;
}
/**
 * 
 */
export class SuppRefExtendedDetail {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name: Packaging Type Text
	 * 																																																							
	 * Desc : Type of packaging the commodity was originally    
	 * picked up in.                                          
	 * 																																																							
	 * Examples: Box, Skid, Case, Roll etc.                   
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 * Required: false
	 * Array: false
	 */
	packageType:string = undefined;
	/**
	 * Expanded Name : Unit of Measure                             
	 * 																																																												
	 * Desc : A code to identify the units in terms of which       
	 * the quantity is measured. For       
	 * eg. weight (in pounds) is the most common unit of           
	 * measure for commodities.                                    
	 * 																																																												
	 * Valid Values :                                              
	 * 1 - Weight (in pounds)                                      
	 * 2 - Yardage (for carpets)                                   
	 * 3 - Count
	 * Required: false
	 * Array: false
	 */
	unitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantify
	 * Desc : Commodity line item quantify associated with this specific supplemental reference number.  Usually expressed in pounds (reference the UOM - Unit of Measure to verify).
	 * Required: false
	 * Array: false
	 */
	quantity:number = undefined;
	/**
	 * Expanded Name : Pieces Count
	 * Desc : Commodity line pieces associated with this specific supplemental reference number.
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name: Pallet or Slip Indicator
	 * 																																																							
	 * Desc : Used on a VICS bill of lading to indicate that the freight at time of pickup was loaded on either a pallet or a slip sheet.
	 * Valid Values:  Y/N
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 * Required: false
	 * Array: false
	 */
	palletOrSlipInd:boolean = undefined;
	/**
	 * Expanded Name: Additional Shipper Information
	 * 																																																							
	 * Desc : Contains additional comments supplied from the shipper, usually regarding how the freight should be handled.
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 * Required: false
	 * Array: false
	 */
	additionalShipperInformation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class SuppRefNbr {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Type code:
	 * Desc : A code describing what the Supplemental Reference is (for eg. PO# for Purchase Order number and GBL for Government Bill of Lading). These are entered by and have a meaning for the customer only. There is no list of valid values. 
	 * Examples :
	 * Purchase orders
	 * Shipper numbers
	 * GBL numbers
	 * SLI numbers 
	 * Bookclosing numbers
	 * Source: E-commerce
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * Expanded Name : Sequence number
	 * Desc : Sequentially assigned number to preserve the order in which various supplemental reference numbers are to be displayed / printed for a commodity / shipment. 
	 * Source : Generated
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Number text
	 * Desc : This is the actual number provided by the customer (sometimes also known as COSTAR - (C)ustomer (O)wned (S)hipment and (T)racking numbers). This valid only when recording supplemental references for a shipment.
	 * Required: false
	 * Array: false
	 */
	refNbr:string = undefined;
	/**
	 * Expanded Name : Description
	 * Desc : A textual description of what the Supplemental Reference number is (Purchase order number, shipper number, etc.)
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name : Commodity Sequence Number
	 * Desc : Sequence number of the specific Commodity (if any) to which the supplemental reference number applies. Should be 0 if the number applies to the Shipment in general.
	 * Required: false
	 * Array: false
	 */
	commoditySequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Desc: Source of the Supplemental Reference Number.
	 * Values:
	 * EDI
	 * WEB
	 * XML
	 * BIL - Billing
	 * COR - currently BE36 transaction (Aug.2011)
	 * Required: false
	 * Array: false
	 */
	sourceCd:string = undefined;
	/**
	 * Desc: Sender-ID of the EDI Customer.
	 * Required: false
	 * Array: false
	 */
	ediSenderId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	suppRefExtendedDetail:SuppRefExtendedDetail[] = undefined;
}
export class InvoiceTransmission {
	/**
	 * Date when Invoice should be sent. Applies only if Invoice status is R-ready for Transmission. In most cases, it is the same date when status changes to R. In thoses cases where customer wants invoices on certain days, such as Tuesday, this can be a future date.
	 * Required: false
	 * Array: false
	 */
	scheduledTransmissionDate:Date = undefined;
	/**
	 * Depending on the delivery mode, this can have one of the following.
	 * EMAIL= Email Address 
	 * FTP = ftp_routing_id given by ETS
	 * FAX = fax#
	 * EDI = Sent_to_party_cis_mad_Cd (mad cd is used by EDI not CIS#) If not matched then Name/addr
	 * Paper=Sent_to_party_cis_mad_Cd  (users query by specifying mad code). If not matched then Name/addr
	 * Required: false
	 * Array: false
	 */
	sendTo:string = undefined;
	/**
	 * Delivery Mode is one of the following
	 * EDI, 
	 * PAPER, 
	 * EMAIL (EIP), 
	 * FAX (EIP),
	 * FTP (EIP), 
	 * SELF-PAID(not sent).
	 * Required: false
	 * Array: false
	 */
	deliveryModeCd:InvoiceModeCd = undefined;
	/**
	 * This information is used when a file is created for EDI and Paper.
	 * S- Statement, 
	 * I-Individual Invoice, 
	 * M-Manifest. 
	 * Following applies only for EIP. It depends on the delivery mode.
	 * Valid values are:
	 * PDF         - applies to EMAIL, FTP,
	 * PDFIMG      - applies to EMAIL, FTP,
	 * CSV         - applies to EMAIL, 
	 * CSV-W-ACC   - csv includes each Accessorial as a separate set of fields, applies to EMAIL, FTP 
	 * XML         - Applies to EMAIL, FAX,FTP. Note XML here refers to the XML document sent to the customer and not the XML sent to EDI/Paper printing app.
	 * Required: false
	 * Array: false
	 */
	presentationFormatCd:InvoicePresentationFormatCd = undefined;
}
export class InvoiceShipmentParty {
	/**
	 * Required: false
	 * Array: false
	 */
	custMadCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	custNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	addressLine1:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
}
export class InvoiceShipmentCarrier {
	/**
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	revAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	revCurrCd:string = undefined;
	/**
	 * Expanded Name: Carrier pick up date
	 * 
	 * Desc: This is the date the carrier picked up the shipment. 
	 * 
	 * In case of an advance carrier this is the date the carrier picked it up from the customer.
	 * 
	 * 	In case of beyond carriers, it is the date the carrier picked up the shipment from CTS.
	 * Required: false
	 * Array: false
	 */
	carrierPickupDate:Date = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This is  the other carrier s PRO number that identifies the shipment for the carrier in their systems.
	 * Required: false
	 * Array: false
	 */
	carrierProNbr:string = undefined;
}
export class InvoiceSendTo {
	/**
	 * C=COLLECT, 
	 * P=PREPAID
	 * For a given PRO, two invoices could have different values because of following two reasons.
	 * 1. Not a both bill but the charge code was changed by correction
	 * 2. Both bill which creates two invoices one for Prepaid and second for Collect'
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Delivery Mode is one of the following
	 * EDI, 
	 * PAPER, 
	 * EMAIL (EIP), 
	 * FAX (EIP),
	 * FTP (EIP), 
	 * SELF-PAID(not sent).
	 * Required: false
	 * Array: false
	 */
	deliveryModeCd:InvoiceModeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	groupNbr:number = undefined;
	/**
	 * Surrogate key. Surrogate key is required because rows that are for non-both bill can be identified by SHP_INST_ID+VERSION_NBR, while rows that are for Both bill are be identified by SHP_INST_ID+charge_code+VERSION_NBR
	 * Required: false
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
	/**
	 * Surrogate key.  This natural key would have been INVC_INVOICE_DATA_HDR_ID+SEND_TO+EIP_ATTM_FMT_CD. However SEND_TO is a wide column. Hence surrogate key is ussed
	 * Required: false
	 * Array: false
	 */
	invoiceDeliveryId:number = undefined;
	/**
	 * '
	 * Valid Values
	 * INDIVIDUAL   Single invoice. applies to Paper, EDI
	 * STATEMENT    Presentation application combinies multiple invoices, applies to Paper
	 * MANIFEST     Presentation application combinies multiple invoices, applies to EDI
	 * following apply to EMAIL, FTP 
	 * CSV            
	 * CSV-ACC      CSV with Accessorial
	 * NO-ATTM      No attachments
	 * PDF          Invoice image as PDF
	 * PDF-IMAGE    Invoice image as PDF with attachment images
	 * XML          Invoice data required with XML doc attachment'
	 * Required: false
	 * Array: false
	 */
	presentationFormatCd:InvoicePresentationFormatCd = undefined;
	/**
	 * Depending on the delivery mode, this can have one of the following.
	 * EMAIL= Email Address 
	 * FTP = ftp_routing_id given by ETS
	 * FAX = fax#
	 * EDI = Sent_to_party_cis_mad_Cd (mad cd is used by EDI not CIS#) If not matched then Name/addr
	 * Paper=Sent_to_party_cis_mad_Cd  (users query by specifying mad code). If not matched then Name/addr
	 * Required: false
	 * Array: false
	 */
	sendTo:string = undefined;
	/**
	 * Identifies the shipment that is invoiced. The value comes from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * When the Invoice
	 * Required: false
	 * Array: false
	 */
	dmsInvoiceImageUrl:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNumber:string = undefined;
}
export class InvoicePreference {
	/**
	 * Y- Indicates that BL is required for invoicing
	 * 				If set to Y, invoicing is held until BL is scanned and available. 
	 * 				BL attachment is applicable only for PAPER and EIP.
	 * 				The child table for MODE indicates which of the transmission mode should attach the BL (in case when invoice is sent via multiple modes or by mutliple emails)
	 * 				
	 * N - Invoice will not be held for BL
	 * Required: false
	 * Array: false
	 */
	bolReqdInd:string = undefined;
	/**
	 * Y- Indicates that DR is required for invoicing
	 * 				If set to Y, invoicing is held until DR is scanned and available. 
	 * 				DR attachment is applicable only for PAPER and EIP.
	 * 				The child table for MODE indicates which of the transmission mode should attach the DR  (in case when invoice is sent via multiple modes or by mutliple emails)
	 * 				
	 * N - Invoice will not be held for DR.
	 * Required: false
	 * Array: false
	 */
	drReqdInd:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceDailyInd:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceMonInd:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceTueInd:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWedInd:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceThuInd:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceFriInd:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWeek1Ind:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWeek2Ind:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWeek3Ind:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWeek4Ind:string = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWeek5Ind:string = undefined;
	/**
	 * PAPER
	 * EDI
	 * EMAI
	 * FTP
	 * SELF-PAID
	 * Required: false
	 * Array: false
	 */
	invoiceModeCd:InvoiceModeCd = undefined;
	/**
	 * Valid Values
	 * INDIVIDUAL   Single invoice. applies to Paper, EDI
	 * STATEMENT    Presentation application combinies multiple invoices, applies to Paper
	 * MANIFEST     Presentation application combinies multiple invoices, applies to EDI
	 * following apply to EMAIL, FTP 
	 * CSV            
	 * CSV-ACC      CSV with Accessorial
	 * NO-ATTM      No attachments
	 * PDF          Invoice image as PDF
	 * PDF-IMAGE    Invoice image as PDF with attachment images
	 * XML          Invoice data required with XML doc attachment
	 * Required: false
	 * Array: false
	 */
	presentationFormatCd:InvoicePresentationFormatCd = undefined;
}
export class InvoicePreferenceAddressDetail {
	/**
	 * Required: false
	 * Array: false
	 */
	invoicePreference:Preference = undefined;
	/**
	 * The bill-to customer address to which the invoice should be sent.
	 * Required: false
	 * Array: false
	 */
	invoiceSendToAddress:Address = undefined;
	/**
	 * The Mad Code for the bill-to customer address to which the invoice should be sent.
	 * Required: false
	 * Array: false
	 */
	invoiceSendToMadCd:string = undefined;
}
/**
 * Information about creating Invoice Hold record
 */
export class InvoiceHold {
	/**
	 * ID from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Reason codes for hold. PRO could be held for multiple reasons. Until all reasons are resolved, the invoicing cannot happen.
	 * Required: false
	 * Array: false
	 */
	holdReasonCd:string = undefined;
	/**
	 * Person(manual hold), App ID (system hold) who put the hold. 
	 * This is in lieu of the field CRTE_BY_ID
	 * Required: false
	 * Array: false
	 */
	holdById:string = undefined;
	/**
	 * Description for Reason for hold
	 * Required: false
	 * Array: false
	 */
	holdDescription:string = undefined;
}
/**
 * Contains Identifying information for a shipment
 */
export class ShipmentBasic {
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNumber:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * Should be populated if resending one of the both bill Invoice
	 * Valid values are: Collect,
	 * Prepaid
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
}
/**
 * Contains Identifying information for a shipment
 */
export class InvoiceShipmentBasic {
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNumber:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * Surrogate key. Surrogate key is required because rows that are for non-both bill can be identified by SHP_INST_ID+VERSION_NBR, while rows that are for Both bill are be identified by SHP_INST_ID+charge_code+VERSION_NBR
	 * Required: false
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
}
export class InvoiceSummary {
	/**
	 * Identifies the shipment that is invoiced. The value comes from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * PRO# of the shipment invoiced.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Pickup date of the shipment invoiced.
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * Final Delivery date of the shipment.  Shipment is not invoiced until it is final delivered. This is decided based on shm dlvr_qlfr_cd (Z-final) and also in some cases where  J-Part short, K-full short and guarantee_flag=false. then it is final delivered. Also service status cd is considered. Complete logic is documented in Functional design doc.
	 * Required: false
	 * Array: false
	 */
	finalDeliveryDate:Date = undefined;
	/**
	 * This is as per invoice instruction for the party receiving the bill (SEND_TO_PARTY_CIS_NBR)
	 * Y-BL image needs to be attached to the invoice. 
	 * N-Not required, '
	 * Required: false
	 * Array: false
	 */
	bolAttachmentRequiredInd:boolean = undefined;
	/**
	 * This is as per invoice instruction for the party receiving the bill (SEND_TO_PARTY_CIS_NBR)
	 * Y-BL image needs to be attached to the invoice. 
	 * N-Not required, '
	 * Required: false
	 * Array: false
	 */
	drAttachmentRequiredInd:boolean = undefined;
	/**
	 * This attribute is meaningfule only when DR_Attach_reqd_ind =Y
	 * Y-DR attachment is available
	 * N- DR attachment is not yet available.'
	 * Required: false
	 * Array: false
	 */
	drAttachmentAvailableInd:boolean = undefined;
	/**
	 * This is null until the shipment is delivered.
	 * Following values are assigned when the shipment is delivered. The values are from SHM_SHIPMENT.
	 * J=Delivery marked complete as All short (we lost the shipment, customer has to pay and then make a claim - business rule)
	 * K=Partial short/MBD1PSRT. Delivered but some pieces were lost and not delivered.
	 * Z= Delivered all good.
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * Surrogate key. Surrogate key is required because rows that are for non-both bill can be identified by SHP_INST_ID+VERSION_NBR, while rows that are for Both bill are be identified by SHP_INST_ID+charge_code+VERSION_NBR
	 * Required: false
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
	/**
	 * Sequence increments when data for new invoice version for the PRO is created. 
	 * A new version is determined as follows: 
	 * If the PRO is non-both-bill then when a new invoice is generated (rating, SR change, payment received), next version number is assigned. 
	 * If the PRO is both bill, then same version# is assigned to the PREPAID and the COLLECT invoice. 
	 * In case of payment application for a Both-bill, only one Invoice will be created. In this case the next time rating/SRN creates new pair of invoices, there will be a gap for one of the charge_code.
	 * Thus for non-both bill (99% cases), SHP_INST_ID +VERSION_sEQ_NBR makes a record unique. For both bill, SHP_INST_ID +VERSION_SEQ+CHRGE_TO_CD makes the row unique. Thus the primary key for the table is SHP_INST_ID +VERSION_SEQ+CHRGE_TO_CD.
	 * Required: false
	 * Array: false
	 */
	versionSequenceNbr:number = undefined;
	/**
	 * C=COLLECT, 
	 * P=PREPAID
	 * For a given PRO, two invoices could have different values because of following two reasons.
	 * 1. Not a both bill but the charge code was changed by correction
	 * 2. Both bill which creates two invoices one for Prepaid and second for Collect'
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Y=YES
	 * N=NO'
	 * Required: false
	 * Array: false
	 */
	bothBillInd:boolean = undefined;
	/**
	 * This is mad_cd corresponding to sent_to_party_cis_nbr.
	 * Required: false
	 * Array: false
	 */
	sentToPartyCisMadCd:string = undefined;
	/**
	 * Invoice Amount shown on the bill that customer is required to pay
	 * Required: false
	 * Array: false
	 */
	invoiceAmount:number = undefined;
	/**
	 * USD, CAD
	 * Required: false
	 * Array: false
	 */
	currencyCd:CurrencyCd = undefined;
	/**
	 * If Customer has made partial payment, then this is set to Invoice Amount minus Amount Credited. Generally Amount Credited is same as Amount Paid by the customer.
	 * Required: false
	 * Array: false
	 */
	invoiceBalanceAmount:number = undefined;
	/**
	 * Date by which Payment due
	 * Required: false
	 * Array: false
	 */
	dueDate:Date = undefined;
	/**
	 * When the Invoice
	 * Required: false
	 * Array: false
	 */
	dmsInvoiceImageUrl:string = undefined;
	/**
	 * Valid Values - O - Original Invoice, B - Balance Due Invoice, C - Corrected Invoice
	 * Required: false
	 * Array: false
	 */
	invoiceTypeCd:InvoiceTypeCd = undefined;
	/**
	 * Current Date - Invoice Create Date
	 * Required: false
	 * Array: false
	 */
	invoiceAge:number = undefined;
	/**
	 * Date when Invoice should be sent. Applies only if Invoice status is R-ready for Transmission. In most cases, it is the same date when status changes to R. In thoses cases where customer wants invoices on certain days, such as Tuesday, this can be a future date.
	 * Required: false
	 * Array: false
	 */
	scheduledTransmissionDate:Date = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	deliveryInfo:InvoiceDelivery[] = undefined;
}
export class CustomsBondInfo {
	/**
	 * Required: false
	 * Array: false
	 */
	bondNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
}
export class InvoiceDeliveryStatus {
	/**
	 * The timestamp when the invoice status change to reflect the transmission life cycle.
	 * Required: false
	 * Array: false
	 */
	statusDateTime:Date = undefined;
	/**
	 * Records the progress of Invoice transmission once the invoice is sent to EDI application.
	 * SENT_TO_SI
	 * 	SENT_TO_ETS
	 * 	SENT_TO_CUST
	 * 	CUST_ACCEPTED
	 * Required: false
	 * Array: false
	 */
	statusCd:InvoicePostDeliveryStatusCd = undefined;
}
/**
 * 
 */
export class AccuracyReq {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceAccuracyReqInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requirementTypeCd:InvoiceRequirementTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requirementDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exprDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerTypeCd:CustomerTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerGroupInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	debtorOnlyRuleInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requirementCategoryCd:InvoiceRequirementCategoryCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Desc: The employee id of the Requestor for the add or change.
	 * 
	 * 05/11/2018 Cathy Doucet Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrEmplid:string = undefined;
	/**
	 * Desc: The name of the Requestor for the add or change.
	 * 
	 * 05/11/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrName:string = undefined;
	/**
	 * Desc: The email address of the Requestor for the add or change.
	 * 
	 * 05/11/2018 Cathy Doucet Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrEmailAddress:string = undefined;
	/**
	 * Desc: The phone number of the Requestor for the add or change.
	 * 
	 * 05/11/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrPhoneNbr:string = undefined;
	/**
	 * Indicates a requirement that is to be applied universally and not dependent upon Customer Id or Group Id.
	 * Required: false
	 * Array: false
	 */
	universalRqmtInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	accuracyReqConstraint:AccuracyReqConstraint[] = undefined;
}
/**
 * 
 */
export class AccuracyReqConstraintRef {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceAccuracyReqConstraintRefId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	constraintCd:InvoiceAccuracyConstraintCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	constraintName:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class AccuracyReqConstraint {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceAccuracyReqConstraintId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	constraintName:InvoiceAccuracyConstraintCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evalOperator:InvoiceEvalOperCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	constraintValue:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	constraintValueNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class AccuracyReqResult {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoicingAccuracyReqResultId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	resultName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	resultValue:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class AppliedPayment {
	/**
	 * This is from the parent Shipment table.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * This is the amount applied to the PRO in the AR financial system.  
	 * 
	 * The current balance for the invoice for the PRO can computed as Invoice Amount minus this amount.
	 * 
	 * 
	 * 
	 * If this is a Both-Bill then this amount is the part that is applied to Prepaid.
	 * Required: false
	 * Array: false
	 */
	appliedAmount:number = undefined;
	/**
	 * This applies for both bill only. It is same as APPLIED_AMT excect that it is the amount that is applied to the Collect part.
	 * Required: false
	 * Array: false
	 */
	appliedAmt2:number = undefined;
	/**
	 * Currency Code for the balance amounts.   
	 * In case of Both-bill, although in theory it is possible that prepaid part and collect part can have different currencies, scan of all pro from PRDA 2008 onwards show both use one currency. This is because the Shipment records the amounts in one currency. 
	 * Values
	 * USD
	 * CAD
	 * Required: false
	 * Array: false
	 */
	arCurrencyCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * An invoice could be held from being sent for various reasons. 
 * An entry is made in this table when an invoice is being held, and the status can be later updated to released
 */
export class HoldInvoicing {
	/**
	 * ID from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The date/time when the hold was put on the shipment, preventing it from invoicing. 
	 * This is in lieu of audit field CRTE_TMST
	 * Required: false
	 * Array: false
	 */
	holdDateTime:Date = undefined;
	/**
	 * When hold is put, a release date can be specified
	 * Required: false
	 * Array: false
	 */
	scheduledReleaseDate:Date = undefined;
	/**
	 * Date/Time when the invoice was actually released for processing. 
	 * This is in lieu of audit field LST_UPDT_TMST
	 * Required: false
	 * Array: false
	 */
	releaseDateTime:Date = undefined;
	/**
	 * Y-manually held, N-Systematically held
	 * Required: false
	 * Array: false
	 */
	manualHoldInd:boolean = undefined;
	/**
	 * Reason codes for hold. PRO could be held for multiple reasons. Until all reasons are resolved, the invoicing cannot happen.
	 * Required: false
	 * Array: false
	 */
	holdReasonCd:string = undefined;
	/**
	 * Person(manual hold), App ID (system hold) who put the hold. 
	 * This is in lieu of the field CRTE_BY_ID
	 * Required: false
	 * Array: false
	 */
	holdById:string = undefined;
	/**
	 * Person(manual release), App ID (systematically released, e.g. 7day rule) who released the hold. 
	 * This is in lieu of the field LST_UPDT_BY_ID
	 * Required: false
	 * Array: false
	 */
	releaseById:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Describes reason and possible resolution that will help the person to release the hold.
	 * Required: false
	 * Array: false
	 */
	holdDescription:string = undefined;
}
/**
 * Created as required based on delivery and presentation mode (see extension_typ_Cd), when new Invoice Data is created.
 */
export class InvoiceDataDetail {
	/**
	 * Part of the Identifier. The value comes from the identifier of the parent Entity
	 * Required: false
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
	/**
	 * The type of data stored in DETAIL_DATA column. 
	 * Valid values: 
	 * XML
	 * JSON
	 * Required: false
	 * Array: false
	 */
	detailDataTypeCd:InvoiceDetailDataTypeCd = undefined;
	/**
	 * Standard XML that has all the data elements of an invoice as needed by various downstream applications
	 * Required: false
	 * Array: false
	 */
	detailData:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Records important information about a customer Invoice.
 */
export class InvoiceDataHeader {
	/**
	 * Surrogate key. Surrogate key is required because rows that are for non-both bill can be identified by SHP_INST_ID+VERSION_NBR, while rows that are for Both bill are be identified by SHP_INST_ID+charge_code+VERSION_NBR
	 * Required: false
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
	/**
	 * Sequence increments when data for new invoice version for the PRO is created. 
	 * A new version is determined as follows: 
	 * If the PRO is non-both-bill then when a new invoice is generated (rating, SR change, payment received), next version number is assigned. 
	 * If the PRO is both bill, then same version# is assigned to the PREPAID and the COLLECT invoice. 
	 * In case of payment application for a Both-bill, only one Invoice will be created. In this case the next time rating/SRN creates new pair of invoices, there will be a gap for one of the charge_code.
	 * Thus for non-both bill (99% cases), SHP_INST_ID +VERSION_sEQ_NBR makes a record unique. For both bill, SHP_INST_ID +VERSION_SEQ+CHRGE_TO_CD makes the row unique. Thus the primary key for the table is SHP_INST_ID +VERSION_SEQ+CHRGE_TO_CD.
	 * Required: false
	 * Array: false
	 */
	versionSequenceNbr:number = undefined;
	/**
	 * C=COLLECT, 
	 * P=PREPAID
	 * For a given PRO, two invoices could have different values because of following two reasons.
	 * 1. Not a both bill but the charge code was changed by correction
	 * 2. Both bill which creates two invoices one for Prepaid and second for Collect'
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Y=YES
	 * N=NO'
	 * Required: false
	 * Array: false
	 */
	bothBillInd:boolean = undefined;
	/**
	 * Customer that received the invoice. 
	 * If billto is not recorded in the SHM_AS_ENTD_CUST by bill entry/correction, then the SENT TO PARTY is Shipper CIS# for prepaid and Consignee CIS# for Collect
	 * 
	 * In many cases Billto CIS# is recorded in SHM_AS_ENTD_CUST and is the SENT_TO_PARTY. However, if the Bill2 in the SHM has CIS invc instr (CIS_INVC_TYPE) then it is bill2 to bill2 situation and the bill2 for the bill2 is used as the Sent to Party.
	 * Required: false
	 * Array: false
	 */
	sentToPartyCisNbr:number = undefined;
	/**
	 * This is mad_cd corresponding to sent_to_party_cis_nbr.
	 * Required: false
	 * Array: false
	 */
	sentToPartyCisMadCd:string = undefined;
	/**
	 * Matched CIS Shipper
	 * Required: false
	 * Array: false
	 */
	shipperCisCustomerNbr:number = undefined;
	/**
	 * Matched CIS Bill2 as recorded in SHM_AS_ENTD_CUST
	 * Required: false
	 * Array: false
	 */
	bill2CisCustomerNbr:number = undefined;
	/**
	 * Matched CIS Consignee
	 * Required: false
	 * Array: false
	 */
	consigneeCisCustomerNbr:number = undefined;
	/**
	 * USD, CAD
	 * Required: false
	 * Array: false
	 */
	currencyCd:CurrencyCd = undefined;
	/**
	 * Invoice Amount shown on the bill that customer is required to pay
	 * Required: false
	 * Array: false
	 */
	invoiceAmount:number = undefined;
	/**
	 * If Customer has made partial payment, then this is set to Invoice Amount minus Amount Credited. Generally Amount Credited is same as Amount Paid by the customer.
	 * Required: false
	 * Array: false
	 */
	invoiceBalanceAmount:number = undefined;
	/**
	 * This is a derived field.
	 * 	It is set to Y if there exists another row for same Shp_inst_id + send to party) in (S)ent status and either
	 * 1. the existing header is already marked corrected and/or
	 * 2. the invc_amt of the new one is different from the existing one
	 * 
	 * 	Note: Sent Status is at Dlvry level, not at header. If there exists at least one dlvry row in Sent then we consider the Invoice (hdr) as sent for the purpose of determining this field.
	 * Required: false
	 * Array: false
	 */
	correctedInd:boolean = undefined;
	/**
	 * SRN Number type PO# (fist occurance)
	 * Required: false
	 * Array: false
	 */
	suppRefNbrPo:string = undefined;
	/**
	 * SRN Number type SN# (fist occurance)
	 * Required: false
	 * Array: false
	 */
	suppRefNbrSn:string = undefined;
	/**
	 * Date when Invoice should be sent. Applies only if Invoice status is R-ready for Transmission. In most cases, it is the same date when status changes to R. In thoses cases where customer wants invoices on certain days, such as Tuesday, this can be a future date.
	 * Required: false
	 * Array: false
	 */
	scheduledTransmissionDate:Date = undefined;
	/**
	 * Date by which Payment due
	 * Required: false
	 * Array: false
	 */
	dueDate:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * N- New  /Not ready for transmission 
	 * 			Reasons why the status is not Ok to transmit is recorded in INV_INVOICE_NOT_RDY_TRANS_RSN
	 * 			
	 * T- OK to Transmit (final dlvrd and all 
	 * 																Reqd attachment avail)
	 * 								Processes that sets this must also add 
	 * 																		reqd attachment images AND 
	 * 																		PDF image to  Invoice Data.
	 * 							The actual transmission will happen on the scheduled_send_Date
	 * 							
	 * E - Expired.  New Row replaced this, Only the invoice that has no INV_INVOICE_DLVRY.Sent_ind = Y can be set to E. That means the invoice was never sent and a new record is being inserted.
	 * 
	 * P - No need to send, as fully Paid or Amt due below threashold (<$5)
	 * P_SELF_PAID. Invoice not sent, Customer pays based on their own rating engine.   
	 * P_CASHPPD Cash was collected by driver at pickup. No invoice to be sent
	 * P_CASHCOLL Cash was collected by driver at Delivery. No invoice to be sent
	 * 
	 * 
	 * X - Do not send Invoice as requested during correction COR_STOP_INVC_RQST
	 * Required: false
	 * Array: false
	 */
	transmitStatusCd:InvoiceTransmitStatusCd = undefined;
	/**
	 * When the Invoice
	 * Required: false
	 * Array: false
	 */
	dmsInvoiceImageUrl:string = undefined;
	/**
	 * N - not a reprint invoice
	 * Y - Created as a result of reprint request. Reprint is actuall recreate. It creates a new invc with latest data. Also the normal delivery mode can be override. e.g. EDI can be sent as paper.
	 * Required: false
	 * Array: false
	 */
	reprintInd:boolean = undefined;
	/**
	 * After EDI rejects an invoice, next invoice can be forced through EDI using slash(force EDI) or pound to paper option.
	 * Values
	 * S - SLASH-TO-EDI
	 * P - POUND-TO-PAPER
	 * Required: false
	 * Array: false
	 */
	ediSlashPoundCd:EdiSlashPoundCd = undefined;
	/**
	 * Valid values
	 * PREPAID    - Prepaid
	 * COLLECT    - Collect
	 * 3P               - 3rd Party
	 * Required: false
	 * Array: false
	 */
	billTypeCd:BillTypeCd = undefined;
	/**
	 * Valid Values
	 * O - Original Invoice. 
	 * B - Balance Due invoice.  
	 * C - Correct  invoice.
	 * Required: false
	 * Array: false
	 */
	invoiceTypeCd:InvoiceTypeCd = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Emp ID of the person who requested the reprint
	 * Required: false
	 * Array: false
	 */
	reprintRequestedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	invoiceDataDetail:InvoiceDataDetail[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	invoiceDelivery:InvoiceDelivery[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	invoiceNotRdyTransportReason:InvoiceNotRdyTransportReason[] = undefined;
}
/**
 * Records transmission information for an invoice to the customer. For example, an invoice could be delivered by EDI, and emailed to 3 different email ids.  In this case there will be 4 rows created. Each will track the transimission information.
 */
export class InvoiceDelivery {
	/**
	 * Surrogate key.  This natural key would have been INVC_INVOICE_DATA_HDR_ID+SEND_TO+PRESENTATION_FMT_CD. However SEND_TO is a wide column. Hence surrogate key is ussed
	 * Required: false
	 * Array: false
	 */
	invoiceDeliveryId:number = undefined;
	/**
	 * Depending on the delivery mode, this can have one of the following.
	 * EMAIL= Email Address 
	 * FTP = ftp_routing_id given by ETS
	 * FAX = fax#
	 * EDI = Sent_to_party_cis_mad_Cd (mad cd is used by EDI not CIS#) If not matched then Name/addr
	 * Paper=Sent_to_party_cis_mad_Cd  (users query by specifying mad code). If not matched then Name/addr
	 * Required: false
	 * Array: false
	 */
	sendTo:string = undefined;
	/**
	 * Delivery Mode is one of the following
	 * EDI, 
	 * PAPER, 
	 * EMAIL (EIP), 
	 * FAX (EIP),
	 * FTP (EIP), 
	 * SELF-PAID(not sent).
	 * Required: false
	 * Array: false
	 */
	deliveryModeCd:InvoiceModeCd = undefined;
	/**
	 * Valid Values
	 * INDIVIDUAL   Single invoice. applies to Paper, EDI
	 * STATEMENT    Presentation application combinies multiple invoices, applies to Paper
	 * MANIFEST     Presentation application combinies multiple invoices, applies to EDI
	 * 
	 * following apply to EMAIL, FTP 
	 * CSV            
	 * CSV-ACC      CSV with Accessorial
	 * NO-ATTM      No attachments
	 * PDF          Invoice image as PDF
	 * PDF-IMAGE    Invoice image as PDF with attachment images
	 * XML          Invoice data required with XML doc attachment
	 * Required: false
	 * Array: false
	 */
	presentationFormatCd:InvoicePresentationFormatCd = undefined;
	/**
	 * Y- Sent to consuming application
	 * N- Not sent. The reason is recorded in TRANSMIT_STAT_CD in the header.
	 * Required: false
	 * Array: false
	 */
	sentInd:boolean = undefined;
	/**
	 * DTTM when the invoice was sent to the consuming application (E.g. Mainframe for paper and EDI, Email server for Email)
	 * Required: false
	 * Array: false
	 */
	sentDateTime:Date = undefined;
	/**
	 * Tibco creates the file to be sent to Mainframe (EDI,Paper), and to EIP consumers) when the status is changing to Sent. 
	 * This stores the file that was created. 
	 * It is mainly for audit. 
	 * The file is dropped in NFS under appdataprod, PROD, ConwayInvoicingPresentment.  Only for Paper  and EDI and ETS sends the file to Mainframe.
	 * Required: false
	 * Array: false
	 */
	sentFileName:string = undefined;
	/**
	 * Typically applies for EDI. After Invoicing sends an invoice to EDI, EDI might not be able to deliver the invoice. 
	 * In most cases, EDI is able to capture the error. 
	 * This attributes gets the feedback from EDI
	 * This can also be set if Tibco is not able to deliver the invoice. In this case, the Sent Ind will remain N. 
	 * Later, after Tibco successfully sends the invoice, this field retains the old error cd value for audit purpose
	 * Required: false
	 * Array: false
	 */
	transmissionErrCd:string = undefined;
	/**
	 * Human readable description of the error reported by the consuming application.
	 * Required: false
	 * Array: false
	 */
	transmissionErrDescr:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Value denormed from B2B_TRS table that identifies whether the invoice went through the parallel stream (Y), or through the actual production stream (N).  Added 4/30/19 as per Ty Kouri/Gary Battershell
	 * Required: false
	 * Array: false
	 */
	parallelInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	invoicePostDeliveryStatusLog:InvoicePostDeliveryStatusLog[] = undefined;
}
/**
 * 
 */
export class InvoiceNotRdyTransportReason {
	/**
	 * Foreign key from the parent header.
	 * Required: false
	 * Array: false
	 */
	invoiceDataHeaderId:number = undefined;
	/**
	 * Reason code why invoice cannot be transmitted.
	 * NOT-DLVR         -  PRO is not yet delivered.
	 * MISS-DR            -  DR has not been scanned.
	 * PEND-CORR      -  Pending correction
	 * HOLD                 - Invoice is held as indicated in   INV_HOLD_INVOICING
	 * Required: false
	 * Array: false
	 */
	reasonCd:InvoiceHoldReasonCd = undefined;
	/**
	 * Further describes the reason why Invoice is not ready for transmission
	 * Required: false
	 * Array: false
	 */
	reasonDescr:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class InvoiceShipment {
	/**
	 * Identifies the shipment that is invoiced. The value comes from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * PRO# of the shipment invoiced.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Pickup date of the shipment invoiced.
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * Final Delivery date of the shipment.  Shipment is not invoiced until it is final delivered. This is decided based on shm dlvr_qlfr_cd (Z-final) and also in some cases where  J-Part short, K-full short and guarantee_flag=false. then it is final delivered. Also service status cd is considered. Complete logic is documented in Functional design doc.
	 * Required: false
	 * Array: false
	 */
	finalDeliveryDate:Date = undefined;
	/**
	 * This is as per invoice instruction for the party receiving the bill (SEND_TO_PARTY_CIS_NBR)
	 * Y-BL image needs to be attached to the invoice. 
	 * N-Not required, '
	 * Required: false
	 * Array: false
	 */
	bolAttachmentRequiredInd:boolean = undefined;
	/**
	 * This is as per invoice instruction for the party receiving the bill (SEND_TO_PARTY_CIS_NBR)
	 * Y-BL image needs to be attached to the invoice. 
	 * N-Not required, '
	 * Required: false
	 * Array: false
	 */
	drAttachmentRequiredInd:boolean = undefined;
	/**
	 * This attribute is meaningfule only when DR_Attach_reqd_ind =Y
	 * Y-DR attachment is available
	 * N- DR attachment is not yet available.'
	 * Required: false
	 * Array: false
	 */
	drAttachmentAvailableInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * This is null until the shipment is delivered.
	 * Following values are assigned when the shipment is delivered. The values are from SHM_SHIPMENT.
	 * J=Delivery marked complete as All short (we lost the shipment, customer has to pay and then make a claim - business rule)
	 * K=Partial short/MBD1PSRT. Delivered but some pieces were lost and not delivered.
	 * Z= Delivered all good.
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * A msg created at the time of transmission indicating that customer might be eligible for an OBI rebate. OBI batch job might not have created the message when invoice was created and hence is not part of the CLOB
	 * Required: false
	 * Array: false
	 */
	obiMessage:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	appliedPayment:AppliedPayment = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	invoiceDataHeader:InvoiceDataHeader[] = undefined;
}
/**
 * After invoice is sent to EDI, the invoice si sent to SI(integration), then to ETS and finally to Customer. Customer sometimes acknowleges with their acceptance status.
 * This entity keeps track of invoice transmission progress
 */
export class InvoicePostDeliveryStatusLog {
	/**
	 * Key of the parent Invoice Delivery table
	 * Required: false
	 * Array: false
	 */
	invoiceDeliveryId:number = undefined;
	/**
	 * The timestamp when the invoice status change to reflect the transmission life cycle.
	 * Required: false
	 * Array: false
	 */
	statusDateTime:Date = undefined;
	/**
	 * Records the progress of Invoice transmission once the invoice is sent to EDI application.
	 * SENT_TO_SI
	 * 	SENT_TO_ETS
	 * 	SENT_TO_CUST
	 * 	CUST_ACCEPTED
	 * Required: false
	 * Array: false
	 */
	statusCd:InvoicePostDeliveryStatusCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Preference {
	/**
	 * Surrogate key
	 * Required: false
	 * Array: false
	 */
	invoicePreferenceId:number = undefined;
	/**
	 * CIS Customer# is either Bill-to or PD location for which the Invoice preference is defined.
	 * Required: false
	 * Array: false
	 */
	cisCustomerNbr:number = undefined;
	/**
	 * This is used by invoicing to select the preference based on the attriibutes of the shipment. 
	 * Shipment is either billed to third party else it is either a prepaid or collect.
	 * 
	 * In OMS this was called Debtor_type.
	 * 
	 * Valid values
	 * PREPAID    - Prepaid
	 * COLLECT    - Collect
	 * 3P               - 3rd Party
	 * Required: false
	 * Array: false
	 */
	billTypeCd:BillTypeCd = undefined;
	/**
	 * Used by Invoicing depending on the type of invoice to be sent. 
	 * 
	 * Valid Values
	 * O - Original Invoice. 
	 * B - Balance Due invoice.  
	 * C - Correct  invoice.
	 * Required: false
	 * Array: false
	 */
	invoiceTypeCd:InvoiceTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	preferenceCd:InvoicePreferenceCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prioritySequence:string = undefined;
	/**
	 * Desc: The date the Invoice Preference is effective.  Defaults to the date the Invoice Preference is created, and may be back dated.  No future dated values are allowed.
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exprDate:Date = undefined;
	/**
	 * P - Pickup Dlvr location
	 * B - Bill-to location
	 * Required: false
	 * Array: false
	 */
	customerTypeCd:CustomerTypeCd = undefined;
	/**
	 * Y- Indicates that BL is required for invoicing
	 * 				If set to Y, invoicing is held until BL is scanned and available. 
	 * 				BL attachment is applicable only for PAPER and EIP.
	 * 				The child table for MODE indicates which of the transmission mode should attach the BL (in case when invoice is sent via multiple modes or by mutliple emails)
	 * 				
	 * N - Invoice will not be held for BL
	 * Required: false
	 * Array: false
	 */
	bolReqdInd:boolean = undefined;
	/**
	 * Y- Indicates that DR is required for invoicing
	 * 				If set to Y, invoicing is held until DR is scanned and available. 
	 * 				DR attachment is applicable only for PAPER and EIP.
	 * 				The child table for MODE indicates which of the transmission mode should attach the DR  (in case when invoice is sent via multiple modes or by mutliple emails)
	 * 				
	 * N - Invoice will not be held for DR.
	 * Required: false
	 * Array: false
	 */
	drReqdInd:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceDailyInd:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceMonInd:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceTueInd:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWedInd:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceThuInd:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceFriInd:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWeek1Ind:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWeek2Ind:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWeek3Ind:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWeek4Ind:boolean = undefined;
	/**
	 * Valid values
	 * Y
	 * N
	 * Required: false
	 * Array: false
	 */
	invoiceWeek5Ind:boolean = undefined;
	/**
	 * Y - Invoice will remain in N-Not ready for transmit status. This is a permanent hold that has to be manually released.  Typically this is applied only to handful of customers.
	 * 					When this is set, HOLD_FOR_DAYS is set to 0.
	 * 
	 * N - Invoice will not be held such that it can be only manually released .
	 * Required: false
	 * Array: false
	 */
	holdInd:boolean = undefined;
	/**
	 * At the time invoice is ready for transmission the schedule date will be set to today+these many days. 
	 * When this is set, HOLD_IND (which is for manual release only) should be set to N
	 * Required: false
	 * Array: false
	 */
	holdForDays:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Desc: The employee id of the Requestor for the Invoice Preference add or change.
	 * 
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrEmplid:string = undefined;
	/**
	 * Desc: The name of the Requestor for the Invoice Preference add or change.
	 * 
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrName:string = undefined;
	/**
	 * Desc: The email address of the Requestor for the Invoice Preference add or change.
	 * 
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrEmailAddress:string = undefined;
	/**
	 * Desc: The phone number of the Requestor for the Invoice Preference add or change.
	 * 
	 * 04/16/2018 Cathy Doucet   Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrPhoneNbr:string = undefined;
	/**
	 * Desc: The name of the Contact for the Invoice Preference add or change.
	 * 
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	contactName:string = undefined;
	/**
	 * Desc: The email address of the Contact for the Invoice Preference add or change.
	 * 
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	contactEmailAddress:string = undefined;
	/**
	 * Desc: The phone number of the Contact for the Invoice Preference add or change.
	 * 
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	contactPhoneNbr:string = undefined;
	/**
	 * Number that identifies the CIS Request that was submitted for requesting the Invoice Preference add/change.
	 * Required: false
	 * Array: false
	 */
	cisRequestId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	preferredTransmissionMode:PreferredTransmissionMode[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	preferenceConstraint:PreferenceConstraint[] = undefined;
}
/**
 * Records various Email and FTP addresses where invoice should be sent to. One invoice could be sent to multiple recipeint emails. Diferent 
 * email addresses could have different attachment requirements.
 * FMT_CD is part of pkey because same email_id can receive 2 emails one w PDF and another wo PDF. There is only one row for when a mode is either PAPER or EDI 
 * Email can be sent to same sent_to address twice once PDF and second as NO-ATTACH, and hence the AK1 includes PRESENTATION_FMT_CD
 */
export class PreferredTransmissionMode {
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Surrogate key
	 * Required: false
	 * Array: false
	 */
	invoicePreferredTransportModeId:number = undefined;
	/**
	 * PAPER
	 * EDI
	 * EMAI
	 * FTP
	 * SELF-PAID
	 * Required: false
	 * Array: false
	 */
	invoiceModeCd:InvoiceModeCd = undefined;
	/**
	 * Email Address - If parent preference INVC_MODE_CD is for EMAIL
	 * FTP address   - If parent preference INVC_MODE_CD is for FTP
	 * MAD CD - If parent preference INVC_MODE_CD is for PAPER, EDI. This corresponds to Cust# of the parent.
	 * Required: false
	 * Array: false
	 */
	sendToAddress:string = undefined;
	/**
	 * Valid Values
	 * INDIVIDUAL   Single invoice. applies to Paper, EDI
	 * STATEMENT    Presentation application combinies multiple invoices, applies to Paper
	 * MANIFEST     Presentation application combinies multiple invoices, applies to EDI
	 * following apply to EMAIL, FTP 
	 * CSV            
	 * CSV-ACC      CSV with Accessorial
	 * NO-ATTM      No attachments
	 * PDF          Invoice image as PDF
	 * PDF-IMAGE    Invoice image as PDF with attachment images
	 * XML          Invoice data required with XML doc attachment
	 * Required: false
	 * Array: false
	 */
	presentationFormatCd:InvoicePresentationFormatCd = undefined;
	/**
	 * BL Reqd ind on the parent holds the invoice for BL.
	 * This indicator tells if BL should be attached for this particular mode.
	 * If parent BL reqd ind is Y  then at least one of the modes should have this indicator set to Y.
	 * If parent BL reqd ind is N then all of the modes should have this indicator set to N.
	 * 
	 * Note there is additional logic involved for a given PRO based on other related data for that PRO, such as if WNI correction happened or not. 
	 * 
	 * Y- Attach BL for this Mode
	 * N - Do not attach BL for this Mode
	 * Required: false
	 * Array: false
	 */
	attachBolInd:boolean = undefined;
	/**
	 * DR Reqd ind on the parent holds the invoice for DR.
	 * This indicator tells if DR should be attached for this particular mode.
	 * If parent DR reqd ind is Y  then at least one of the modes should have this indicator set to Y.
	 * If parent DR reqd ind is N then all of the modes should have this indicator set to N.
	 * 
	 * Y- Attach DR for this Mode
	 * N - Do not attach DR  for this Mode
	 * Required: false
	 * Array: false
	 */
	attachDrInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class PreferenceConstraint {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoicePreferenceConstraintId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	constraintName:InvoicePreferenceConstraintCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evalOperator:InvoiceEvalOperCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	constraintValue:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	constraintValueNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PreferenceConstraintRef {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoicePreferenceConstraintRefId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	constraintCd:InvoicePreferenceConstraintCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	constraintName:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PreferenceOperatorRef {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoicePreferenceOperatorRefId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operatorValue:InvoiceEvalOperCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
export class InvoiceDetailData_InvoiceShipmentParty extends InvoiceShipmentParty {
	/**
	 * Shipper Bill To or Consignee Bill to
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * Full Country Name: UNITED STATES, CANADA, or MEXICO
	 * Required: false
	 * Array: false
	 */
	countryName:string = undefined;
}


