import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	CreateCisCustomerRqst,
	CreateCisCustomerResp,
	DeleteCisCustomerAliasQuery,
	DeleteCisCustomerAliasPath,
	DeleteCisCustomerContactQuery,
	DeleteCisCustomerContactPath,
	DeleteCisDb2CustomerAliasPath,
	DeleteCisDb2ContactPath,
	DeleteCisDb2CustomerHeaderPath,
	DetermineDuplicateCustomerAddressGroupingRqst,
	DetermineDuplicateCustomerAddressGroupingResp,
	GetCisContactDetailsResp,
	GetCisContactDetailsPath,
	GetCisCustomerAliasesResp,
	GetCisCustomerAliasesQuery,
	GetCisCustomerAliasesPath,
	GetCisCustomerDetailsResp,
	GetCisCustomerDetailsQuery,
	GetCisCustomerDetailsPath,
	GetCisCustomerHierarchyResp,
	GetCisCustomerHierarchyQuery,
	GetCisCustomerHistoryResp,
	GetCisCustomerHistoryQuery,
	GetCisDb2InvoiceInstructionsResp,
	GetCisDb2InvoiceInstructionsQuery,
	GetCisImmediateHierarchyResp,
	GetCisImmediateHierarchyQuery,
	GetCisPaymentTermsResp,
	MatchCustomerRqst,
	MatchCustomerResp,
	UpdateGeoCoordinatesForCustRqst,
	UpdateGeoCoordinatesForCustResp,
	UpdateGeoCoordinatesForCustQuery,
	UpdateGeoCoordinatesForCustPath,
	UpsertCisCustomerAliasRqst,
	UpsertCisCustomerAliasResp,
	UpsertCisCustomerAliasPath,
	UpsertCisCustomerContactRqst,
	UpsertCisCustomerContactResp,
	UpsertCisCustomerContactPath,
	UpsertCisCustomerNameAndAddressRqst,
	UpsertCisCustomerNameAndAddressResp,
	UpsertCisCustomerNameAndAddressPath,
	UpsertCisDb2CustomerAliasRqst,
	UpsertCisDb2CustomerAliasResp,
	UpsertCisDb2CustomerAliasPath,
	UpsertCisDb2ContactRqst,
	UpsertCisDb2ContactResp,
	UpsertCisDb2ContactPath,
	UpsertCisDb2CustomerHeaderRqst,
	UpsertCisDb2CustomerHeaderResp,
	UpsertCisDb2CustomerHeaderPath,
	UpdateCisDb2InvoiceInstructionRqst,
	UpdateCisDb2InvoiceInstructionResp,
	GetCustomerLocationOperationsProfileResp,
	GetCustomerLocationOperationsProfilePath
} from './api-customer';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class CustomerApiService extends BaseService {
	private static CustomerApiEndpoint = 'customerV2ApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will be used to create a Customer based the input.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid input is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid and found, then a status code of 201 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public createCisCustomer(request: CreateCisCustomerRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateCisCustomerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation will be used to delete the alias of a customer based on the customer ID and sequence number provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid customer ID and sequence number are required.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If a customer is found with the provided input, then it is deleted and a status code of 200 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deleteCisCustomerAlias(
							   pathParams: DeleteCisCustomerAliasPath,
							   queryParams: DeleteCisCustomerAliasQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerId}/aliases/{sequenceNbr}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation will be used to delete a customer contact based on the customer ID and contact ID, which are filtered by role type.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid customer ID, contact ID, and contact role code are provided. 
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If a customer is found with the provided input, then it is deleted and a status code of 200 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deleteCisCustomerContact(
							   pathParams: DeleteCisCustomerContactPath,
							   queryParams: DeleteCisCustomerContactQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerId}/contacts/{contactId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation will be used to delete the Customer alias of a customer in the CIS DB2 system based on the customer ID, sequence number, and function code provided.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid customer ID, sequence number, and function code are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a customer is found with the provided input, then it is deleted and a status code of 200 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deleteCisDb2CustomerAlias(
							   pathParams: DeleteCisDb2CustomerAliasPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{legacyCustomerId}/functions/{functionCd}/db2-customer-aliases/{legacyAliasSeqNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation will be used to delete (unrelate) the Contact in the CIS DB2 tables.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid customer ID, contact Key number, and function code are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a customer is found with the provided input, then the corresponding entry is deleted (unrelated) and a status code of 200 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.        
	*/
	public deleteCisDb2Contact(
							   pathParams: DeleteCisDb2ContactPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{legacyCustomerId}/functions/{functionCd}/db2-customer-contact/{keyNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* 
	* <br/>			This operation will be used to delete the Customer Header of a customer in the CIS DB2.
	* <br/>
	* <br/>			Pre-condition:
	* <br/>			1. A valid customer ID and function code are required.
	* <br/>
	* <br/>			Post-conditions:
	* <br/>			1. If a customer is found with the provided input, then it is deleted and a status code of 200 is returned.
	* <br/>			2. Otherwise an appropriate error message is returned.
	*/
	public deleteCisDb2CustomerHeader(
							   pathParams: DeleteCisDb2CustomerHeaderPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerId}/{functionCd}/db2-customer-header'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Cluster input Customers that have similar address information into grouping numbers in the response.  This is used for consolidating a list of input customers that may have similar name and addresses so that customer related objects can be consolidated.
	* <br/>		1)	Input: List of Customers with Name, Address and Cust Nbr info.               
	* <br/>		2)	Standardize the Customer addresses using Lotus Address Standardization Module
	* <br/>		3)	Cluster standardized Cusotomer address results into groupings (using a groupingId).
	* <br/>		4)	Provide the Customer cluster groupingId information and the standardized Customer addresses back in the response. 
	* <br/>		
	*/
	public determineDuplicateCustomerAddressGrouping(request: DetermineDuplicateCustomerAddressGroupingRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DetermineDuplicateCustomerAddressGroupingResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/addresses/determine-duplicate-grouping'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation will be used to retrieve all details about a contact based on the contact person ID provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid NCIS contact person ID.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input is valid, then a status code of 200, with all relevant details, is returned.
	* <br/>2. If the contact person ID is invalid or doesn't exist, then a status code of 404 (not found) is returned.
	* <br/>3. Otherwise an appropriate error message is returned.
	*/
	public getCisContactDetails(
							   pathParams: GetCisContactDetailsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCisContactDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/contact-people/{contactPersonId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will be used to retrieve all details about a customer alias based on the customer location function ID and alias sequence number. If no alias sequence number is supplied, then all aliases for a customer will be returned.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid customerLocationFuncId and optional customerLocationAliasSequenceNbr are provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input is valid, then a status code of 200 with all relevant details is returned.
	* <br/>2. If the input is invalid, then a status code of 404 - Not Found is returned.
	*/
	public getCisCustomerAliases(
							   pathParams: GetCisCustomerAliasesPath,
							   queryParams: GetCisCustomerAliasesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCisCustomerAliasesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerLocationFuncId}/aliases'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will be used to retrieve all details about a customer based on the customer ID or MAD code provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid customer ID or MAD code is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input is valid, then a status code of 200, with all relevant details, is returned.
	* <br/>2. If the customer ID is invalid or doesn't exist, then a status code of 404 (not found) is returned.
	* <br/>3. Otherwise an appropriate error message is returned.
	*/
	public getCisCustomerDetails(
							   pathParams: GetCisCustomerDetailsPath,
							   queryParams: GetCisCustomerDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCisCustomerDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customer/{id}/details'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will be used to either return all the offspring or all the parents for a customer MAD code or legacy CIS customer number.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A customer MAD code or a legacy CIS customer number is provider.
	* <br/>2. The customer hierarchy type is provided.
	* <br/>		
	* <br/>Post-condition:
	* <br/>1. If the input is found, then a status code of 200 is returned along with all of the offspring or parents for the requested customer.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getCisCustomerHierarchy(
							   queryParams: GetCisCustomerHierarchyQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCisCustomerHierarchyResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/hierarchy'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will be used to retrieve customer history of data changes
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. Either a customer MAD code or a legacy CIS customer number must be provided.
	* <br/>		
	* <br/>Post-condition:
	* <br/>1. If the input is found, then a status code of 200 is returned along with all history items
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getCisCustomerHistory(
							   queryParams: GetCisCustomerHistoryQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCisCustomerHistoryResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/history'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will return information about an invoicing instruction. If the customerNbr is provided, then a specific instruction is returned.  Otherwise all instructions are returned.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the customerNbr does not exist will return 404 error if not will return the specific instruction.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getCisDb2InvoiceInstructions(
							   queryParams: GetCisDb2InvoiceInstructionsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCisDb2InvoiceInstructionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-instructions'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns the immediate hierarchy for a customer.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid legacyCisCustomerNbr or madCd must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the immediate hierarchy for a customer is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getCisImmediateHierarchy(
							   queryParams: GetCisImmediateHierarchyQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCisImmediateHierarchyResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/immediate-hierarchy'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will be used to return the customer payment terms.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A payment term list exists in properties file.
	* <br/>
	* <br/>Post-condition:
	* <br/>2. If it exists, the list of payment terms is returned.
	* <br/>2. Otherwise a not found messaage is returned.
	* <br/>		
	*/
	public getCisPaymentTerms(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCisPaymentTermsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/payment-terms'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Performs name and address matching for Customer name and address input.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Customer name and address is supplied.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, match results are returned for the name and address provided.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public matchCustomer(request: MatchCustomerRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<MatchCustomerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/match'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Updates the geo latitude/longitude coordinates for a CIS/Future customer using a customer ID.
	* <br/> 
	* <br/>Pre-condition:
	* <br/>1. A valid customer ID is provided and the ID is valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the update is successful, a success boolean is returned.
	* <br/>2. Otherwise an apprpriate error message is returned.
	*/
	public updateGeoCoordinatesForCust(request: UpdateGeoCoordinatesForCustRqst,
							   pathParams: UpdateGeoCoordinatesForCustPath,
							   queryParams: UpdateGeoCoordinatesForCustQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateGeoCoordinatesForCustResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerId}/coordinates'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation will be used to upsert the customer alias for a customer based on the customer ID.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid customer ID is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid and found, then a status code of 200 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.	
	*/
	public upsertCisCustomerAlias(request: UpsertCisCustomerAliasRqst,
							   pathParams: UpsertCisCustomerAliasPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertCisCustomerAliasResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerId}/alias'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation will be used to update/create a Customer contact based on the customer ID .
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid customer ID and Contact ID (optional) is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid and found, then a status code of 200 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public upsertCisCustomerContact(request: UpsertCisCustomerContactRqst,
							   pathParams: UpsertCisCustomerContactPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertCisCustomerContactResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerId}/contact'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation will be used to upsert the Name and Address section for a Customer based on the customer ID.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid customer ID is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid and found, then a status code of 200 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public upsertCisCustomerNameAndAddress(request: UpsertCisCustomerNameAndAddressRqst,
							   pathParams: UpsertCisCustomerNameAndAddressPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertCisCustomerNameAndAddressResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerId}/name-and-address'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation will be used to upsert the DB2 Customer Alias based on the legacy customer ID and sequence number. A functionCd is also required in order to specify the type of customer (PnD, BillTo, Corporate) the alias is for.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid legacy customer ID, sequence number, and function code are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid and found, then a status code of 200 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.	
	*/
	public upsertCisDb2CustomerAlias(request: UpsertCisDb2CustomerAliasRqst,
							   pathParams: UpsertCisDb2CustomerAliasPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertCisDb2CustomerAliasResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{legacyCustomerId}/db2-customer-alias'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation will be used to upsert the DB2 Contact based on the customer function code provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid legacy customer ID and function code are provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the contact was successfully updated or created, then a status code of 200 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	* <br/>        
	*/
	public upsertCisDb2Contact(request: UpsertCisDb2ContactRqst,
							   pathParams: UpsertCisDb2ContactPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertCisDb2ContactResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{legacyCustomerId}/db2-customer-contact'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* 
	* <br/>			This operation will be used to upsert the DB2 Customer Header based on customer function code.
	* <br/>			Pre-condition:
	* <br/>			1. A valid customer function code.
	* <br/>			2. A valid customer ID is provided.
	* <br/>
	* <br/>			Post-condition:
	* <br/>			1. Return status code 200 if the customer header was successfully updated or created.
	* <br/>			2. Otherwise an appropriate error message is returned.
	* <br/>		
	*/
	public upsertCisDb2CustomerHeader(request: UpsertCisDb2CustomerHeaderRqst,
							   pathParams: UpsertCisDb2CustomerHeaderPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertCisDb2CustomerHeaderResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customers/{customerId}/db2-customer-header'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation updates a DB2 invoice instruction.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. All invoice tyeps field provided must be valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the invoice instruction was updated successfully, then a status code of 200 is returned.
	* <br/>2. Otherwise an appropriate error message is returned.	
	*/
	public updateCisDb2InvoiceInstruction(request: UpdateCisDb2InvoiceInstructionRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateCisDb2InvoiceInstructionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-instructions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Returns all details for a customer location operations profile using the customer number.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. A valid customer ID is provided.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If the input is valid, then all the details for a customer location operations profile in the Customer system are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getCustomerLocationOperationsProfile(
							   pathParams: GetCustomerLocationOperationsProfilePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCustomerLocationOperationsProfileResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/customer-locations/{customerAcctNbr}/operations-profile'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(CustomerApiService.CustomerApiEndpoint);
	}
}
