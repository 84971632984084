import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetShipmentStatusResp,
	GetShipmentStatusQuery,
	ListShipmentReferenceNumbersResp,
	ListShipmentReferenceNumbersQuery,
	ListShipmentReferenceNumbersPath,
	ListShipmentTrackingEventsResp,
	ListShipmentTrackingEventsQuery,
	ListShipmentTrackingEventsPath,
	ListShipmentsByAccountResp,
	ListShipmentsByAccountQuery,
	ListShipmentsByAccountPath,
	GetManifestReportResp,
	GetManifestReportPath,
	CreateManifestReportRqst,
	CreateManifestReportResp
} from './api-shipmenttracking';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class ShipmentTrackingApiService extends BaseService {
	private static ShipmentTrackingApiEndpoint = 'shipmenttrackingApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service retrieves the shipment status for the given Pro number.1 Shipment Pro Number must be provided.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/> Valid input data - At least one Pro or shipment reference number is provided.
	* <br/>Post-conditions: 
	* <br/> Shipment status is returned.
	* <br/>Pre-conditions: 
	* <br/> Invalid shipment reference number or shipment found.
	* <br/>Post-conditions: 
	* <br/> Not found message is returned.
	* <br/>Note: if the PRO searched for has children, information on the children is returned with the PRO result. If the pro searched for has a parent shipment, the parent's PRO is returned with the information (but no detailed information on the parent shipment is returned).
	* <br/>
	*/
	public getShipmentStatus(
							   queryParams: GetShipmentStatusQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetShipmentStatusResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/shipment-status-details'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service retrieves all the reference numbers associated to the given Pro number.1 Shipment Pro Number must be provided.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/> Valid input data - PRO Number is provided.
	* <br/>Post-conditions: 
	* <br/> All the associated reference numbers are returned.
	* <br/>Pre-conditions: 
	* <br/> No reference numbers exist for the given Pro number.
	* <br/>Post-conditions: 
	* <br/> Not found message is returned.
	* <br/>
	* <br/>
	*/
	public listShipmentReferenceNumbers(
							   pathParams: ListShipmentReferenceNumbersPath,
							   queryParams: ListShipmentReferenceNumbersQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListShipmentReferenceNumbersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{proNbr}/reference-numbers'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service retrieves the shipment history for the given Pro number.1 Shipment Pro Number must be provided.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/> Valid input data - Pro Number is provided.
	* <br/>Post-conditions: 
	* <br/> Shipment History for the pro is returned.
	* <br/>Pre-conditions: 
	* <br/> No Shipment history for the given Pro number.
	* <br/>Post-conditions: 
	* <br/> Not found message is returned.
	* <br/>
	* <br/>
	*/
	public listShipmentTrackingEvents(
							   pathParams: ListShipmentTrackingEventsPath,
							   queryParams: ListShipmentTrackingEventsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListShipmentTrackingEventsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{proNbr}/tracking-events'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the pro numbers for the account number provided and lists the shipment status details.  One account number and account type must be provided.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>Valid input data - account number and account type has to be provided.
	* <br/> 
	* <br/>Post-conditions: 
	* <br/>1. Shipment status details are returned if found.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listShipmentsByAccount(
							   pathParams: ListShipmentsByAccountPath,
							   queryParams: ListShipmentsByAccountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListShipmentsByAccountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{accountNbr}/shipment-status-details'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the manifest report for the execution ID provided.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>Valid input data - Input Execution ID is mandatory.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. Shipment status is returned if successfull.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getManifestReport(
							   pathParams: GetManifestReportPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetManifestReportResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/manifest-report/{executionId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation creates a manifest report and returns an execution ID.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>Valid input data - Account Nbr, partyRoleCd and reportTypeCd are mandatory.
	* <br/>
	* <br/>PartyRoleCd Values: 
	* <br/>- INB(Inbound) 
	* <br/>- OUTB(Outbound) 
	* <br/>- INBOTB(Inbound or Outbound)
	* <br/>- THIRD(Third Party)
	* <br/>
	* <br/>ManifestReportTypeCd Values:
	* <br/>- M(Regular Manifest) 
	* <br/>- S(New Shipment Manifest) 
	* <br/>- E(Exceptions Manifest) 
	* <br/>- O(Out for Delivery Manifest) 
	* <br/>- D(Delivered Manifest) 
	* <br/>- G(Guaranteed Manifest)
	* <br/>
	* <br/>Post-conditions: 
	* <br/>Execution ID is returned.
	*/
	public createManifestReport(request: CreateManifestReportRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateManifestReportResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/manifest-report'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(ShipmentTrackingApiService.ShipmentTrackingApiEndpoint);
	}
}
