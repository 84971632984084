import { NgModule } from '@angular/core';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import {InvoiceApiService} from './invoice-api.service';

@NgModule({
  imports: [
    ConfigManagerModule
  ],
  providers: [
    InvoiceApiService,
    { provide: CONFIG_MANAGER_SERVICE_CONFIGURATION, useValue: { configFileUrl: './assets/config.json'}},
  ]
})
export class SdkInvoiceModule { }
