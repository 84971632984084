import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	CreateAndArchiveCopyBillDocumentRqst,
	CreateAndArchiveCopyBillDocumentResp,
	CreateHandlingUnitMovementRqst,
	CreateHandlingUnitMovementResp,
	CreateHandlingUnitMovementPath,
	CreateShipmentDockExceptionRqst,
	CreateShipmentDockExceptionResp,
	CreateShipmentManagementRemarkRqst,
	CreateShipmentManagementRemarkResp,
	CreateShipmentRemarkRqst,
	CreateShipmentRemarkResp,
	CreateShipmentSkeletonRqst,
	DeleteAdHocMovementExceptionPath,
	DeleteMovementExceptionActionQuery,
	DeleteMovementExceptionActionPath,
	DeleteShipmentManagementRemarkQuery,
	DeleteShipmentManagementRemarkPath,
	DeleteShipmentMovementPath,
	GetPredictedTrueDebtorResp,
	GetPredictedTrueDebtorQuery,
	GetShipmentResp,
	GetShipmentQuery,
	GetShipmentAsMatchedPartiesResp,
	GetShipmentAsMatchedPartiesQuery,
	GetShipmentManagementRemarkResp,
	GetShipmentManagementRemarkQuery,
	GetShipmentManagementRemarkPath,
	GetShipmentCountByShipperResp,
	GetShipmentCountByShipperPath,
	GetShipmentRemarkResp,
	GetShipmentRemarkPath,
	GetShipmentsAggregationResp,
	GetShipmentsAggregationQuery,
	ListDeliveryCollectionInstructionForShipmentsRqst,
	ListDeliveryCollectionInstructionForShipmentsResp,
	ListFBDSDocumentsRqst,
	ListFBDSDocumentsResp,
	ListHandlingUnitsRqst,
	ListHandlingUnitsResp,
	ListShipmentHistTraceEventsRqst,
	ListShipmentHistTraceEventsResp,
	ListShipmentManagementRemarksRqst,
	ListShipmentManagementRemarksResp,
	ListShipmentsRqst,
	ListShipmentsResp,
	PrintFBDSDocumentsRqst,
	PrintFBDSDocumentsResp,
	UpdateHandlingUnitDimensionsRqst,
	UpdateHandlingUnitDimensionsResp,
	UpdateHandlingUnitDimensionsPath,
	UpdateMovementForShipmentsRqst,
	UpdateShipmentFoodAndMotorMovesRqst,
	UpdateShipmentHazMatRqst,
	UpdateShipmentHazMatResp,
	UpdateShipmentLinehaulDimensionsRqst,
	UpdateShipmentManagementRemarkRqst,
	UpdateShipmentManagementRemarkResp,
	UpdateShipmentsUponTripCompletionRqst,
	UpdateShipmentsUponTripDispatchRqst,
	UpdateShipmentsWithRouteDtlRqst,
	UpsertAdHocMovementExceptionRqst,
	UpsertAdHocMovementExceptionResp,
	UpsertMovementExceptionActionRqst,
	UpsertMovementExceptionActionResp,
	UpsertOperationsShipmentRqst,
	UpsertOperationsShipmentResp,
	UpsertOperationsShipmentPilotRqst,
	UpsertOperationsShipmentPilotResp,
	ValidateShipmentsForDeliveryRqst,
	ValidateShipmentsForDeliveryResp,
	CreateBaseLogRqst,
	CreateBaseLogPath,
	DeleteRulePath,
	ExecuteRuleRqst,
	ExecuteRuleResp,
	GetRuleResp,
	GetRulePath,
	GetRuleByNameResp,
	GetRuleByNamePath,
	ListRulesResp,
	ListRulesQuery,
	ListRulesByExpressionsRqst,
	ListRulesByExpressionsResp,
	ListRulesByRulesetNameResp,
	ListRulesByRulesetNameQuery,
	ListRulesForExecutionResp,
	ListRulesForExecutionQuery,
	UpsertRuleRequest,
	UpsertRuleResp,
	DeleteExpiredMessagesResp,
	DeleteExpiredMessagesQuery,
	DeleteExpiredMessagesPath,
	GetEnsembleStatusCountsResp,
	GetEnsembleStatusCountsPath,
	GetQueuePendingCountResp,
	GetQueuePendingCountPath,
	KillEnsembleRqst,
	KillEnsembleResp,
	ListExpiredMessagesResp,
	ListExpiredMessagesPath,
	ListQueuesResp,
	ReplayMessagePath,
	RestartEnsembleRqst,
	RestartEnsembleResp,
	RestartEnsemblePath,
	ResumeEnsembleRqst,
	ResumeEnsembleResp,
	ResumeEnsemblePath,
	StartQueuePath,
	StopQueuePath,
	StopQueueWebhookPath,
	StopQueueWebhookQuery,
	ExportShipmentUnfilteredRqst,
	SearchShipmentUnfilteredRqst,
	SearchShipmentUnfilteredResp,
	ChangesFeedShipmentUnfilteredRqst,
	RegisterFilterShipmentUnfilteredRqst,
	RegisterFilterShipmentUnfilteredResp,
	UnregisterFilterShipmentUnfilteredRqst,
	BulkUpdateElasticDocumentShipmentResp,
	DeleteElasticDocumentShipmentResp,
	DeleteElasticDocumentShipmentPath,
	GetElasticDocumentShipmentResp,
	GetElasticDocumentShipmentQuery,
	GetElasticDocumentShipmentPath,
	GetElasticDocumentShipmentCountResp,
	GetElasticDocumentShipmentCountQuery,
	UpdateElasticDocumentShipmentRqst,
	UpdateElasticDocumentShipmentResp,
	UpdateElasticDocumentShipmentPath,
	ExportEnsembleStateUnfilteredRqst,
	SearchEnsembleStateUnfilteredRqst,
	SearchEnsembleStateUnfilteredResp,
	ChangesFeedEnsembleStateUnfilteredRqst,
	RegisterFilterEnsembleStateUnfilteredRqst,
	RegisterFilterEnsembleStateUnfilteredResp,
	UnregisterFilterEnsembleStateUnfilteredRqst,
	BulkUpdateElasticDocumentEnsembleStateResp,
	DeleteElasticDocumentEnsembleStateResp,
	DeleteElasticDocumentEnsembleStatePath,
	GetElasticDocumentEnsembleStateResp,
	GetElasticDocumentEnsembleStateQuery,
	GetElasticDocumentEnsembleStatePath,
	GetElasticDocumentEnsembleStateCountResp,
	GetElasticDocumentEnsembleStateCountQuery,
	UpdateElasticDocumentEnsembleStateRqst,
	UpdateElasticDocumentEnsembleStateResp,
	UpdateElasticDocumentEnsembleStatePath,
	StartShmApiChEnsembleRqst,
	StartShmApiChEnsembleResp
} from './api-shipment';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class ShipmentApiService extends BaseService {
	private static ShipmentApiEndpoint = 'shipmentV2ApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will create the Copy Bill PDF and then archive to DMS and return the DMS Document ID.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid PRO or shipment instance ID is provided as input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If succesful, the a copy bill PDF document for the shipment will be created.  The PDF is then sent to DMS and archived.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public createAndArchiveCopyBillDocument(request: CreateAndArchiveCopyBillDocumentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateAndArchiveCopyBillDocumentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/copy-bills'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation creates a Handling Unit Movement record.
	* <br/>
	* <br/>Mandatory Data: 
	* <br/>	- Handling Unit childProNbr
	* <br/>	- movementTypeCd (Pickup, Load, Close, Unload, Forced to Location, Out for Delivery, Delivery)
	* <br/>	- movementReportingSicCd, 
	* <br/>	- movementDateTime
	* <br/>	- either trailerInstanceId or dockInstanceId
	* <br/>
	* <br/>Use Case 1
	* <br/>Pre-condition: A valid childProNbr is supplied along with all the other mandatory data.
	* <br/>Post-conditions: A Handling Unit Movement record is created.  Also, the parent shipment movement record is created for the first handling unit of the parent shipment.
	* <br/>
	* <br/>Use Case 2
	* <br/>Pre-condition: A valid childProNbr is supplied along with all the other mandatory data and movementTypeCd is Close. 
	* <br/>Post-conditions: A Handling Unit Movement record is created.  Also, the parent shipment and shipment movement are updated with the last movement reporting SIC code and timestamp.  A shipment event of sub type 32 is also created.
	* <br/>
	*/
	public createHandlingUnitMovement(request: CreateHandlingUnitMovementRqst,
							   pathParams: CreateHandlingUnitMovementPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateHandlingUnitMovementResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/handling-units/{childProNbr}/handling-unit-movements'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation creates a shipment dock exception record.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid shipment instance ID or PRO number is supplied along with Xdock exception data.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, the shipment dock exception is created.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public createShipmentDockException(request: CreateShipmentDockExceptionRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateShipmentDockExceptionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/dock-exceptions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Creates a shipment management remark or shipment note.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A new and valid shipment management remark or shipment note is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If succuessful, then the created shipment management remark or shipment note is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public createShipmentManagementRemark(request: CreateShipmentManagementRemarkRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateShipmentManagementRemarkResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/management-remarks'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Creates a shipment remark.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A new and valid shipment remark is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If succuessful, then the created shipment remark is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public createShipmentRemark(request: CreateShipmentRemarkRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateShipmentRemarkResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/remarks'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation creates a shipment with skeleton information like proNbr, destinationSic, hazmatInformation, and one or more handling units.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The Shipment information supplied must be valid.
	* <br/>2. Mandatory input: parentProNbr, 1 or more handlingUnits, destPostalCd, totalPieceCount, totalPalletCount, and motorizedPiecesKnownInd.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a shipment skeleton is created with data provided.
	* <br/>2. Otherwise an appropriate error message is returned.
	* <br/>
	*/
	public createShipmentSkeleton(request: CreateShipmentSkeletonRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/skeleton-shipments'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation deletes an ad hoc movement exception that is tied to a movement.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. An valid movement exception is provided that already exists.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the movement exception is deleted.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deleteAdHocMovementException(
							   pathParams: DeleteAdHocMovementExceptionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{shipmentInstId}/movement-exceptions/{movementSequenceNbr}/{sequenceNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This opeation deletes a movement exception action that is tied to a movement exception.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. An valid movement exception remark is provided that already exists.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the movement exception remark is deleted.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deleteMovementExceptionAction(
							   pathParams: DeleteMovementExceptionActionPath,
							   queryParams: DeleteMovementExceptionActionQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{shipmentInstId}/movement-exception-actions/sequences/{sequenceNbr}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Deletes a shipment movement exception remark.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. shipmentInstId, movementSequenceNbr, and exceptionRemarksSequenceNbr are provided in the request as well as valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all values are valid, then the shipment movement exception remark is deleted.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deleteShipmentManagementRemark(
							   pathParams: DeleteShipmentManagementRemarkPath,
							   queryParams: DeleteShipmentManagementRemarkQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{shipmentInstId}/sequences/{sequenceNbr}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation deletes a shipment movement.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid shipmentInstId and sequenceNbr are provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the associated shipment movement is deleted.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deleteShipmentMovement(
							   pathParams: DeleteShipmentMovementPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{shipmentInstId}/movements/{sequenceNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This opeartion retrieves the predicted true debtor for a pre-rated shipment.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid shipper customer ID and consignee zip code must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then details for the predicted true debtor for the pre-rated shipment are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getPredictedTrueDebtor(
							   queryParams: GetPredictedTrueDebtorQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPredictedTrueDebtorResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/predicted-true-debtors'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get details of a Shipment for a given Pro Number or Shipment Instance Id.  Optional input shipmentDetailCd allows consumers to specify a list of enum values to indicate the type of related Shipment data to be returned.  If no shipmentDetailCd value is supplied, all shipment details will be returned.
	* <br/>
	* <br/>Inputs
	* <br/>- Shipment pro number or instance id is required
	* <br/>- Optional shipmentDetailCd to indicate the type of related shipment data to be returned in the request.
	* <br/>- ShipmentDetailCd not supplied - return SHM_SHIPMENT data and all its child data
	* <br/>- SHPONLY - return SHM_SHIPMENT data only
	* <br/>- ACC - return SHM_SHIPMENT data + related SHM_AC_SVC
	* <br/>- ADVBYD - return SHM_SHIPMENT data + related SHM_ADV_BYD_CARR
	* <br/>- PARTY  - return SHM_SHIPMENT data + related SHM_AS_ENTD_CUST, SHM_AS_ENTD_BOL_PARTY
	* <br/>- BESTAT - return SHM_SHIPMENT data + related SHM_BILL_ENTRY_STATS
	* <br/>- SHMBOL - return SHM_SHIPMENT data + related SHM_BOL_ATTACHMENT, SHM_BOL_USAGE
	* <br/>- CMDTY - return SHM_SHIPMENT data + related SHM_COMMODITY, SHM_CMDY_DIMENSION, SHM_HAZ_MAT
	* <br/>- CUSTOMS  - return SHM_SHIPMENT data + related SHM_CUSTOMS_BOND, SHM_CUSTOMS_CNTRL
	* <br/>- HNDLNGUNIT - return SHM_SHIPMENT data + related SHM_HANDLING_UNIT_MVMT
	* <br/>- LNHDIM - return SHM_SHIPMENT data + related SHM_LNH_DIMENSION
	* <br/>- MISCITM - return SHM_SHIPMENT data + related SHM_MISC_LINE_ITEM
	* <br/>- NTFICTN - return SHM_SHIPMENT data + related SHM_NOTIFICATION
	* <br/>- OPSSHP - return SHM_SHIPMENT data + related SHM_OPS_SHIPMENT
	* <br/>- REMARK - return SHM_SHIPMENT data + related SHM_REMARK
	* <br/>- RULEOVR - return SHM_SHIPMENT data + related SHM_RULE_OVERRIDE
	* <br/>- SRN - return SHM_SHIPMENT data + related SHM_SR_NBR, SHM_SR_XTND_DTL
	* <br/>- TDC - return SHM_SHIPMENT data + related SHM_TM_DT_CRITICAL
	* <br/>- XDOCK_EXCP - return SHM_SHIPMENT data + related SHM_XDOCK_EXCP
	* <br/>
	* <br/>Outputs
	* <br/>- Shipment details.
	*/
	public getShipment(
							   queryParams: GetShipmentQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the as matched parties for a shipment for a given PRO and date or shipment instance ID.  
	* <br/>
	* <br/>Inputs
	* <br/>- Shipment PRO number and pkup date or instance ID is required.
	* <br/>
	* <br/>Outputs
	* <br/>- Shipment as matched parties are returned.
	*/
	public getShipmentAsMatchedParties(
							   queryParams: GetShipmentAsMatchedPartiesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetShipmentAsMatchedPartiesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/as-matched-parties'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a shipment management remark.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. shipmentInstId, movementSequenceNbr, and exceptionRemarksSequenceNbr are provided in the request as well as valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all values are valid, then the shipment movement exception remark is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getShipmentManagementRemark(
							   pathParams: GetShipmentManagementRemarkPath,
							   queryParams: GetShipmentManagementRemarkQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetShipmentManagementRemarkResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{shipmentInstId}/sequences/{sequenceNbr}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the shipment count by shipper customer number for the previous 12 months.  
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid customer number is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If exists, then the count will be returned.
	* <br/>2. Otherwise, an appropriate error message will be returned.
	*/
	public getShipmentCountByShipper(
							   pathParams: GetShipmentCountByShipperPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetShipmentCountByShipperResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/shippers/{shipperCisCustomerNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a shipment remark.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid shipmentInstId and typeCd are provided in the request.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all values are valid, then the shipment movement exception remark is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getShipmentRemark(
							   pathParams: GetShipmentRemarkPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetShipmentRemarkResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{shipmentInstId}/remarks/{typeCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This oepration retrieves details for shipments for a given date range.  The optional input parameter, pricing agreement ID, allows consumers to specify a particular agreement for which the shipments are related.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid beginDate and endDate must be provided. The beginDate is an inclusive date range low end. Shipments aggregated will be for this date or in the future. The endDate is an inclusive date range high end. Shipments aggregated will be for this date or in the past.  
	* <br/>2. The pricingAgreementId is optional and must be valid if provided. Shipments aggregated should be related to the given pricing agreement.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then shipment aggregation details are provided including the count and total revenue amount and optionally the total weight in pounds.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getShipmentsAggregation(
							   queryParams: GetShipmentsAggregationQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetShipmentsAggregationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/aggregations'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists the delivery collection instruction for each shipment identifier provided.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. At least one valid shipment identifier (PRO number and/or shipment instance ID) is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, the payment collection instructions for the shipments are listed.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listDeliveryCollectionInstructionForShipments(request: ListDeliveryCollectionInstructionForShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListDeliveryCollectionInstructionForShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/delivery-collection-instructions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation lists FBDS/CopyBill document details for a shipment for one or more PRO numbers. The mandatory input documentRequestType allows consumers to specify the document (FBDS/CopyBill) to retrieve to be returned.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. One or more valid PRO numbers and the documentRequestType (FBDS/CopyBill) must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the documents details are listed.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listFBDSDocuments(request: ListFBDSDocumentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListFBDSDocumentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/fbds-documents'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation lists handling units and associated movements for the list of child PRO numbers and/or combinations of shipment instance ID and sequence number.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A list of valid child shipment IDs is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the list of handling units and associated movements for the child shipments IDs is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listHandlingUnits(request: ListHandlingUnitsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListHandlingUnitsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/handling-units'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation lists active shipment, operations, and admin events for the shipment ID provided as input.  This goes against the active shipment database unlike listOdsShipmentHistTraceEvents in ShipmentOds and uses a different UI.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid shipmentId is required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of active shipments, operations, and admin events for the shipment ID provided is returned.
	* <br/>2. Otherwise an appropriate error message is returned.	
	*/
	public listShipmentHistTraceEvents(request: ListShipmentHistTraceEventsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListShipmentHistTraceEventsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/hist-trace-events'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Lists shipment management remarks for a specific shipment.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid shipmentInstId, seqNbr, and typeCd must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all values are valid, then a list of the shipment management remarks is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listShipmentManagementRemarks(request: ListShipmentManagementRemarksRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListShipmentManagementRemarksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/management-remarks/list'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation lists the details of a shipment for a list of PRO numbers or shipment instance IDs.  shipmentDetailCd, an optional input, allows consumers to specify a list of enum values to indicate the type of related shipment data to be returned.  If no shipmentDetailCd value is supplied, all shipment details will be returned.
	* <br/>
	* <br/>Inputs
	* <br/>- Shipment PRO number or instance ID is required.
	* <br/>- Optional shipmentDetailCd to indicate the type of related shipment data to be returned in the request.  If shipmentDetailCd is not supplied, then return SHM_SHIPMENT data and all its child data.  Otherwise return SHM_SHIPMENT data plus the the data referenced by each code:
	* <br/>    - SHPONLY: return SHM_SHIPMENT data only
	* <br/>	- ACC - return SHM_SHIPMENT data + related SHM_AC_SVC
	* <br/>	- ADVBYD - return SHM_SHIPMENT data + related SHM_ADV_BYD_CARR
	* <br/>	- PARTY  - return SHM_SHIPMENT data + related SHM_AS_ENTD_CUST, SHM_AS_ENTD_BOL_PARTY
	* <br/>	- CMDTY - return SHM_SHIPMENT data + related SHM_COMMODITY, SHM_CMDY_DIMENSION, SHM_HAZ_MAT
	* <br/>	- CUSTOM  - return SHM_SHIPMENT data + related SHM_CUSTOMS_BOND, SHM_CUSTOMS_CNTRL
	* <br/>	- LNHDIM - return SHM_SHIPMENT data + related SHM_LNH_DIMENSION
	* <br/>	- MSCITM - return SHM_SHIPMENT data + related SHM_MISC_LINE_ITEM
	* <br/>	- NTFICTN - return SHM_SHIPMENT data + related SHM_NOTIFICATION
	* <br/>	- OPSSHP - return SHM_SHIPMENT data + related SHM_OPS_SHIPMENT
	* <br/>	- REMARK - return SHM_SHIPMENT data + related SHM_REMARK
	* <br/>	- SRN - return SHM_SHIPMENT data + related SHM_SR_NBR, SHM_SR_XTND_DTL
	* <br/>	- TDC - return SHM_SHIPMENT data + related SHM_TM_DT_CRITICAL
	* <br/>
	* <br/>Outputs
	* <br/>- Shipment details.
	*/
	public listShipments(request: ListShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation sends a request to print FBDS documents for PRO numbers with additional parameters.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid list of PRO numbers, route names, or equipment full IDs (just one of the three) along with a printer code, printFBDSDocumentsTypeCd, report, and form types (FBDS/CopyBill) must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the print request is sent.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public printFBDSDocuments(request: PrintFBDSDocumentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<PrintFBDSDocumentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/printed-fbds-documents'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation updates the dimensions of a shipment handling unit.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A shipment hndling unit must exist for the tracking PRO number provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the shipment handling unit with the dimensions from the input is updated and the volume is calculated based on these values. 
	* <br/>Pre-condition
	* <br/>2. If a shipment handling unit doesn't exist for the tracking PRO number, then a 404 - Not Found error message is returned.
	*/
	public updateHandlingUnitDimensions(request: UpdateHandlingUnitDimensionsRqst,
							   pathParams: UpdateHandlingUnitDimensionsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateHandlingUnitDimensionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipment-handling-units/{trackingProNbr}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation updates a movement for shipments along with details for shipment and event log.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. At least one valid shipment identifier is required.
	* <br/>2. Valid shipment movement details must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the shipment, shipment movement, and shipment event log detail for each of the shipments provided is updated.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public updateMovementForShipments(request: UpdateMovementForShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/movements'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation updates the following for a given shipment: foodPoisonCd, motorizedPiecesCount, loosePiecesCount, and bulkLiquidInd.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A shipment with either the shipment instance ID or the PRO number must exist.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, the shipment is updated with the input provided.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public updateShipmentFoodAndMotorMoves(request: UpdateShipmentFoodAndMotorMovesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/food-and-motor-moves'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation updates the hazmat details for the requested shipment instance ID.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A Shipment with the shipment instance ID provided must exist.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the shipment hazmat details are updated based on the data provided.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public updateShipmentHazMat(request: UpdateShipmentHazMatRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateShipmentHazMatResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hazmats'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation updates shipment linehaul dimensions. The input replaces all existing shipment dimensions for the shipment.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid capturedByUserId, reportingSicCd, and linehaul dimension details must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the shipment linehaul dimensions are updated. 
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public updateShipmentLinehaulDimensions(request: UpdateShipmentLinehaulDimensionsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipment-linehaul-dimensions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates a shipment management remark.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A new and valid shipment management remark is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the values provided are valid and found, then update the shipment management remark and return the updated shipment management remark.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public updateShipmentManagementRemark(request: UpdateShipmentManagementRemarkRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateShipmentManagementRemarkResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/management-remarks'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation updates one or more shipments, shipment movements, and shipment event logs upon trip completion.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid PRO number or shipment instance ID is required.
	* <br/>2. The update audit attributes are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the shipment movement and shipment event log detail for each of the shipments provided as input is updated.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public updateShipmentsUponTripCompletion(request: UpdateShipmentsUponTripCompletionRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/city-trips/completions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation updates one or more shipments, shipment movements, and shipment event logs upon trip dispatch.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid PRO number or shipment instance ID is required.
	* <br/>2. The update audit attributes are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the shipment movement and shipment event log detail for each of the shipments provided as input is updated.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public updateShipmentsUponTripDispatch(request: UpdateShipmentsUponTripDispatchRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/city-trips/dispatches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates one or more shipments with the supplied route details.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Route details (routePrefix, routeSuffix, routeTypeCd) are required.
	* <br/>2. shipmentInstId - at least one is required for identifying the shipment.
	* <br/>3. updatedTimestamp, updateByPgmId, and updateById are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the values provided are valid and found, then the route detail on each of the input shipments is updated.
	* <br/>2. If the update is successful, an empty response is returned.  Otherwise an appropriate error message is returned.
	*/
	public updateShipmentsWithRouteDtl(request: UpdateShipmentsWithRouteDtlRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/route-details'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation upserts a movement exception of type ad hoc that will be tied to a movement.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid movement exception is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the upserted movement exception is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public UpsertAdHocMovementException(request: UpsertAdHocMovementExceptionRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertAdHocMovementExceptionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/movement-exceptions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation upserts a movement exception action that will be tied to a movement exception.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid movement exception remark is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the upserted movement exception remark is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public upsertMovementExceptionAction(request: UpsertMovementExceptionActionRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertMovementExceptionActionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/movement-exception-actions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation upserts an Operations Shipment record.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Valid data is provided to either create or update the existing record.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the upserted Operations Shipment record is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public upsertOperationsShipment(request: UpsertOperationsShipmentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertOperationsShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/operations-shipment'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation upserts an Operations Shipment pilot record.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Valid data is provided to either create or update the existing record.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the upserted Operations Shipment Pilot record is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public upsertOperationsShipmentPilot(request: UpsertOperationsShipmentPilotRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertOperationsShipmentPilotResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/operations-shipment-pilots'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation validates shipments to determine which are eligible and which are not.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid list of PRO numbers or shipment instance IDs must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the list of eligible shipments and ineligible shipments are returned.
	* <br/>2. Otherwise an appropraite error message is returned.
	*/
	public validateShipmentsForDelivery(request: ValidateShipmentsForDeliveryRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ValidateShipmentsForDeliveryResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/delivery-validations'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation will create a base log for the list of shipment identifiers (PRO numbers or shipment instance IDs) provided. This is just a temporary API because it's implemented using Gen proxy.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid logId along with a list of valid shipments identifiers (PRO numbers or shipment instance IDs) is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a base log will be created for the shipments.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public createBaseLog(request: CreateBaseLogRqst,
							   pathParams: CreateBaseLogPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/base-logs/{logId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete Rule
	*/
	public deleteRule(
							   pathParams: DeleteRulePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/{ruleInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Execute a rule
	*/
	public executeRule(request: ExecuteRuleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ExecuteRuleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/execute'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Get a Rule by identifier
	*/
	public getRule(
							   pathParams: GetRulePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetRuleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/{ruleInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* 
	* <br/>			Get full rule with rulesets, expressions and outcomes by the rule name.
	* <br/>			Pre-conditions:
	* <br/>				1. The current ruleName must be provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>				1. If input value is valid, then the rule will be returned. 
	* <br/>				2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public getRuleByName(
							   pathParams: GetRuleByNamePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetRuleByNameResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/name/{ruleName}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all rules that are available.
	*/
	public listRules(
							   queryParams: ListRulesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all rules filtered by supplied expressions
	*/
	public listRulesByExpressions(request: ListRulesByExpressionsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRulesByExpressionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/rulesets'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Lists all rules that are available by ruleset name with inclusion and exclusion.
	*/
	public listRulesByRulesetName(
							   queryParams: ListRulesByRulesetNameQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRulesByRulesetNameResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules/ruleset-names'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all rules ruturning minimum properties for rule execution
	*/
	public listRulesForExecution(
							   queryParams: ListRulesForExecutionQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRulesForExecutionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/executable-rules'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Upsert Rule
	*/
	public upsertRule(request: UpsertRuleRequest,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertRuleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-rules/rules'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Delete expired messages in the queue
	*/
	public deleteExpiredMessages(
							   pathParams: DeleteExpiredMessagesPath,
							   queryParams: DeleteExpiredMessagesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteExpiredMessagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation returns counts for the number of ensembles in each of the status cd values.
	* <br/>Pre-conditions:
	* <br/>1. Input search criteria may be provided.
	* <br/>Post-condition:
	* <br/>1. Counts for each ensemble status are returned.
	*/
	public getEnsembleStatusCounts(
							   pathParams: GetEnsembleStatusCountsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetEnsembleStatusCountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/counts/{fromTmst}/{toTmst}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns counts for the number of messages pending in the queue.
	* <br/>Pre-conditions:
	* <br/>1. Queue name is provided.
	* <br/>Post-condition:
	* <br/>1. Count of all messages in ready and wait statuses
	*/
	public getQueuePendingCount(
							   pathParams: GetQueuePendingCountPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetQueuePendingCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/counts/pending'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation updates one or more SUSPENDED Ensemble execution row to a status of KILLED, in order to indicate it is has been forced to stop processing.
	* <br/>Pre-conditions:
	* <br/>1. One or more Ensemble Instance Ids that is in a status of SUSPENDED is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. Ensemble record(s) updated to a Status of KILLED.
	*/
	public killEnsemble(request: KillEnsembleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<KillEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/ensembles/kill'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* List all expired messages in the queue
	*/
	public listExpiredMessages(
							   pathParams: ListExpiredMessagesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExpiredMessagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages/{enqueueFromTmst}/{enqueueToTmst}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List the queues.
	*/
	public listQueues(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListQueuesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Replay a message in expired status
	*/
	public replayMessage(request: ReplayMessagePath,
							   pathParams: ReplayMessagePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages/{messageId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation Restarts a STARTED or NOT_STARTED Ensemble execution based on input Ensemble Instance Id.
	* <br/>Pre-conditions:
	* <br/>1. Input Ensemble Instance Id is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. STARTED or NOT_STARTED Ensemble execution status is updated to STARTED and execution is initiated; if any Minuets exist for the Ensemble, those are initiated in sequence and status updates are performed.
	*/
	public restartEnsemble(request: RestartEnsembleRqst,
							   pathParams: RestartEnsemblePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RestartEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/ensembles/{ensembleInstId}/restart'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation Resumes a SUSPENDED Ensemble execution based on input Ensemble Instance Id.
	* <br/>Pre-conditions:
	* <br/>1. Input Ensemble Instance Id is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. SUSPENDED Ensemble execution status is updated to STARTED and execution is initiated; if any Minuets exist for the Ensemble, those are initiated in sequence and status updates are performed.
	*/
	public resumeEnsemble(request: ResumeEnsembleRqst,
							   pathParams: ResumeEnsemblePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ResumeEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/ensembles/{ensembleInstId}/resume'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Start a queue.
	*/
	public startQueue(request: StartQueuePath,
							   pathParams: StartQueuePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/start'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Stop a queue. Stopping a queue will only stop the dequeuing process, it will continue to enqueue
	*/
	public stopQueue(request: StopQueuePath,
							   pathParams: StopQueuePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/stop'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Stop a queue. Stopping a queue will only stop the dequeuing process, it will continue to enqueue
	*/
	public stopQueueWebhook(request: StopQueueWebhookPath,
							   pathParams: StopQueueWebhookPath,
							   queryParams: StopQueueWebhookQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/webhooks/queues/{queueName}/stop'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Export documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All documents matching the filter will be exported as CSV or Excel format
	* <br/>		
	*/
	public exportShipmentUnfiltered(request: ExportShipmentUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipment-unfiltered/exports'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Documents matching the filter will be returned
	* <br/>		
	*/
	public searchShipmentUnfiltered(request: SearchShipmentUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchShipmentUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipment-unfiltered/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for shipment-unfiltered
	*/
	public changesFeedShipmentUnfiltered(request: ChangesFeedShipmentUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipment-unfiltered/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for ShipmentUnfiltered
	*/
	public registerFilterShipmentUnfiltered(request: RegisterFilterShipmentUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterShipmentUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipment-unfiltered/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for ShipmentUnfiltered
	*/
	public unregisterFilterShipmentUnfiltered(request: UnregisterFilterShipmentUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipment-unfiltered/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete Elastic document for Shipment
	*/
	public deleteElasticDocumentShipment(
							   pathParams: DeleteElasticDocumentShipmentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteElasticDocumentShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/shipment-unfiltered/_doc/{shipmentInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Get Elastic document from Shipment index for given primary key
	*/
	public getElasticDocumentShipment(
							   pathParams: GetElasticDocumentShipmentPath,
							   queryParams: GetElasticDocumentShipmentQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/shipment-unfiltered/_doc/{shipmentInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get document count from Shipment index
	*/
	public getElasticDocumentShipmentCount(
							   queryParams: GetElasticDocumentShipmentCountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentShipmentCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/shipment-unfiltered/_count'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update Elastic document for Shipment
	*/
	public updateElasticDocumentShipment(request: UpdateElasticDocumentShipmentRqst,
							   pathParams: UpdateElasticDocumentShipmentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateElasticDocumentShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/shipment-unfiltered/_doc/{shipmentInstId}/_update'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Export documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All documents matching the filter will be exported as CSV or Excel format
	* <br/>		
	*/
	public exportEnsembleStateUnfiltered(request: ExportEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/exports'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Documents matching the filter will be returned
	* <br/>		
	*/
	public searchEnsembleStateUnfiltered(request: SearchEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchEnsembleStateUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for ensemble-state-unfiltered
	*/
	public changesFeedEnsembleStateUnfiltered(request: ChangesFeedEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for EnsembleStateUnfiltered
	*/
	public registerFilterEnsembleStateUnfiltered(request: RegisterFilterEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterEnsembleStateUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for EnsembleStateUnfiltered
	*/
	public unregisterFilterEnsembleStateUnfiltered(request: UnregisterFilterEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete Elastic document for EnsembleState
	*/
	public deleteElasticDocumentEnsembleState(
							   pathParams: DeleteElasticDocumentEnsembleStatePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Get Elastic document from EnsembleState index for given primary key
	*/
	public getElasticDocumentEnsembleState(
							   pathParams: GetElasticDocumentEnsembleStatePath,
							   queryParams: GetElasticDocumentEnsembleStateQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get document count from EnsembleState index
	*/
	public getElasticDocumentEnsembleStateCount(
							   queryParams: GetElasticDocumentEnsembleStateCountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentEnsembleStateCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_count'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update Elastic document for EnsembleState
	*/
	public updateElasticDocumentEnsembleState(request: UpdateElasticDocumentEnsembleStateRqst,
							   pathParams: UpdateElasticDocumentEnsembleStatePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}/_update'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Start ensemble by invoking API
	* <br/>        	PRE-CONDITION:
	* <br/>        		valid channelName is required
	* <br/>        		valid payload is required
	* <br/>        	POST-CONDITION:
	* <br/>        		Ensemble is started and an id is returned in the response
	* <br/>        
	*/
	public startShmApiChEnsemble(request: StartShmApiChEnsembleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<StartShmApiChEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/channels/SHM_SHIPMENT_ENSEMBLE_CH/ensembles'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(ShipmentApiService.ShipmentApiEndpoint);
	}
}
