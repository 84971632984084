/**
 * Shipment API
 * Version: 2.0
 * Build: 2.0.0.96
 */

import {
	ShipmentId,
	AcctId,
	ListInfo,
	ChildShipmentId,
	AuditInfo,
	EquipmentId,
	SearchRecordMessageHeader,
	XrtAttributeFilter,
	XrtSortExpression,
	XrtSearchRespHeader,
	MonetaryAmount,
	ContactInfo,
	Comment,
	Status,
	ReferenceNumber,
	Weight,
	Volume,
	Phone,
	Dimensions,
	Charge,
	SupplementalReference,
	StatusException,
	Density,
	Address,
	Location,
	PayloadType,
	AccessorialUnitOfMeasureCd,
	ActionCd,
	AdvanceBeyondTypeCd,
	ArchiveControlCd,
	BillClassCd,
	BillStatusCd,
	BillToRelationCd,
	BolPartyTypeCd,
	BolUsageTypeCd,
	ChargeToCd,
	CollectMoneyAtDeliveryCd,
	CommodityClassCd,
	CommodityPackageCd,
	ConsigneeUnloadedTrailerCd,
	CountryCd,
	CurrencyCd,
	CustomsControlTypeCd,
	CustomsPortCd,
	DebtorCd,
	DeliveryInfoRequiredCd,
	DeliveryQualifierCd,
	EnsembleExecutionStatusCd,
	EnsembleListenerModeCd,
	EventLogSubTypeCd,
	EventLogTypeCd,
	ExemptReasonCd,
	FoodPoisonCd,
	HandlingUnitTypeCd,
	HazmatSourceCd,
	InvoiceCurrencyCd,
	LateTenderCd,
	LineItemChargeToCd,
	MatchedPartySourceCd,
	MatchedPartyStatusCd,
	MatchedPartyTypeCd,
	MinuetExecutionStatusCd,
	MiscLineItemCd,
	MiscLineItemPaymentMethodCd,
	MovementExceptionTypeCd,
	MovementStatusCd,
	NetExplosiveMassUomCd,
	NotificationCd,
	PrintFBDSDocumentsTypeCd,
	RadioactivityUomCd,
	RatingCurrencyCd,
	RouteTypeCd,
	RuleActiveTypeCd,
	RuleExpressionValueTypeCd,
	RuleFailureReasonCd,
	RuleLogicalOperatorCd,
	RuleOperatorCd,
	RuleOverrideReasonCd,
	RuleShmEntityTypeCd,
	ServiceCalculationStatusCd,
	ServiceStatusCd,
	ServiceTypeCd,
	ShipmentAcquiredTypeCd,
	ShipmentCreditStatusCd,
	ShipmentDetailCd,
	ShipmentManagementRemarkTypeCd,
	ShipmentMovementTypeCd,
	ShipmentRemarkTypeCd,
	ShipmentSourceCd,
	ShipmentSpecialServiceCd,
	ShipmentVolumeMethodCd,
	ShipmentVolumeTypeCd,
	ShipperLoadedTrailerCd,
	TransactionEventDetailTypeCd,
	WarrantyStatusCd
} from '@xpo-ltl/sdk-common';

/**
 * Create the Copy Bill document and then archive to DMS and return the DMS Document Id.
 */
export class CreateAndArchiveCopyBillDocumentRqst {
	/**
	 * Either the PRO or shipmentInstId can be used. Â They act as identifiers for the shipment.
	 * Required: true
	 * Array: false
	 */
	shipmentId:ShipmentId = undefined;
	/**
	 * The person who made the request.
	 * Required: false
	 * Array: false
	 */
	requestor:string = undefined;
}
/**
 * Response to Create the Copy Bill document and then archive to DMS and return the DMS Document Id.
 */
export class CreateAndArchiveCopyBillDocumentResp {
	/**
	 * The DMS document ID for the archived copy bill.
	 * Required: false
	 * Array: false
	 */
	dmsDocumentId:string = undefined;
}
/**
 * The path parameter which is used to create a base log.
 */
export class CreateBaseLogPath {
	/**
	 * The unique identifier associated with a base log.
	 * Required: true
	 * Array: false
	 */
	logId:number = undefined;
}
/**
 * Request to create a base log.
 */
export class CreateBaseLogRqst {
	/**
	 * The list of shipment identifiers for which a base log will be created.  Either the proNbr or shipmentInstId can be used.
	 * Required: true
	 * Array: true
	 */
	shipmentIds:ShipmentId[] = undefined;
}
/**
 * Creates a new shipment management remark.
 */
export class CreateShipmentManagementRemarkRqst {
	/**
	 * The shipment movement exception remark/shipment note which will be created.
	 * Required: true
	 * Array: false
	 */
	managementRemark:ManagementRemark = undefined;
}
/**
 * The created shipment management remark.
 */
export class CreateShipmentManagementRemarkResp {
	/**
	 * The shipment movement exception remark/shipment note that was created.
	 * Required: false
	 * Array: false
	 */
	managementRemark:ManagementRemark = undefined;
}
/**
 * A new shipment remark.
 */
export class CreateShipmentRemarkRqst {
	/**
	 * The shipment remark which will be created.
	 * Required: true
	 * Array: false
	 */
	shipmentRemark:Remark = undefined;
}
/**
 * The created shipment movement exception remark.
 */
export class CreateShipmentRemarkResp {
	/**
	 * The shipment remark that was created.
	 * Required: false
	 * Array: false
	 */
	shipmentRemark:Remark = undefined;
}
/**
 * Path parameters used to delete an ad hoc movement exception.
 */
export class DeleteAdHocMovementExceptionPath {
	/**
	 * The unique identifier for the shipment.
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The sequence number associated with the movement.
	 * Required: true
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * The sequence number associated with the movement exception.
	 * Required: true
	 * Array: false
	 */
	sequenceNbr:number = undefined;
}
/**
 * Path parameters used to delete a shipment management remark.
 */
export class DeleteMovementExceptionActionPath {
	/**
	 * The unique identifier for the shipment.
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The sequence number associated with the movement exception action.
	 * Required: true
	 * Array: false
	 */
	sequenceNbr:number = undefined;
}
/**
 * Query parameters used to delete a shipment management remark.
 */
export class DeleteMovementExceptionActionQuery {
	/**
	 * The sequence number associated with the shipment movement.
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * The sequence number associated with the shipment movement exception.
	 * Required: false
	 * Array: false
	 */
	movementExceptionSequenceNbr:number = undefined;
}
/**
 * Path parameters used to delete a shipment management remark.
 */
export class DeleteShipmentManagementRemarkPath {
	/**
	 * The unique identifier for the shipment.
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The sequence number associated with the exception remarks.
	 * Required: true
	 * Array: false
	 */
	sequenceNbr:number = undefined;
}
/**
 * Query parameters used to delete a shipment management remark.
 */
export class DeleteShipmentManagementRemarkQuery {
	/**
	 * The sequence number associated with the shipment movement.
	 * Required: true
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * The sequence number associated with the shipment movement exception.
	 * Required: true
	 * Array: false
	 */
	movementExceptionSequenceNbr:number = undefined;
}
/**
 * Path parameters which are used to delete a shipment movement.
 */
export class DeleteShipmentMovementPath {
	/**
	 * The unique identifier for the shipment.
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The sequence number associated with the shipment movement.
	 * Required: true
	 * Array: false
	 */
	sequenceNbr:number = undefined;
}
/**
 * Request to retrieve information about a Shipment
 */
export class GetShipmentQuery {
	/**
	 * Business identifier to the shipment
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Date on which the shipment was picked up
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * Internal identifier to the shipment
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Enum element to allow consumers to optionally indicate the type of data to be included in the response:
	 *     - ShipmentDetailCd not supplied - return SHM_SHIPMENT data and all its child data
	 * - SHPONLY - return SHM_SHIPMENT data only
	 * - ACC - return SHM_SHIPMENT data + related SHM_AC_SVC
	 * - ADVBYD - return SHM_SHIPMENT data + related SHM_ADV_BYD_CARR
	 * - PARTY  - return SHM_SHIPMENT data + related SHM_AS_ENTD_CUST, SHM_AS_ENTD_BOL_PARTY
	 * - BESTAT - return SHM_SHIPMENT data + related SHM_BILL_ENTRY_STATS
	 * - SHMBOL - return SHM_SHIPMENT data + related SHM_BOL_ATTACHMENT, SHM_BOL_USAGE
	 * - CMDTY - return SHM_SHIPMENT data + related SHM_COMMODITY, SHM_CMDY_DIMENSION, SHM_HAZ_MAT
	 * - CUSTOMS  - return SHM_SHIPMENT data + related SHM_CUSTOMS_BOND, SHM_CUSTOMS_CNTRL
	 * - HNDLNGUNIT - return SHM_SHIPMENT data + related SHM_HANDLING_UNIT_MVMT
	 * - LNHDIM - return SHM_SHIPMENT data + related SHM_LNH_DIMENSION
	 * - MISCITM - return SHM_SHIPMENT data + related SHM_MISC_LINE_ITEM
	 * - NTFICTN - return SHM_SHIPMENT data + related SHM_NOTIFICATION
	 * - OPSSHP - return SHM_SHIPMENT data + related SHM_OPS_SHIPMENT
	 * - REMARK - return SHM_SHIPMENT data + related SHM_REMARK
	 * - RULEOVR - return SHM_SHIPMENT data + related SHM_RULE_OVERRIDE
	 * - SRN - return SHM_SHIPMENT data + related SHM_SR_NBR, SHM_SR_XTND_DTL
	 * - TDC - return SHM_SHIPMENT data + related SHM_TM_DT_CRITICAL
	 * - XDOCK_EXCP - return SHM_SHIPMENT data + related SHM_XDOCK_EXCP
	 * Required: false
	 * Array: true
	 */
	shipmentDetailCd:ShipmentDetailCd[] = undefined;
}
/**
 * Response to the request to retrieve information about a Shipment.
 */
export class GetShipmentResp {
	/**
	 * Required: false
	 * Array: false
	 */
	shipment:Shipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	asMatchedParty:AsMatchedParty[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	commodity:Commodity[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	accessorialService:AccessorialService[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	suppRefNbr:SuppRefNbr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	remark:Remark[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	advanceBeyondCarrier:AdvanceBeyondCarrier[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customsControl:CustomsControl[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customsBond:CustomsBond[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	hazMat:HazMat[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	miscLineItem:MiscLineItem[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	timeDateCritical:TimeDateCritical = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeInfo:ChargeInfo = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ratingInfo:RatingInfo = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	prepaidDebtorParty:AcctId = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	collDebtorParty:AcctId = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	managementRemark:ManagementRemark[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	movement:Movement[] = undefined;
	/**
	 * Contains Identifying information for a parent shipment.
	 * Required: false
	 * Array: false
	 */
	parentShipmentId:ShipmentId = undefined;
	/**
	 * Formatted PickupDate of the Shipment
	 * Required: false
	 * Array: false
	 */
	pickupDateText:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	childShipmentIds:ShipmentId[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	siblingShipmentIds:ShipmentId[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	custPricingAgreementId:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	shipmentSpecialServiceSummaries:ShipmentSpecialServiceSummary[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	xdockExceptions:XdockException[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appointmentRequiredInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	withinTdcInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	handlingUnits:HandlingUnit[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	operationsShipment:OperationsShipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	linehaulDimensions:LnhDimension[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	notifications:ShmNotification[] = undefined;
}
/**
 * Request to retrieve as matched parties for a shipment.
 */
export class GetShipmentAsMatchedPartiesQuery {
	/**
	 * The business identifier for the shipment.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * The date on which the shipment was picked up.
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * The internal unique identifier for the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
}
/**
 * Response to the request to retrieve party information on a Shipment.
 */
export class GetShipmentAsMatchedPartiesResp {
	/**
	 * Required: false
	 * Array: true
	 */
	asMatchedParties:AsMatchedParty[] = undefined;
}
/**
 * The path parameter of the request to get shipment count.
 */
export class GetShipmentCountByShipperPath {
	/**
	 * The shipper CIS customer number.
	 * Required: true
	 * Array: false
	 */
	shipperCisCustomerNbr:number = undefined;
}
/**
 * The response to the request to get shipment count.
 */
export class GetShipmentCountByShipperResp {
	/**
	 * The shipment count for the previous 12 months.
	 * Required: false
	 * Array: true
	 */
	shipmentCount:number[] = undefined;
}
/**
 * Path parameters used to retrieve a shipment management remark.
 */
export class GetShipmentManagementRemarkPath {
	/**
	 * The unique identifier for the shipment.
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The sequence number associated with the exception remarks.
	 * Required: true
	 * Array: false
	 */
	sequenceNbr:number = undefined;
}
/**
 * Query parameters used to retrieve a shipment management remark.
 */
export class GetShipmentManagementRemarkQuery {
	/**
	 * The sequence number associated with the shipment movement.
	 * Required: true
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * The sequence number associated with the shipment movement exception.
	 * Required: true
	 * Array: false
	 */
	movementExceptionSequenceNbr:number = undefined;
}
/**
 * The response to the request to retrieve a shipment management remark.
 */
export class GetShipmentManagementRemarkResp {
	/**
	 * A single movement exception remark/shipment note that matches the input parameters.
	 * Required: false
	 * Array: false
	 */
	managementRemark:ManagementRemark = undefined;
}
/**
 * Path parameters used to retrieve a shipment movement exception remark.
 */
export class GetShipmentRemarkPath {
	/**
	 * The unique identifier for the shipment.
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The type code associated with the shipment remark.
	 * Required: true
	 * Array: false
	 */
	typeCd:ShipmentRemarkTypeCd = undefined;
}
/**
 * The response to the request to retrieve a shipment movement exception remark.
 */
export class GetShipmentRemarkResp {
	/**
	 * A single shipment remark that matches the input parameters.
	 * Required: false
	 * Array: false
	 */
	shipmentRemark:Remark = undefined;
}
/**
 * Request to retrieve aggregated information about shipments.
 */
export class GetShipmentsAggregationQuery {
	/**
	 * The date on which the shipment was picked up (range low end - YYYY-MM-DD).
	 * Required: true
	 * Array: false
	 */
	beginDate:string = undefined;
	/**
	 * The date on which the shipment was picked up (range high end - YYYY-MM-DD).
	 * Required: true
	 * Array: false
	 */
	endDate:string = undefined;
	/**
	 * The internal identifiers of the pricing agreements which are associated with the shipments.
	 * Required: false
	 * Array: true
	 */
	pricingAgreementIds:number[] = undefined;
}
/**
 * Response to request to retrieve aggregated information about shipments.
 */
export class GetShipmentsAggregationResp {
	/**
	 * The number of aggregated shipments.
	 * Required: true
	 * Array: false
	 */
	count:number = undefined;
	/**
	 * The total revenue amount of all the aggregated shipments.
	 * Required: true
	 * Array: false
	 */
	totalRevenueAmount:number = undefined;
	/**
	 * The total weight of all the aggregated shipments (in pounds).
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
}
/**
 * Request to list the delivery collection instruction for shipments.
 */
export class ListDeliveryCollectionInstructionForShipmentsRqst {
	/**
	 * The list of shipment identifiers for which the delivery collection instructions will be listed.
	 * Required: true
	 * Array: true
	 */
	shipmentIds:ShipmentId[] = undefined;
}
/**
 * Response to the request to list the delivery collection instruction for shipments.
 */
export class ListDeliveryCollectionInstructionForShipmentsResp {
	/**
	 * The list of delivery collection instructions for the shipment identifiers provided.
	 * Required: false
	 * Array: true
	 */
	deliveryCollectionInstructions:DeliveryCollectionInstruction[] = undefined;
}
/**
 * Request to list FBDS/CopyBill document details.
 */
export class ListFBDSDocumentsRqst {
	/**
	 * The list of business identifiers for the shipments.
	 * Required: false
	 * Array: true
	 */
	proNbrs:string[] = undefined;
	/**
	 * The type of document (FBDS/CopyBill) to be retrieved.
	 * Required: true
	 * Array: false
	 */
	documentRequestType:string = undefined;
}
/**
 * Response to the request to retrieve FBDS/CopyBill document details.
 */
export class ListFBDSDocumentsResp {
	/**
	 * The list of FBDS/CopyBill documents that are associated with the shipments provided.
	 * Required: false
	 * Array: true
	 */
	fbdsDocuments:FBDSDocument[] = undefined;
}
/**
 * Request message to retrieve Shipment details.
 */
export class ListShipmentHistTraceEventsRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentId:ShipmentId = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to request message to retrieve Shipment details.
 */
export class ListShipmentHistTraceEventsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	shipmentHistTraceEvents:ShipmentHistTraceEvent[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Query parameters used to list shipment management remarks.
 */
export class ListShipmentManagementRemarksRqst {
	/**
	 * A list of unique identifiers for shipment management remarks.
	 * Required: false
	 * Array: true
	 */
	managementRemarks:ManagementRemark[] = undefined;
	/**
	 * Indicates whether remarks are shown to a customer.
	 * Required: false
	 * Array: false
	 */
	showToCustomerInd:boolean = undefined;
}
/**
 * Contains information on shipment management remarks such as comment, reason, etc.
 */
export class ListShipmentManagementRemarksResp {
	/**
	 * A list of shipment movement exception remarks/shipment notes that match the input criteria.
	 * Required: false
	 * Array: true
	 */
	managementRemarks:ManagementRemark[] = undefined;
}
/**
 * 
 * 		A request to retrieve a collection of Shipments based upon the shipment instance ID or pro numbers.
 * 	
 */
export class ListShipmentsRqst {
	/**
	 * Contains identifying information for a shipment.
	 * Required: false
	 * Array: true
	 */
	shipmentIds:ShipmentId[] = undefined;
	/**
	 * Enum element to allow consumers to optionally indicate the type of data to be included in the response:
	 * 						- ShipmentDetailCd not supplied - return SHM_SHIPMENT data and all its child data
	 * 						- SHPMT - return SHM_SHIPMENT data only
	 * 						- ACC - return SHM_SHIPMENT data + related SHM_AC_SVC
	 * 						- ADVBYD - return SHM_SHIPMENT data + related SHM_ADV_BYD_CARR
	 * 						- PARTY  - return SHM_SHIPMENT data + related SHM_AS_ENTD_CUST, SHM_AS_ENTD_BOL_PARTY
	 * 						- CMDTY - return SHM_SHIPMENT data + related SHM_COMMODITY, SHM_CMDY_DIMENSION, SHM_HAZ_MAT
	 * 						- CUSTOM  - return SHM_SHIPMENT data + related SHM_CUSTOMS_BOND, SHM_CUSTOMS_CNTRL
	 * 						- LNHDIM - return SHM_SHIPMENT data + related SHM_LNH_DIMENSION
	 * 						- MSCITM - return SHM_SHIPMENT data + related SHM_MISC_LINE_ITEM
	 * 						- NTFICTN - return SHM_SHIPMENT data + related SHM_NOTIFICATION
	 * 						- OPSSHP - return SHM_SHIPMENT data + related SHM_OPS_SHIPMENT
	 * 						- REMARK - return SHM_SHIPMENT data + related SHM_REMARK
	 * 						- SRN - return SHM_SHIPMENT data + related SHM_SR_NBR, SHM_SR_XTND_DTL
	 * 						- TDC - return SHM_SHIPMENT data + related SHM_TM_DT_CRITICAL
	 * Required: false
	 * Array: true
	 */
	shipmentDetailCd:ShipmentDetailCd[] = undefined;
}
/**
 * Response to the request to retrieve information for the list of ShipmentIds.
 */
export class ListShipmentsResp {
	/**
	 * Contains information for the shipment and its children.
	 * Required: false
	 * Array: true
	 */
	shipmentDetails:ShipmentDetails[] = undefined;
}
/**
 * Request to list handling units and their associated movements for the list child shipment identifiers provided.
 */
export class ListHandlingUnitsRqst {
	/**
	 * The list of identifiers for handling units including the shipment instance ID, sequence number, and child PRO number.
	 * Required: false
	 * Array: true
	 */
	childShipmentIds:ChildShipmentId[] = undefined;
}
/**
 * Response to the request to retrieve information for the  list of child shipment identifiers.
 */
export class ListHandlingUnitsResp {
	/**
	 * The list of handling units and associated movements that matched the child shipment identifers provided.
	 * Required: false
	 * Array: true
	 */
	handlingUnits:HandlingUnit[] = undefined;
}
/**
 * Request to update a shipment movement along with shipment and shipment event log records.
 */
export class UpdateMovementForShipmentsRqst {
	/**
	 * Details for the shipment movement which will be used to update the existing records.
	 * Required: true
	 * Array: false
	 */
	movement:Movement = undefined;
	/**
	 * The list of shipments identifiers for the shipments which will be updated.
	 * Required: true
	 * Array: true
	 */
	shipmentIds:ShipmentId[] = undefined;
}
/**
 * Request to update a shipment management remark.
 */
export class UpdateShipmentManagementRemarkRqst {
	/**
	 * A shipment management remark/shipment note used to update an existing one.
	 * Required: true
	 * Array: false
	 */
	managementRemark:ManagementRemark = undefined;
}
/**
 * Response to the request to update a shipment management remark.
 */
export class UpdateShipmentManagementRemarkResp {
	/**
	 * The updated shipment management remark/shipment note.
	 * Required: false
	 * Array: false
	 */
	managementRemark:ManagementRemark = undefined;
}
/**
 * Updates one or more shipments, shipment movements, and shipment event logs upon trip completion.
 */
export class UpdateShipmentsUponTripCompletionRqst {
	/**
	 * The list of identifiers, including the shipmentInstId, proNumber, and pickupDate, for the shipments that will be updated upon trip completion.
	 * Required: true
	 * Array: true
	 */
	shipmentIds:ShipmentId[] = undefined;
	/**
	 * The equipment details for the trip completion.
	 * Required: true
	 * Array: false
	 */
	tripCompletionEquipment:DispatchEquipment = undefined;
	/**
	 * Date and time when the trailer had been closed.
	 * Required: true
	 * Array: false
	 */
	closeDateTime:Date = undefined;
	/**
	 * Standard audit trail information.
	 * Required: true
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Updates one or more shipments, shipment movements, and shipment event logs upon trip dispatch.
 */
export class UpdateShipmentsUponTripDispatchRqst {
	/**
	 * The list of identifiers, including the shipmentInstId, proNumber, and pickupDate, for the shipments that will be updated upon trip dispatch.
	 * Required: false
	 * Array: true
	 */
	shipmentIds:ShipmentId[] = undefined;
	/**
	 * The equipment details for the dispatch trip.
	 * Required: false
	 * Array: false
	 */
	dispatchEquipment:DispatchEquipment = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Request to Update one or more Shipments with the supplied route detail.
 */
export class UpdateShipmentsWithRouteDtlRqst {
	/**
	 * Shipment Instance IDs
	 * Required: true
	 * Array: true
	 */
	shipmentInstIds:number[] = undefined;
	/**
	 * Prefix for the route
	 * Required: true
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * Suffix for the route
	 * Required: true
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * Type Code for the route
	 * Required: true
	 * Array: false
	 */
	routeTypeCd:RouteTypeCd = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * The request to upsert a movement exception action.
 */
export class UpsertAdHocMovementExceptionRqst {
	/**
	 * The movement exception action which will be upserted.
	 * Required: true
	 * Array: false
	 */
	movementException:MovementException = undefined;
}
/**
 * The response to the request to upsert a movement exception action.
 */
export class UpsertAdHocMovementExceptionResp {
	/**
	 * The movement exception action that was upserted.
	 * Required: false
	 * Array: false
	 */
	movementException:MovementException = undefined;
}
/**
 * The movement exception Action which will be upserted.
 */
export class UpsertMovementExceptionActionRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	movementExceptionAction:MovementExceptionAction = undefined;
}
/**
 * The movement exception Action that was upserted.
 */
export class UpsertMovementExceptionActionResp {
	/**
	 * Required: false
	 * Array: false
	 */
	movementExceptionAction:MovementExceptionAction = undefined;
}
/**
 * The OperationsShipment Action which will be upserted.
 */
export class UpsertOperationsShipmentRqst {
	/**
	 * The Operations Shipment record which will be upserted.
	 * Required: true
	 * Array: false
	 */
	operationsShipment:OperationsShipment = undefined;
}
/**
 * The OperationsShipment Action that was upserted.
 */
export class UpsertOperationsShipmentResp {
	/**
	 * The Operations Shipment record that was upserted.
	 * Required: false
	 * Array: false
	 */
	operationsShipment:OperationsShipment = undefined;
}
/**
 * The OperationsShipment Action which will be upserted.
 */
export class UpsertOperationsShipmentPilotRqst {
	/**
	 * The Operations Shipment record which will be upserted.
	 * Required: true
	 * Array: false
	 */
	operationsShipmentPilot:OperationsShipmentPilot = undefined;
}
/**
 * The OperationsShipment Action that was upserted.
 */
export class UpsertOperationsShipmentPilotResp {
	/**
	 * The Operations Shipment record that was upserted.
	 * Required: false
	 * Array: false
	 */
	operationsShipmentPilot:OperationsShipmentPilot = undefined;
}
/**
 * Request payload to create a Shipment Handling Unit Movement record
 */
export class CreateHandlingUnitMovementPath {
	/**
	 * This number identifies a handling unit of a shipment. This is an alternate key of the table.  The numbers are printed on labels and are assigned to service centers.  The child PRO number format is 0ppppSSSSSc where pppp is the prefix from 1001 to 9999 (excluding numbers where last digit is 0, ex: 2000), SSSSS is a suffix/serial number, and c is a check-digit of the ppppSSSSS using modulus 7.
	 * Required: true
	 * Array: false
	 */
	childProNbr:string = undefined;
}
/**
 * Request payload to create a Shipment Handling Unit Movement record
 */
export class CreateHandlingUnitMovementRqst {
	/**
	 * Handling Unit Movement Information
	 * Required: true
	 * Array: false
	 */
	handlingUnitMovement:HandlingUnitMovement = undefined;
	/**
	 * Information about the trailer. for eg. Trailer Id and Trailer Prefix and suffix
	 * Required: false
	 * Array: false
	 */
	trailer:EquipmentId = undefined;
}
/**
 * Response payload to create a Shipment Handling Unit Movement record
 */
export class CreateHandlingUnitMovementResp {
	/**
	 * Required: false
	 * Array: false
	 */
	handlingUnitMovement:HandlingUnitMovement = undefined;
}
/**
 * Request to create a shipment skeleton.
 */
export class CreateShipmentSkeletonRqst {
	/**
	 * Skeleton information about the shipment which will be used to create the record.
	 * Required: false
	 * Array: false
	 */
	shipmentSkeleton:ShipmentSkeleton = undefined;
}
/**
 * Request to create a shipment dock exception.
 */
export class CreateShipmentDockExceptionRqst {
	/**
	 * The unique identifier of the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * This number identifies a shipment. This is the identifier given to the customer as a reference.
	 * Required: false
	 * Array: false
	 */
	parentProNbr:string = undefined;
	/**
	 * Details about the dock exception that will be used to create the record.
	 * Required: true
	 * Array: false
	 */
	dockException:XdockException = undefined;
}
/**
 * Response to the requset to create a shipment dock exception.
 */
export class CreateShipmentDockExceptionResp {
	/**
	 * Details about the dock exception record that was created.
	 * Required: false
	 * Array: false
	 */
	dockException:XdockException = undefined;
}
/**
 * Request to print FBDS/CopyBill documents.
 */
export class PrintFBDSDocumentsRqst {
	/**
	 * The list of PRO numbers to send to the printer.
	 * Required: false
	 * Array: true
	 */
	proNbrs:string[] = undefined;
	/**
	 * The list of route names to send to the printer.
	 * Required: false
	 * Array: true
	 */
	routeNames:RouteName[] = undefined;
	/**
	 * The list of equipment identifers (prefix and suffix number) to send to the printer.
	 * Required: false
	 * Array: true
	 */
	equipmentIds:EquipmentId[] = undefined;
	/**
	 * Identifies the service center associated with routes or city shipments for which documents are to be printed.
	 * Required: false
	 * Array: false
	 */
	hostDestinationSicCd:string = undefined;
	/**
	 * The type of printer for FBDS Documents.  Permitted values: DotMatrix and Laser.
	 * Required: true
	 * Array: false
	 */
	printFBDSDocumentsTypeCd:PrintFBDSDocumentsTypeCd = undefined;
	/**
	 * The printer code identifier.
	 * Required: true
	 * Array: false
	 */
	printerCd:string = undefined;
	/**
	 * The document report type (FBDS/Copy Bill).
	 * Required: true
	 * Array: false
	 */
	reportType:string = undefined;
	/**
	 * The form type of print (FBDS/Copy Bill).
	 * Required: true
	 * Array: false
	 */
	formType:string = undefined;
	/**
	 * Indicates whether documents should be reprinted.
	 * Required: false
	 * Array: false
	 */
	reprintInd:boolean = undefined;
	/**
	 * *** Placeholder: Comes from Coogen Proxy. Â Its specific purpose isn't known yet. ***
	 * Required: false
	 * Array: false
	 */
	includeProsDestinedInd:boolean = undefined;
	/**
	 * *** Placeholder: Comes from Coogen Proxy. Â Its specific purpose isn't known yet. ***
	 * Required: false
	 * Array: false
	 */
	includeProsNotDestinedInd:boolean = undefined;
	/**
	 * Indicates whether the request should be validated.
	 * Required: false
	 * Array: false
	 */
	validateInd:boolean = undefined;
	/**
	 * The service center identification code for the domicile.
	 * Required: false
	 * Array: false
	 */
	domicileSicCd:string = undefined;
	/**
	 * Indicates whether closed trailers should be included in the document.
	 * Required: false
	 * Array: false
	 */
	includeClosedTrailersInd:boolean = undefined;
}
/**
 * Response to the request to print FBDS documents.
 */
export class PrintFBDSDocumentsResp {
	/**
	 * The list of business identifiers for the shipments that were printed.
	 * Required: false
	 * Array: true
	 */
	proNbrs:string[] = undefined;
	/**
	 * The PDF binary data for FBDS documents.
	 * Required: false
	 * Array: true
	 */
	documentData:string[] = undefined;
}
/**
 * Path parameter used to update a shipment handling unit's dimensions.
 */
export class UpdateHandlingUnitDimensionsPath {
	/**
	 * The business identifier for the handling unit.
	 * Required: true
	 * Array: false
	 */
	trackingProNbr:string = undefined;
}
/**
 * Request to update a shipment handling unit's dimensions.
 */
export class UpdateHandlingUnitDimensionsRqst {
	/**
	 * The length in inches of the handling unit.
	 * Required: true
	 * Array: false
	 */
	lengthNbr:number = undefined;
	/**
	 * The width in inches of the handling unit.
	 * Required: true
	 * Array: false
	 */
	widthNbr:number = undefined;
	/**
	 * Height of the handling unit piece
	 * Required: true
	 * Array: false
	 */
	heightNbr:number = undefined;
	/**
	 * The user ID of the dock worker who captured the dimensions of the handling unit.
	 * Required: true
	 * Array: false
	 */
	capturedByUserId:string = undefined;
	/**
	 * The location where the handling unit dimensions were captured.
	 * Required: true
	 * Array: false
	 */
	requestingSicCd:string = undefined;
}
/**
 * Response to the request to update a shipment handling unit's dimensions.
 */
export class UpdateHandlingUnitDimensionsResp {
	/**
	 * The updated handling unit's record details.
	 * Required: false
	 * Array: false
	 */
	handlingUnit:HandlingUnit = undefined;
}
/**
 * Request to update the shipment food and motor moves.
 */
export class UpdateShipmentFoodAndMotorMovesRqst {
	/**
	 * The unique identifier of the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The business identifier of the shipment.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Identifies that the shipment contains bulk.
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * The total number of loose pieces from all commodities in the shipment.
	 * Required: false
	 * Array: false
	 */
	loosePiecesCount:number = undefined;
	/**
	 * Indicates whether the shipment contains food or posion.  A shipment will never contain both.  Permitted values: 1 - Neither, 2 - Food, and 3 - Poison.
	 * Required: false
	 * Array: false
	 */
	foodPoisonCd:FoodPoisonCd = undefined;
	/**
	 * Identifies the number of moves or lifts made with a forklift or a pallet jack to move freight without manual intervention by the driver. This count may be equal to or less than the number of pieces in the shipment.
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesCount:number = undefined;
	/**
	 * Indicates if the motorized piece count needs to be updated.
	 * Required: false
	 * Array: false
	 */
	updateMotorMovesInd:boolean = undefined;
	/**
	 * The user ID of the dock worker who captured the hazmat details.
	 * Required: true
	 * Array: false
	 */
	capturedByUserId:string = undefined;
	/**
	 * The location where the handling unit dimensions were captured.
	 * Required: true
	 * Array: false
	 */
	requestingSicCd:string = undefined;
}
/**
 * Request to update the hazmat details for a shipment.
 */
export class UpdateShipmentHazMatRqst {
	/**
	 * The unique identifier of the shipment.
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The unique identifier of the shipment.
	 * Required: true
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * The user ID of the dock worker who captured the hazmat details.
	 * Required: true
	 * Array: false
	 */
	capturedByUserId:string = undefined;
	/**
	 * The location where the hazmat details were captured.
	 * Required: true
	 * Array: false
	 */
	requestingSicCd:string = undefined;
	/**
	 * The list of hazmat details associated with the shipment.
	 * Required: false
	 * Array: true
	 */
	hazMats:HazMat[] = undefined;
}
/**
 * Response to the request to update the hazmat details for a shipment.
 * Hazmat details of the shipment
 */
export class UpdateShipmentHazMatResp {
	/**
	 * Hazmat details of the shipment
	 * Required: false
	 * Array: true
	 */
	hazMats:HazMat[] = undefined;
}
/**
 * Request to update the linehaul dimensions of shipments.
 */
export class UpdateShipmentLinehaulDimensionsRqst {
	/**
	 * The identifier of the user who captured the linehaul dimensions.
	 * Required: true
	 * Array: false
	 */
	capturedByUserId:string = undefined;
	/**
	 * The location where the linehaul dimensions were captured.
	 * Required: true
	 * Array: false
	 */
	requestingSicCd:string = undefined;
	/**
	 * The shipments with their new list of linehaul dimensions.
	 * Required: true
	 * Array: true
	 */
	shipmentWithDimensions:ShipmentWithDimension[] = undefined;
}
/**
 * Request to validate if the shipments are eligible or ineligible for delivery.
 */
export class ValidateShipmentsForDeliveryRqst {
	/**
	 * The list of shipments which will be validated to determine if they are eligible for delivery.
	 * Required: false
	 * Array: true
	 */
	shipmentIds:ShipmentId[] = undefined;
}
/**
 * Response to the request to validate if the shipments are eligible or ineligible for delivery.
 */
export class ValidateShipmentsForDeliveryResp {
	/**
	 * The list of shipments that are eligible for delivery.
	 * Required: false
	 * Array: true
	 */
	eligibleDeliveryShipments:ShipmentId[] = undefined;
	/**
	 * The list of shipments that are ineligible for delivery.
	 * Required: false
	 * Array: true
	 */
	ineligibleDeliveryShipments:ShipmentId[] = undefined;
}
/**
 * Request to create or update a rule.
 * 			
 */
export class UpsertRuleRequest {
	/**
	 * The Rule to be created or updated
	 * Required: false
	 * Array: false
	 */
	rule:Rule = undefined;
}
/**
 * Response for the request to create or update a Rule.
 */
export class UpsertRuleResp {
	/**
	 * The rule unique identifier; generated when the rule is created
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
}
/**
 * Request for listing of rules.
 */
export class ListRulesQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of Rules request.
 */
export class ListRulesResp {
	/**
	 * All rules are returned.
	 * Required: false
	 * Array: true
	 */
	rules:Rule[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to get a rule by identifier.
 */
export class GetRulePath {
	/**
	 * The rule unique identifier; generated when the rule is created
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
}
/**
 * Response to get a rule by identifier.
 */
export class GetRuleResp {
	/**
	 * Rule details
	 * Required: false
	 * Array: false
	 */
	rule:Rule = undefined;
}
/**
 * Request to get a rule by name.
 */
export class GetRuleByNamePath {
	/**
	 * Descriptive Name of the rule
	 * Required: false
	 * Array: false
	 */
	ruleName:string = undefined;
}
/**
 * Response to get a rule by name.
 */
export class GetRuleByNameResp {
	/**
	 * Rule details
	 * Required: false
	 * Array: false
	 */
	rule:Rule = undefined;
}
/**
 * Request for deleting a rule.
 */
export class DeleteRulePath {
	/**
	 * The rule unique identifier; generated when the rule is created
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
}
/**
 * Request to execute a rule
 */
export class ExecuteRuleRqst {
	/**
	 * The name of the rule to be executed
	 * Required: false
	 * Array: false
	 */
	ruleName:string = undefined;
	/**
	 * Desc: Identifies a rule as being a precondition rule.  Precondition rules are used to determine which rules to run.
	 * Required: false
	 * Array: false
	 */
	preconditionInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	context:Shipment = undefined;
}
/**
 * Response of the request to execute a rule 
 */
export class ExecuteRuleResp {
	/**
	 * Rule details with the execution outcomes
	 * Required: false
	 * Array: false
	 */
	rule:Rule = undefined;
}
/**
 * Request for listing of rules by rulesetName.
 */
export class ListRulesByRulesetNameQuery {
	/**
	 * Date used to identify active rules.
	 * Required: false
	 * Array: false
	 */
	ruleEffectiveDate:Date = undefined;
	/**
	 * Descriptive Names of the rulesets to include.
	 * Required: false
	 * Array: true
	 */
	rulesetNameInclusion:string[] = undefined;
	/**
	 * Descriptive Names of the rulesets to exclude.
	 * Required: false
	 * Array: true
	 */
	rulesetNameExclusion:string[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of Rules By RulesetName request.
 */
export class ListRulesByRulesetNameResp {
	/**
	 * All rules matching the parameters in the request are returned.
	 * Required: false
	 * Array: true
	 */
	rules:RuleForExecution[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to list rules by rule expressions.
 */
export class ListRulesByExpressionsRqst {
	/**
	 * Rule Expressions to filter the rules.
	 * Required: false
	 * Array: true
	 */
	ruleExpressions:RuleExpression[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of Rules By Expressions request.
 */
export class ListRulesByExpressionsResp {
	/**
	 * All rules matching the expressions in the request are returned.
	 * Required: false
	 * Array: true
	 */
	rules:Rule[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * The basic rule data needed for execution
 */
export class RuleForExecution {
	/**
	 * Desc: The rule Unique identifier; generated when rule is created
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
	/**
	 * Desc: Descriptive Name of the rule
	 * Required: false
	 * Array: false
	 */
	ruleName:string = undefined;
	/**
	 * Desc: Identifies a rule as being a precondition rule.  Precondition rules are used to determine which rules to run.
	 * Required: false
	 * Array: false
	 */
	preconditionInd:boolean = undefined;
	/**
	 * The rule details. A ruleset is associated with a group of conditions(expressions) to evaluate using a specific logical operator. If the evaluation of the conditions is true, the ruleset will return a group of outcomes.
	 * Required: false
	 * Array: true
	 */
	ruleset:RulesetForExecution[] = undefined;
}
/**
 * The rule details. A ruleset is associated with a group of conditions(expressions) to evaluate using a specific logical operator. If the evaluation of the conditions is true, the ruleset will return a group of outcomes.
 */
export class RulesetForExecution {
	/**
	 * Desc: The ruleset Unique identifier; generated when the ruleset is created
	 * Required: false
	 * Array: false
	 */
	rulesetInstId:number = undefined;
	/**
	 * The priority order for the execution of the ruleset. If a rule is associated with multiple rulesets, the ruleset with the highest priority is executed first. The number 1 is a higher priority than 2 and so on.
	 * Required: false
	 * Array: false
	 */
	priorityOrderNbr:number = undefined;
	/**
	 * Desc: Identifies the logical operator value. The Valid Values are: AND, OR
	 * Required: false
	 * Array: false
	 */
	logicalOperatorCd:RuleLogicalOperatorCd = undefined;
	/**
	 * Desc: The action that should be performed if the logical operator function is true.
	 * Required: false
	 * Array: false
	 */
	actionCallBack:string = undefined;
	/**
	 * Descriptive Name of the ruleset.  Added 5/1/2019
	 * Required: false
	 * Array: false
	 */
	rulesetName:string = undefined;
	/**
	 * The group of conditions(expressions) to be evaluated for the ruleset.
	 * Required: false
	 * Array: true
	 */
	ruleExpression:RuleExpressionForExecution[] = undefined;
	/**
	 * The group of outcomes to be returned if the conditions of the ruleset are evaluated to true.
	 * Required: false
	 * Array: true
	 */
	ruleOutcome:RuleOutcomeForExecution[] = undefined;
}
/**
 * The outcome is a key,value entry to be returned if the conditions of the ruleset are evaluated to true.
 */
export class RuleOutcomeForExecution {
	/**
	 * Desc: the outcome Unique identifier; generated when the outcome is created.
	 * Required: false
	 * Array: false
	 */
	outcomeInstId:number = undefined;
	/**
	 * Added as an additional part of PK so that Outcome name/value pairs can be grouped. 4/15/2019
	 * Required: false
	 * Array: false
	 */
	outcomeSeqNbr:number = undefined;
	/**
	 * Desc: The key/name of the outcome.
	 * Required: false
	 * Array: false
	 */
	outcomeName:string = undefined;
	/**
	 * The value of the outcome.
	 * Required: false
	 * Array: false
	 */
	outcomeValue:string = undefined;
}
/**
 * A rule expression is a condition to be evaluated in the rule.
 */
export class RuleExpressionForExecution {
	/**
	 * The generated unique identifier.
	 * Required: false
	 * Array: false
	 */
	expressionInstId:number = undefined;
	/**
	 * Desc: Name of the attribute to be used in the logical expression.
	 * Required: false
	 * Array: false
	 */
	leftOperand:string = undefined;
	/**
	 * Desc: The operand to be used in the logical expression. The valid values are: 
	 * EQUAL_TO
	 * NOT_EQUAL_TO
	 * GREATER_THAN
	 * GREATER_THAN_OR_EQUAL
	 * IN
	 * NOT_IN
	 * LESS_THAN
	 * LESS_THAN_OR_EQUAL
	 * MATCHES
	 * NOT_MATCHES
	 * Required: false
	 * Array: false
	 */
	operatorCd:RuleOperatorCd = undefined;
	/**
	 * Desc: The type of value of the attribute to be used in the logical expression.. The valid values are:
	 * BOOLEAN
	 * DATE_TIME
	 * LIST
	 * NUMBER
	 * TEXT
	 * Required: false
	 * Array: false
	 */
	valueTypeCd:RuleExpressionValueTypeCd = undefined;
	/**
	 * Desc: The value of the attribute to be used in the logical expression.
	 * Required: false
	 * Array: false
	 */
	value:string = undefined;
}
/**
 * Response for listing of Rules for execution request.
 */
export class ListRulesForExecutionQuery {
	/**
	 * Date used to identify active rules.
	 * Required: false
	 * Array: false
	 */
	ruleEffectiveDate:Date = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of Rules for execution request.
 */
export class ListRulesForExecutionResp {
	/**
	 * All rules are returned.
	 * Required: false
	 * Array: true
	 */
	rules:RuleForExecution[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response outcome from a ruleset
 */
export class RulesetOutcomeResp {
	/**
	 * Desc: The ruleset Unique identifier; generated when the ruleset is created
	 * Required: false
	 * Array: false
	 */
	rulesetInstId:number = undefined;
	/**
	 * Desc: the outcome Unique identifier; generated when the outcome is created.
	 * Required: false
	 * Array: false
	 */
	outcomeInstId:number = undefined;
	/**
	 * Added as an additional part of PK so that Outcome name/value pairs can be grouped. 4/15/2019
	 * Required: false
	 * Array: false
	 */
	outcomeSeqNbr:number = undefined;
	/**
	 * Desc: The key/name of the outcome.
	 * Required: false
	 * Array: false
	 */
	outcomeName:string = undefined;
	/**
	 * The value of the outcome.
	 * Required: false
	 * Array: false
	 */
	outcomeValue:string = undefined;
}
/**
 * Path parameters for get Ensemble status counts
 */
export class GetEnsembleStatusCountsPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * From timestamp
	 * Required: true
	 * Array: false
	 */
	fromTmst:Date = undefined;
	/**
	 * To timestamp
	 * Required: true
	 * Array: false
	 */
	toTmst:Date = undefined;
}
/**
 * Response to the request to get Ensemble status counts
 */
export class GetEnsembleStatusCountsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleMetrics:EnsembleMetrics = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	queueMetrics:QueueMetrics = undefined;
}
/**
 * Path parameters for get Ensemble status counts
 */
export class GetQueuePendingCountPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Response for getQueuePendingCount
 */
export class GetQueuePendingCountResp {
	/**
	 * Number of messages in either ready or wait status. This count indicates the queue depth.
	 * Required: false
	 * Array: false
	 */
	pendingCount:number = undefined;
}
/**
 * Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsemblePath {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Unique identifier for the application workflow.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsembleRqst {
}
/**
 * Response to the Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsembleResp {
	/**
	 * Ensemble that was Restarted.
	 * Required: false
	 * Array: false
	 */
	ensembleRestartedInd:boolean = undefined;
}
/**
 * Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsemblePath {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Unique identifier for the application workflow.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsembleRqst {
}
/**
 * Response to the Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsembleResp {
	/**
	 * Ensemble that was Resumed
	 * Required: false
	 * Array: false
	 */
	ensembleResumedInd:boolean = undefined;
}
/**
 * Request to start ensemble.
 */
export class StartMessageDrivenEnsembleRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	queue:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	message:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	eventDataType:string = undefined;
}
/**
 * Enter comments
 */
export class StartMessageDrivenEnsembleResp {
	/**
	 * Instance ID of the ensemble that was started.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to stop execution of an Ensemble
 */
export class KillEnsembleRqst {
	/**
	 * Unique identifier for the ensemble.
	 * Required: true
	 * Array: true
	 */
	ensembleInstIds:string[] = undefined;
}
/**
 * Response to the request to stop execution of an Ensemble
 */
export class KillEnsembleResp {
	/**
	 * Ensembles that were killed.
	 * Required: false
	 * Array: true
	 */
	status:KillEnsembleStatus[] = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueuePath {
	/**
	 * Name of the queue to stop
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueueWebhookPath {
	/**
	 * Name of the queue to stop
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueueWebhookQuery {
	/**
	 * Authorization token
	 * Required: false
	 * Array: false
	 */
	auth_token:string = undefined;
}
/**
 * Path parameters for startQueue
 */
export class StartQueuePath {
	/**
	 * Name of the queue to start
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Response for listQueues
 */
export class ListQueuesResp {
	/**
	 * Name of the queue to start
	 * Required: false
	 * Array: true
	 */
	queues:SymphonyQueue[] = undefined;
}
/**
 * Path parameters
 */
export class ListExpiredMessagesPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * From enqueue timestamp
	 * Required: true
	 * Array: false
	 */
	enqueueFromTmst:Date = undefined;
	/**
	 * Tom enqueue timestamp
	 * Required: true
	 * Array: false
	 */
	enqueueToTmst:Date = undefined;
}
/**
 * Response
 */
export class ListExpiredMessagesResp {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: true
	 */
	message:SymphonyMessage[] = undefined;
}
/**
 * Path parameters
 */
export class DeleteExpiredMessagesPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters
 */
export class DeleteExpiredMessagesQuery {
	/**
	 * Id of the message to be deleted
	 * Required: true
	 * Array: true
	 */
	messageId:string[] = undefined;
}
/**
 * Response to the request to stop execution of an Ensemble
 */
export class DeleteExpiredMessagesResp {
	/**
	 * Ensembles that were killed.
	 * Required: false
	 * Array: true
	 */
	status:DeleteExpiredMessageStatus[] = undefined;
}
/**
 * Path parameters
 */
export class ReplayMessagePath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * ID of the message to replay. It needs to be in expired status
	 * Required: true
	 * Array: false
	 */
	messageId:string = undefined;
}
export class MessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The event occurrence timestamp
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:object = undefined;
}
export class DynamicMessageDrivenPayload {
	/**
	 * The type of the payload
	 * Required: true
	 * Array: false
	 */
	eventDataType:string = undefined;
	/**
	 * The message payload
	 * Required: true
	 * Array: false
	 */
	payload:string = undefined;
}
export class TransactionEventEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The event occurrence timestamp
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:TransactionEvent = undefined;
}
/**
 * Status of a delete expired message request
 */
export class DeleteExpiredMessageStatus {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Indicates whether the ensemble was killed.
	 * Required: false
	 * Array: false
	 */
	messageDeletedInd:boolean = undefined;
	/**
	 * A status describing why the message could not be deleted. Populated if messageDeletedInd is false
	 * Required: false
	 * Array: false
	 */
	failStatus:string = undefined;
}
/**
 * Status of a kill ensemble request
 */
export class KillEnsembleStatus {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
	/**
	 * Indicates whether the ensemble was killed.
	 * Required: false
	 * Array: false
	 */
	ensembleKilledInd:boolean = undefined;
	/**
	 * A status describing why the ensemble could not be killed. Populated if ensembleKilledInd is false
	 * Required: false
	 * Array: false
	 */
	failStatus:string = undefined;
}
/**
 * Stores execution information for application workflows.
 */
export class EnsembleStateSearchRecord {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	instId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState:EnsembleState = undefined;
}
export class Payload {
	/**
	 * Required: true
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	value:object = undefined;
}
export class EnsembleState {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	instId:string = undefined;
	/**
	 * Name of the application workflow.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * A short description of the ensemble
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * Name of the queue publishing this message.
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Id of the message.
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * The timestamp of when the trigger event was created.
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The timestamp of when the message was received by ensemble.
	 * Required: false
	 * Array: false
	 */
	recvTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble was dispatched to the executor thread.
	 * Required: false
	 * Array: false
	 */
	dispatchTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble processing started.
	 * Required: false
	 * Array: false
	 */
	strtTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble processing ended.
	 * Required: false
	 * Array: false
	 */
	endTmst:Date = undefined;
	/**
	 * Dequeue time in milliseconds. Populated for started ensembles, and is the difference between the received and event timestamps.
	 * Required: false
	 * Array: false
	 */
	dequeueTime:number = undefined;
	/**
	 * Dispatch time in milliseconds. Populated for started ensembles, and is the difference between the dispatch and received timestamps.
	 * Required: false
	 * Array: false
	 */
	dispatchTime:number = undefined;
	/**
	 * Wait time in milliseconds. Populated for started ensembles, and is the difference between the start and dispatch timestamps.
	 * Required: false
	 * Array: false
	 */
	waitTime:number = undefined;
	/**
	 * Time in milliseconds it took to process this ensemble from the time it was started. Populated for completed ensembles, and is the difference between the end and start timestamps.
	 * Required: false
	 * Array: false
	 */
	processTime:number = undefined;
	/**
	 * Total elapsed time in milliseconds to process this ensemble from the time the event was created. Populated for completed ensembles, and is the difference between the start and event timestamps.
	 * Required: false
	 * Array: false
	 */
	totalTime:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * Status of workflow execution.  
	 * 
	 * Valid values: 
	 * NOT_STARTED 
	 * STARTED
	 * SUSPENDED 
	 * COMPLETED
	 * Required: false
	 * Array: false
	 */
	statCd:EnsembleExecutionStatusCd = undefined;
	/**
	 * Status message for the workflow execution.  This could be an error message, stacktrace, success message, etc.
	 * Required: false
	 * Array: false
	 */
	statMsg:string = undefined;
	/**
	 * Same value for CORRELATION 
	 * 
	 * id is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multiple rows across various tables for a single business 
	 * 
	 * transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * Name of the host on which the ensemble was last processed
	 * Required: false
	 * Array: false
	 */
	host:string = undefined;
	/**
	 * Name of the thread on which the ensemble was last processed
	 * Required: false
	 * Array: false
	 */
	thread:string = undefined;
	/**
	 * Timestamp of when the ensemble started waiting for lock
	 * Required: false
	 * Array: false
	 */
	lockWaitStartTmst:Date = undefined;
	/**
	 * Time spent on waiting for lock
	 * Required: false
	 * Array: false
	 */
	elapsedLockWaitTime:number = undefined;
	/**
	 * Number of times this ensemble was put on lock wait
	 * Required: false
	 * Array: false
	 */
	lockWaitCount:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	minuetStates:MinuetState[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	gotoEnsembleStates:NestedEnsembleState[] = undefined;
	/**
	 * Payload that is input to the ensemble execution.
	 * Required: false
	 * Array: false
	 */
	payload:Payload = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	executionContext:EnsembleExecutionContext[] = undefined;
}
export class NestedEnsembleState {
	/**
	 * Name of the application workflow.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	minuetStates:MinuetState[] = undefined;
}
export class EnsembleExecutionContext {
	/**
	 * Name of the application workflow.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	minuetPtr:number = undefined;
}
/**
 * Stores execution information for the tasks that compose the application workflow.
 */
export class MinuetState {
	/**
	 * Name of the task.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Response payload that is output  from the task.
	 * Required: false
	 * Array: false
	 */
	respPayload:Payload = undefined;
	/**
	 * Datetime the task execution started
	 * Required: false
	 * Array: false
	 */
	strtTmst:Date = undefined;
	/**
	 * Datetime the task execution ended
	 * Required: false
	 * Array: false
	 */
	endTmst:Date = undefined;
	/**
	 * Time in milliseconds it took to process this minuet from the time it was started. Populated for completed minuets, and is the difference between the end and start timestamps.
	 * Required: false
	 * Array: false
	 */
	processTime:number = undefined;
	/**
	 * Number of times this minuet was retried
	 * Required: false
	 * Array: false
	 */
	executionCount:number = undefined;
	/**
	 * Status of task execution.  
	 * 
	 * Valid values:  
	 * STARTED
	 * FAILED 
	 * COMPLETED
	 * Required: false
	 * Array: false
	 */
	statCd:MinuetExecutionStatusCd = undefined;
	/**
	 * Status message for the task.  This could be an error message, stacktrace, success message, etc.
	 * Required: false
	 * Array: false
	 */
	statMsg:string = undefined;
}
export class EnsembleMetrics {
	/**
	 * Required: false
	 * Array: false
	 */
	notStartedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	startedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	completedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suspendedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	failedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	killedCount:number = undefined;
}
export class QueueMetrics {
	/**
	 * Required: false
	 * Array: false
	 */
	readyCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	waitCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	processedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expiredCount:number = undefined;
}
export class EnsembleConfiguration {
	/**
	 * Required: false
	 * Array: false
	 */
	component:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	mode:EnsembleListenerModeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shortName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sourceTable:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	payloadType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cacheEnabled:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lockEnabled:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	retriggerEnabled:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	waitEnabled:boolean = undefined;
}
export class HndlngUnitMessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:HandlingUnitSnapshot = undefined;
}
export class ShmEventLogMessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:EventLogSnapshot = undefined;
}
export class ShipmentSnapshotMdePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:ShipmentSnapshot = undefined;
}
export class AsMatchedPartySnapshotMdePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:AsMatchedPartySnapshot = undefined;
}
export class AccessorialServiceSnapshotMdePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:AccessorialServiceSnapshot = undefined;
}
export class LTLEventShipment {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: true
	 */
	eventData:ShipmentEventPayload[] = undefined;
}
/**
 * An Update response from a BULK update to elastic
 */
export class BulkUpdateResp {
	/**
	 * Required: false
	 * Array: false
	 */
	_index:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	_id:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	error:BulkUpdateItemError = undefined;
}
/**
 * Details of an error for an item
 */
export class BulkUpdateItemError {
	/**
	 * Required: false
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	reason:string = undefined;
}
/**
 * An item in the bulk api response
 */
export class BulkApiRespItem {
	/**
	 * Required: false
	 * Array: false
	 */
	update:BulkUpdateResp = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	index:BulkUpdateResp = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	create:BulkUpdateResp = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	delete:BulkUpdateResp = undefined;
}
export class ShipmentSearchFilterNot {
	/**
	 * Required: false
	 * Array: true
	 */
	and:ShipmentSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:ShipmentSearchFilterOr[] = undefined;
}
export class ShipmentSearchFilterOr {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:ShipmentSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:ShipmentSearchFilterNot[] = undefined;
	public toJSON = function(this: ShipmentSearchFilterOr) {
		return {
			"proNbr" : this.proNbr,
			"proNbr.keyword" : this.proNbr_keyword,
			"shipperName1" : this.shipperName1,
			"shipperName1.keyword" : this.shipperName1_keyword,
			"consigneeName1" : this.consigneeName1,
			"consigneeName1.keyword" : this.consigneeName1_keyword,
			"and" : this.and,
			"not" : this.not		}
	}
}
export class ShipmentSearchFilterAnd {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:ShipmentSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:ShipmentSearchFilterNot[] = undefined;
	public toJSON = function(this: ShipmentSearchFilterAnd) {
		return {
			"proNbr" : this.proNbr,
			"proNbr.keyword" : this.proNbr_keyword,
			"shipperName1" : this.shipperName1,
			"shipperName1.keyword" : this.shipperName1_keyword,
			"consigneeName1" : this.consigneeName1,
			"consigneeName1.keyword" : this.consigneeName1_keyword,
			"or" : this.or,
			"not" : this.not		}
	}
}
export class ShipmentSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:ShipmentSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:ShipmentSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:ShipmentSearchFilterNot[] = undefined;
	public toJSON = function(this: ShipmentSearchFilter) {
		return {
			"q" : this.q,
			"b" : this.b,
			"proNbr" : this.proNbr,
			"proNbr.keyword" : this.proNbr_keyword,
			"shipperName1" : this.shipperName1,
			"shipperName1.keyword" : this.shipperName1_keyword,
			"consigneeName1" : this.consigneeName1,
			"consigneeName1.keyword" : this.consigneeName1_keyword,
			"and" : this.and,
			"or" : this.or,
			"not" : this.not		}
	}
}
/**
 * Path parameters
 */
export class GetElasticDocumentShipmentPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:string = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentShipmentQuery {
	/**
	 * Source filter
	 * Required: false
	 * Array: false
	 */
	_source:string = undefined;
	/**
	 * Source include filter
	 * Required: false
	 * Array: false
	 */
	_source_includes:string = undefined;
	/**
	 * Source exclude filter
	 * Required: false
	 * Array: false
	 */
	_source_excludes:string = undefined;
}
/**
 * Response from getElasticDocument.
 */
export class GetElasticDocumentShipmentResp {
	/**
	 * The record if found
	 * Required: false
	 * Array: false
	 */
	record:ShipmentSearchRecord = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentShipmentCountQuery {
	/**
	 * Query filter
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
}
/**
 * Response from getElasticDocumentCount.
 */
export class GetElasticDocumentShipmentCountResp {
	/**
	 * The count of the documents
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
}
/**
 * Request message to update information for Shipment.
 */
export class UpdateElasticDocumentShipmentRqst {
	/**
	 * The record to upsert in elastic
	 * Required: false
	 * Array: false
	 */
	doc:ShipmentSearchRecord = undefined;
	/**
	 * Indicate if an upsert operation should be executed if the document was not found
	 * Required: false
	 * Array: false
	 */
	doc_as_upsert:boolean = undefined;
}
/**
 * Path parameters
 */
export class UpdateElasticDocumentShipmentPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:string = undefined;
}
/**
 * Response from updateElasticDocument.
 */
export class UpdateElasticDocumentShipmentResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Response from bulkUpdateElasticDocument.
 */
export class BulkUpdateElasticDocumentShipmentResp {
	/**
	 * The time it took to process this request
	 * Required: false
	 * Array: false
	 */
	took:number = undefined;
	/**
	 * Indicates if there are any errors
	 * Required: false
	 * Array: false
	 */
	errors:boolean = undefined;
	/**
	 * Results of the bulk operations
	 * Required: false
	 * Array: true
	 */
	items:BulkApiRespItem[] = undefined;
}
/**
 * Path parameters
 */
export class DeleteElasticDocumentShipmentPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:string = undefined;
}
/**
 * Request message to delete information for Shipment.
 */
export class DeleteElasticDocumentShipmentResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Request message to search information for shipment-unfiltered.
 */
export class SearchShipmentUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:ShipmentSearchFilter = undefined;
}
/**
 * Response to the request to search information for shipment-unfiltered.
 */
export class SearchShipmentUnfilteredResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:ShipmentSearchRecord[] = undefined;
}
/**
 * Request message to export information for shipment-unfiltered.
 */
export class ExportShipmentUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileType:string = undefined;
	/**
	 * Query for exporting
	 * Required: false
	 * Array: false
	 */
	filter:ShipmentSearchFilter = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterShipmentUnfilteredRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:ShipmentSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterShipmentUnfilteredResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterShipmentUnfilteredRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedShipmentUnfilteredRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:ShipmentSearchRecord[] = undefined;
}
export class EnsembleStateSearchFilterNot {
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
}
export class EnsembleStateSearchFilterOr {
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilterOr) {
		return {
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"and" : this.and,
			"not" : this.not		}
	}
}
export class EnsembleStateSearchFilterAnd {
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilterAnd) {
		return {
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"or" : this.or,
			"not" : this.not		}
	}
}
export class EnsembleStateSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilter) {
		return {
			"q" : this.q,
			"b" : this.b,
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"and" : this.and,
			"or" : this.or,
			"not" : this.not		}
	}
}
/**
 * Path parameters
 */
export class GetElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentEnsembleStateQuery {
	/**
	 * Source filter
	 * Required: false
	 * Array: false
	 */
	_source:string = undefined;
	/**
	 * Source include filter
	 * Required: false
	 * Array: false
	 */
	_source_includes:string = undefined;
	/**
	 * Source exclude filter
	 * Required: false
	 * Array: false
	 */
	_source_excludes:string = undefined;
}
/**
 * Response from getElasticDocument.
 */
export class GetElasticDocumentEnsembleStateResp {
	/**
	 * The record if found
	 * Required: false
	 * Array: false
	 */
	record:EnsembleStateSearchRecord = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentEnsembleStateCountQuery {
	/**
	 * Query filter
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
}
/**
 * Response from getElasticDocumentCount.
 */
export class GetElasticDocumentEnsembleStateCountResp {
	/**
	 * The count of the documents
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
}
/**
 * Request message to update information for EnsembleState.
 */
export class UpdateElasticDocumentEnsembleStateRqst {
	/**
	 * The record to upsert in elastic
	 * Required: false
	 * Array: false
	 */
	doc:EnsembleStateSearchRecord = undefined;
	/**
	 * Indicate if an upsert operation should be executed if the document was not found
	 * Required: false
	 * Array: false
	 */
	doc_as_upsert:boolean = undefined;
}
/**
 * Path parameters
 */
export class UpdateElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Response from updateElasticDocument.
 */
export class UpdateElasticDocumentEnsembleStateResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Response from bulkUpdateElasticDocument.
 */
export class BulkUpdateElasticDocumentEnsembleStateResp {
	/**
	 * The time it took to process this request
	 * Required: false
	 * Array: false
	 */
	took:number = undefined;
	/**
	 * Indicates if there are any errors
	 * Required: false
	 * Array: false
	 */
	errors:boolean = undefined;
	/**
	 * Results of the bulk operations
	 * Required: false
	 * Array: true
	 */
	items:BulkApiRespItem[] = undefined;
}
/**
 * Path parameters
 */
export class DeleteElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Request message to delete information for EnsembleState.
 */
export class DeleteElasticDocumentEnsembleStateResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Request message to search information for ensemble-state-unfiltered.
 */
export class SearchEnsembleStateUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Response to the request to search information for ensemble-state-unfiltered.
 */
export class SearchEnsembleStateUnfilteredResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:EnsembleStateSearchRecord[] = undefined;
}
/**
 * Request message to export information for ensemble-state-unfiltered.
 */
export class ExportEnsembleStateUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	fields:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileType:string = undefined;
	/**
	 * Query for exporting
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterEnsembleStateUnfilteredRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterEnsembleStateUnfilteredResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterEnsembleStateUnfilteredRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedEnsembleStateUnfilteredRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:EnsembleStateSearchRecord[] = undefined;
}
/**
 * Stores the predictions for the True Debtor for a given Shipper and Consignee Zip code.  At the time of pickup, the exact consignee is not known.  The True debtor is determined based on the historical shipments and number of consignees for a given shipper within a customer destination zip code.
 * True debtor can be predicted if shipper has largely sent shipment to one customer at a given zip code. The data is computed based on shipment history in the data warehouse.  Added 05/11/2020
 * 
 */
export class PredictedTrueDebtor {
	/**
	 * Shipper Customer ID. Until NCIS is fully implemented, this will store the CIS_CUST_NBR, afterwards this will store the customer ID from NCIS table.
	 * Required: false
	 * Array: false
	 */
	shipperCustomerId:number = undefined;
	/**
	 * Zip code of the consignee.
	 * Required: false
	 * Array: false
	 */
	consigneeZipCd:string = undefined;
	/**
	 * True debtor Customer Number for shipments from PRDA  for the combination of Shipper Id and Consignee Zip code. Until NCIS is fully implemented, this will store the CIS_CUST_NBR, afterwards this will store the customer ID from NCIS table.
	 * Required: false
	 * Array: false
	 */
	trueDebtorCustomerId:number = undefined;
	/**
	 * SIC code of the pickup terminal
	 * Required: false
	 * Array: false
	 */
	originSicCode:string = undefined;
	/**
	 * Number of shipments for the Shipper Cust Number, Consignee Zip Code and True Debtor.
	 * Required: false
	 * Array: false
	 */
	shipmentCount:number = undefined;
	/**
	 * Total shipments for the Shipper Cust Number and Consignee Zip Code.
	 * Required: false
	 * Array: false
	 */
	totalShipmentCount:number = undefined;
	/**
	 * Number of distinct true debtors for the Shipper Cust Number and Zip Code
	 * Required: false
	 * Array: false
	 */
	trueDebtorCount:number = undefined;
	/**
	 * Percentage of shipments for the Shipper Cust Number, Consignee Zip Code and True Debtor (SHM_COUNT/TOT_SHM_COUNT * 100)
	 * Required: false
	 * Array: false
	 */
	confidenceLevelPercentage:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class AccessorialService {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * 
	 * Desc : Sequential number assigned to uniquely identify  each extra service for the shipment.
	 * 
	 * Source : Generated
	 * 
	 * Design Considerations :
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Accessorial  code
	 * 
	 * Desc : Indicates the type of accessorial service requested or performed
	 * Required: false
	 * Array: false
	 */
	accessorialCd:string = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the accessorial charges for an accessorial service . The rate is multiplied by the unit of measure to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE
	 * Required: false
	 * Array: false
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge for the accessorial service.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * 
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 * Required: false
	 * Array: false
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the accessorial are the minimum charges for the extra service. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name : Description
	 * 
	 * Desc : A textual description of the service.
	 * 
	 * Source : MBF  Variable Segment :
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indication whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Specifies the units for the quantity for which the accessorial fee is charged.
	 * 
	 * Valid Values
	 * 1- Labour Hour
	 * 2- 1/4 hour
	 * 3- 1/2 hour
	 * 4- CWT
	 * 5- CWT/Day
	 * 6- Check
	 * 7- Correction
	 * 8- Day
	 * 9- Document
	 * A- Package
	 * B- Shipment
	 * C- Vehicle
	 * D- Mile
	 * E- Pound
	 * ' ' - Not applicable
	 * Required: false
	 * Array: false
	 */
	accessorialUnitOfMeasure:AccessorialUnitOfMeasureCd = undefined;
	/**
	 * Specifies the quantity of units (in AC_UOM) for which the accessorial fee is charged.
	 * Required: false
	 * Array: false
	 */
	accessorialQuantity:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chrgToCd:LineItemChargeToCd = undefined;
}
/**
 * Holds a snapshot of AccessorialService. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class AccessorialServiceSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:AccessorialService = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:AccessorialService = undefined;
}
/**
 * 
 */
export class AdvanceBeyondCarrier {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : A system assigned number to uniquely identify each carrier for a shipment. There can be at most two other carriers for a shipment one advance and one beyond.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Type code 
	 * 
	 * Desc : Identifies the type movement carried out by the carrier.
	 * 
	 * 
	 * Valid Values:
	 * 1 = Advance carrier
	 * 2 = Beyond carrier
	 * Required: false
	 * Array: false
	 */
	typeCd:AdvanceBeyondTypeCd = undefined;
	/**
	 * Expanded Name: Standard Carrier Abbreviation Code
	 * 
	 * Desc : Other Carrier s identification code for Advance and Beyond movements for the shipment.
	 * 
	 * This is the identifying code for the carrier from whom CTS picked up (or transfers to) the freight. If the code is unknown, NONE is entered by the bill entry personnel. 
	 * 
	 * Source : MBF root  -  MBADSCAC
	 * Required: false
	 * Array: false
	 */
	carrierScacCd:string = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This is  the other carrier s PRO number that identifies the shipment for the carrier in their systems.
	 * Required: false
	 * Array: false
	 */
	carrierProNbr:string = undefined;
	/**
	 * Expanded Name: Carrier pick up date
	 * 
	 * Desc: This is the date the carrier picked up the shipment. 
	 * 
	 * In case of an advance carrier this is the date the carrier picked it up from the customer.
	 * 
	 * 	In case of beyond carriers, it is the date the carrier picked up the shipment from CTS.
	 * Required: false
	 * Array: false
	 */
	carrierPickupDate:string = undefined;
	/**
	 * Expanded Name : From terminal SIC
	 * 
	 * Desc : SIC where the shipment originates for the carrrier.  
	 * 
	 * For advance carriers it is the sic that services the shipper and for beyond carrier this is CTS service center that delivers teh PRO to the beyond carrier. 
	 * 
	 * This is mostly stored for Beyond carriers.
	 * 
	 * Source :
	 * Required: false
	 * Array: false
	 */
	fromTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : To terminal SIC
	 * 
	 * Desc : This is CTS SIC where an advance carrier dropped the shipment for further movement. For beyond carrier this is the SIC code that services the consignee. 
	 * 
	 * Source :
	 * Required: false
	 * Array: false
	 */
	toTerminalSicCd:string = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the advance or beyond charge.
	 * Required: false
	 * Array: false
	 */
	chargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * The name and address printed on the paper BOL might be different from how the address validation and customer matching might produce.   
 * This entity records the name and address for various billing parties that the bill entry person enters from the paper BOL. 
 * Note, after Biller submits the page, address validtation and customer matching might change the name/address that was entered from the paper BOL.
 * 
 * Billing parties include shipper, consignee, and bill-to.  
 * 
 * The record is not expected to be updated after it is inserted into the table.
 */
export class AsEnteredBolParty {
	/**
	 * Foreign key: Parent SHM_SHIPMENT. Unique surrogate key that identifies a shipment
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * S - shipper, C- Consignee, B-Bill-to.   Although there could be two bill-to parties for both bill, on paper BOL only one bill-to is mentioned.
	 * Required: false
	 * Array: false
	 */
	partyTypeCd:BolPartyTypeCd = undefined;
	/**
	 * Name  line 1 as printed on the paper BOL
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * Name  line 2 as printed on the paper BOL
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Address as printed on the paper BOL
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * City  as printed on the paper BOL
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * State Code  as printed on the paper BOL
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * US , CA, MX. Country as specified on paper BOL
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Zip code as specified on paper BOL
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * Zip Extension for US zip codes
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * Only if available on paper BOL for the party
	 * Required: false
	 * Array: false
	 */
	phoneNbr:string = undefined;
	/**
	 * Y= A valid address according to the Postal Service. The postal service recognizes that the numbers are within the valid range for the street name.  
	 * N= Address is not certified by Postal authority.
	 * Required: false
	 * Array: false
	 */
	PostalCertifiedInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of AsEnteredBolParty. Contains the details at the time on an event. It also provides the previous values if an update occured
 * The name and address printed on the paper BOL might be different from how the address validation and customer matching might produce.   
 * This entity records the name and address for various billing parties that the bill entry person enters from the paper BOL. 
 * Note, after Biller submits the page, address validtation and customer matching might change the name/address that was entered from the paper BOL.
 * 
 * Billing parties include shipper, consignee, and bill-to.  
 * 
 * The record is not expected to be updated after it is inserted into the table.
 */
export class AsEnteredBolPartySnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:AsEnteredBolParty = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:AsEnteredBolParty = undefined;
}
/**
 * 
 */
export class AsMatchedParty {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence Number
	 * 
	 * Desc : A system assigned sequential number to uniquely identify each As Entered Customer for a Shipment.
	 * 
	 * There are always at least two customers for a shipment and atmost 6 customers for a shipment.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name :  Type code
	 * 
	 * Desc : Specifies the nature of relationship of the customer with respect to the Service Item in question. For Shipments, a Customer can be the shipper, consignee, bill-to, requestor or contact. 
	 * 
	 * Permitted Values:
	 * 										1=Shipper address
	 * 										2=Consignee address 
	 * 										3=Inbound bill-to address 
	 * 										4=Outbound bill-to address
	 * 										5=Requestor address
	 * 										6=Contact address 
	 * 
	 * Source : Internal
	 * Required: false
	 * Array: false
	 */
	typeCd:MatchedPartyTypeCd = undefined;
	/**
	 * Expanded Name : Debtor indicator
	 * 
	 * Desc : Specifies whether the customer is also responsible for paying some or all of the charges for a Service Item. Invoices, allocations (if any) are created only for debtors. 
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	debtorInd:boolean = undefined;
	/**
	 * Expanded Name : Name Line 1 (text format)
	 * 
	 * Desc : Line 1 of the customer name
	 * 
	 * Source : MBF variable segments
	 * 													AA  Shipper segment
	 * 													AB  Consignee segment
	 * 													AC  Bill to segment
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * Expanded Name : Name Line 2 (text format)
	 * 
	 * Desc : Second line (if any) of customer name
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Expanded Line : Address 
	 * 
	 * Desc : Street address of the customer location.
	 * 
	 * Source: MBF variable segments
	 * 	AA  Shipper segment
	 * 	AB  Consignee segment
	 * 	AC  Bill to segment
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * Expanded Name : City
	 * 
	 * Desc : City where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State (code)
	 * 
	 * Desc : State where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * Expanded Name : Country Code
	 * 
	 * Desc : Specifies the code of the country where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Expanded Name : Zip (code) first 5 (characters) (6 characters for Canadian zips)
	 * 
	 * Desc : First 5 characters of the zip code for a US address or first 6 characters of the Canadian zip code of the address where the customer is located. 
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * Expanded Name : Zip (Code) last 4 (characters)
	 * 
	 * Desc : Last 4 characters of the zip code for a US address
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * Expanded Name : Matching Status
	 * 
	 * Desc : Match status from CIS matching.
	 * 
	 * Valid Values :
	 * 
	 * The following values ARE ACTIVE for Forward Sync:
	 * 
	 * Spaces - Initial value, matching activity has not yet occurred.  
	 * 	70  - Full Match 
	 * 	60  - Un-matched (valid name / address but does not exist in CIS)
	 * 
	 * The following values ARE NOT ACTIVE for Forward Sync:
	 * 
	 * 	15  - Possible matches found
	 * 	30  - Invalid address (Bad US postal address as per Code-1)
	 * 	50  - Blank bill2 - no bill-to was entered
	 * 
	 * The following was added by C.Doucet 10/12/2001:
	 * 
	 * 	CM  - CIS Customer Merge correction changed the CIS cust nbr value
	 * Required: false
	 * Array: false
	 */
	matchedStatusCd:MatchedPartyStatusCd = undefined;
	/**
	 * Expanded Name : Matcher s Initials 
	 * 
	 * Desc : Contains the initials of the Review Clerk who performed matching on a pro, or forced the MAD code on a pro to a different MAD using a Pro Override.  
	 * 
	 * Source : Extended Master Bill File. These initials are the same as those stored on the EMB (JABCOB).
	 * Required: false
	 * Array: false
	 */
	matchedInitials:string = undefined;
	/**
	 * Expanded Name : CIS Customer Number
	 * 
	 * Desc : Identifier of the appropriate customer in CIS, determined by the matching sub-routine
	 * Required: false
	 * Array: false
	 */
	cisCustNbr:number = undefined;
	/**
	 * Expanded Name : As-Matched MAD code
	 * 
	 * Desc : Customer Account code.  This is the MAD code that came from CIS at the time of Customer Matching.
	 * 
	 * Source : Customer Information System
	 * Required: false
	 * Array: false
	 */
	asMatchedMadCd:string = undefined;
	/**
	 * Expanded Name : Direct code
	 * 
	 * Desc : Used to group customer oriented service reports and manifests for direct customer access via the online system.
	 * 
	 * This is a de-normalisation as direct code is available only for CIS customers and it should be picked up from appropriate CIS table. 
	 * 
	 * Source : MBF: root: MBSCUST
	 * Required: false
	 * Array: false
	 */
	dirCd:string = undefined;
	/**
	 * Expanded Name : Credit Status Code
	 * 
	 * Desc : Specifies the credit rating / status for a Customer, if not set up in CIS. If the customer exists in CIS, it is copied here for performance reasons.
	 * 
	 * Source : MBF: root: MBONOST (Out-bound)
	 * 														MBF: root: MBINOST (In-bound)
	 * 
	 * 
	 * Valid Values : 
	 * 
	 * B=Bad debt/MBOBAD
	 * C=Credit/MBOCRED
	 * N=Non-credit/MBONOCRD
	 * P=PCO Control/MBOPCO
	 * V=Voluntary/MBOVOLON
	 * $=Cash only/MBOCASH
	 * Required: false
	 * Array: false
	 */
	creditStatusCd:ShipmentCreditStatusCd = undefined;
	/**
	 * Expanded Name:  Bill to Relationship Code
	 * 
	 * Desc: Indicates who the Bill to customer is related to (Shipper or Consignee) in the CIS system. This field applies only to BILL TO type of customers. 
	 * 
	 * Valid Values:
	 * 				- Not Applicable
	 * S - Bill To is related to Shipper
	 * C - Bill To is related to Consignee
	 * B - Bill To is related to Both
	 * N - Bill To is related to None
	 * Required: false
	 * Array: false
	 */
	billToRelationshipCd:BillToRelationCd = undefined;
	/**
	 * Expanded Name : Use as entered indicator
	 * 
	 * Desc : 
	 * 
	 * Source : E-commerce
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	useAsEntrdInd:boolean = undefined;
	/**
	 * Expanded Name : All shipments (must be) prepaid indicator
	 * 
	 * Desc : Specifies whether all shipments from the customer have to be pre-paid
	 * 
	 * Source : MBF: root: MBR4PPDA (x 02  of MBRGFLG4)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	allShipmentPrepaidInd:boolean = undefined;
	/**
	 * Expanded Name :   Proof Of Delivery  required indicator
	 * 
	 * Desc : Indicates whether the customer (usually shipper and / or consignee) requires a Proof Of Delivery.
	 * 
	 * Source : MBF: root: MBSPD (x 80  of MBFLAGD)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	proofOfDeliveryRequiredInd:boolean = undefined;
	/**
	 * Expanded Name :  Proof Of Delivery  image indicator
	 * 
	 * Desc : Specifies that an image of the delivery receipt will suffice, instead of an original 
	 * 
	 * Source : MBF: root: MBSMG (x 40  of MBFLAGD)
	 * 
	 * Valid values : Y/N
	 * Required: false
	 * Array: false
	 */
	proofOfDeliveryImageInd:boolean = undefined;
	/**
	 * Expanded Name : Broker customer key number
	 * 
	 * Desc : For international shipments, identifies the CIS customer number of any broker for the shipper or consignee. 
	 * 
	 * Source : MBF: root: MBBRKKEY
	 * Required: false
	 * Array: false
	 */
	brokerCustKeyNbr:number = undefined;
	/**
	 * Expanded Name :  Customer E-mail Id
	 * 
	 * Desc : E-mail address of the Customer on a bill, used as supplemental information in case that customer needs to be contacted via e-mail.
	 * Required: false
	 * Array: false
	 */
	eMailId:string = undefined;
	/**
	 * Expanded Name : Phone Country Code 
	 * 
	 * Desc : Country code for the phone number of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneCountryCdNbr:string = undefined;
	/**
	 * Expanded Name :  Phone Area Code 
	 * 
	 * Desc : Area Code for phone number of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneAreaCdNbr:string = undefined;
	/**
	 * Expanded Name : Customer Phone 
	 * 
	 * Desc : Phone number of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneNbr:string = undefined;
	/**
	 * Expanded Name : Customer Phone Extension Number
	 * 
	 * Desc : Phone Extension of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneExtensionNbr:string = undefined;
	/**
	 * Expanded Name : Last Match Timestamp
	 * 
	 * Desc : Identifies the last time Customer Matching was performed against this particular customer for this Service Item.
	 * 
	 * Source : MBF root: MBTMDTE
	 * Required: false
	 * Array: false
	 */
	lastMatchedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded Name: Preferred Payment Currency Code
	 * 
	 * Desc: The customer prefers to make payments in this currecny.
	 * 
	 * Valid Values:
	 * 
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	preferredPaymentCurrencyCd:CurrencyCd = undefined;
	/**
	 * Expanded Name: Alternate Customer Number
	 * 
	 * Desc: Contains the customer key to an uncoded customer in the customer component.  These are pickup and delivery locations that have been a part of at least one shipment in the Con-way system.  (Note that they are not currently stored in the P  table in the customer component.) They do not have an assigned MAD code (uncoded) and are therefore identified and matched separately so that we know when we service the same physical location.
	 * 
	 * OR
	 * Contains the non-debtor P location where there were multiple full matches (89% and higher) at the same perentage.  (Changed meaning of attribute 3/1/2010)
	 * 
	 * Added 2/2/2010 Cathy Doucet (for Hand Held Scorecard project)
	 * Required: false
	 * Array: false
	 */
	alternateCustNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Identifies whether the system full matched the party, or if the Biller chose from a list of possibles. 
	 * 
	 * Valid Values 
	 * S=System Matched
	 * U=User Selected
	 * F=User Selected from Search
	 * N=Not matched
	 * Required: false
	 * Array: false
	 */
	matchedSourceCd:MatchedPartySourceCd = undefined;
	/**
	 * Identifies if the invoice address should be read from the PICKUP_DELIVERY_LOCATION(self) versus from an associated Bill To.
	 * Required: false
	 * Array: false
	 */
	selfInvoiceInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of AsMatchedParty. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class AsMatchedPartySnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:AsMatchedParty = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:AsMatchedParty = undefined;
}
/**
 * Typically there will be one row per shipment. This will be inserted when biller submits the form.
 * However, iIF the user clicks the Save button and then at later time resumes entry of the bill for that PRO and submits then there would be two rows. 
 * So a row is recorded for a PRO every time user either saves or submits the page.
 * The record is not expected to be updated after it is inserted into the table.
 */
export class BillEntryStats {
	/**
	 * Foreign key: Parent SHM_SHIPMENT. Unique surrogate key that identifies a shipment
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * User that entered the bill. 
	 * Note, if bill entry clerk enters the bill and save it and then Later supervisor updates missing information and submits the bill, then there will be two rows for the same shipment. 
	 * The first row will have user id of the clerk and second will have user id of the supervisor.
	 * Required: false
	 * Array: false
	 */
	billEntryUserId:string = undefined;
	/**
	 * Time when bill entry person started the bill entry activity.
	 * Required: false
	 * Array: false
	 */
	billEntryStartDateTime:Date = undefined;
	/**
	 * Time when bill entry person either saved or submitted the page.
	 * Required: false
	 * Array: false
	 */
	billEntryEndDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Desc: Identifies if one or m
 * ore attachments were included with the BOL to represent additional service.
 * not all attachments are scanned. Some are in paper form that travel with the shipment. Paper BOL indicates which attachments are available for the shipment 
 * ces.09/28/2017 Cathy Doucet Added for SpeedyG.
 */
export class BolAttachment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shpInstId:number = undefined;
	/**
	 * Desc: Id
	 * entifies if Customer Invoice Document is attached to Bill of Lading for 
	 * shipment.
	 * Required: false
	 * Array: false
	 */
	custInvoiceInd:boolean = undefined;
	/**
	 * Desc: Id
	 * entifies if Packing List Document is attached to Bill of lading for ship
	 * ment.
	 * Required: false
	 * Array: false
	 */
	packingListInd:boolean = undefined;
	/**
	 * De
	 * sc: Identifies if Supplemental Pages Document is attached to Bill of lad
	 * ing for shipment.
	 * Required: false
	 * Array: false
	 */
	supplementalPagesInd:boolean = undefined;
	/**
	 * Desc
	 * : Identifies if CDP Form (Inbound) Document is attached to shipment.
	 * Required: false
	 * Array: false
	 */
	customsCdpFormInd:boolean = undefined;
	/**
	 * Expanded
	 * 	Name : Created by transaction codeDesc : Transaction code of the online
	 * 	screen or proxy (program module name if in batch mode) that created thi
	 * s record.
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class BolUsage {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bolInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bolBillingVersionNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:BolUsageTypeCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CommodityDimension {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	commoditySequenceNbr:string = undefined;
	/**
	 * Expanded Name: Sequence Number
	 * 
	 * Desc: A system generated sequential number to uniquely identify the recorded dimensions for a shipment.
	 * Required: false
	 * Array: false
	 */
	dimSequenceNbr:string = undefined;
	/**
	 * Expanded Name: Pieces Count
	 * 
	 * Desc: This is the number of pieces the recorded dimensions apply to.
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name: Length                               
	 * 																																																				
	 * Description: This is the length of the whole or part
	 * of the shipment. The number of pieces attribute     
	 * indicates how many pieces of the shipment the       
	 * dimensions apply to.
	 * Required: false
	 * Array: false
	 */
	lengthNbr:number = undefined;
	/**
	 * Expanded Name: Width                                   
	 * 																																																							
	 * Description: This is the width of the whole or part of 
	 * the shipment. The number of pieces attribute indicates 
	 * how many pieces of the shipment the dimensions apply   
	 * to.
	 * Required: false
	 * Array: false
	 */
	widthNbr:number = undefined;
	/**
	 * Expanded Name: Height                                
	 * 																																																								
	 * Description: This is the height of the whole or part of  
	 * the shipment. The number of pieces attribute indicates  
	 * how many pieces of the shipment the dimensions apply    
	 * to.
	 * Required: false
	 * Array: false
	 */
	heightNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Commodity {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : A system assigned number to uniquely identify each commodity for a shipment.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : (Commodity) class
	 * 
	 * Desc : Identifies the type (class) of commodity being shipped.
	 * 
	 * Examples:  Carpet, Matresses, Computer paper
	 * 
	 * Valid Values : 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 350, 400, 500  
	 * 
	 * Source: MBF Variable Segment : MBVDESC
	 * Required: false
	 * Array: false
	 */
	classType:CommodityClassCd = undefined;
	/**
	 * Expanded Name : NMFC item code
	 * 
	 * Desc : This is the NMFC (National Motor Freight Classification) code for the commodity.
	 * 
	 * Source: MBF Variable Segment - MBNMFC
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Expanded Name : Source Code
	 * 
	 * Desc : Code indicating the source of a particular Commodity Line.  Provides a way to distinguish between a Commodity Line on a bill that originated from the Common Shipper Bill of Lading (CSBOL) profile or a new Commodity Line that is added onto a bill where CSBOL Commodity Lines already exist.
	 * 
	 * Source : FBES E-Commerce
	 * 
	 * Valid Values :
	 * 1 - from CSBOL
	 * 2 - Not from CSBOL
	 * Required: false
	 * Array: false
	 */
	sourceCd:string = undefined;
	/**
	 * Expanded Name: Packaging code
	 * 
	 * Desc : Type of packaging the commodity was originally picked up in. 
	 * 
	 * Examples: Box, Skid, Case, Roll etc.
	 * 
	 * Source: MBF Variable Segment - MBVDESC
	 * Required: false
	 * Array: false
	 */
	packageCd:CommodityPackageCd = undefined;
	/**
	 * Expanded Name : Pieces count
	 * 
	 * Desc : Number of pieces of the commodity in the shipment.
	 * 
	 * Source : MBF Variable Segment - MBPIECES
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * 
	 * Desc : The volume of the freight; the height multiplied by the width multiplied by the depth
	 * 
	 * Source :
	 * Required: false
	 * Array: false
	 */
	volumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total weight (in pounds)
	 * 
	 * Desc : Total weight of the commdity in pounds. 
	 * 
	 * Source: MBF Root - MBTOTWT
	 * Required: false
	 * Array: false
	 */
	weightLbs:number = undefined;
	/**
	 * Expanded Name : Reduced weight
	 * 
	 * Desc : This is the weight deducted from the actual weight of the commodity to get the net weight. This weight is normally the pallet or the container weight. This part of the weight gets a free ride.
	 * 
	 * Source : MBF Variable Segment - MBWT
	 * Required: false
	 * Array: false
	 */
	reducedWeight:number = undefined;
	/**
	 * Expanded Name : Rating Unit of Measure
	 * 
	 * Desc : A code to identify the units in terms of which the quantity is measured for calculating charges. For eg. weight (in pounds) is the most common unit of measure for commodities.
	 * 
	 * Valid Values : 
	 * 1 - Weight (in pounds)
	 * 2 - Yardage (for carpets)
	 * 3 - Count
	 * Required: false
	 * Array: false
	 */
	ratingUnitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantity
	 * 
	 * Desc : Quantity (measured in terms of UOM) to be used when calculating charges for the commodity.
	 * Required: false
	 * Array: false
	 */
	ratingQuantity:number = undefined;
	/**
	 * Expanded Name : Freezable Indicator 
	 * 
	 * Desc : Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * 
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 * 
	 * Y - Can be damaged by freezing
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous material indicator
	 * 
	 * Desc : Indicates whether the commodity is a hazardous material. 
	 * 
	 * Source : MBF Variable Segment : MBNMHMX
	 * 
	 * Valid Values :
	 * Y - Has hazardous materials
	 * N - No hazardous materials
	 * Required: false
	 * Array: false
	 */
	hazardousMtInd:boolean = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the total freight charges for a commodity. The rate is multiplied by the unit of measure (weight in most cases) to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE, MBRATES
	 * Required: false
	 * Array: false
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge for the commodity.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * 
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 * Required: false
	 * Array: false
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the commodity. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name : Mixed class commodity exists indicator
	 * 
	 * Desc :
	 * 
	 * Source : MBF: root: MBMXCLS (x 01  of MBFLAGA)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	mixClassCommodityInd:boolean = undefined;
	/**
	 * Expanded Name : Description
	 * 
	 * Desc : A textual description of the commodity
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * 
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * 
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * 
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	asRatedClassCd:CommodityClassCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chrgToCd:LineItemChargeToCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalDescription:string = undefined;
	/**
	 * Indicates that the biller selected to have the commodity class default to 100 during bill entry since the class was not listed on the Bill of Lading.  This is to distinguish between commodity lines that actually have a true commodity class of 100 and commodity lines where the default situation occurs.  Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	defaultClassSelectedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazMat:HazMat = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	commodityDimension:CommodityDimension[] = undefined;
}
/**
 * 
 */
export class CustomsBond {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : System generated sequence number assigned to each instance to uniquely identify each customs bond information for a shipment
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Bond number text
	 * 
	 * Desc : This is the IT, TE or WDTE number (7,8 or a 9 digit number) on the in-bond document. It is used to identify the Shipment while it is in the customs warehouse.
	 * 
	 * Source: MBF  Variable Segment - MBITNUM
	 * Required: false
	 * Array: false
	 */
	bondNbr:string = undefined;
	/**
	 * Expanded Name : City
	 * 
	 * Desc : Name of the City where the customs inspection is to be made.
	 * 
	 * Source: MBF Variable Segment - MBINBOND
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State
	 * 
	 * Desc : State of the city where the customs inspection is to be made.
	 * 
	 * Source: MBF Variable Segment - MBINBOND
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomsControl {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * 
	 * Desc : Sequential number assigned to uniquely identify  each customs control for a Shipment.
	 * 
	 * Source : Generated
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Customs type code
	 * 
	 * Desc : Indicates the type of customs crossing by identifying the two different countries involved. 
	 * 
	 * Source :
	 * 
	 * Valid Values : 
	 * 
	 * 1 - Shipment crossing from the US to Canada (Canadian 
	 * 					customs information) 
	 * 2 - Shipment crossing from Canada to the US (US
	 * 					customs information) 
	 * 3 - Shipment crossing from the US to Mexico (Mexican
	 * 					customs information)
	 * 4 - Shipment crossing from Mexico to the US (US
	 * 					customs information)
	 * Required: false
	 * Array: false
	 */
	lineTypeCd:CustomsControlTypeCd = undefined;
	/**
	 * Expanded name: CSA status indicator
	 * 
	 * Desc: Code that indicates whether this shipment is a CSA approved shipment.
	 * 
	 * Valid values:
	 * Y = Yes the shipment is a CSA shipment
	 * N = No the shipment is not a CSA shipment
	 * 
	 * *Note: CSA stands for Customer Self Accessment.  This is a Canadian Customs program that identifies shipments inwhich all of the parties involved are  trusted  by Canada.  Con-Way and certain of it s drivers are certified in the program.  The importer must also be certifed in the program.  However, not all shipments that have all certifed parties are CSA shipments.
	 * Required: false
	 * Array: false
	 */
	csaStatusInd:boolean = undefined;
	/**
	 * Expanded name: CSA status yes indicator
	 * 
	 * Desc: Code that indicates whether this shipment ever had the CSA status indictor set to YES.  This is for audit purposes.
	 * 
	 * Valid values:
	 * Y = Yes the shipment is or has been marked as CSA
	 * 						approved.
	 * N = No the shipment has never been marked as CSA
	 * 						approved.
	 * Required: false
	 * Array: false
	 */
	csaStatusYesInd:boolean = undefined;
	/**
	 * Expanded name: CSA Number Text
	 * 
	 * Desc: Number that the Canadian Customs agency assigns to  an importer.
	 * Required: false
	 * Array: false
	 */
	csaNbr:string = undefined;
	/**
	 * Expanded name: Port Code
	 * 
	 * Description: This is a code supplied by the specified customs agency that identifies the location that shipments will cross the border.
	 * Required: false
	 * Array: false
	 */
	portCd:CustomsPortCd = undefined;
	/**
	 * Expanded name: Border Crossed Indicator
	 * 
	 * Desc: Flag that indicates if this shipment has crossed this border yet.  Currently we only know that when the arrive happens.  With handhelds we may be able to know this sooner.
	 * Required: false
	 * Array: false
	 */
	borderCrossedInd:boolean = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * 
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * 
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * 
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomsDocument {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sentDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bolDocSentInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customsDocSentInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	customsDocImage:CustomsDocImage[] = undefined;
}
/**
 * Desc: This table captures the list of  individual documents that were sent to Descartes. 02/27/2019 Cathy Doucet Added as per Ashutosh.
 */
export class CustomsDocImage {
	/**
	 * Desc: Part of unique identifier.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Desc: Part of unique identifier.
	 * Required: false
	 * Array: false
	 */
	docSequenceNbr:string = undefined;
	/**
	 * Desc: Part of unique identifier.
	 * Required: false
	 * Array: false
	 */
	imageSequenceNbr:string = undefined;
	/**
	 * Desc: Document Class as stored in DMS. For eg. BL (Bill of Lading) or CUST(Customs)
	 * Required: false
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * Desc: This is the Document ID of the document stored in DMS.
	 * Required: false
	 * Array: false
	 */
	docId:string = undefined;
	/**
	 * Desc: Date and Time when the document was sent to Descartes
	 * Required: false
	 * Array: false
	 */
	sentDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class CustomsDocumentPending {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class EventLog {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:EventLogTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	subTypeCd:EventLogSubTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	transactionId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	programId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportingSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	occurredDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:ArchiveControlCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trlrIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trlrIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	occurredSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enrouteInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCustNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCustNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bil21StCustNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bil22NdCustNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCountryCd:CountryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCountryCd:CountryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	adminInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billTo1StRelationshipCd:BillToRelationCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	adminStatusCd:BillStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billTo2NdRelationshipCd:BillToRelationCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ediSenderId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ediSenderShipmentId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	debtorCd:DebtorCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	purInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bolInstId:number = undefined;
	/**
	 * A unique identifier to correlate multiple transactions. This can be used by a log aggregator to tie events from multiple technologies such as UI, Middle ware and integration.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	thirdPartyInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Surrogate key for SHM_EVENT_LOG.  Added 02/11/2020 for Project Radar (Saravanan)
	 * Required: false
	 * Array: false
	 */
	shipmentEventLogId:string = undefined;
}
/**
 * Holds a snapshot of EventLog. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class EventLogSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:EventLog = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:EventLog = undefined;
}
/**
 * 
 */
export class HandlingUnit {
	/**
	 * Expanded Name : 
	 * Instance identifierDesc : System generated unique identifier (as per 
	 * CBD standard).
	 * Required: false
	 * Array: false
	 */
	shipmentInstanceId:number = undefined;
	/**
	 * A system assigned number
	 * 	used as part of how to uniquely identify each handling unit of a
	 * 	shipment.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * Child PRO
	 * 	number identifies a handling unit of a shipment. This is an alternate
	 * 	key of the table.
	 * The numbers are printed on labels and are assigned to service centers.
	 * 	Labels are placed on 2 sides of each pieces (usually by pallet) of the
	 * 	shipment picked up from the customer.
	 * Child PRO number format is 0ppppSSSSSc where pppp is the prefix from
	 * 	1001 to 9999 (excluding numbers where last digit is 0, ex: 2000), SSSSS
	 * 	is a suffix/serial number, and c is a check-digit of the ppppSSSSS
	 * 	using modulus 7.
	 * Required: false
	 * Array: false
	 */
	childProNbr:string = undefined;
	/**
	 * This is the
	 * 	parent PRO number of the handling unit. A denormed field from the
	 * 	parent shipment table.
	 * Refer to the main source table for more description.
	 * Required: false
	 * Array: false
	 */
	parentProNbr:string = undefined;
	/**
	 * The date of when a
	 * 	shipment has to be picked up
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * Weight of this 
	 * handling unit.
	 * Required: false
	 * Array: false
	 */
	weightLbs:number = undefined;
	/**
	 * Volume in cubic 
	 * feet of this handling unit.
	 * Required: false
	 * Array: false
	 */
	volumeCubicFeet:number = undefined;
	/**
	 * Length of this 
	 * handling unit.
	 * Required: false
	 * Array: false
	 */
	lengthNbr:number = undefined;
	/**
	 * Width of this 
	 * handling unit.
	 * Required: false
	 * Array: false
	 */
	widthNbr:number = undefined;
	/**
	 * Height of this 
	 * handling unit.
	 * Required: false
	 * Array: false
	 */
	heightNbr:number = undefined;
	/**
	 * SIC where 
	 * the Shipment currently is.
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * Trailer
	 * 	Load Instance ID. A denormalized system generated unique 
	 * identifier of a piece of equipment that is currently involved with this
	 * 	
	 * handling unit.
	 * Required: false
	 * Array: false
	 */
	currentTrailerInstanceId:number = undefined;
	/**
	 * The 
	 * current dock location of this handling unit.
	 * Required: false
	 * Array: false
	 */
	currentDockLocation:string = undefined;
	/**
	 * The movement 
	 * status that reflects the latest movement event on a handling unit.Valid
	 * 	
	 * Values : Spaces - Not applicable1 - On dock2 - On trailer3 - Out for 
	 * delivery4-  Interim Delivery5 - Final Delivered6 - Not Applicable6 - No
	 * 	
	 * movements allowed7 - Cancell
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:string = undefined;
	/**
	 * Date and 
	 * time, when the last movement for this handling unit occurred.
	 * Required: false
	 * Array: false
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * Indicates 
	 * whether the handling unit can be stacked or not.
	 * Required: false
	 * Array: false
	 */
	stackableInd:boolean = undefined;
	/**
	 * Indicates 
	 * whether or not the handling unit is a hazardous material.
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Indicates 
	 * whether or not the handling unit is a freezable material.
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * This 
	 * indicator is set to true (Y) if the handling unit is to be archived. 
	 * When the record is retrieved from the archives, it is reset to false 
	 * (N).
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:HandlingUnitTypeCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	handlingUnitMovement:HandlingUnitMovement[] = undefined;
}
/**
 * Holds a snapshot of HandlingUnit. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class HandlingUnitSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:HandlingUnit = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:HandlingUnit = undefined;
}
/**
 * 
 */
export class HandlingUnitMovement {
	/**
	 * Expanded Name :
	 * 	
	 * Instance identifierDesc : System generated unique identifier (as per 
	 * CBD standard).
	 * Required: false
	 * Array: false
	 */
	shipmentInstanceId:number = undefined;
	/**
	 * A system assigned
	 * 	number used as part of how to uniquely identify each handling unit of a
	 * 	shipment.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * A system
	 * 	assigned number to uniquely identify each movement leg for a movement
	 * 	event of a handling unit
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * Identifies the
	 * 	type of movement event. 
	 * Valid Values :
	 * LOAD
	 * UNLOAD
	 * SCAN
	 * CLOSE
	 * STAGE
	 * Required: false
	 * Array: false
	 */
	movementTypeCd:ShipmentMovementTypeCd = undefined;
	/**
	 * SIC 
	 * where the movement event for the unit happened and reported.
	 * Required: false
	 * Array: false
	 */
	movementReportingSicCd:string = undefined;
	/**
	 * Date and 
	 * time, when the movement event occurred.
	 * Required: false
	 * Array: false
	 */
	movementDateTime:Date = undefined;
	/**
	 * Denormed
	 * 	trailer instance ID. A system generated unique identifier 
	 * assigned to a piece of equipment (mostly trailers) used to carry on 
	 * movements of handling units. This applies to all type of movement except
	 * 	for 
	 * staged on dock.
	 * Required: false
	 * Array: false
	 */
	trailerInstanceId:number = undefined;
	/**
	 * Unique ID of a
	 * 	Dock Location. This is the dock location where a shipment is staged on
	 * 	dock.
	 * Required: false
	 * Array: false
	 */
	dockInstanceId:number = undefined;
	/**
	 * Expanded
	 * 	Name : Archive Control CodeDesc : Identifies a row of this 
	 * table as archived or retrieved from archive.  The Princeton Archive 
	 * tool sets the value upon archive or retrieval of data for selected 
	 * PROs.FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSI
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded Name :
	 * 	Exception Type Code
	 * Desc : Describes whether the pieces count is for an overage or a
	 * 	shortage or damaged or refused hanlding unit.
	 * Valid Values : 
	 * 1 - Over
	 * 2 - Short
	 * 3 - Damaged
	 * 4 - Refused
	 * 5 - Undeliverable
	 * 6 - Exception Noted
	 * 7 - Daamaged But Accepted
	 * 8 - Over But Accepted
	 * Required: false
	 * Array: false
	 */
	exceptionTypeCd:string = undefined;
	/**
	 * Expanded Name :
	 * 	Refused Reason Code
	 * Desc : The reason code  for refused handling unit. Applicable only for
	 * 	refusal count. 
	 * Valid Values :
	 * 1 - Unauthorized return
	 * 2 - Consignee won't accept freight collect
	 * 3 - Arrived too late  
	 * 4 - Order cancelled
	 * 5 - Did not Order
	 * 6 - Wrong Order
	 * 7 - Customer Wanted Earlier
	 * 8 - Insufficient Delivery Time
	 * 9 - Manufacturer's Defect
	 * A - Arrived too early
	 * B - Duplicate Order
	 * C - Sent to wrong location
	 * U - <used by BI team>
	 * Z - Other (Remarks required)
	 * Required: false
	 * Array: false
	 */
	refusedReasonCd:string = undefined;
	/**
	 * Expanded Name
	 * 	: Undelivarable Reason Code
	 * Desc : The reason code explaining why the handling unit could not be
	 * 	delivered. Applicable only for undelivered handling unit. 
	 * Valid Values :
	 * 1 - Construction site, not deliverable within 2 day
	 * 2 - No response to arrival notification after 2 working days
	 * 3 - Consignee involved in labor dispute
	 * 4 - Consignee cannot be located at address or phone number shown
	 * 5 - Consignee has moved 
	 * 6 - Credit Hold
	 * 7 - Suspended at Customer Request
	 * 8 - Insufficient Delivery Time
	 * Z - Other (Remarks required)
	 * Required: false
	 * Array: false
	 */
	undeliveredReasonCd:string = undefined;
	/**
	 * Expanded Name
	 * 	: Damaged category
	 * Desc : A code further describing the nature of damage.
	 * Source : SMART (Dock Automation)
	 * Valid Values :
	 * Required: false
	 * Array: false
	 */
	damagedCategoryCd:string = undefined;
	/**
	 * Expanded Name:
	 * 	Remarks text
	 * Desc : Any remarks noted during the exception or as a response to a
	 * 	recorded exception. Sometimes describes the reason for the exception
	 * 	when it is not one of the standard reason codes.
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HazMat {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceCd:HazmatSourceCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatUnna:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatBulkQuantityCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatResidueInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zoneCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packingGroupCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideMethodNm:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dotSpecialPermit:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	limitedQuantityInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportedQuantityInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	explosiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	netExplosiveMassNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	netExplosiveMassUnitOfMeasure:NetExplosiveMassUomCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	marinePollutantInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	marinePollutantChemical:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactiveChemicalName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactivityNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactivityUnitOfMeasure:RadioactivityUomCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	transportIndex:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fissileExceptedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyContactName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyContactPhoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	technicalName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unnaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classLabel:string = undefined;
}
/**
 * Holds a snapshot of HazMat. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class HazMatSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:HazMat = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:HazMat = undefined;
}
/**
 * 
 */
export class HazmatCdRef {
	/**
	 * Expanded Name : UN Number Text
	 * 
	 * Desc : United Nations Identification Number.
	 * Required: false
	 * Array: false
	 */
	unNbr:string = undefined;
	/**
	 * Expanded Name : Packing Group Code
	 * 
	 * Desc : Indicates degree of danger presented by material.
	 * 
	 * Valid Values:
	 * 'I' = great degree of danger
	 * 'II' = medium degree of danger
	 * 'III' = minor degree of danger
	 * blank = not applicable
	 * Required: false
	 * Array: false
	 */
	packingGroupCd:string = undefined;
	/**
	 * Expanded Name : Haz Mat Class Code
	 * 
	 * Desc : Hazardous Materials Classification Code.
	 * Required: false
	 * Array: false
	 */
	hazmatClassCd:string = undefined;
	/**
	 * Expanded Name : Haz Mat Level Code
	 * 
	 * Desc : Hazardous Materials Level Code.
	 * 
	 * Valid Values:
	 * 0 = Hazardous
	 * 1 = Extremely Hazardous
	 * Required: false
	 * Array: false
	 */
	hazmatLevelCd:string = undefined;
	/**
	 * Expanded Name : Haz Mat Zone Code
	 * 
	 * Desc : Hazardous Materials Zone Code.
	 * 
	 * Valid Values:
	 * A = Hazard Zone A
	 * B = Hazard Zone B
	 * C = Hazard Zone C
	 * D = Hazard Zone D
	 * blank = Not Applicable
	 * Required: false
	 * Array: false
	 */
	hazmatZoneCd:string = undefined;
	/**
	 * Expanded Name : Description Text
	 * 
	 * Desc : Hazardous materials descriptions and proper shipping names.
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name: Restricted indicator                                                            
	 * 
	 * Desc:  Y  indicates a class of hazardous material that Con-way has chosen not to transport.
	 * Required: false
	 * Array: false
	 */
	restrictedInd:boolean = undefined;
	/**
	 * Expanded Name:  Class label          
	 * 
	 * Desc: The DOT combined hazard class codes listed in order of importance required for labeling hazardous material.
	 * Required: false
	 * Array: false
	 */
	classLabel:string = undefined;
	/**
	 * Expanded Name:  Over-ride method name                        
	 * 
	 * Desc: The override class code used by the Placarding wizard.  Under normal circumstances the HZMT_CLASS_CD is used by the Placarding wizard for its method call.  This attribute will only be populated when the HZMT_CLASS_CD contains the incorrect method for the Placarding wizard.
	 * Required: false
	 * Array: false
	 */
	overrideMethodNm:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unSequenceNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class InventoryGap {
	/**
	 * Expanded Name : Gap Tracking Number
	 * 
	 * Desc : A sequential number that was missed in the main SHM_INVENTORY_TRACKING table.
	 * Required: false
	 * Array: false
	 */
	gapTrackingNbr:number = undefined;
	/**
	 * Expanded Name : Status Code
	 * 
	 * Desc : Self-documenting value that describes the status of the Shipment from an Inventory Tracking perspective.
	 * 
	 * VALID VALUES
	 * BCK=Transaction back-out
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class InventoryTracking {
	/**
	 * Expanded Name : Instance identifier
	 * 
	 * Desc : System generated unique identifier (as per CBD standard).
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This number identifies a shipment. This is the identifier given to the customer as a reference.
	 * 
	 * The numbers are printed on labels for each service center.  Labels are put on the Bill-Of-Lading and on the individual pieces of freight for identification.
	 * 
	 * Internal PRO number format is Oppp-sssss-c where 'ppp' is the pro-prefix, 'ssss' is the pro-suffix and 'c' is the check digit (modulus 7). It is usually displayed in the format 'ppp-sssss-c'
	 * 
	 * An expedited shipment may not always have a real PRO number. It may be given a dummy number in the same format and should not be written out to MBF.
	 * 
	 * MBF: root MBPRO
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Pickup Date                       
	 * 																																																		
	 * Desc : Earliest available date that Shipment can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * Expanded Name : Status Code
	 * 
	 * Desc : Self-documenting value that describes the status of the Shipment from an Inventory Tracking perspective.
	 * 
	 * VALID VALUES
	 * BIL= Billed
	 * RES=Reset
	 * PRG=Purged
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentTrackingNbr:number = undefined;
	/**
	 * Expanded Name : Tracking Exception Code
	 * 
	 * Desc: Tracks discrepancies between SHM_INVENTORY_TRACKING and SHM_SHIPMENT. 
	 * 
	 * VALID VALUES
	 * OREP=Overage Report Bill
	 * Required: false
	 * Array: false
	 */
	trackingExceptionCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LnhDimension {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dimensionsSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lengthNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	widthNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	heightNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stackableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	capturedByUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	capturedByDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	capturedByCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Table captures comments by a Customer care person managing key accounts regarding reason and resolution of movement exceptions. This information is sent proactively to the customer. The goal is to keep customer happy.  
 * 10/24/2019 This table now stores remarks for Shipments as well as Movement Exceptions.  PK has to be changed.
 */
export class ManagementRemark {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementExceptionSequenceNbr:number = undefined;
	/**
	 * Remark entered by the customer care person, describing the exception. This is typically shared with the customer.
	 * Required: false
	 * Array: false
	 */
	remark:string = undefined;
	/**
	 * Remarks in this table will be of type:
	 * Customer Service Remarks
	 * Exception Remarks
	 * 11/14/2019 Renamed from REASON_CD to TYPE_CD
	 * Required: false
	 * Array: false
	 */
	typeCd:ShipmentManagementRemarkTypeCd = undefined;
	/**
	 * Y- default. The data is shown to the external customer. N- These comments are for XPO internal documentation and will not be shown to the customer.
	 * Required: false
	 * Array: false
	 */
	showToCustomerInd:boolean = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class MiscLineItem {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Line type code
	 * 
	 * Desc : Indicates the type of  line item. 
	 * 
	 * Source : MBF Variable Segment - MBWEIGHT
	 * 
	 * Valid Values : 
	 * 
	 * 1 - As weight 
	 * 2 - Deficit weight 
	 * 3 - COD Amount
	 * 4 - Discount line (Reduced charge)
	 * 5 - Part PPD Line
	 * 6 - Part Collect Line
	 * 7- Cash Prepaid Line
	 * 8 - Cash Collected line
	 * 
	 * Note: As weight - Often the commodities are rated at a higher weight bracket than their net weight,  to get lower freight charges. In these cases the 'as weight'  is the weight used in calculating the freight charges. Most of the time this is sytem generated.
	 * Required: false
	 * Array: false
	 */
	lineTypeCd:MiscLineItemCd = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the  charges for the line item. Not all line items have a charge associated with them. The rate when present is multiplied by the unit of measure  to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE, MBRATES
	 * Required: false
	 * Array: false
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name : Unit of Measure
	 * 
	 * Desc : A code to identify the units in terms of which the quantity is measured for calculating charges. For eg. weight (in pounds) is the unit of measure for the AS WEIGHT and  DEFICIT WEIGHT line items. Not all line items have an UOM. 
	 * 
	 * Valid Values :
	 * space - Where UOM is n/a to the Charge. 
	 * 1 - Weight (in pounds)
	 * 2 - Yardage (for carpets)
	 * 3 - Count
	 * Required: false
	 * Array: false
	 */
	unitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantity
	 * 
	 * Desc : Quantity (measured in terms of UOM) to be used in calculating charges
	 * 
	 * It represents As Weight on an AS WEIGHT line. Often the commodities are rated at a higher weight bracket than their net weight,  to get lower freight charges. In these cases the 'as weight'  is the weight used in calculating the freight charges. Most of the time this is sytem generated. 
	 * 
	 * For some Charge lines where it does not make sense to have a QTY, such as a COD, zeros may be formatted; UOM must be a space in this case.
	 * 
	 * Source: MBF Variable Segment - MBWEIGHT
	 * Required: false
	 * Array: false
	 */
	quantity:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge associated with the line item.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * 
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 * Required: false
	 * Array: false
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the line item. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name :  Description
	 * 
	 * Desc : A textual description for the line item.
	 * 
	 * Source : MBF  Variable Segment :
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * 
	 * Desc : Sequential number assigned to uniquely identify  each miscelleneous charge for a Shipment. This entity stores all the differnt types of charge lines that appear on the invoice for the shipment. Not all of these lines contribute to the CTS revenue.Some examples are COD, As Weight, Deficit Weight lines etc. 
	 * 
	 * Source : Generated
	 * 
	 * Design Considerations :
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	paymentMethodCd:MiscLineItemPaymentMethodCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	checkNbr:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chrgToCd:LineItemChargeToCd = undefined;
}
/**
 * 
 */
export class Movement {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementReportingSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rshpCreditedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	obcPickupDlvRouteCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	qlfrReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerLocationArrivalDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Expanded Name : Movement status
	 * 
	 * Desc : The status of the latest movement for a Shipment from an operations point-of-view. This is a denormalized atribute from the MOVEMENT entity.
	 * 
	 * Source: MBF
	 * Name: Various
	 * 
	 * Valid Values : 
	 * Spaces - Not applicable
	 * 1 - On dock
	 * 2 - On trailer
	 * 3 - Out for delivery
	 * 4-  Interim Delivery
	 * 5 - Final Delivered
	 * 6 - Not Applicable
	 * 6 - No movements allowed
	 * 7 - Cancelled
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	movementException:MovementException[] = undefined;
}
/**
 * Holds a snapshot of Movement. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class MovementSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:Movement = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:Movement = undefined;
}
/**
 * 
 */
export class MovementException {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:MovementExceptionTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rfsdReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	undlvdReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmgdCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exceptionSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exceptionReportingSicCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	movementExceptionAction:MovementExceptionAction[] = undefined;
}
/**
 * Holds a snapshot of MovementException. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class MovementExceptionSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:MovementException = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:MovementException = undefined;
}
/**
 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.  Added 01/02/2020
 */
export class MovementExceptionAction {
	/**
	 * Part of unique identifier
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Part of unique identifier
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * Part of unique identifier
	 * Required: false
	 * Array: false
	 */
	movementExceptionSequenceNbr:number = undefined;
	/**
	 * Uniquely identifies a row
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * Responsible sic could be determined to be different from what SHM_movement has recorded
	 * Required: false
	 * Array: false
	 */
	responsibleSicCd:string = undefined;
	/**
	 * Describes what corrective action XPO has taken to address the exception. This is typically shared with the customer.
	 * Required: false
	 * Array: false
	 */
	correctiveAction:string = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Indicates whether the carrier was in a position to control or prevent the exception.  Added 03/23/2020 LISG-4722
	 * Required: false
	 * Array: false
	 */
	controlInd:boolean = undefined;
}
/**
 * Holds a snapshot of MovementExceptionAction. Contains the details at the time on an event. It also provides the previous values if an update occured
 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.  Added 01/02/2020
 */
export class MovementExceptionActionSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:MovementExceptionAction = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:MovementExceptionAction = undefined;
}
/**
 * This has one row per shipment, and contains operational data for the PRO.  Added 01/22/2020
 */
export class OperationsShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Indicates the priority of the shipment. Initially set when shipment is created. High values indicate lower priority, lower values indicate higher priority. This will be recalculated when any property that determines priority is changed.
	 * Required: false
	 * Array: false
	 */
	priorityNbr:number = undefined;
	/**
	 * Calculated ETA by BI and updated; it is recalculated every 5 minutes.
	 * Required: false
	 * Array: false
	 */
	destinationSicEtaDateTime:Date = undefined;
	/**
	 * Another way to set priority of a shipment; it is set by users through UI.
	 * Required: false
	 * Array: false
	 */
	hotShipmentInd:boolean = undefined;
	/**
	 * Calculated any time event comes in, this will be recalculated. E.g. if one of the agenda list items 'NOT_MET'
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryDateTime:Date = undefined;
	/**
	 * Following will set the value: Final value is set when PRO arrives at dest sic.  Customer might ask for specific time (not TDC, not entered at billing). There might be other cases where this might be set.
	 * Required: false
	 * Array: false
	 */
	customerExpectedDeliveryDateTime:Date = undefined;
	/**
	 * After the shipment is final delivered. Same as in SHM_MOVEMENT (DLVRY QLFR=Z)
	 * Required: false
	 * Array: false
	 */
	actualDeliveryDateTime:Date = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * This will allow parallel running of current and new version.   Added 07/31/2020
 */
export class OperationsShipmentPilot {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Indicates the priority of the shipment. Initially set when shipment is created. High values indicate lower priority, lower values indicate higher priority. This will be recalculated when any property that determines priority is changed.
	 * Required: false
	 * Array: false
	 */
	priorityNbr:number = undefined;
	/**
	 * Calculated ETA by BI and updated; it is recalculated every 5 minutes.
	 * Required: false
	 * Array: false
	 */
	destinationSicEtaDateTime:Date = undefined;
	/**
	 * Calculated any time event comes in, this will be recalculated. E.g. if one of the agenda list items 'NOT_MET'
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryDateTime:Date = undefined;
	/**
	 * Following will set the value: Final value is set when PRO arrives at dest sic.  Customer might ask for specific time (not TDC, not entered at billing). There might be other cases where this might be set.
	 * Required: false
	 * Array: false
	 */
	customerExpectedDeliveryDateTime:Date = undefined;
	/**
	 * After the shipment is final delivered. Same as in SHM_MOVEMENT (DLVRY QLFR=Z)
	 * Required: false
	 * Array: false
	 */
	actualDeliveryDateTime:Date = undefined;
	/**
	 * Another way to set priority of a shipment; it is set by users through UI.
	 * Required: false
	 * Array: false
	 */
	hotShipmentInd:boolean = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PackageCdRef {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	aliasCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displayDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displayCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Remark {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name :  Type code
	 * 
	 * Desc : This code can be used to categorize the remarks.  
	 * 
	 * Valid Values : 
	 * 1 - Adhoc Hazardous Material Remarks
	 * 2 - Shipping Remarks
	 * 3 - Authority Line text Remarks
	 * 4 - Delivery Attachment Remarks
	 * 5 - Operational Freight Handling Remarks
	 * 6 - Final Delivery Date Correction Remarks
	 * 
	 * Source: New/Entered
	 * 
	 * Design Considerations : This can be used for subsetting purposes and for programatically pulling certain remarks to display and/or print.
	 * Required: false
	 * Array: false
	 */
	typeCd:ShipmentRemarkTypeCd = undefined;
	/**
	 * Expanded Name: Remark Text
	 * 
	 * Desc: Remark Text
	 * Required: false
	 * Array: false
	 */
	remark:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of Remark. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class RemarkSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:Remark = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:Remark = undefined;
}
/**
 * 
 */
export class RatingInformation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rulesetOwnerCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperAgreementId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperRulesetNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeAgreementId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeRulesetNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToAgreementId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToRulesetNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	defaultTariffsId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingTariffsId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rateBaseName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rateBaseVersNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tariffTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingCurrencyCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	applcblDiscountPercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualDsctPercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rateAuditorInitials:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rateOrRateAudtqName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rateQueTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classOverrideCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	multiClassInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rulesTariffsId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualFuelPriceAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fuelPriceEffDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fscRateUnitOfMeasure:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fscRateNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fscOverrideInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	specialRatingProgramId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	manualRatedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	couponCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	spotQuoteId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	excRate:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lnhlChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpLnhlChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpTotalChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalAccessorialAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpAccessorialAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalFscAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpFscAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalTaxAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpTaxAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalDscntAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpDscntAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratedAsWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpAmcInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	codAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ctsRevenueAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ctsRevenueAmountInUsd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalChargeAmountInUsd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceCurrencyCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpMaxChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpBaseChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpFactorNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpPersonMileRate:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpTrailerCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpSavingsAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	diLnhlChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	diFscAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reducedWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cctrfTotalChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cctrfLnhlChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cctrfFscAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cctrfDscntAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cctrfAmcInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingOriginStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingOriginZipCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingDestinationStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingDestinationZipCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalObiDscntAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingTariffsRbn:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnstyLnhChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnstyCalculatedClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnstySrcTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeToCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCisCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCustomerMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperZip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCisCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCustomerMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeZip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoCisCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoCustomerMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoCity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoZip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnstyTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	elsLengthNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	elsLengthUnitOfMeasure:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RuleOverride {
	/**
	 * Shipment identifier for which the rule was overridden.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Uniquely identifies multiple rule validation failures for a shipment.
	 * Required: false
	 * Array: false
	 */
	ruleOverrideSequenceNbr:string = undefined;
	/**
	 * Describes the type of shipment line that failed the rule validation. This identifies the shipment entity such as SHM_SR_NBR.SR = Supplemental Reference NumberCM = CommodityAC = Accessorial
	 * Required: false
	 * Array: false
	 */
	shmEntityTypeCd:RuleShmEntityTypeCd = undefined;
	/**
	 * Identifies the actual line item of the SHM ENTITY that failed rule validation. For example, if SRN stored in SHM_SR_NBR with SEQ_NBR=3 failed, then this will be recorded here.
	 * Required: false
	 * Array: false
	 */
	shmEntityTypeSequenceNbr:string = undefined;
	/**
	 * Idenfies the rule that failed validation. For example, the RULE_INST_ID in CBL_SRN_RULE.
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
	/**
	 * Identifies the failure reason as determined by the logic executing the rule. Valid Values:
	 * 1 SRNRQ= Required SRN not entered Entered 2 SRNFMT = SRN format does not match expected format as defined in the rule.
	 * Required: false
	 * Array: false
	 */
	failureReasonCd:RuleFailureReasonCd = undefined;
	/**
	 * User supplied reason, chosen from drop down, for ignoring/overriding a Rule. Valid values: 1 SRNNA = SRN required as per the rule ((CBL_SRN_RULE)) but not available on paper Bill of Lading. 2 SRNFMT = SRN Number on the BOL does not match with the format mask specified by the rule (CBL_SRN_RULE)
	 * Required: false
	 * Array: false
	 */
	overrideReasonCd:RuleOverrideReasonCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The error message describing the rule that failed
	 * Required: false
	 * Array: false
	 */
	errorDescription:string = undefined;
}
/**
 * 
 */
export class Shipment {
	/**
	 * Expanded Name : Instance identifier
	 * 
	 * Desc : System generated unique identifier (as per CBD standard).
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Type code 
	 * 
	 * Desc : Identifies the type of shipment.
	 * 
	 * 
	 * Valid Values:
	 * A = Normal Movement(NORM)
	 * B = Master Shipment(MSTR) 
	 * C = Segment of a Master shipment (MSEG)
	 * D = Revenue Bill for Split segments (PTLT)
	 * E = Partial Shipment Segment (PSEG)
	 * G = Claims overage Reporting Bill (OREP)
	 * H = Genral Claims Business Shipment (GCBZ)
	 * I =  Astray Freight Segment (MOVR)
	 * J = Company Business Shipment (COBZ) 
	 * K = Expedite
	 * 
	 * 
	 * Note:
	 * Overage Reporting Bill (OREP) is entered into the system just  to indicate to the claims system there is an overage shipment on dock. 
	 * 
	 * Astray freight(MOVR) is used to move freight to it s correct destination after it accidentally ended up somewhere else and its original PRO number is known.
	 * Required: false
	 * Array: false
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This number identifies a shipment. This is the identifier given to the customer as a reference.
	 * 
	 * The numbers are printed on labels for each service center.  Labels are put on the Bill-Of-Lading and on the individual pieces of freight for identification.
	 * 
	 * Internal PRO number format is Oppp-sssss-c where 'ppp' is the pro-prefix, 'ssss' is the pro-suffix and 'c' is the check digit (modulus 7). It is usually displayed in the format 'ppp-sssss-c'
	 * 
	 * An expedited shipment may not always have a real PRO number. It may be given a dummy number in the same format and should not be written out to MBF.
	 * 
	 * MBF: root MBPRO
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Bill Status Code
	 * 
	 * Desc : Current status of the Shipment from billing point of view. 
	 * 
	 * Valid Values:
	 * 1 - Unbilled 
	 * 2 - In FBES Suspense Queue
	 * 3 - Billed
	 * 4 - Rated
	 * 5 - Paid
	 * 6 - Cancelled
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	billStatusCd:BillStatusCd = undefined;
	/**
	 * Expanded Name :  Charge to code
	 * 
	 * Desc : Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. For BOTH bills, the split for each line item will be stored along with the line item in the commodity, ac_svc or the misc_line_item table in future. Currently the total PPD and total COLLECT charges are currently stored as separate line items in the misc_line_item entity.
	 * 
	 * Valid Values : 
	 * 	P    -  Prepaid - shipper responsible for all charges
	 * 	C    -  Collect - consignee responsible for all charges
	 * 	B    -  Both - shipper and consignee responsible 
	 * 								-  Valid only for Non-Revenue bills.
	 * 			
	 * Source: MBF : MBPRTPPD, MBPRTCOL
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Expanded Name : Original terminal SIC
	 * 
	 * Desc : SIC where the Shipment originates.  Usually associated with PRO prefix and can be overriden.
	 * 
	 * Source : MBF: root MBORGIN
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Destination terminal SIC
	 * 
	 * Desc : SIC that is scheduled to deliver the Shipment. 
	 * 
	 * Source : MBF: root MBDEST
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Current SIC
	 * 
	 * Desc : SIC where the Shipment currently is.  
	 * 
	 * This is a denormalization to avoid scanning all movements to find current location.
	 * 
	 * Source : MBF: root MBLOCX
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * Expanded Name : Source Code
	 * 
	 * Desc : Indicates the source or origination of the Pro.  This code is needed for ACOR processing to determine whether to roll back to the initial data.
	 * 
	 * Valid Values:
	 * 	1  - Web        (Pickup Request web page)
	 * 	2  - EDI        (set 204 or set 211)    
	 * 	3  - FTP        (file transfer)
	 * 	4  - FBES    (manual Bill Entry)
	 * 	5  - TCON or LOAD    (i.e. skeleton TCON or non-TCON LOAD)
	 * 	6  - Pickup Request
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	sourceCd:ShipmentSourceCd = undefined;
	/**
	 * Expanded Name : Pickup Date                       
	 * 																																																		
	 * Desc : Earliest available date that Shipment can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * Expanded Name : Ready Time                        
	 * 																																																		
	 * Desc : Earliest available time that freight can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	readyTime:string = undefined;
	/**
	 * Expanded Name : Close Time                      
	 * 																																																
	 * Desc : Latest available time that freight can be
	 * pickup up from a customer.                      
	 * 																																																
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	closeTime:string = undefined;
	/**
	 * Expanded Name : Total pieces count
	 * 
	 * Desc : Total pieces from all commodities in the shipment.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTPCS
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * Exapnded Name : Motorized piece count
	 * 
	 * Desc : Identifies the number of moves or lifts made with a forklift or a pallet jack to move freight without manual intervention by the driver. This count may be equal to or less than the number of pieces in the shipment.  This is used by the costing system.  
	 * 
	 * Source : MBF root -  MBMTRPCS
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesCount:number = undefined;
	/**
	 * Expanded Name : Total weight (in pounds)
	 * 
	 * Desc : Total weight of all the freight being moved as a unit.  If this is a  Mother  bill then this is the total of all of the  Daughter  bills. 
	 * 
	 * Any rating weight allowances or variations (AW, DW, RW, NW) are not included.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTWT
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Expanded Name : Default Tariff
	 * 
	 * Desc : This identifies the default qualifying tariff for the shipment. If the rating process does not identify any other tariffs for the customer from rate aids, this is used as the rating tariff. 
	 * 	
	 * Source : MBF root - MBTARIFF
	 * Required: false
	 * Array: false
	 */
	defaultTariffsId:string = undefined;
	/**
	 * Expanded Name : Total charges
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * Required: false
	 * Array: false
	 */
	totalChargeAmount:number = undefined;
	/**
	 * Expanded Name : Total charges in US dollars
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount in US dollars.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * 
	 * Design Notes: This field was added as a denorm to keep the total charges in USD. Normally this field is same as the tot charges field. However this field is differnt when the rating currncy is not USD.
	 * Required: false
	 * Array: false
	 */
	totalUsdAmount:number = undefined;
	/**
	 * Expanded Name: Split Indicator
	 * 
	 * Desc: Indicates whether the shipment has been split or not. 
	 * 
	 * Valid values: Y/N
	 * 
	 * Source: MBF MBCLAS2 -  This actually replaces the BILL CLASS 2 field in the MBF.
	 * Required: false
	 * Array: false
	 */
	splitInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous Materials indicator
	 * 	
	 * Desc : Indicates whether or not a shipment includes  any commodities with hazardous materials. It is a denormalized attribute for performance puposes. 
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Expanded Name : Freezable Indicator 
	 * 
	 * Desc : Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * 
	 * Ideally, it should be specified at the commodity level, but has been de-normalized for Shipment, since that is level at which it is currently captured
	 * 
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 * 
	 * Y - Can be damaged by freezing
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name : Signature service indicator
	 * 
	 * Desc : Indicates if anyone handling the freight is required to sign on a signature sheet. 
	 * 
	 * Source : MBF: root: MBSGSRVC (x 04  of MBFLAG9)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	signatureServiceInd:boolean = undefined;
	/**
	 * Expanded Name : Revenue Bill Indicator
	 * 
	 * Desc : Indicates whether the shipment will generate revenue for Con-way or not.
	 * 
	 * Valid Values : 
	 * 
	 * Y - generates revenue
	 * N - does not generate revenue
	 * Required: false
	 * Array: false
	 */
	revenueBillInd:boolean = undefined;
	/**
	 * Expanded Name : Manual rating indicator 
	 * 
	 * Desc : Specifies whether the shipment  in question needs to be manually rated or not. If required, it may be changed to a code indicating the reason as well for manually rating it. 
	 * 
	 * It is same as special instructions field on the FBES screen.
	 * 
	 * ==NOTE==
	 * When this indicator is set, it means the rates and charges must be manully reviewed before the bill is marked as RATED. Howerver the auto rating system is allowed to put rates and charges on the bill prior to putting it in a manual entry or manual audit queue.
	 * 
	 * This is different from the indicator AUTO_RATEABLE_IND which indicates whether the auto rating system is even allowed to put rates and charges on the bill.
	 * 
	 * Source : MBF: root: MBSCHDI (x 80  of MBFLAGB)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	manualRatingRequiredInd:boolean = undefined;
	/**
	 * Expanded Name : Audit Required Indicator
	 * 																		
	 * Desc : Used to determine if the pro requires auditing after rating. 
	 * 																	
	 * Valid Values :
	 * Y - Pro does require auditing
	 * N - Pro does not require auditing
	 * 					
	 * Source : MBF:  Root: MBFBAUDT (X 01  of MBFLAGI)
	 * Required: false
	 * Array: false
	 */
	auditInd:boolean = undefined;
	/**
	 * Expanded Name : Cash indicator
	 * 
	 * Desc : This indicates if cash was collected at the tim eof pick up or should be collected at the time of delivery.
	 * 
	 * This information is also printed on the delivery set to inform the driver to collect cash.
	 * 
	 * Source : MBF: root: MBCASH (x 10  of MBFLAG2)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	cashInd:boolean = undefined;
	/**
	 * Expanded Name : Cash collected code                    
	 * 																																																							
	 * Desc : A code indicating whether any cash was          
	 * collected at the time of pick up or delivery or not. Cash may be collected  regardless of whether it was required or not and if  so, the invoice may need to be stopped.                
	 * 																																																							
	 * It has been modelled as a code instead of an indicator 
	 * since it needs to be initialized to a value other than 
	 * NO.                                                    
	 * 																																																							
	 * Source : New                                           
	 * 																																																							
	 * Valid Values :                                         
	 * blank - Initial value                                  
	 * Y - Yes, cash was collected                            
	 * N - No cash collected on delivery
	 * Required: false
	 * Array: false
	 */
	cashCollectInd:boolean = undefined;
	/**
	 * Expanded Name : Government Bill of Lading indicator
	 * 
	 * Desc : Indicates whether this is a government shipment or not.
	 * 
	 * Source : MBF: root: MBGBLT (x 01  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	govtBolTrafficInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Indicator
	 * 
	 * Desc : Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 * 
	 * Values : Y/N
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Coupon Indicator
	 * 
	 * Desc : Indicates that a Coupon has been used to allow the shipment to participate in the Con-Way Guaranteed Service Program for free.
	 * 
	 * Values : Y/N
	 * 
	 * Source : BASE
	 * Required: false
	 * Array: false
	 */
	guaranteedCouponInd:boolean = undefined;
	/**
	 * Expanded Name : Purge blocked indicator
	 * 
	 * Desc : Indicates that the details cannot be purged. Usually set when there are inconsistencies for Master / MSEG or Part lot/PSEG  shipments. 
	 * 
	 * Valid values : Y/N
	 * 
	 * Source : MBF: root: MBPUBLK (x 10  of MBFLAG3)
	 * Required: false
	 * Array: false
	 */
	purgeBlockInd:boolean = undefined;
	/**
	 * Expanded Name: Last MOVR PRO Number 
	 * 
	 * Desc: The Pro number for the last MOVR PRO billed for this shipment.
	 * Required: false
	 * Array: false
	 */
	lastMoverPro:string = undefined;
	/**
	 * Expanded Name: Archive Indicator
	 * 
	 * Desc: Indicates whether the record for the PRO is an active one or one retrieved from the archive.
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Expanded Name: COD Indicator
	 * 
	 * Desc: Indictaes whether it is a COD shipment or not. It is a denormalized attribute for performance puposes. The actual COD information is in the MISC_LINE_ITEM entity.
	 * Required: false
	 * Array: false
	 */
	codInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Code 
	 * 
	 * Desc: This is the discount code that was applied to the shipment when it was rated. 
	 * 
	 * Source: MBF - MBRCCODE
	 * Required: false
	 * Array: false
	 */
	discountCd:string = undefined;
	/**
	 * Expanded Name :Movement Reporting SIC Code
	 * 
	 * Desc : SIC where the last movement event for the shipment  happened and reported.  This is a denormalized attribute from the MOVEMENT entity for performance purposes.
	 * 
	 * Source : MBF: root MBMOVE
	 * Required: false
	 * Array: false
	 */
	lastMoveRptgSicCd:string = undefined;
	/**
	 * Expanded Name : Movement Timestamp
	 * 
	 * Desc : Date and time, when the last movement for this shipment  occurred. This is a denormalized attribute from the MOVEMENT entity. 
	 * 
	 * Source: MBF - MBMVTM
	 * Required: false
	 * Array: false
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * Expanded Name : Movement status
	 * 
	 * Desc : The status of the latest movement for a Shipment from an operations point-of-view. This is a denormalized atribute from the MOVEMENT entity.
	 * 
	 * Source: MBF
	 * Name: Various
	 * 
	 * Valid Values : 
	 * Spaces - Not applicable
	 * 1 - On dock
	 * 2 - On trailer
	 * 3 - Out for delivery
	 * 4-  Interim Delivery
	 * 5 - Final Delivered
	 * 6 - Not Applicable
	 * 6 - No movements allowed
	 * 7 - Cancelled
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * Expanded Name : Delivery qualifier
	 * 
	 * Desc : Code representing results of a specific delivery activity. The values are the same as what used to be  Event that stopped service).
	 * 
	 * This has been denormalized from Movement for performance reasons and should always be kept in sync. 
	 * 
	 * Source : MBF: root: MBD1FLG / MBD2FLG
	 * 
	 * Valid Values :
	 * A= Over Shipment
	 * B=Trapped shipment/MBD1TRAP
	 * C=Hold for customs/MBD1CSTM
	 * D=Undelivered/MBD1UNDL
	 * E=Hold for appointment/MBD1APPT
	 * F=On spotted trailer/MBD1SPOT
	 * G=Cartage
	 * H=Attempted delivery/MBD1ATMP
	 * J=All short
	 * K=Partial short/MBD1PSRT
	 * L=Refused delivery/MBD1RFSD
	 * M=Damaged shipment/MBD1RDMG
	 * N=Suspense/MBD1SUSP     - Not Used
	 * O=Wait/MBD1WAIT              - Not Used
	 * P=Purge Blocked/MBD2PBLK
	 * R=Return Status/MBD2RTRN
	 * T=Transfer/MBD2TSFR
	 * Z=Final delivery/MBD1FINL
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * Expanded Name : Rating Currency Code
	 * 
	 * Desc: The rates and charges for the shipment were calculated using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	ratingCurrencyCd:RatingCurrencyCd = undefined;
	/**
	 * Expanded Name : Invoicing Currency Code
	 * 
	 * Desc:  PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * The total charges for the shipment should be displayed using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	invoicingCurrencyCd:InvoiceCurrencyCd = undefined;
	/**
	 * Expanded Name: Currency Converstion Factor
	 * 
	 * Desc: PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * Provides the currency conversion factor on the day of shipment pick up between the rating currency and invoicing currency, if they are different.
	 * Required: false
	 * Array: false
	 */
	currencyConversionFctr:number = undefined;
	/**
	 * Expanded Name : Required Delivery Date
	 * 
	 * Desc : Date the shipment is required to be delivered
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	requiredDeliveryDate:string = undefined;
	/**
	 * Expanded Name : Delivery Time Start (if any)
	 * 
	 * Desc : The start time of the window specified by the customer for delivering the shipment.
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	startDeliveryTime:string = undefined;
	/**
	 * Expanded Name : Delivery Time End (if any)
	 * 
	 * Desc : The end time of the window specified by the customer for delivering the shipment.
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	endDeliveryTime:string = undefined;
	/**
	 * Expanded Name : International manifest from (port) code
	 * 
	 * Desc : Generated by rating. Identifies port of entry code through which freight will enter the US from another country. This may be associated with the destination city or the customer s broker.
	 * 
	 * Source : MBF: root: MBFROMPT
	 * Required: false
	 * Array: false
	 */
	fromPortCd:string = undefined;
	/**
	 * Expanded Name : International  manifest to (port) code
	 * 
	 * Desc : Generated by rating: Identifies the port of entry through which freight will enter another country.  This is either associated with the destination or with the customer s broker.
	 * 
	 * Source : MBF: root: MBTOPORT
	 * Required: false
	 * Array: false
	 */
	toPortCd:string = undefined;
	/**
	 * Expanded Name : OBC pickup delivery route
	 * 
	 * Desc : Pickup delivery route code from CAD used for sorting to print delivery sets in this order.
	 * 
	 * Source : MBF: root: MBPDRTE
	 * Required: false
	 * Array: false
	 */
	obcPickupDlvRouteCd:string = undefined;
	/**
	 * Expanded Name : Customer Profile Instance Id
	 * 
	 * Desc : A unique, randomly-generated number that identifies a registered customer in the Con-Way Customer Profile Component.
	 * 
	 * Source : Customer Profile Component
	 * Required: false
	 * Array: false
	 */
	custProfitabilityInstId:number = undefined;
	/**
	 * Expanded Name :  Rating tariff
	 * 
	 * Desc : This identifies the actual tariff used to rate the shipment. 
	 * 
	 * Source : MBF root - MBRATTAR
	 * Required: false
	 * Array: false
	 */
	ratingTariffsId:string = undefined;
	/**
	 * Expanded Name : Rate auditor initials
	 * 
	 * Desc : Initials of the rating auditor who last audited the shipment for rates. 
	 * 
	 * Source : MBF: root: MBRTADID
	 * Required: false
	 * Array: false
	 */
	rateAuditorInitials:string = undefined;
	/**
	 * Expanded Name : Rate or rate audit queue name
	 * 
	 * Desc : The name of the rating (or the rate audit) queue used when the shipment was last rated / audited. 
	 * 
	 * Source : MBF: root: MBRATEQ
	 * Required: false
	 * Array: false
	 */
	rateOrRateAudtqNm:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	heaviestCommoditySequenceNo:string = undefined;
	/**
	 * Expanded name:  Service type code.
	 * 
	 * Description:  Defines the specific type of service provided for a shipment.
	 * 
	 * Values:  1 - Normal LTL, 2 - Con-Way Deferred Express.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Expanded name: Estimated Delivery Date
	 * 
	 * Description: Date that the shipment should be delivered based on standard lane days.
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * Expanded name: Freight Bill Delivery Set Print Count
	 * 
	 * Description:
	 * The number of times that a FBDS has been printed for this shipment.
	 * Required: false
	 * Array: false
	 */
	fbdsPrintCount:string = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * 
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * 
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * 
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded name: Delivery Information Required Code
	 * 
	 * Desc: This code indicates that there is a requirement to capture delivery information for this shipment because of customer special requirements.  This is usually an EDI customer requirement that must be met.  The name of the person that signed for delivery and the timestamp are also captured in this record.
	 * 
	 * Values: 
	 * R - delivery info is required 
	 * C - delivery informaiton has been captured  and exists with this record
	 * blank - No delivery information required for this shipment
	 * Required: false
	 * Array: false
	 */
	deliveryInformationRqdCd:DeliveryInfoRequiredCd = undefined;
	/**
	 * Expanded name: Delivery Signature Name
	 * 
	 * Desc: This is the name of the person that signed for the delivery of the freight.  This name is required by some customers and is passed along to them via EDI.  This name is captured if the DLVR_UNFO_RQD_CD is set.
	 * 
	 * Since a shipment can be delivered in parts, (part short, etc) there can be more than one signature.  This field will hold the name of the last delivery movement.  The previous signatures can be retrieved from shipment history records.
	 * Required: false
	 * Array: false
	 */
	deliverySignatureNm:string = undefined;
	/**
	 * Expanded name: Delivery Signature TimeStamp
	 * 
	 * Desc: This is the date and time recorded when the person named in DLVR_SIG_NM_TXT signed for the shipment.
	 * Required: false
	 * Array: false
	 */
	deliverySignatureDateTime:Date = undefined;
	/**
	 * Expanded Name : Standard Transit Days (in number of days)
	 * 
	 * Desc : The standard number of days it should take to move a shipment from origin to destination, as defined in the OSS_SVC_STDS_A/B tables. 
	 * 
	 * Source : OSS_SVC_STDS_A/B - TRNST_DAYS
	 * Required: false
	 * Array: false
	 */
	standardTransitDays:string = undefined;
	/**
	 * Expanded Name : Actual Transit Days (in number of days)
	 * 
	 * Desc : The number of days it actually takes to move a shipment from shipper to consignee.
	 * 
	 * Source : calculated by subtracting PKUP_DT from the date portion of the (LST_)MVMT_TMST of the qualifying delivery event / movement, adjusted appropriately for non-work days occuring between the two dates.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation  (SPOT, PSRT?, RDMG?, RFSD?, FINL).
	 * Required: false
	 * Array: false
	 */
	actualTransitDays:string = undefined;
	/**
	 * Expanded Name : Transit Movement Sequence Number
	 * 
	 * Desc : The sequence number identifying the movement (qualifying delivery event) for the calculation of ACTL_TRNST_DAYS.
	 * NOTE - VALUE CAN BE ZERO FOR PARTLOT (PTLT) PROS.
	 * 
	 * Source : SHM_MOVEMENT - MVMT_SEQ_NBR
	 * Required: false
	 * Array: false
	 */
	transitMovementSequenceNbr:string = undefined;
	/**
	 * Expanded Name : Warranty Indicator
	 * 
	 * Desc : Indicates whether the shipment is eligible for the money-back guarantee if moving between direct points under Tariff CNWY-599.
	 * 
	 * Valid Values : 
	 * 
	 * Y - eligible for warranty
	 * N - not eligible for warranty - default value
	 * Required: false
	 * Array: false
	 */
	warrantyInd:boolean = undefined;
	/**
	 * Expanded Name : Warranty Status Code
	 * 
	 * Desc : Identifies the warranty status of a shipment
	 * 
	 * For example, one value of this code would indicate that a warranty  claim has been filed  by the debtor if a shipment has failed service.
	 * 
	 * Valid Values:
	 * 0 - default value; This means either the shipment is not eligible (Warranty flag =  N ) or it is eligible and no one has filed a claim.
	 * 1 - The customer has filed a claim and it has not been audited.
	 * 2 - The customer has filed a claim and it was approved.
	 * 3 - The customer has filed a claim and it was denied.
	 * Required: false
	 * Array: false
	 */
	warrantyStatusCd:WarrantyStatusCd = undefined;
	/**
	 * Expanded name: Notification Code
	 * 
	 * Desc: Indicates whether or not an notification exists for this shipment.  If it exists, the details will exist in the SHM NOTFICATION table.  The notification is created in the SCO (Smart4) system.
	 * 
	 * Values:
	 * A - Appointment exists
	 * N - Notification exists
	 * O -Appointment Not Required  
	 * R- Reappointment candidate.  PSRT/RDMG Pro  could be reappointment for delivery
	 * 
	 * blank - Neither exist
	 * Required: false
	 * Array: false
	 */
	notificationCd:NotificationCd = undefined;
	/**
	 * Expanded Name: Absolute minimum charge indicator
	 * 
	 * Desc: This inidcates if the charge for this shipment is the absolute minimum charge.
	 * Required: false
	 * Array: false
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Percentage
	 * 
	 * Desc: This is the percentage discount applied to the freigt chrages and discountable accessorial charges of the shipment.
	 * Required: false
	 * Array: false
	 */
	discountPercentage:number = undefined;
	/**
	 * Expanded Name: Pricing Agreement Instance Id
	 * 
	 * Desc: This is the instance id of the pricing agreement that was used to rate the shipment.
	 * 
	 * ==NOTE== Link to BASE 5 PRC component
	 * Required: false
	 * Array: false
	 */
	priceAgreementId:number = undefined;
	/**
	 * Expanded Name: Rule set Number
	 * 
	 * Desc: This identifies the rule set of the pricing agreement that was used to rate the shipment.
	 * 
	 * ==NOTE== link to BASE 5 PRC component
	 * Required: false
	 * Array: false
	 */
	priceRulesetNbr:string = undefined;
	/**
	 * Expanded Name: Route prefix 
	 * 
	 * Desc: Identifies the last Route the shipment was put on.
	 * 
	 * ==NOTE== Link to SMART 4 SCO component
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * Expanded Name: Route Suffix
	 * 
	 * Desc: Identifies the last Route the shipment was put on.
	 * 
	 * ==NOTE== Link to SMART 4 SCO component
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * Expanded Name: Route Type Code
	 * 
	 * Desc: Identifies whether the shipment is currently on a  Route (either planning route or dispatched route) Staged (available) for Routing, or is Not on a route.
	 * 
	 * Valid Values
	 * 				- Not Routed
	 * R - Routed
	 * S - Staged
	 * Required: false
	 * Array: false
	 */
	routeTypeCd:RouteTypeCd = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * 
	 * Desc : The volume of the freight; derived from the dimensions captured by driver, or entered by a CSR or dock worker for an SL  (Shipper Load and Count), or from one of a variety of profiles. For details, see the description of CFT_PRFL_MTHD_CD.
	 * 
	 * 06/21/2016 Cathy Doucet modified description as per Ariel/Sunya.
	 * Required: false
	 * Array: false
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total Pallet Count
	 * 
	 * Desc: Total Number of Pallets in the Shipment
	 * Required: false
	 * Array: false
	 */
	totalPalletsCount:number = undefined;
	/**
	 * Expanded Name: Area instance Id
	 * 
	 * Desc: Each service center is divided into multiple geographic areas for delivery. This identifies the delivery area of destination service center in which this shipment s consignee is located.
	 * 
	 * Source: SMART 4 Requirement
	 * 
	 * == NOTE ==  Ties back to the geo-area stored in Oracle spatial database for each service center.
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * Expanded Name: Auto Rateable Indicator
	 * 
	 * Desc: This indicates whether the auto rating system is allowed to put rates and charges on the bill. This is irrespective of whether the bill needs to be manually reviewed or not before being marked RATED. 
	 * 
	 * Valid Values: 
	 * Y - Auto rating system may put rates and charges
	 * N - Auto Rating system should not out or change any rates and charges on the bill
	 * Required: false
	 * Array: false
	 */
	autoRateableInd:boolean = undefined;
	/**
	 * Expanded Name: Motorized-Move Pieces Known Indicator
	 * 
	 * Desc: This indicates whether MTRZD_PCS_CNT is known or applicable.. 
	 * 
	 * Valid Values: 
	 * Y - Yes, MTRZD_PCS_CNT is known/applicable
	 * N - No, MTRZD_PCS_CNT is NOT known/applicable
	 * 
	 * Source:  FBES
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesKnownInd:boolean = undefined;
	/**
	 * Expanded Name : Estimated Transit Days (in number of days)
	 * 
	 * Desc : The standard number of days it should take to move a shipment from origin to destination, as defined in the OSS_SVC_STDS_A/B tables, adjusted by +1 if LATE_TENDER_IND =  Y 
	 * 
	 * Source : STD_TRNST_DAYS +1, if LATE_TENDER_IND =  Y
	 * Required: false
	 * Array: false
	 */
	estimatedTransitDays:string = undefined;
	/**
	 * Expanded Name : Calculated Service Days (in number of days)
	 * 
	 * Desc : The number of days it takes to move a shipment from shipper until it reaches a service stopping event.
	 * 
	 * Source : calculated by subtracting PKUP_DT from the date portion of the (LST_)MVMT_TMST of the first service-stopping event / movement, adjusted appropriately for non-work days occuring between the two dates.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation (ie. stop service).
	 * Required: false
	 * Array: false
	 */
	calculatedServiceDays:string = undefined;
	/**
	 * Expanded Name : Calculated Service Timestamp
	 * 
	 * Desc : The timestamp of a service stopping event.
	 * 
	 * Source : (LST_)MVMT_TMST of the first service-stopping event / movement.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation (ie. stop service).
	 * Required: false
	 * Array: false
	 */
	calculatedServiceDateTime:Date = undefined;
	/**
	 * Expanded Name : Calculated Movement Sequence Number
	 * 
	 * Desc : The sequence number identifying the movement (service-stopping event) for the calculation of CALC_SVC_DAYS.
	 * NOTE - VALUE CAN BE ZERO FOR PARTLOT (PTLT) PROS.
	 * 
	 * Source : SHM_MOVEMENT - MVMT_SEQ_NBR
	 * Required: false
	 * Array: false
	 */
	calculatedMovementSequenceNbr:string = undefined;
	/**
	 * Expanded Name : Difference of Calculated Days
	 * 
	 * Desc : Difference between CALC_SVC_DAYS and EST_TRNST_DAYS.
	 * 
	 * Source : CALC_SVC_DAYS - EST_TRNST_DAYS.
	 * Required: false
	 * Array: false
	 */
	diffCalculatedDays:string = undefined;
	/**
	 * Expanded Name : Difference of Transit Days
	 * 
	 * Desc : Difference between ACTL_TRNST_DAYS and EST_TRNST_DAYS.
	 * 
	 * Source : ACTL_TRNST_DAYS - EST_TRNST_DAYS.
	 * Required: false
	 * Array: false
	 */
	diffTransitDays:string = undefined;
	/**
	 * Expanded Name : Late Tender Code
	 * 
	 * Desc : Indicates whether shipment could not be picked up in time (due to late tender by shipper) to make service.
	 * 
	 * Values: Y - Late Tender; blank or N - Not Late Tender
	 * 
	 * Source : Bill Entry/FBES; can be populated at Pick-up Request create time if a skeleton PRO is available; may be changed in Corrections
	 * Required: false
	 * Array: false
	 */
	lateTenderCd:LateTenderCd = undefined;
	/**
	 * Expanded Name : Shipment Service Status Code
	 * 
	 * Desc : Indicates whether shipment met service standards.
	 * 
	 * Values: Fail, Success, Exempt, Unknown
	 * 
	 * Source : Calculated
	 * Required: false
	 * Array: false
	 */
	shipmentServiceStatusCd:ServiceStatusCd = undefined;
	/**
	 * Expanded Name : Service Calculation Status Code
	 * 
	 * Desc : Indicates whether shipment service calculations have been performed.
	 * 
	 * Values:
	 * 			0 - Not Calculated Yet
	 * 			1 - Cannot Calculate (all variables not defined)
	 * 			? - Calculated from (service stopping event or appointment - replace '?' with DLVRY_QALFR_CD values)
	 * 
	 * Source : Calculated
	 * Required: false
	 * Array: false
	 */
	serviceCalculatedStatusCd:ServiceCalculationStatusCd = undefined;
	/**
	 * Expanded Name : Exemption Reason Code
	 * 
	 * Desc : Indicates why a shipment may be exempt from meeting service standards.
	 * 
	 * Values:
	 * 			blank - Not Exempt;
	 * 			any valid exemption reason code
	 * 
	 * Source : OPS_EXEMPT_SHPMT; shipment exemptions are created immediately upon full trailer and service center exemption events.
	 * Required: false
	 * Array: false
	 */
	exemptReasonCd:ExemptReasonCd = undefined;
	/**
	 * Expanded Name : Pickup Date Back-Date Indicator
	 * 
	 * Desc : Indicates whether a shipment pickup date was backdated during bill entry.
	 * 
	 * Values:
	 * 			Y - Shipment pickup date was backdated at bill entry
	 * 			N - Shipment pickup date = bill entry date
	 * 
	 * Source : Bill Entry/FBES.
	 * Required: false
	 * Array: false
	 */
	pickupBackdateInd:boolean = undefined;
	/**
	 * Expanded Name: FOOD OR POISON CODE
	 * 
	 * Desc; Indicates whether the shipment contains food or posion. Assumption: A shipment will never contain both.
	 * 
	 * Valid Values:
	 * 
	 * 1 - Neither
	 * 2 - Food
	 * 3 -  Poison
	 * Required: false
	 * Array: false
	 */
	foodPoisonCd:FoodPoisonCd = undefined;
	/**
	 * Expanded Name: Rating Override Code
	 * 
	 * Desc: Indicates if a rating override has been indicated during billing. For example to use a spot quote or lineal feet rating.
	 * 
	 * Valid Values:
	 * 1: Spot Quote Number Specified for rating
	 * 2: Lineal Feet Rating should be used
	 * Required: false
	 * Array: false
	 */
	ratingOverrideCd:string = undefined;
	/**
	 * Expanded Name : Pick-Up Time
	 * 
	 * Desc : Time when the shipment was picked up. This will be required for Late Tender shipments and optional for other shipments.
	 * 
	 * Source : Bill Entry/FBES; can be populated at Pick-up
	 * Request create time if a skeleton PRO is available;
	 * may be changed in Corrections
	 * Required: false
	 * Array: false
	 */
	pickupTime:string = undefined;
	/**
	 * Expanded Name : Service Start Date
	 * 
	 * Desc : Business date on which the service clock starts.  This value will be calculated automatically for all shipments, based on the values of PKUP_DT and LATE_TENDER_CD.  If a shipment is tendered late, the service start date will be set to the next business date after the pick-up date.
	 * 
	 * Source : Will be populated at pkup request create time if a skeleton PRO is available, during bill entry, through the late tender correction screen.
	 * Required: false
	 * Array: false
	 */
	serviceStartDate:string = undefined;
	/**
	 * Expanded Name: Inspected Indicator
	 * 
	 * Desc: Indicates whether the shipment has been inspected or not.
	 * Required: false
	 * Array: false
	 */
	inspectedInd:boolean = undefined;
	/**
	 * Expanded Name: Spot Quote ID
	 * 
	 * Desc: The Spot Quote ID that was entered during Billing. This may be different from the one that was used for rating.  For the latest Spot Quote ID that was used for raing, please look at the rating info record.
	 * Required: false
	 * Array: false
	 */
	spotQuoteId:number = undefined;
	/**
	 * Expanded Name: Shipper to Consignee Miles
	 * 
	 * Desc: This captures the mileage from shipper to consignee as calculated by the Milemaker package (Rand McNally)
	 * 
	 * Added by C.Doucet 05/06/09 as per Shibashis
	 * Required: false
	 * Array: false
	 */
	shipperToConsigneeMiles:number = undefined;
	/**
	 * Expanded name: Shipper loaded trailer code
	 * 
	 * Desc: Identifies whether a shipper loaded a trailer, and if so, where Con-way took possession of trailer. 
	 * 
	 * Values: 
	 * N Â Shipper did not load trailer (default)
	 * H Â Hook loaded; a trailer is loaded by shipper and picked up by Con-way at shipper s site 
	 * D Â Dock Drop; a trailer is loaded by shipper and dropped at Con-way dock by shipper
	 * Required: false
	 * Array: false
	 */
	shipperLoadedTrlrCd:ShipperLoadedTrailerCd = undefined;
	/**
	 * Expanded Name: Consignee unloaded trailer code
	 * 
	 * Desc: Identifies whether a consignee unloaded a trailer, and if so, where consignee took possession of trailer. 
	 * 
	 * Values:
	 * N Â Consignee did not unload trailer (default)
	 * D Â Dock Pickup; a loaded trailer is picked up at Con-way dock by consignee to be unloaded by consignee 
	 * S Â Spot loaded; a loaded trailer is left at the consignee site for consignee to unload
	 * Required: false
	 * Array: false
	 */
	consigneeUnloadTrlrCd:ConsigneeUnloadedTrailerCd = undefined;
	/**
	 * Expanded Name: Lineal Foot Total Number
	 * 
	 * Desc: This is the length in feet that a shipment will take up in a trailer.  This dimension is typically used when the freight is long and narrow and cannot have anything stacked on top of it (e.g. carpet rolls).  For this reason it takes a larger portion of the trailer volume than a shipment of the same weight that is packaged on pallets.  This value can then be used in the planning of trailer loads and to rate shipments that have these characteristics.
	 * Required: false
	 * Array: false
	 */
	linealFootTotalNbr:string = undefined;
	/**
	 * Expanded Name: Single Shipment Acquired Indicator                                                                                                                                                Desc:                                                                                                      This indicates whether this was the only Shipment that we acquired from the Customer at the time that we received this one.
	 * Values:
	 * Y Â Yes:  This was the only Shipment Acquired at the time that we received this one.
	 * N Â No: There were other Shipments Acquired at the time that we received this one.
	 * Required: false
	 * Array: false
	 */
	singleShipmentAcquiredInd:boolean = undefined;
	/**
	 * Desc: This code identifies the method in which Con-way received the freight from the customer.
	 * Current values are: 
	 * PU Â Regular Pickup:  This is when the driver goes to the customer dock and loads the Shipment on the trailer to return to the service center.
	 * HL Â Hook Loaded: This is when the driver goes to the customer site and pickup a loaded trailer that this Shipment was on.
	 * DD Â Dock Drop:  This is when the customer delivers the Shipment to a Con-way Service Center, so no driver went to the Customer site
	 * Required: false
	 * Array: false
	 */
	shipmentAcquiredTypeCd:ShipmentAcquiredTypeCd = undefined;
	/**
	 * Expanded Name : Loose pieces count
	 * 
	 * Desc : Total loose pieces from all commodities in the shipment.
	 * 
	 * Count of non Motor Move pieces associated with a shipment. This count plus Motor Moves defines the total number of pieces moving within the Con-way system for Operations productivity.  This does not necessarily balance with the total pieces or commodity pieces entered during bill entry.
	 * Required: false
	 * Array: false
	 */
	loosePiecesCount:number = undefined;
	/**
	 * Expanded Name: PUP_VOLUME_PERCENTAGE
	 * 
	 * Desc: This is the volume of the shipment expressed as the percentage of a full pup (28 feet long trailer).
	 * Required: false
	 * Array: false
	 */
	pupVolumePercentage:number = undefined;
	/**
	 * Desc: Instance id of the Pickup Request that is associated with this Shipment during the Confirm On-Board process.
	 * Required: false
	 * Array: false
	 */
	purInstId:number = undefined;
	/**
	 * Expanded Name: Bulk Liquid Indicator
	 * 
	 * Desc: Identifies the shipment as containing bulk liquid.  If volume is known, this applies to shipments >119 gallons.
	 * 
	 * Added 8/5/14 by Paul Bramhall for Tanker Endorsement Project.
	 * -------------------------------------------------------------
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cubicFeetProfileMthdCd:ShipmentVolumeMethodCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cubicFeetProfileTypeCd:ShipmentVolumeTypeCd = undefined;
	/**
	 * In the event a customer requires a shipment to travel from origin to destination 
	 * without the loading of any additional shipments on the trailer(s), the shipment is considered Exclusive Use.
	 * Required: false
	 * Array: false
	 */
	exclusiveUseInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates that this shipment is marked as a Mexico Door to Door shipment.
	 * Required: false
	 * Array: false
	 */
	mexicoDoorToDoorInd:boolean = undefined;
	/**
	 * Indicates that this shipment is time date critical and operations needs to call for a delivery appointment.
	 * Required: false
	 * Array: false
	 */
	callForAppointmentInd:boolean = undefined;
	/**
	 * The value of the freight as declared by the customer.
	 * Required: false
	 * Array: false
	 */
	declaredValueAmount:number = undefined;
	/**
	 * Indicates that the customer would like to insure the shipment due to excessive value of the freight.
	 * Required: false
	 * Array: false
	 */
	excessiveValueInd:boolean = undefined;
	/**
	 * Indicates whether the payment terms were flipped on a bill after biller submit, based on input shipment party data.
	 * Required: false
	 * Array: false
	 */
	debtorTermFlipInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	handlingUnit:HandlingUnit[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	remark:Remark[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	movement:Movement[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	managementRemark:ManagementRemark[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsShipment:OperationsShipment = undefined;
}
/**
 * Holds a snapshot of Shipment. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class ShipmentSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:Shipment = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:Shipment = undefined;
}
/**
 * 
 */
export class SuppRefNbr {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Type code:
	 * 
	 * Desc : A code describing what the Supplemental Reference is (for eg. PO# for Purchase Order number and GBL for Government Bill of Lading). These are entered by and have a meaning for the customer only. There is no list of valid values. 
	 * 
	 * Examples :
	 * 
	 * Purchase orders
	 * Shipper numbers
	 * GBL numbers
	 * SLI numbers 
	 * Bookclosing numbers
	 * 
	 * Source: E-commerce
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : Sequentially assigned number to preserve the order in which various supplemental reference numbers are to be displayed / printed for a commodity / shipment. 
	 * 
	 * Source : Generated
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Number text
	 * 
	 * Desc : This is the actual number provided by the customer (sometimes also known as COSTAR - (C)ustomer (O)wned (S)hipment and (T)racking numbers). This valid only when recording supplemental references for a shipment.
	 * Required: false
	 * Array: false
	 */
	refNbr:string = undefined;
	/**
	 * Expanded Name : Description
	 * 
	 * Desc : A textual description of what the Supplemental Reference number is (Purchase order number, shipper number, etc.)
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name : Commodity Sequence Number
	 * 
	 * Desc : Sequence number of the specific Commodity (if any) to which the supplemental reference number applies. Should be 0 if the number applies to the Shipment in general.
	 * Required: false
	 * Array: false
	 */
	commoditySequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Desc: Source of the Supplemental Reference Number.
	 * 
	 * Values:
	 * EDI
	 * WEB
	 * XML
	 * BIL - Billing
	 * COR - currently BE36 transaction (Aug.2011)
	 * Required: false
	 * Array: false
	 */
	sourceCd:string = undefined;
	/**
	 * Desc: Sender-ID of the EDI Customer.
	 * Required: false
	 * Array: false
	 */
	ediSenderId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	suppRefExtendedDetail:SuppRefExtendedDetail[] = undefined;
}
/**
 * Holds a snapshot of SuppRefNbr. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class SuppRefNbrSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:SuppRefNbr = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:SuppRefNbr = undefined;
}
/**
 * 
 */
export class SuppRefExtendedDetail {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name: Packaging Type Text
	 * 																																																							
	 * Desc : Type of packaging the commodity was originally    
	 * picked up in.                                          
	 * 																																																							
	 * Examples: Box, Skid, Case, Roll etc.                   
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 * Required: false
	 * Array: false
	 */
	packageType:string = undefined;
	/**
	 * Expanded Name : Unit of Measure                             
	 * 																																																												
	 * Desc : A code to identify the units in terms of which       
	 * the quantity is measured. For       
	 * eg. weight (in pounds) is the most common unit of           
	 * measure for commodities.                                    
	 * 																																																												
	 * Valid Values :                                              
	 * 1 - Weight (in pounds)                                      
	 * 2 - Yardage (for carpets)                                   
	 * 3 - Count
	 * Required: false
	 * Array: false
	 */
	unitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantify
	 * 
	 * Desc : Commodity line item quantify associated with this specific supplemental reference number.  Usually expressed in pounds (reference the UOM - Unit of Measure to verify).
	 * Required: false
	 * Array: false
	 */
	quantity:number = undefined;
	/**
	 * Expanded Name : Pieces Count
	 * 
	 * Desc : Commodity line pieces associated with this specific supplemental reference number.
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name: Pallet or Slip Indicator
	 * 																																																							
	 * Desc : Used on a VICS bill of lading to indicate that the freight at time of pickup was loaded on either a pallet or a slip sheet.
	 * 
	 * Valid Values:  Y/N
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 * Required: false
	 * Array: false
	 */
	palletOrSlipInd:boolean = undefined;
	/**
	 * Expanded Name: Additional Shipper Information
	 * 																																																							
	 * Desc : Contains additional comments supplied from the shipper, usually regarding how the freight should be handled.
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 * Required: false
	 * Array: false
	 */
	additionalShipperInformation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ShmNotification {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scoNtficnInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	categoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reschdReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryFromTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeRefNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryToTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callerRacfId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of ShmNotification. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class ShmNotificationSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:ShmNotification = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:ShmNotification = undefined;
}
/**
 * 
 */
export class TimeDateCritical {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded: Time Date Critical Source Code
	 * 
	 * Desc: Where the Time Date Critical information was entered.
	 * 
	 * Valid Values:
	 * BIL=Bill Entered
	 * PUR=Pick Up Request
	 * EDI=EDI
	 * WEB=Web
	 * COR=Corrections
	 * Required: false
	 * Array: false
	 */
	tdcSourceCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Date Type Code
	 * 
	 * Desc: What kind the dates on the TDC shipment is/are.  There are 3 main flavors of date on Time Date Critical shipments: Deliver on a date, Deliver by a date, or a Deliver sometime within a date range  
	 * 
	 * Valid Values:
	 * ON=On
	 * BY=By
	 * RNG=Range
	 * Required: false
	 * Array: false
	 */
	tdcDateTypeCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Date #1
	 * 
	 * Desc: There are 3 flavors of date for a TDC shipment.  This attribute captures the following flavor of date: ON, BY or FROM
	 * Required: false
	 * Array: false
	 */
	tdcDate1:string = undefined;
	/**
	 * Expanded: Time Date Critical Date #2
	 * 
	 * Desc: There are 3 flavors of date for a TDC shipment.  This attribute captures the TO date if the type is a date range.
	 * Required: false
	 * Array: false
	 */
	tdcDate2:string = undefined;
	/**
	 * Expanded: Time Date Critical Time Type
	 * 
	 * Desc: What kind the times on the TDC shipment is/are.  There are 4 main flavors of time for Time Date Critical shipments: Deliver at a time, Deliver before a time, Deliver after a time, or a Deliver sometime within a time range  
	 * 
	 * Valid Values:
	 * AT=At
	 * BEF=Before
	 * AFT=After
	 * RNG=Range
	 * Required: false
	 * Array: false
	 */
	tdcTimeTypeCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Time #1
	 * 
	 * Desc: There are 4 flavors of time for a TDC shipment.  This attribute captures the following flavor of time: AT, AFTER, BEFORE, FROM
	 * Required: false
	 * Array: false
	 */
	tdcTime1:string = undefined;
	/**
	 * Expanded: Time Date Critical Time #2
	 * 
	 * Desc: There are 4 flavors of time for a TDC shipment.  This attribute captures the TO time if the type is a time range.
	 * Required: false
	 * Array: false
	 */
	tdcTime2:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of TimeDateCritical. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class TimeDateCriticalSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:TimeDateCritical = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:TimeDateCritical = undefined;
}
/**
 * 
 */
export class XdockException {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedReleaseNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportingSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shortPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmgdPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	allShrtInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	comments:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoor:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of XdockException. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class XdockExceptionSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:XdockException = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:XdockException = undefined;
}
/**
 * 
 */
export class Rule {
	/**
	 * Desc: Unique identifier; generated
	 * Required: false
	 * Array: false
	 */
	ruleInstId:number = undefined;
	/**
	 * Desc: Descriptive Name of the rule
	 * Required: false
	 * Array: false
	 */
	ruleName:string = undefined;
	/**
	 * Desc: Description of what the rule does
	 * Required: false
	 * Array: false
	 */
	ruleDescription:string = undefined;
	/**
	 * Desc: Identifies a rule as being a precondition rule.  Precondition rules are used to determine which rules to run.
	 * Required: false
	 * Array: false
	 */
	preconditionInd:boolean = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * You can have multiple rules 
	 * 
	 * entered in the system that run from June-Aug, then Aug-Oct, and you want them both to 
	 * 
	 * be in the system but not be processed until the date being used for comparison is in 
	 * 
	 * that range.   Added 7/19/2019 as per Richard
	 * Required: false
	 * Array: false
	 */
	effectiveDateTime:Date = undefined;
	/**
	 * You can have multiple rules entered 
	 * 
	 * in the system that run from June-Aug, then Aug-Oct, and you want them both to be in 
	 * 
	 * the system but not be processed until the date being used for comparison is in that 
	 * 
	 * range.. Added 07/19/2019 as per Richard.
	 * Required: false
	 * Array: false
	 */
	expiryDateTime:Date = undefined;
	/**
	 * Allows a Rule to be in TEST mode 
	 * 
	 * before it can run fully. When the rule is ready to run, then it will be set to PROD.  
	 * 
	 * If a rule is needed to be turned off, it can be set to INACTIVE.  Added 07/19/2019 as 
	 * 
	 * per Richard.
	 * 
	 * VALID VALUES:
	 * TEST
	 * PROD
	 * INACTIVE
	 * Required: false
	 * Array: false
	 */
	activeTypeCd:RuleActiveTypeCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	ruleset:Ruleset[] = undefined;
}
/**
 * 
 */
export class Ruleset {
	/**
	 * Desc: Unique identifier; generated
	 * Required: false
	 * Array: false
	 */
	rulesetInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	priorityOrderNbr:number = undefined;
	/**
	 * Desc: Identifies the logical operator value.  
	 * 
	 * Valid Values
	 * AND 
	 * OR
	 * NOT
	 * Required: false
	 * Array: false
	 */
	logicalOperatorCd:RuleLogicalOperatorCd = undefined;
	/**
	 * Desc: The action that should be performed if the logical operator function is true.
	 * Required: false
	 * Array: false
	 */
	actionCallBack:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Descriptive Name of the ruleset.  Added 5/1/2019
	 * Required: false
	 * Array: false
	 */
	rulesetName:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	ruleOutcome:RuleOutcome[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	ruleExpression:RuleExpression[] = undefined;
}
/**
 * 
 */
export class RuleExpression {
	/**
	 * Generated unique identifier
	 * Required: false
	 * Array: false
	 */
	expressionInstId:number = undefined;
	/**
	 * Desc: Name of the attribute to be used in the logical expression.
	 * Required: false
	 * Array: false
	 */
	leftOperand:string = undefined;
	/**
	 * Desc: The operand to be used in the logical expression.  
	 * 
	 * Valid Values 
	 * EQUAL_TO
	 * NOT_EQUAL_TO
	 * GREATER_THAN
	 * GREATER_OR_EQUAL_TO
	 * LESS_THAN
	 * LESS_OR_EQUAL_TO
	 * Required: false
	 * Array: false
	 */
	operatorCd:RuleOperatorCd = undefined;
	/**
	 * Desc: The type of value of the attribute to be used in the logical expression.  
	 * 
	 * Valid Values
	 * NUMERIC
	 * CHAR
	 * Required: false
	 * Array: false
	 */
	valueTypeCd:RuleExpressionValueTypeCd = undefined;
	/**
	 * Desc: The value of the attribute to be used in the logical expression.
	 * Required: false
	 * Array: false
	 */
	value:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RuleOutcome {
	/**
	 * Desc: Unique identifier; generated
	 * Required: false
	 * Array: false
	 */
	outcomeInstId:number = undefined;
	/**
	 * Added as an additional part of PK so that Outcome name/value pairs can be grouped. 4/15/2019
	 * Required: false
	 * Array: false
	 */
	outcomeSeqNbr:number = undefined;
	/**
	 * Desc:
	 * Required: false
	 * Array: false
	 */
	outcomeName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outcomeValue:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class EventWait {
	/**
	 * Unique identifier of records in this table. Values populated from sequence
	 * Required: false
	 * Array: false
	 */
	waitInstanceId:number = undefined;
	/**
	 * Id of the ensemble that is being put on lock wait
	 * Required: false
	 * Array: false
	 */
	ensembleInstanceId:string = undefined;
	/**
	 * Name of the queue that was the source of this ensemble message
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * The timestamp of when this ensemble was first put on lock wait
	 * Required: false
	 * Array: false
	 */
	waitStartTmst:Date = undefined;
	/**
	 * The number of times this ensemble was put on lock wait
	 * Required: false
	 * Array: false
	 */
	waitCount:number = undefined;
	/**
	 * The duration of wait in milliseconds
	 * Required: false
	 * Array: false
	 */
	waitTime:number = undefined;
	/**
	 * The maximum wait time in milliseconds before the lock will be overridden
	 * Required: false
	 * Array: false
	 */
	maxWaitTime:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RetriggerEvent {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventRetriggerId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceTableName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	transactionId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Details of a symphony message
 */
export class SymphonyMessage {
	/**
	 * ID of the message
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Timestamp of when the message was enqueued
	 * Required: false
	 * Array: false
	 */
	enqueueTmst:Date = undefined;
	/**
	 * Number of time the message was retried
	 * Required: false
	 * Array: false
	 */
	retryCount:number = undefined;
	/**
	 * Message payload
	 * Required: false
	 * Array: false
	 */
	payload:string = undefined;
}
/**
 * Details about a message queue. E.g. Oracle Advanced Queue
 */
export class SymphonyQueue {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Maximum number of retries
	 * Required: false
	 * Array: false
	 */
	maxRetries:number = undefined;
	/**
	 * Delay for retries
	 * Required: false
	 * Array: false
	 */
	retryDelay:number = undefined;
	/**
	 * Indicates if enqueue is enabled
	 * Required: false
	 * Array: false
	 */
	enqueueEnabled:string = undefined;
	/**
	 * Indicates if dequeue is enabled
	 * Required: false
	 * Array: false
	 */
	dequeueEnabled:string = undefined;
	/**
	 * The retention time
	 * Required: false
	 * Array: false
	 */
	retention:string = undefined;
}
/**
 * Captures transaction events. API writes one record per transaction.  Added to EVT seed model  06/26/2020 
 */
export class TransactionEvent {
	/**
	 * PK. Unique ID of the transaction. For Oracle, use the transaction id available in the context. Populated by trigger.
	 * Required: false
	 * Array: false
	 */
	transactionId:string = undefined;
	/**
	 * Identifies the type of transaction. Could be method name
	 * Required: false
	 * Array: false
	 */
	transactionType:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * Number of seconds to hold event before publishing.  Added 09/21/2020  LISG-7498
	 * Required: false
	 * Array: false
	 */
	eventReleaseDelaySeconds:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	transactionDetails:TransactionEventDetail[] = undefined;
}
/**
 * Stores the snapshot of all the tables that were updated as part of the transaction. The snapshot is a json representation of all columns that were changed. The snapshot will be created by insert/update/delete triggers on the source table.   Added to EVT seed model  06/26/2020
 */
export class TransactionEventDetail {
	/**
	 * PK. Unique id for the snapshot record. Generated by Sequence.
	 * Required: false
	 * Array: false
	 */
	detailId:number = undefined;
	/**
	 * Unique ID of the transaction. For Oracle, use the transaction id available in the context. Populated by trigger.
	 * Required: false
	 * Array: false
	 */
	transactionId:string = undefined;
	/**
	 * Name of the table for which the snapshot is stored
	 * Required: false
	 * Array: false
	 */
	tableName:string = undefined;
	/**
	 * Json string containing the before and after values for all the columns that were changed by the transaction.
	 * Required: false
	 * Array: false
	 */
	snapshot:object = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Identifies the type of transaction. Could be method name
	 * Required: false
	 * Array: false
	 */
	transactionType:TransactionEventDetailTypeCd = undefined;
}
/**
 * 
 */
export class Accessorial {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	quantity:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	quantityUom:string = undefined;
	/**
	 * Description of the Accessorial
	 * Required: false
	 * Array: false
	 */
	accessorialDesc:string = undefined;
	/**
	 * Accessorial Amount
	 * Required: false
	 * Array: false
	 */
	chargeAmt:MonetaryAmount = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	accessorialCd:string = undefined;
}
/**
 * 
 */
export class Appointment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	apptTypeCd:string = undefined;
	/**
	 * A system generated sequence number to uniquely identify a nootification for a shipment
	 * Required: false
	 * Array: false
	 */
	seq:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	startDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	endDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callDateTime:Date = undefined;
	/**
	 * *** Needs Review
	 * Required: false
	 * Array: false
	 */
	apptContact:ContactInfo = undefined;
	/**
	 * This might be any special instructions for accessing customer dock
	 * Required: false
	 * Array: false
	 */
	apptNote:Comment = undefined;
	/**
	 * customer provided reference number for the appointment
	 * Required: false
	 * Array: false
	 */
	apptConfirmation:string = undefined;
	/**
	 * Reason for rescheduling the appt.
	 * Required: false
	 * Array: false
	 */
	rescheduleReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	apptStatus:Status = undefined;
	/**
	 * SIC that will delivery freight according to this appt.
	 * Required: false
	 * Array: false
	 */
	dlvrySIC:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	references:ReferenceNumber[] = undefined;
}
/**
 * 
 */
export class Bond {
	/**
	 * Expanded Name : Bond number  : This is the IT, TE or WDTE number (7,8 or a 9 digit number) on the in-bond document. It is used to identify the Shipment while it is in the customs warehouse.
	 * Required: false
	 * Array: false
	 */
	bondNbr:string = undefined;
	/**
	 * Desc : Name of the City where the customs inspection is to be made.
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Desc : State of the city where the customs inspection is to be made.
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
}
/**
 * 
 */
export class ChemicalCharacteristics {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chemProperShippingName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chemTechnicalName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chemTechnicalName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chemTechnicalName3:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classPrimary:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classSecondary:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classTertiary:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contentWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contentWeightUOM:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportableQuantityInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportQuantityWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportQuantityWeightUOM:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packagingGroupCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	specialProvision:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyGuideBookName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hotInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	residueInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	poisonousInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notOtherwiseSpecifiedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	limitedQuantityInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	toxicInhalationInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	passengerAircraftForbidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	commercialAircraftForbidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	massivePollutantInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	oilContainedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	temperatureUOM:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	flashPointTemperature:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyTemperature:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	controlTemperature:string = undefined;
}
/**
 * 
 */
export class CommodityBasicInfo {
	/**
	 * number of commodity items
	 * Required: false
	 * Array: false
	 */
	pieceCnt:number = undefined;
	/**
	 * Type of Package
	 * Required: false
	 * Array: false
	 */
	packageCode:string = undefined;
	/**
	 * total weight for package and item together
	 * Required: false
	 * Array: false
	 */
	grossWeight:Weight = undefined;
	/**
	 * weight of commodity without packaging
	 * Required: false
	 * Array: false
	 */
	tareWeight:Weight = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	volume:Volume = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Set to True if there is any Hazardous material
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
}
/**
 * 
 */
export class CommodityInfo {
	/**
	 * number of commodity items
	 * Required: false
	 * Array: false
	 */
	pieceCnt:number = undefined;
	/**
	 * packaging information
	 * Required: false
	 * Array: false
	 */
	packaging:Packaging = undefined;
	/**
	 * total weight for package and item together
	 * Required: false
	 * Array: false
	 */
	grossWeight:Weight = undefined;
	/**
	 * weight of commodity without packaging
	 * Required: false
	 * Array: false
	 */
	tareWeight:Weight = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	volume:Volume = undefined;
	/**
	 * Commodity description
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * Y - Can be damaged by freezing
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * Indicates whether the commodity contains hazardous materials
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInfo:HazardousCharacteristics = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chemicalCharacteristics:ChemicalCharacteristics = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyContactName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyContactPhone:Phone = undefined;
}
/**
 * ***Needs Review
 */
export class CommodityLine {
	/**
	 * Might be versions of a commodity line - original, corrected, as-rated
	 * Required: false
	 * Array: false
	 */
	cmdyLineTypeCd:string = undefined;
	/**
	 * sequence on shipment
	 * Required: false
	 * Array: false
	 */
	seq:number = undefined;
	/**
	 * ***Needs Review - will there be dimensions on commodities?
	 * Required: false
	 * Array: false
	 */
	dimensions:Dimensions = undefined;
	/**
	 * commodity and packing info including hazmat
	 * Required: false
	 * Array: false
	 */
	commodityInfo:CommodityInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	commoditySubLine:CommodityInfo[] = undefined;
	/**
	 * this will have the rate and charge information. if weight was changed based on rated as, then the new weight will be in quantity. The class will be reflected in asRatedNmfcClass
	 * Required: false
	 * Array: false
	 */
	charge:Charge = undefined;
	/**
	 * this is an FAK that has overridden the as entered class when rated
	 * Required: false
	 * Array: false
	 */
	asRatedNmfcClass:string = undefined;
	/**
	 * This is value declared by the shipper. This might have implications for the claims.
	 * Required: false
	 * Array: false
	 */
	declaredValue:MonetaryAmount = undefined;
	/**
	 * These are refenence numbers provided by the party on the bill (shipper) specific to this commodity line.
	 * Required: false
	 * Array: true
	 */
	suppRef:SupplementalReference[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	reference:ReferenceNumber[] = undefined;
}
/**
 * 
 */
export class CommodityRatingInfo {
	/**
	 * number of commodity items
	 * Required: false
	 * Array: false
	 */
	pieceCnt:number = undefined;
	/**
	 * Type of Package
	 * Required: false
	 * Array: false
	 */
	packageCode:string = undefined;
	/**
	 * total weight for package and item together
	 * Required: false
	 * Array: false
	 */
	grossWeight:Weight = undefined;
	/**
	 * weight of commodity without packaging
	 * Required: false
	 * Array: false
	 */
	tareWeight:Weight = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	charge:Charge = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	volume:Volume = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Set to True if there is any Hazardous material
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
}
/**
 * Reveals the criteria for the application of deficit rating
 */
export class DeficitRating {
	/**
	 * The freight class for this calculation. This will be eitther the actual class or if the customer has an FAK, it will be the FAK
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
	/**
	 * difference between the actual weight and the nearest weight break
	 * Required: false
	 * Array: false
	 */
	deficitWght:Weight = undefined;
	/**
	 * The weight used to rate the shipment
	 * Required: false
	 * Array: false
	 */
	asRatedWeight:Weight = undefined;
	/**
	 * The rate used to calculate the freight charges
	 * Required: false
	 * Array: false
	 */
	deficitRate:MonetaryAmount = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deficitAmt:MonetaryAmount = undefined;
}
/**
 * 
 */
export class FreightExceptions {
	/**
	 * sequence number of the movement for which this exception applies
	 * Required: false
	 * Array: false
	 */
	movementSeq:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exception:StatusException = undefined;
	/**
	 * Over Short and Damage
	 * Required: false
	 * Array: false
	 */
	osdPieces:number = undefined;
}
/**
 * 
 */
export class HazardousCharacteristics {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * UN/NA Hazardous codes
	 * Required: false
	 * Array: false
	 */
	hazardousCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
}
/**
 * 
 */
export class Lading {
	/**
	 * number of lading units (pallets, cartons, etc.) with the same set of dimensions
	 * Required: false
	 * Array: false
	 */
	pieceCnt:number = undefined;
	/**
	 * Describes the type of packaging. Barrell, Create, Pallet etc.
	 * Required: false
	 * Array: false
	 */
	packageInfo:Packaging = undefined;
	/**
	 * additional description if required
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	weight:Weight = undefined;
	/**
	 * This is the source of weight information - ltl.xpo or customer
	 * Required: false
	 * Array: false
	 */
	weightSrc:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	volume:Volume = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dimensions:Dimensions = undefined;
	/**
	 * pounds per cubic foot
	 * Required: false
	 * Array: false
	 */
	density:Density = undefined;
	/**
	 * reference to commodity lines, trackable serial numbers, packing slip number, etc.
	 * Required: false
	 * Array: true
	 */
	references:ReferenceNumber[] = undefined;
}
/**
 * The details about the lading unit with dimensions for all pieces of the same dimensions. Both dimensions originally entered by the driver and the inspector may be included. 
 */
export class LadingPieceDimensions {
	/**
	 * sequence on shipment
	 * Required: false
	 * Array: false
	 */
	seqNbr:number = undefined;
	/**
	 * The package code for this group of pieces (lading units)
	 * Required: false
	 * Array: false
	 */
	packageCd:string = undefined;
	/**
	 * number of  lading units
	 * Required: false
	 * Array: false
	 */
	pieceCnt:number = undefined;
	/**
	 * dimensions recorded by either inspector, driver or derived by footprint. These may not be populated depending on source of dimensions
	 * Required: false
	 * Array: false
	 */
	dimensions:Dimensions = undefined;
}
/**
 * A NMFTA description and encoding for a particular commodity used by the motor freight industry
 */
export class NmfcItem {
	/**
	 * Numeric identifier for the commodity(s) description.
	 * Required: false
	 * Array: false
	 */
	itemNbr:number = undefined;
	/**
	 * Identifies a particular commodity(s) and may include allowable packaging.
	 * Required: false
	 * Array: false
	 */
	itemDesc:string = undefined;
	/**
	 * Identifies the item group that this item is subordinate to. For example Abrasives group 1020 and 1060 are subordinates and reference back to 1010
	 * Required: false
	 * Array: false
	 */
	itemReferenceGroup:number = undefined;
	/**
	 * A numeric ranking (class) assigned to the commodity(s) described based on its transportation characteristics. There are 18 classes: 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500. (Also see item/rule 110 and 420) 
	 * When 0 is shown in the Class field, it means the commodity(s) is subject to rates and regulations of the individual carrier. 
	 * In some cases, it is also 0 when the sub items have different class codes
	 * When Exempt is shown in the Class field, it means the commodity(s) is not subject to economic regulation. 
	 * When NotTaken is shown in the Class field, it means the commodity(s) will not be accepted for shipment.
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
	/**
	 * All the sub-items that exist for this item. The sub, if used indicates that there is further refinement of the description for the commodity(s) embraced by this item. For example if there are two ranges that have different classes because of different densities - less than and greater than 12.
	 * Required: false
	 * Array: true
	 */
	itemSub:NmfcSubItem[] = undefined;
}
/**
 * A NMFTA description and encoding for a particular commodity used by the motor freight industry
 */
export class NmfcSubItem {
	/**
	 * The sub, if used indicates that there is further refinement of the description for the commodity(s) embraced by this item. For example if there are two ranges that have different classes. less than density per cubic foot of 12 and greater than 12
	 * Required: false
	 * Array: false
	 */
	itemSubNbr:number = undefined;
	/**
	 * Numeric identifier for the maing commodity(s) description.  This identifier appears as the first part of the commodity id - 
	 * For example 1060.1 describes a commodity that 4. Abrasive wheels, other than pulp grinding, may be shipped in Package 2105. Belts, abrasive, in boxes, subject to Item 170 and having a density in pounds per cubic foot of: Less than 12 and 1060.2 is the same for > 12
	 * Required: false
	 * Array: false
	 */
	parentItemNbr:string = undefined;
	/**
	 * Further describes the item for this sub item.
	 * Required: false
	 * Array: false
	 */
	subItemDesc:string = undefined;
	/**
	 * A numeric ranking (class) assigned to the commodity(s) described based on its transportation characteristics. There are 18 classes: 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500. (Also see item/rule 110 and 420) 
	 * When 0 is shown in the Class field, it means the commodity(s) is subject to rates and regulations of the individual carrier. 
	 * When Exempt is shown in the Class field, it means the commodity(s) is not subject to economic regulation. 
	 * When NotTaken is shown in the Class field, it means the commodity(s) will not be accepted for shipment.
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
}
/**
 * 
 */
export class Notification {
	/**
	 * identifies the reason the notification was necessary. valid values are                 1- Driver Collect   
	 * 2- NOA/DNC          
	 * 3- Construction Site
	 * 4- Residential      
	 * 5- Other
	 * Required: false
	 * Array: false
	 */
	notificationTypeCd:string = undefined;
	/**
	 * A system generated sequence number to uniquely identify a nootification for a shipment
	 * Required: false
	 * Array: false
	 */
	seq:number = undefined;
	/**
	 * date time when the notification was made. (typically a phone call)
	 * Required: false
	 * Array: false
	 */
	callDateTime:Date = undefined;
	/**
	 * This might be any special instructions for accessing customer dock
	 * Required: false
	 * Array: false
	 */
	comment:Comment = undefined;
	/**
	 * person who was contacted for the notification.
	 * Required: false
	 * Array: false
	 */
	contact:ContactInfo = undefined;
	/**
	 * Pending or Set
	 * Required: false
	 * Array: false
	 */
	notificationStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	references:ReferenceNumber[] = undefined;
}
/**
 * 
 */
export class Packaging {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageWeight:Weight = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageDimensions:Dimensions = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageVolume:Volume = undefined;
}
/**
 * The route name prefix and suffix.
 */
export class RouteName {
	/**
	 * The prefix of the route name, which is usually the name of the geographic area.
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * The suffix of the route name, which is usually user assigned and could be a number assigned to uniquely identify the route within the geographic area.
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
}
/**
 * Information about measurements for the pieces on a shipment.
 */
export class ShipmentDimension {
	/**
	 * The length measurement for the piece(s)
	 * Required: false
	 * Array: false
	 */
	length:number = undefined;
	/**
	 * The width measurement for the piece(s)
	 * Required: false
	 * Array: false
	 */
	width:number = undefined;
	/**
	 * The height measurement for the piece(s)
	 * Required: false
	 * Array: false
	 */
	height:number = undefined;
	/**
	 * The weight of the piece(s)
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
	/**
	 * The number of pieces for which the measurement and weight values apply.
	 * Required: false
	 * Array: false
	 */
	piecesCnt:number = undefined;
	/**
	 * Indicates whether the piece(s) can be stacked.
	 * Required: false
	 * Array: false
	 */
	stackableInd:boolean = undefined;
}
/**
 * 
 */
export class ShipmentParty {
	/**
	 * A sequential number used to identify a specifc shipment party in a database.
	 * Required: false
	 * Array: false
	 */
	shipmentPartySeq:number = undefined;
	/**
	 * An internal identifier to the customer that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	custInstID:string = undefined;
	/**
	 * A business key that identifies the customer that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	custMadCd:string = undefined;
	/**
	 * Identifies the type of shipment party that this is: (e.g. Shipper; Consignee; etc.  This will only be used in otherShipmentParties element
	 * Required: false
	 * Array: false
	 */
	shipmentPartyTypeCd:string = undefined;
	/**
	 * This is the name and address information that was provided by the customer directly.  This could be keyed from a paper BOL or provided via EDI or some other electronic means.  This is sometimes referred to as the 'as entered' address.
	 * Required: false
	 * Array: false
	 */
	shipmentPartyBOLAddress:Address = undefined;
	/**
	 * This is the shipment party location information that comes from the customer that the shipment party was matched to.
	 * Required: false
	 * Array: false
	 */
	shipmentPartyLocation:Location = undefined;
	/**
	 * The time stamp of the last time that this shipment party was matched to a customer.
	 * Required: false
	 * Array: false
	 */
	lastMatchedDateTime:Date = undefined;
	/**
	 * Indicates whether this shipment party is a debtor for this shipment.  Note that a 'bill-to' associated with a shipper/consignee is not a debtor.  They are actiing on behalf of the debtor.
	 * Required: false
	 * Array: false
	 */
	debtorInd:boolean = undefined;
	/**
	 * Indicates whether all shipments for this shipment party are to be billed a prepaid.
	 * Required: false
	 * Array: false
	 */
	allPrepaidInd:boolean = undefined;
	/**
	 * Indicates whether the address for this shipment party, to be used on an invoice, should be the one provided by the customer instead of the one on the customer that was matched.
	 * Required: false
	 * Array: false
	 */
	useAsEnteredInd:boolean = undefined;
	/**
	 * Indicates whether this shipment party requires a 'Proof of Delivery' document to be captured and provided with an invoice.
	 * Required: false
	 * Array: false
	 */
	proofOfDlvryRequiredInd:boolean = undefined;
	/**
	 * Indicates whether a 'Proof of Delivery' image has been recorded for this shipment party for this shipment.
	 * Required: false
	 * Array: false
	 */
	proofOfDlvryImagedInd:boolean = undefined;
	/**
	 * This iindicates that type of credit that this shipment party has.  Values: Credit; Non-credit; Bad Debt; A/R Control; Voluntary; Cash Only
	 * Required: false
	 * Array: false
	 */
	creditStatusCd:string = undefined;
	/**
	 * Defines the currency that this shipment party prefers using.
	 * Required: false
	 * Array: false
	 */
	preferredCurrency:string = undefined;
	/**
	 * This is an internal identifier to the customs broker that this shipment party uses for a shipment that will cross a border.
	 * Required: false
	 * Array: false
	 */
	brokerInstID:string = undefined;
	/**
	 * This is a business key that identifies the customs broker that this shipment party uses for a shipment that will cross a border.
	 * Required: false
	 * Array: false
	 */
	brokerBusinessKey:string = undefined;
	/**
	 * This is a code that defines the relationship that a bill-to shipment party has to the shipper and the consignee.  Values are: None; Shipper; Consignee; Both
	 * Required: false
	 * Array: false
	 */
	bil2RelationshipCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	suppRef:SupplementalReference[] = undefined;
	/**
	 * Match status from CIS matching. Valid Values :
	 * The following values ARE ACTIVE for Forward Sync:
	 * Spaces - Initial value, matching activity has not yet occurred. 
	 * '70' - Full Match 
	 * '60' - Un-matched (valid name / address but does not exist in CIS)
	 * The following values ARE NOT ACTIVE for Forward Sync:
	 * '15' - Possible matches found
	 * '30' - Invalid address (Bad US postal address as per Code-1)
	 * '50' - Blank bill2 - no bill-to was entered
	 * Required: false
	 * Array: false
	 */
	mchStatCd:string = undefined;
	/**
	 * Contains the initials of the Review Clerk who performed matching on a pro, or forced the MAD code on a pro to a different MAD using a Pro Override.
	 * Required: false
	 * Array: false
	 */
	mchInitTxt:string = undefined;
	/**
	 * Used to group customer oriented service reports and manifests for direct customer access via the online system.
	 * Required: false
	 * Array: false
	 */
	directCd:string = undefined;
	/**
	 * Flag to indicate whether the Bill-To is related to the Shipper.
	 * Required: false
	 * Array: false
	 */
	bil2RelToShipperInd:boolean = undefined;
	/**
	 * Flag to indicate whether the Bill-To is related to the Consignee.
	 * Required: false
	 * Array: false
	 */
	bil2RelToConsigneeInd:boolean = undefined;
	/**
	 * Contains the customer key to an uncoded customer in the customer component. These are pickup and delivery locations that have been a part of at least one shipment in the Con-way system. (Note that they are not currently stored in the P and D table in the customer component.) They do not have an assigned MAD code (uncoded) and are therefore identified and matched separately so that we know when we service the same physical location.
	 * Required: false
	 * Array: false
	 */
	alternateCustNbr:string = undefined;
	/**
	 * Phone information for the party on the shipment.
	 * Required: false
	 * Array: false
	 */
	phone:Phone = undefined;
}
/**
 * 
 */
export class ShipmentPartyBasic {
	/**
	 * An internal identifier to the customer that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	custInstID:string = undefined;
	/**
	 * A business key that identifies the customer that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	custMadCd:string = undefined;
	/**
	 * Identifies the type of shipment party that this is: (e.g. Shipper; Consignee; etc.  This will only be used in otherShipmentParties element
	 * Required: false
	 * Array: false
	 */
	shipmentPartyTypeCd:string = undefined;
	/**
	 * This is the name and address information that was provided by the customer directly.  This could be keyed from a paper BOL or provided via EDI or some other electronic means.  This is sometimes referred to as the 'as entered' address.
	 * Required: false
	 * Array: false
	 */
	shipmentPartyBOLAddress:Address = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	suppRef:SupplementalReference[] = undefined;
}
/**
 * A generic representation of an indicator for special treatment of a delivery/pickup.
 */
export class ShipmentSpecialServiceSummary {
	/**
	 * The types of special services.
	 * Required: false
	 * Array: false
	 */
	specialService:ShipmentSpecialServiceCd = undefined;
	/**
	 * A boolean value indicating if the indicator type is applicable.
	 * Required: false
	 * Array: false
	 */
	applicableInd:boolean = undefined;
}
/**
 * 
 */
export class Tender {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tenderOfferDateTime:Date = undefined;
	/**
	 * Expiration of tender offer
	 * Required: false
	 * Array: false
	 */
	tenderExpDateTime:Date = undefined;
	/**
	 * Tender response deadline
	 * Required: false
	 * Array: false
	 */
	tenderRespDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	earliestReqPkupDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latestReqPkupDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	earliestDlvryDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latestDlvryDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tenderResponseCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tenderRejectionReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tenderID:string = undefined;
}
/**
 * Elastic Search document for Shipment details
 */
export class ShipmentSearchRecord {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentOrLastSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperZipCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeZipCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToCity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToZipCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	suppRefNbrs:SuppRefNbr[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finalDeliveryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPcsCnt:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timeDateCritical:TimeDateCritical = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	exceptions:MovementException[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	apptRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	apptScheduledDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	apptContactName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	apptDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	apptTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	afterHoursPickupInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exceptionRemark:ManagementRemark = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hitTdcInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	codInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exclusiveUseInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	excessiveValueInd:boolean = undefined;
}
/**
 * Details for cash on delivery payments.
 */
export class CashOnDelivery {
	/**
	 * The description of the payment method.
	 * Required: false
	 * Array: false
	 */
	description1:string = undefined;
	/**
	 * The description of the COD amount.
	 * Required: false
	 * Array: false
	 */
	description2:string = undefined;
	/**
	 * The COD amount.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
}
/**
 * Information about Shipment Charges
 */
export class ChargeInfo {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalChargeAmt:MonetaryAmount = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalRevAmt:MonetaryAmount = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ctsRevAmt:MonetaryAmount = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalUsdAmt:MonetaryAmount = undefined;
	/**
	 * ISO 3 Letter Currency codes
	 * Required: false
	 * Array: false
	 */
	ratingCurrencyCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currencyConvFactor:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	minChrgInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cashInd:boolean = undefined;
	/**
	 * ISO 3 Letter Currency codes
	 * Required: false
	 * Array: false
	 */
	cashPrepaidText:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cashPrepaidAmt:MonetaryAmount = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cashCollInd:boolean = undefined;
	/**
	 * ISO 3 Letter Currency codes
	 * Required: false
	 * Array: false
	 */
	cashCollText:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cashCollAmt:MonetaryAmount = undefined;
}
/**
 * Details for a delivery collection instruction.
 */
export class DeliveryCollectionInstruction {
	/**
	 * The unique identifier for the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The COD amount that needs to be collected.
	 * Required: false
	 * Array: false
	 */
	codAmount:number = undefined;
	/**
	 * The freight charges amount that needs to be collected.
	 * Required: false
	 * Array: false
	 */
	freightChargesAmount:number = undefined;
	/**
	 * Indicates whether to only accept cash as payment.
	 * Required: false
	 * Array: false
	 */
	acceptCashOnlyInd:boolean = undefined;
	/**
	 * This code determines how money will be collected at delivery.  Permitted values: Both, Cod, Freight, and None.
	 * Required: false
	 * Array: false
	 */
	collectMoneyAtDeliveryCd:CollectMoneyAtDeliveryCd = undefined;
}
/**
 * Equipment details for the dispatch trip board.
 */
export class DispatchEquipment {
	/**
	 * The equipment prefix and suffix concatenated.
	 * Required: false
	 * Array: false
	 */
	equipmentNbr:string = undefined;
	/**
	 * The unique identifier for equipment.
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * The prefix component for the equipment.
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * The suffix component for the equipment.
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * The current SIC of the equipment.
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
}
/**
 * Shipment delivery instructions.
 */
export class DoNotHoldDeliveryLine {
	/**
	 * The first line showing the description.
	 * Required: false
	 * Array: false
	 */
	description1:string = undefined;
	/**
	 * The second line showing the description.
	 * Required: false
	 * Array: false
	 */
	description2:string = undefined;
}
/**
 * Document details for FBDS/Copy-Bill for a shipment
 */
export class FBDSDocument {
	/**
	 * The business identifier for the shipment.
	 * Required: true
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * The business identifier for the shipment that is related to the parent PRO.
	 * Required: false
	 * Array: false
	 */
	childProNbr:string = undefined;
	/**
	 * Identifies the type of shipment.
	 * Required: true
	 * Array: false
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * The formatted serial number for the shipment.
	 * Required: false
	 * Array: false
	 */
	shipperNbr:string = undefined;
	/**
	 * The equipment number associated with the shipment.
	 * Required: true
	 * Array: false
	 */
	equipmentNbr:string = undefined;
	/**
	 * The date on which the shipment is picked up.
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * The origin location for the shipment.
	 * Required: true
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * The destination location for the shipment.
	 * Required: true
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * The shipment's shipper information.
	 * Required: false
	 * Array: false
	 */
	shipper:AsMatchedParty = undefined;
	/**
	 * The shipment's consignee message.
	 * Required: false
	 * Array: false
	 */
	consigneeMessage:string = undefined;
	/**
	 * The shipment's consignee information.
	 * Required: false
	 * Array: false
	 */
	consignee:AsMatchedParty = undefined;
	/**
	 * The customer to whom the shipment is being billed.
	 * Required: false
	 * Array: false
	 */
	billTo:AsMatchedParty = undefined;
	/**
	 * The list of commodities for the shipment.
	 * Required: false
	 * Array: true
	 */
	commodities:Commodity[] = undefined;
	/**
	 * The list of accessorials for the shipment.
	 * Required: false
	 * Array: true
	 */
	accessorials:AccessorialService[] = undefined;
	/**
	 * The type of payment.
	 * Required: false
	 * Array: false
	 */
	paymentType:string = undefined;
	/**
	 * A summary containing descriptions of all items in the current shipment.
	 * Required: false
	 * Array: false
	 */
	finalItemsDescription:string = undefined;
	/**
	 * The In Bond description.
	 * Required: false
	 * Array: false
	 */
	inBondDescription:string = undefined;
	/**
	 * The Freezable text.
	 * Required: false
	 * Array: false
	 */
	freezableText:string = undefined;
	/**
	 * The Warranty text.
	 * Required: false
	 * Array: false
	 */
	warrantyText:string = undefined;
	/**
	 * The list of Customer Reference numbers.
	 * Required: false
	 * Array: true
	 */
	customerReferenceNbrs:string[] = undefined;
	/**
	 * The list of PO numbers.
	 * Required: false
	 * Array: true
	 */
	poNbrs:string[] = undefined;
	/**
	 * The list of other reference numbers.
	 * Required: false
	 * Array: true
	 */
	otherReferenceNbrs:string[] = undefined;
	/**
	 * The CTS revenue amount.
	 * Required: false
	 * Array: false
	 */
	ctsRevenueAmount:number = undefined;
	/**
	 * The list of PRO numbers of the child shipments of the current shipment.
	 * Required: false
	 * Array: true
	 */
	childProNbrs:string[] = undefined;
	/**
	 * The advanced carrier revenue amount.
	 * Required: false
	 * Array: false
	 */
	advancedRevenueAmount:number = undefined;
	/**
	 * The beyond carrier revenue amount.
	 * Required: false
	 * Array: false
	 */
	beyondRevenueAmount:number = undefined;
	/**
	 * The collector of custom message.
	 * Required: false
	 * Array: false
	 */
	collectorOfCustomMessage:string = undefined;
	/**
	 * The code associatd with the delivery route.
	 * Required: false
	 * Array: false
	 */
	deliveryRoute:string = undefined;
	/**
	 * The clearance bill text for the MOVR/astray freight segment shipment (BillClassCd = 'I').
	 * Required: false
	 * Array: false
	 */
	movrClearanceBillText:string = undefined;
	/**
	 * Clearance bill text of the remaining pieces for the MOVR/astray freight segment shipment (BillClassCd = 'I').
	 * Required: false
	 * Array: false
	 */
	movrClearancePiecesOutstandingText:string = undefined;
	/**
	 * Verification of Hazardous Material commodities for the MOVR/astray freight segment shipment (BillClassCd = 'I').
	 * Required: false
	 * Array: false
	 */
	movrClearanceBillHazMatText:string = undefined;
	/**
	 * The COD payment method for COD type miscellaneous line items.
	 * Required: false
	 * Array: false
	 */
	codPaymentMethod:string = undefined;
	/**
	 * The COD calculated amount for COD type miscellaneous line items.
	 * Required: false
	 * Array: false
	 */
	codAmountText:number = undefined;
	/**
	 * The total number of pieces for the shipment.
	 * Required: false
	 * Array: false
	 */
	totalPieces:number = undefined;
	/**
	 * The total charge amount on line one.
	 * Required: false
	 * Array: false
	 */
	totalChargeAmountTextLine1:string = undefined;
	/**
	 * The total charge amount on line two.
	 * Required: false
	 * Array: false
	 */
	totalChargeAmountTextLine2:string = undefined;
	/**
	 * The total weight of the shipment.
	 * Required: false
	 * Array: false
	 */
	totalWeight:number = undefined;
	/**
	 * The total charge amount for the shipment.
	 * Required: false
	 * Array: false
	 */
	totalAmount:string = undefined;
	/**
	 * Advance carrier (type code 1).
	 * Required: false
	 * Array: false
	 */
	advanceCarrier:AdvanceBeyondCarrier = undefined;
	/**
	 * The notification information for the current shipment.
	 * Required: false
	 * Array: false
	 */
	notification:ShipmentNotification = undefined;
	/**
	 * Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Estimated delivery date of the shipment.
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * Indicates credit status, if the driver needs to collect frieght amount.
	 * Required: false
	 * Array: false
	 */
	driverCollectDescription:string = undefined;
	/**
	 * Description of the discount provided.
	 * Required: false
	 * Array: false
	 */
	discountMsgDescription:string = undefined;
	/**
	 * FBDS Rates and Charges info.
	 * Required: false
	 * Array: false
	 */
	ratesAndCharges:RateAndCharge = undefined;
	/**
	 * Remarks for Shipment authority line text.
	 * Required: false
	 * Array: false
	 */
	authorityLineRemark:string = undefined;
	/**
	 * Remarks for Shipment delivery attachment.
	 * Required: false
	 * Array: false
	 */
	deliveryAttachmentRemark:string = undefined;
	/**
	 * Shipment biller initials.
	 * Required: false
	 * Array: false
	 */
	billerInitials:string = undefined;
	/**
	 * Remarks for Shipment by the Shipper.
	 * Required: false
	 * Array: false
	 */
	shipmentRemark:string = undefined;
	/**
	 * Remarks for HazMat items in the Shipment.
	 * Required: false
	 * Array: false
	 */
	hazmatRemark:string = undefined;
	/**
	 * Remarks for Shipment associated Canadian freight.
	 * Required: false
	 * Array: false
	 */
	canadianGoodsServicesRemark:string = undefined;
	/**
	 * Remarks for Shipment GST associated freight transported from/to Quebec.
	 * Required: false
	 * Array: false
	 */
	quebecGstRemark:string = undefined;
	/**
	 * Count of copies of FBDS document printed.
	 * Required: false
	 * Array: false
	 */
	nbrOfCopiesText:string = undefined;
	/**
	 * The list of handling units.
	 * Required: false
	 * Array: true
	 */
	handlingUnits:HandlingUnit[] = undefined;
	/**
	 * Indicates whether the shipment is tracked using handling units.
	 * Required: false
	 * Array: false
	 */
	trackWithHandlingUnitsInd:boolean = undefined;
	/**
	 * The code which represents results for a specific delivery activity.
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
}
/**
 * For updating PRO numbers with Reference Numbers
 */
export class ProSupplementalReference {
	/**
	 * The business identifier for a shipment which is given to the customer as a reference.
	 * Required: false
	 * Array: false
	 */
	pro:string = undefined;
	/**
	 * A code describing what the Supplemental Reference is (e.g. PO# for Purchase Order number and GBL).
	 * Required: false
	 * Array: false
	 */
	typeCode:string = undefined;
	/**
	 * The reference number provided by the customer.
	 * Required: false
	 * Array: false
	 */
	value:string = undefined;
}
/**
 * Information about FBDS rates and charges.
 */
export class RateAndCharge {
	/**
	 * Rates and charges information based on shipment weight.
	 * Required: false
	 * Array: false
	 */
	ratedAsWeight:RatedAsWeight = undefined;
	/**
	 * Reduced charge or discount details.
	 * Required: false
	 * Array: false
	 */
	reducedCharge:ReducedCharge = undefined;
	/**
	 * Details for Part collect, Part prepaid, Cash prepaid, and Freight charges.
	 * Required: false
	 * Array: true
	 */
	shipmentCharges:ShipmentCharge[] = undefined;
	/**
	 * Shipment delivery instructions.
	 * Required: false
	 * Array: false
	 */
	doNotHoldDeliveryLine:DoNotHoldDeliveryLine = undefined;
	/**
	 * Details of cash on delivery payments.
	 * Required: false
	 * Array: false
	 */
	cashOnDelivery:CashOnDelivery = undefined;
}
/**
 * Rates and charges information based on shipment weight.
 */
export class RatedAsWeight {
	/**
	 * The description for rates and charges based on shipment weight.
	 * Required: false
	 * Array: false
	 */
	ratedAsWeightDescription:string = undefined;
	/**
	 * The rated as weight quantity.
	 * Required: false
	 * Array: false
	 */
	quantity:number = undefined;
	/**
	 * The text for the tariff rate.
	 * Required: false
	 * Array: false
	 */
	tariffRateTxt:string = undefined;
	/**
	 * The rated as weight amount.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
}
/**
 * Info about Shipment Rating Info
 */
export class RatingInfo {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	linehaulChgAmt:MonetaryAmount = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalDiscAmt:MonetaryAmount = undefined;
	/**
	 * Density Linehaul Charge Amount
	 * Required: false
	 * Array: false
	 */
	densityLnhChgAmt:MonetaryAmount = undefined;
	/**
	 * Density Calculation Class Code
	 * Required: false
	 * Array: false
	 */
	densityCalcClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingTariffId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	spotQuoteId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	defaultTariffId:string = undefined;
	/**
	 * Density Calculation Class Code
	 * Required: false
	 * Array: false
	 */
	densitySourceTypeCd:string = undefined;
}
/**
 * The reduced charge or discount information.
 */
export class ReducedCharge {
	/**
	 * The description of the reduced charge.
	 * Required: false
	 * Array: false
	 */
	reducedChargeText:string = undefined;
	/**
	 * The reduced charge amount.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
}
/**
 * Details for Part collect, Part prepaid, Cash prepaid, and Freight charges.
 */
export class ShipmentCharge {
	/**
	 * The type of charge - Part collect, Part prepaid, Cash prepaid, or Freight charge.
	 * Required: false
	 * Array: false
	 */
	chargeType:string = undefined;
	/**
	 * The type of payment.
	 * Required: false
	 * Array: false
	 */
	paymentType:string = undefined;
	/**
	 * The description of the charge amount.
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * The Charge amount received.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
}
/**
 * contains the information of shipment and its children.
 */
export class ShipmentDetails {
	/**
	 * Required: false
	 * Array: false
	 */
	shipment:Shipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	accessorialService:AccessorialService[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	advanceBeyondCarrier:AdvanceBeyondCarrier[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	asMatchedParty:AsMatchedParty[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	commodity:Commodity[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customsBond:CustomsBond[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customsControl:CustomsControl[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	hazMat:HazMat[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	linehaulDimension:LnhDimension[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	managementRemark:ManagementRemark[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	miscLineItem:MiscLineItem[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	notifications:ShmNotification[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	operationsShipment:OperationsShipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	remark:Remark[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	suppRefNbr:SuppRefNbr[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	timeDateCritical:TimeDateCritical = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeInfo:ChargeInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingInfo:RatingInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prepaidDebtorParty:AcctId = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	collDebtorParty:AcctId = undefined;
	/**
	 * Contains Identifying information for a parent shipment.
	 * Required: false
	 * Array: false
	 */
	parentShipmentId:ShipmentId = undefined;
	/**
	 * Formatted PickupDate of the Shipment
	 * Required: false
	 * Array: false
	 */
	pickupDateText:string = undefined;
}
/**
 * Shipment event and trace history.
 */
export class ShipmentHistTraceEvent {
	/**
	 * The unique identifier for the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The timestamp for when the event occurred.
	 * Required: false
	 * Array: false
	 */
	occurredTmst:Date = undefined;
	/**
	 * The elapsed time between the previous and current events.
	 * Required: false
	 * Array: false
	 */
	elapsedTm:Date = undefined;
	/**
	 * The service center where the event occurred.
	 * Required: false
	 * Array: false
	 */
	occurredSic:string = undefined;
	/**
	 * The description of the event.
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * The origin SIC of the shipment.
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * The destination SIC of the shipment.
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * The load release number of the trailer the shipment is on during the event.
	 * Required: false
	 * Array: false
	 */
	loadReleaseNbr:string = undefined;
	/**
	 * The Destination SIC of the trailer the shipment was loaded on during the event.
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic:string = undefined;
	/**
	 * The trailer number the shipment was loaded on during the event.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * The weight of the trailer the shipment was loaded on during the event.
	 * Required: false
	 * Array: false
	 */
	trailerWeightLbs:number = undefined;
	/**
	 * The cube of the trailer the shipment was loaded on during the event.
	 * Required: false
	 * Array: false
	 */
	trailerCubePct:number = undefined;
	/**
	 * The bill count of shipments on the trailer the shipment was loaded on during the event.
	 * Required: false
	 * Array: false
	 */
	shipmentsOnTrailerCnt:number = undefined;
	/**
	 * The user that made the change associated with the event.
	 * Required: false
	 * Array: false
	 */
	updtBy:string = undefined;
	/**
	 * The service center where the update associated with the event occurred.
	 * Required: false
	 * Array: false
	 */
	updtAtSic:string = undefined;
}
/**
 * Notification information for a shipment.
 */
export class ShipmentNotification {
	/**
	 * The nofiication message for a shipment.
	 * Required: false
	 * Array: false
	 */
	message:string = undefined;
	/**
	 * The observation for the notification message.
	 * Required: false
	 * Array: false
	 */
	messageObservation:string = undefined;
	/**
	 * The custom FBDS document notification message.
	 * Required: false
	 * Array: false
	 */
	fbdsMessage:string = undefined;
	/**
	 * The date of the notification.
	 * Required: false
	 * Array: false
	 */
	notificationDate:string = undefined;
	/**
	 * The time of the notification.
	 * Required: false
	 * Array: false
	 */
	notificationTime:string = undefined;
	/**
	 * The contact name associated with the notification.
	 * Required: false
	 * Array: false
	 */
	contactName:string = undefined;
}
/**
 * Skeleton information about a shipment
 */
export class ShipmentSkeleton {
	/**
	 * The unique Identifier of the device making the request.
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * The version of the app used on the device.
	 * Required: false
	 * Array: false
	 */
	appVersion:string = undefined;
	/**
	 * The employee ID of the user that is requesting the information.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * SIC Code of the location where the dimensions were entered
	 * Required: false
	 * Array: false
	 */
	requestingSicCd:string = undefined;
	/**
	 * Hazmat details for the shipment
	 * Required: false
	 * Array: true
	 */
	hazmatGroups:HazMat[] = undefined;
	/**
	 * PRO number of the Shipment
	 * Required: false
	 * Array: false
	 */
	parentProNbr:string = undefined;
	/**
	 * 1 or more Handling Unit information for the Shipment
	 * Required: false
	 * Array: true
	 */
	handlingUnits:HandlingUnit[] = undefined;
	/**
	 * Identifies the shipment as containing bulk liquid.  If volume is known, this applies to shipments >119 gallons.
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * Total loose pieces from all commodities in the shipment.
	 * Count of non Motor Move pieces associated with a shipment. This count plus Motor Moves defines the total number of pieces moving within the Con-way system for Operations productivity.  This does not necessarily balance with the total pieces or commodity pieces entered during bill entry.
	 * Required: false
	 * Array: false
	 */
	loosePiecesCount:number = undefined;
	/**
	 * Indicates whether the shipment contains food or posion. Assumption: A shipment will never contain both.
	 * Valid Values:
	 * 1 - Neither
	 * 2 - Food
	 * 3 -  Poison
	 * Required: false
	 * Array: false
	 */
	foodPoisonCd:FoodPoisonCd = undefined;
	/**
	 * Identifies the number of moves or lifts made with a forklift or a pallet jack to move freight without manual intervention by the driver. This count may be equal to or less than the number of pieces in the shipment.  This is used by the costing system.  
	 * Source : MBF root -  MBMTRPCS
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesCount:number = undefined;
	/**
	 * Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 * Values : Y/N
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Shipment Destination Postal Code
	 * Required: false
	 * Array: false
	 */
	destPostalCd:string = undefined;
	/**
	 * Total weight of all the freight being moved as a unit.  If this is a  Mother  bill then this is the total of all of the  Daughter  bills. 
	 * Any rating weight allowances or variations (AW, DW, RW, NW) are not included.
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * Source : MBF: root: MBTOTWT
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Total pieces from all commodities in the shipment.
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * Source : MBF: root: MBTOTPCS
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * Indicates whether or not a shipment includes  any commodities with hazardous materials. It is a denormalized attribute for performance puposes. 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * Ideally, it should be specified at the commodity level, but has been de-normalized for Shipment, since that is level at which it is currently captured
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * Valid Values : Y/N
	 * Y - Can be damaged by freezing
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * The volume of the freight; derived from the dimensions captured by driver, or entered by a CSR or dock worker for an SL  (Shipper Load and Count), or from one of a variety of profiles. For details, see the description of CFT_PRFL_MTHD_CD.
	 * Required: false
	 * Array: false
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * Total Number of Pallets in the Shipment
	 * Required: false
	 * Array: false
	 */
	totalPalletsCount:number = undefined;
	/**
	 * This indicates whether MTRZD_PCS_CNT is known or applicable.. 
	 * Valid Values: 
	 * Y - Yes, MTRZD_PCS_CNT is known/applicable
	 * N - No, MTRZD_PCS_CNT is NOT known/applicable
	 * Source:  FBES
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesKnownInd:boolean = undefined;
	/**
	 * Date and time, when the last movement for this shipment  occurred. This is a denormalized attribute from the MOVEMENT entity. 
	 * Source: MBF - MBMVTM
	 * Required: false
	 * Array: false
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * Defines the specific type of service provided for a shipment.
	 * Values:  1 - Normal LTL, 2 - Con-Way Deferred Express.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:ServiceTypeCd = undefined;
}
/**
 * The shipments with their new list of linehaul dimensions.
 */
export class ShipmentWithDimension {
	/**
	 * Identifies the shipment that owns the linehaul dimensions.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * The list of shipment linehaul dimesion details that will be used to update the records.
	 * Required: false
	 * Array: true
	 */
	linehaulDimensions:LnhDimension[] = undefined;
}
/**
 * Response to the request to retrieve information about a Shipment.
 */
export class EvtShipmentSnapshot {
	/**
	 * Required: false
	 * Array: false
	 */
	xDockExceptionSnapshot:XdockExceptionSnapshot = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shmNotificationSnapshot:ShmNotificationSnapshot = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	timeDateCriticalSnapshot:TimeDateCriticalSnapshot = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	eventLogSnapshot:EventLogSnapshot = undefined;
}
/**
 * Payload used to start en ensemble via the API channel. Specify one of shipmentInstId or ProNbr and PickupDate
 */
export class StartShipmentEnsemblePayload {
	/**
	 * System generated unique identifier of a Shipment
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Business identifier to the shipment
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Date on which the shipment was picked up
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
}
/**
 * Path parameters used to retrieve the predicted true debtor for a pre-rated shipment
 */
export class GetPredictedTrueDebtorQuery {
	/**
	 * The customer ID of the shipper.
	 * Required: false
	 * Array: false
	 */
	shipperCustomerId:string = undefined;
	/**
	 * The ZIP or postal code of the consignee.
	 * Required: false
	 * Array: false
	 */
	consigneeZipCd:string = undefined;
}
/**
 * Response to the request to retrieve the predicted true debtor for a pre-rated shipment.
 */
export class GetPredictedTrueDebtorResp {
	/**
	 * The predicted true debtor for the pre-rate shipment.
	 * Required: false
	 * Array: false
	 */
	predictedTrueDebtor:PredictedTrueDebtor = undefined;
}
/**
 * Request for startShmApiChEnsemble
 */
export class StartShmApiChEnsembleRqst {
	/**
	 * The name of the ensemble to start
	 * Required: false
	 * Array: false
	 */
	ensembleName:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The payload for the ensemble process
	 * Required: false
	 * Array: false
	 */
	payload:StartShipmentEnsemblePayload = undefined;
}
/**
 * Path parameters for startShmApiChEnsemble
 */
export class StartShmApiChEnsemblePathParam {
}
/**
 * Response for startShmApiChEnsemble
 */
export class StartShmApiChEnsembleResp {
	/**
	 * Instance ID of the ensemble that was started.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class ShipmentSearchRecordMessage extends ShipmentSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	messageHeader:SearchRecordMessageHeader = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class EnsembleStateSearchRecordMessage extends EnsembleStateSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	messageHeader:SearchRecordMessageHeader = undefined;
}
/**
 * Contains basic key information needed to search for a customer. 
 */
export class ShipmentEventPayload extends PayloadType {
	/**
	 * Required: false
	 * Array: false
	 */
	shipment:Shipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	asMatchedParty:AsMatchedParty[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	commodity:Commodity[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	accessorialService:AccessorialService[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	suppRefNbr:SuppRefNbr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	remark:Remark[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	advanceBeyondCarrier:AdvanceBeyondCarrier[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customsControl:CustomsControl[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customsBond:CustomsBond[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	hazMat:HazMat[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	miscLineItem:MiscLineItem[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	timeDateCritical:TimeDateCritical = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeInfo:ChargeInfo = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ratingInfo:RatingInfo = undefined;
	/**
	 * Formatted PickupDate of the Shipment
	 * Required: false
	 * Array: false
	 */
	pickupDateText:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	shipmentSpecialServiceSummaries:ShipmentSpecialServiceSummary[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appointmentRequiredInd:boolean = undefined;
	/**
	 * List of Shipment Handling Units
	 * Required: false
	 * Array: true
	 */
	handlingUnits:HandlingUnit[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	operationsShipment:OperationsShipment = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	snapshot:EvtShipmentSnapshot = undefined;
}



