import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	ValidateSRNResp,
	ValidateSRNQuery,
	ListInvoiceAccuracyConstraintCodesResp,
	ListInvoiceAccuracyRequirementsResp,
	ListInvoiceAccuracyRequirementsQuery,
	ExecutePreRatingInvoiceAccuracyRulesRqst,
	ExecutePreRatingInvoiceAccuracyRulesResp,
	ExecutePostRatingInvoiceAccuracyRulesRqst,
	ExecutePostRatingInvoiceAccuracyRulesResp,
	GetInvoiceAccuracyRequirementResp,
	GetInvoiceAccuracyRequirementPath,
	CreateInvoiceAccuracyRequirementRqst,
	CreateInvoiceAccuracyRequirementResp,
	UpdateInvoiceAccuracyRequirementRqst,
	UpdateInvoiceAccuracyRequirementResp,
	UpdateInvoiceAccuracyRequirementPath,
	DeleteInvoiceAccuracyRequirementPath,
	DetermineInvoicePreferenceRqst,
	DetermineInvoicePreferenceResp,
	GetInvoicePreferenceResp,
	GetInvoicePreferencePath,
	ListInvoicePreferencesResp,
	ListInvoicePreferencesQuery,
	ListInvoicePreferencesWithAddressResp,
	ListInvoicePreferencesWithAddressPath,
	GetCustomerInvoicePreferenceResp,
	GetCustomerInvoicePreferencePath,
	CreateInvoicePreferenceRqst,
	CreateInvoicePreferenceResp,
	DeleteInvoicePreferencePath,
	UpdateInvoicePreferenceRqst,
	UpdateInvoicePreferenceResp,
	UpdateInvoicePreferencePath,
	UpsertCustomerInvoicePreferenceRqst,
	CreateInvoiceRqst,
	CreateInvoiceResp,
	UpdateInvoiceTransmitStatusRqst,
	UpdateInvoiceTransmitStatusResp,
	UpdateInvoiceDeliveryRqst,
	UpdateInvoiceDeliveryResp,
	UpdateInvoiceTransmissionDateRqst,
	UpdateInvoiceTransmissionDateResp,
	UpdateInvoicePostDeliveryStatusLogRqst,
	UpdateInvoicePostDeliveryStatusLogPath,
	UpsertInvoiceAppliedPaymentRqst,
	UpsertInvoiceAppliedPaymentResp,
	UpsertInvoiceAppliedPaymentPath,
	ListReadyForTransmitInvoicesResp,
	ListReadyForTransmitInvoicesQuery,
	BulkUpdateInvoiceTransmitStatusResp,
	ListInvoiceDeliverySendToByDeliveryModeResp,
	ListInvoiceDeliverySendToByDeliveryModeQuery,
	GetInvoiceDataDetailByHeaderIdResp,
	GetInvoiceDataDetailByHeaderIdPath,
	CreateInvoiceHoldRqst,
	CreateInvoiceHoldResp,
	ListInvoiceSummaryResp,
	ListInvoiceSummaryQuery,
	ArchiveInvoicePdfRqst,
	ArchiveInvoicePdfResp,
	ExecuteReadyForTransmissionRulesRqst,
	ExecuteReadyForTransmissionRulesResp,
	ExecuteReadyForTransmissionRulesPath,
	DeleteInvoicePath,
	ExecutePrefChangeCreateInvoiceRqst,
	ExecutePrefChangeCreateInvoiceResp,
	ListInvoiceDetailByProResp,
	ListInvoiceDetailByProPath,
	GetInvoiceDetailResp,
	GetInvoiceDetailQuery,
	GetInvoiceDetailPath,
	ListInvoiceTransmissionHistoryResp,
	ListInvoiceTransmissionHistoryQuery,
	ListInvoiceTransmissionHistoryPath,
	ReleaseHeldInvoicesRqst,
	ReleaseHeldInvoicesResp,
	ListOnHoldInvoicesByCustomerResp,
	ListOnHoldInvoicesByCustomerQuery,
	ListOnHoldInvoicesByCustomerPath,
	ResendInvoicesRqst,
	ResendInvoicesResp,
	SubmitResendInvoicesRqst,
	SubmitResendInvoicesResp
} from './api-invoice';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class InvoiceApiService extends BaseService {
	private static InvoiceApiEndpoint = 'invoiceApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service operation will validate if the Supp Ref Numbers are present on the Shipment. The service will first get the shipment details including the matched parties on the shipment and all the Supp Ref Numbers. For each of the matched party CBL_SRN_RULE table will be read and if there are SRN rules that exist for any matched party then those must be present in the Shipment record. If not found then SRN rule from CBL_SRN_RULE will be moved to the response missingSRN record and message will be set to Missing SRNs. If none of the matched party has any SRN rule or If all SRN rules match then message will be set to SRN Validated.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>Either Shipment Inst Id or PRO Number and pickup date is provided. At least one matched party on the shipment has SRN rules and the shipment has all the Supp Ref Numbers that match the customer SRN Rules.
	* <br/>Post-condition:
	* <br/>Set the response message to "SRN Validated", isValid = true and status code = 200
	* <br/>
	* <br/>Pre-condition:
	* <br/>Either Shipment Inst Id or PRO Number and pickup date is provided. Atleast one matched party on the shipment has SRN rules and the shipment is missing one or more SRN rules.
	* <br/>Post-condition:
	* <br/>Set the response message to "Missing SRNs", isValid = false and status code = 200 and missingSRN list will have SRN that are missing in the Shipment.   
	*/
	public validateSRN(
							   queryParams: ValidateSRNQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ValidateSRNResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accuracy-validations/srn'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all the invoice accuracy constraint codes. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>None
	*/
	public listInvoiceAccuracyConstraintCodes(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListInvoiceAccuracyConstraintCodesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accuracy-constraint-codes'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists existing invoice accuracy requirements and constraints for a given customer, group or category cd.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input customer, group or category cd are supplied and valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>Matching Invoice Accuracy Requirement records are returned.
	*/
	public listInvoiceAccuracyRequirements(
							   queryParams: ListInvoiceAccuracyRequirementsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListInvoiceAccuracyRequirementsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accuracy-requirements'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation performs First Invoice Accuracy rules associated with the imported customer and exports the rule results.
	* <br/>			
	* <br/>Pre-condition:
	* <br/>None
	* <br/>				
	* <br/>Post-conditions:
	* <br/>1. If the rules are executed sucessfully, the ouptut will have a status code = 201.
	* <br/>2. The output will also have the execution status of each rule that is applicable to the customer group.
	* <br/>		
	*/
	public executePreRatingInvoiceAccuracyRules(request: ExecutePreRatingInvoiceAccuracyRulesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ExecutePreRatingInvoiceAccuracyRulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/rules/invoice-accuracy/pre-rating'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation performs First Invoice Accuracy rules associated with the imported customer and exports the rule results.
	* <br/>			
	* <br/>Pre-condition:
	* <br/>None				
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the rules are executed sucessfully, the ouptut will have a status code = 201.
	* <br/>2. The output will also have the execution status of each rule that is applicable to the customer group.
	*/
	public executePostRatingInvoiceAccuracyRules(request: ExecutePostRatingInvoiceAccuracyRulesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ExecutePostRatingInvoiceAccuracyRulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/rules/invoice-accuracy/post-rating'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Retrieves an existing invoice accuracy requirement and constraints for the given instance id. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Invoice accuracy requirement exists for the input invoice accuracy requirement instance id.
	* <br/>
	* <br/>Post-conditions:
	* <br/>Matching invoice accuracy requirement and associated constraints are returned.
	*/
	public getInvoiceAccuracyRequirement(
							   pathParams: GetInvoiceAccuracyRequirementPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetInvoiceAccuracyRequirementResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accuracy-requirements/{invoiceAccuracyReqInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Create new invoice accuracy requirement and constraints based on input values. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Invoice accuracy requirement, and optionally constraints, are supplied as input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>A new invoice accuracy requirement and associated constraints are saved to the database. 
	*/
	public createInvoiceAccuracyRequirements(request: CreateInvoiceAccuracyRequirementRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateInvoiceAccuracyRequirementResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accuracy-requirements'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Updates an existing invoice accuracy requirement and constraints. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Existing invoice accuracy requirement and constraints.
	* <br/>
	* <br/>Post-conditions:
	* <br/>Updated invoice accuracy requirement and associated constraints are saved to the database. 
	*/
	public updateInvoiceAccuracyRequirement(request: UpdateInvoiceAccuracyRequirementRqst,
							   pathParams: UpdateInvoiceAccuracyRequirementPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateInvoiceAccuracyRequirementResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accuracy-requirements/{invoiceAccuracyReqInstId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Delete existing invoice accuracy requirement and constraints for the given instance id.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Invoice accuracy requirement exists for the input invoice accuracy requirement instance id.
	* <br/>
	* <br/>Post-conditions:
	* <br/>Matching invoice accuracy requirement and associated constraints are deleted.
	*/
	public deleteInvoiceAccuracyRequirement(
							   pathParams: DeleteInvoiceAccuracyRequirementPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/accuracy-requirements/{invoiceAccuracyReqInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	*  This service operation will determine the Invoice Preferences applicable for a given shipment. Shipment Id, Bill Type Code, Customer Number, Invoice Type Code and Customer Type code is required to derive the preferences. If the Invoice preference for input Customer Number is found for the Bill type and Invoice type then that preference will be returned. If there is no Invoice preference setup for the given Customer Number for input Invoice type and bill type then default invoice preferences will be returned.
	* <br/>		
	* <br/>		Pre Condition:
	* <br/>			Shipment Id is required along with Invoice Type Code, Bill Type Code and Customer Number. The Invoice Preferences exist for given combination
	* <br/>		Post Condition:
	* <br/>			The invoice preferences for the input Customer number, Invoice type code, and Bill type code is returned.
	* <br/>		Pre Condition:
	* <br/>			Shipment Id is required along with Invoice Type Code, Bill Type Code and Customer Number. The Invoice Preferences doesn't exist for given combination
	* <br/>		Post Condition:
	* <br/>			The default invoice preferences are returned.
	* <br/>		Pre Condition:
	* <br/>			Shipment Id is required along with Invoice Type Code, Bill Type Code and Customer Number. More than 1 Invoice transmission mode preference exists for the customer	
	* <br/>		Post Condition:
	* <br/>			The transmission mode preference is determined based on the Shipment Characteristics and additional constraints setup.
	* <br/>   
	*/
	public determineInvoicePreference(request: DetermineInvoicePreferenceRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DetermineInvoicePreferenceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preferences'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation returns details for one Invoice Preference Rule for a given invoicePreferenceId.
	* <br/>
	* <br/>Pre-condition: invoicePreferenceId is supplied and is valid.
	* <br/>
	* <br/>Post-condition: Matching Invoice Preference Rule details are returned for the input ID.
	*/
	public getInvoicePreference(
							   pathParams: GetInvoicePreferencePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetInvoicePreferenceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-preferences/{invoicePreferenceId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all the invoice preferences for the customer account number. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. Lists all the invoice preferences for the customer account ID if valid and found.
	* <br/>2. Otherwise it returns the appropriate error message.
	*/
	public listInvoicePreferences(
							   queryParams: ListInvoicePreferencesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListInvoicePreferencesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-preferences'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves invoice preferences for the input customer number and any corresponding invoice address for each invoice preference.
	* <br/>Pre-condition: Input customer number is supplied and valid.
	* <br/>Post-condition: CIS Invoice Preferences for the input customer along with any corresponding invoice address for each invoice preference is returned.
	*/
	public listInvoicePreferencesWithAddress(
							   pathParams: ListInvoicePreferencesWithAddressPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListInvoicePreferencesWithAddressResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-preference-addresses/{customerNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns customer level invoice preference details for the input customer number.
	* <br/>
	* <br/>Pre-condition:
	* <br/>Customer number is supplied and valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>Customer level invoice preference details are returned.
	*/
	public getCustomerInvoicePreference(
							   pathParams: GetCustomerInvoicePreferencePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCustomerInvoicePreferenceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-preferences/customer-preferences/{cisCustomerNumber}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates a new invoice preference with preferred transmission mode and constraints. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>A new invoice preference with preferred transmission mode and associated constraints are saved to the database. 
	*/
	public createInvoicePreference(request: CreateInvoicePreferenceRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateInvoicePreferenceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-preferences'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Deletes an invoice preference with preferred transmission mode and constraints. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Existing invoice preference, transmission mode, and constraints.
	* <br/>
	* <br/>Post-conditions:
	* <br/>A new invoice preference with preferred transmission mode and associated constraints are deleted from the database. 
	*/
	public deleteInvoicePreference(
							   pathParams: DeleteInvoicePreferencePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-preferences/{invoicePreferenceId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Updates an invoice preference with preferred transmission mode and constraints. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Existing invoice preference, transmission mode, and constraints.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. An updated invoice preference, preferred transmission mode and constraints are saved to the database. 
	*/
	public updateInvoicePreference(request: UpdateInvoicePreferenceRqst,
							   pathParams: UpdateInvoicePreferencePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateInvoicePreferenceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-preferences/{invoicePreferenceId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation maintains the customer level invoice preference details and may add/update invoice preferences at the invoice type/bill type level for the input customer, based on the request.
	* <br/>For the input customerInvoicePreference data,
	* <br/>IF there are no existing INV_PREFERENCE rows for the input cisCustomerNumber
	* <br/>
	* <br/>    create the following default rows in the INV_PREFERENCE table for each combination of Invoice Type Cd and Bill Type Cd, AND apply the input Hold Ind and Hold Days to each of the rows:
	* <br/>    Prepaid, Original
	* <br/>    Prepaid, Corrected
	* <br/>    Prepaid, Balance Due
	* <br/>    Collect, Original
	* <br/>    Collect, Corrected
	* <br/>    Collect, Balance Due
	* <br/>    FOR EACH default INV_PREFERENCE row, create a related default INV_PREFERENCE_TRANS_MODE that has INVC_MODE_CD of Paper and PRESENTATION_FMT_CD of Individual.
	* <br/>
	* <br/>ELSE (there are one or more existing INV_PREFERENCE rows that are already setup for the input customer)
	* <br/>
	* <br/>    Determine which types of rows exist by Invoice Type Cd and Bill Type Cd and which rows are missing from the full set of 6 possible
	* <br/>    For each of the existing Invoice Type Cd, Bill Type Cd rows, update those rows with the Hold Ind and Hold Days values from the input data
	* <br/>    For each of the missing Invoice Type Cd, Bill Type Cd rows, insert the missing default row into INV_PREFERENCE as described above, with the Hold Ind and Hold Days values from the input data
	* <br/>    For each of the inserted missing INV_PREFERENCE default rows, insert a related default INV_PREFERENCE_TRANS_MODE that has INVC_MODE_CD of Paper and PRESENTATION_FMT_CD of Individual.
	* <br/>
	*/
	public upsertCustomerInvoicePreference(request: UpsertCustomerInvoicePreferenceRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-preferences/customer-preferences'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service will create either an Original, Corrected, or Bal-Due Invoice depending upon the shipment information.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>A new invoice will be created. 
	*/
	public createInvoice(request: CreateInvoiceRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateInvoiceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will update the Invoice transmit status based on certain rules. It is assumed that for newly created Invoice data the Invoice transmit status is 'N' (not ready for transmit). The invoice transmit status will be updated to 'T'(Ready for transmit) or 'P'(No need to transmit) based on the following rules:
	* <br/>		1. If the Invoice is on hold and not yet released then Invoice Transmit Status code will be 'N'.
	* <br/>		2. If the Invoice amount greater than 0 and less than 5 USD then Invoice transmit status code will be set to 'P'.
	* <br/>		3. If Invoice pickupDate + 7 days is less than Today's date then Invoice transmit status code will be set to 'T'. 
	* <br/>		4. If Today's date is less than 7 days then 
	* <br/>			4.a. If Pending Corrections then set the Invoice transmit status code to 'N' and add Invoice Not Ready Transmit Reason code to 'PEND-CORR'
	* <br/>			4.b. If no Pending Corrections and not Final Delivered then set the Invoice transmit status code to 'N' and add Invoice Not Ready Transmit Reason code to 'NOT-DLVR'
	* <br/>			4.c. If no Pending Corrections and If DR attachment is required but DR image not available then set the Invoice transmit status code to 'N' and Invoice Not Ready Transmit Reason Code to 'MISS-DR'
	* <br/>			4. d. If none of the above conditions are satisfied then Invoice is ready for Transmission. Set the Invoice Transmit Status code = 'T'
	* <br/>	Pre-conditions:
	* <br/>At minimum the Shipment Inst Id is input. Other optional fields are: finalDeliveryDate, isDRScanned, isPendingCorrection, pendingCorrChecked, proNbr, isReleaseFromHold
	* <br/>
	* <br/>Post-conditions:
	* <br/>Updates the Invoice Transmit Status code based on the applicable rules.
	* <br/>
	*/
	public updateInvoiceTransmitStatus(request: UpdateInvoiceTransmitStatusRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateInvoiceTransmitStatusResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/transmitstatus'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service will update and return the Invoice Delivery record for the invoiceDeliveryId provided.  However, if a proNbr is provided, then the most recent invoice header will be read and joined with INV_INVOICE_DLVRY.  An optional invoice delivery mode may be provided.
	* <br/>
	* <br/>1) invoiceDeliveryId is provided
	* <br/>Pre-conditions:
	* <br/>The invoiceDeliveryId is input along with the sentInd (Y or N), sentDateTime, sentFileName, transmissionErrCd, and transmissionErrDescr.
	* <br/>
	* <br/>InvoiceDeliveryId is input along with invoiceDeliveryEdiStatus.statusCd and sentDateTime.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the invoiceDeliveryId is found, the Invoice Delivery record is updated based on the input data.
	* <br/>2. If not found, a 404 Not Found is returned.
	* <br/>
	* <br/>2) proNbr is provided
	* <br/>Pre-conditions:
	* <br/>1. A valid PRO number is provided for which the Invoice exists in the Database. Optional - delivery mode is not provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the deliveryModeCd isn't null, the deliveryModeCd input matches the database record, and the sentInd is equal to 'Y' in the database, then all the Invoice Delivery data is returned for the input PRO. If there is more than 1 delivery record, then all records are returned.
	* <br/>2. If the deliveryId was provided, then all of the fields that are passes in the request will be updated in the record.  
	* <br/>    If invoiceDeliveryId is input along with invoiceDeliveryEdiStatus.sentDateTime and statusCd then a new row will be inserted for input deliveryId and sentDateTime
	* <br/>3. Otherwise a status code = 404 - Invoice Delivery Not found -- message is returned.
	*/
	public updateInvoiceDelivery(request: UpdateInvoiceDeliveryRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateInvoiceDeliveryResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/delivery'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service will update the Invoice transmission date for an Invoice that is Ready for Transmission.
	* <br/>	Pre-conditions:
	* <br/>   Shipment Instance Id and scheduledTransmssionDate are required. The scheduledTransmissionDate can not be in the past.
	* <br/>
	* <br/>Post-conditions:
	* <br/>Updates the Invoice Transmission Date
	* <br/>
	*/
	public updateInvoiceTransmissionDate(request: UpdateInvoiceTransmissionDateRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateInvoiceTransmissionDateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/scheduled-transmission-date'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service will insert a new row into the InvoicePostDeliveryStatusLog table with the input StatusCd and StatusDateTime
	* <br/>1) invoiceDeliveryId is provided
	* <br/>
	* <br/>Pre-conditions:
	* <br/>The invoiceDeliveryId is input along with the status Date time and status.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the invoiceDeliveryId is found, the Invoice Post Delivery status record is inserted based on the input data. Status Code of 200 is returned.
	* <br/>2. If not found, a 404 Not Found is returned.
	*/
	public updateInvoicePostDeliveryStatusLog(request: UpdateInvoicePostDeliveryStatusLogRqst,
							   pathParams: UpdateInvoicePostDeliveryStatusLogPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/delivery/{invoiceDeliveryId}/status-log'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service will either update or insert the Payments applied to Invoice. 
	* <br/>If the applied payment amount is less than the Invoice amount then this will trigger the creation of a Balance Due Invoice.
	* <br/>		
	* <br/>
	*/
	public upsertInvoiceAppliedPayment(request: UpsertInvoiceAppliedPaymentRqst,
							   pathParams: UpsertInvoiceAppliedPaymentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertInvoiceAppliedPaymentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/{shipmentInstId}/payments'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service operation will return a list of Invoices that are ready to be transmitted (INV_DATA_HEADER.TRANSMIT_STAT_CD = 'T') and the Invoice Delivery sent Ind = N. The optional input filter parameters include: deliveryModeCd (EDI, PAPER, EMAIL etc) and/or eipAttachmentFormatCd (PDF, PDFIMG,CSV,CSV-W-ACC etc) and/or sendTo (email address). The service checks for all Invoices that have INV_DATA_HEADER.SCHED_TRANSMISSION_DATE less or equal to TODAY and INV_DATA_HEADER.TRANSMIT_STAT_CD = 'T'
	* <br/>		
	* <br/>Pre-conditions:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>None
	*/
	public listReadyForTransmitInvoices(
							   queryParams: ListReadyForTransmitInvoicesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListReadyForTransmitInvoicesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/readyfortransmit'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will be scheduled daily from Scheduler.The service will first select all shipment ids from INV_INVOICE_DATA_HEADER  where TRANSMIT_STAT_CD = 'N' . The service will then execute the normal Ready for Transmit rules to determine if the Invoice is ready to be transmitted if all other ready for transmission rules are satisfied. It will then update the transmitStatusCd and scheduledTransmitDate in the INV_INVOICE_DATA_HEADER table.
	* <br/>	Pre-conditions:
	* <br/>	none
	* <br/>
	* <br/>	Post-conditions:
	* <br/>	Updates the Invoice Transmit Status code based on the applicable rules.
	* <br/>
	*/
	public bulkUpdateInvoiceTransmitStatus(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<BulkUpdateInvoiceTransmitStatusResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/bulkupdate'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service operation will return a list of send to addresses from Invoice Delivery table for the input Delivery Mode. The service will read the INV_INVOICE_DATA_HEADER table where the Transmit status cd = T and then group the response based on send to addresses and presentation format code and assigns a group number to each combination.
	* <br/>		
	* <br/>Pre-conditions:
	* <br/>Mandatory input: Delivery Mode cd is required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>Returns a list of Send To Addresses along with the presentation format codes for the invoices that are ready to be transmitted today.
	*/
	public listInvoiceDeliverySendToByDeliveryMode(
							   queryParams: ListInvoiceDeliverySendToByDeliveryModeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListInvoiceDeliverySendToByDeliveryModeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/sendto'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will retrieve the CLOB data for the header ID provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The header ID is provided and valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If successful, the invoice data is retrieved for the header ID.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getInvoiceDataDetailByHeaderId(
							   pathParams: GetInvoiceDataDetailByHeaderIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetInvoiceDataDetailByHeaderIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/{invoiceDataHeaderId}/datadetails'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will put an Invoice on hold by inserting a record in the INV_HOLD_INVOICING table with the information supplied by the consumer.
	* <br/>
	* <br/>Pre-conditions:
	* <br/> - ShipmentId, holdReasonCd, holdDescription, and holdById are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>A new invoice hold will be created. 
	* <br/>
	*/
	public createInvoiceHold(request: CreateInvoiceHoldRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateInvoiceHoldResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/hold'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will return invoice summary information for each input Pro Number. Atleast 1 PRO Number is required. If invoice is not yet created for the PRO then message will be sent for each PRO for which the Invoice was not found.
	* <br/>Pre-conditions:
	* <br/>	Mandatory input: Min. 1 PRO Number is entered and there is Invoice record created for input PRO.
	* <br/>Post-conditions:
	* <br/>    Returns the Invoice summary for each PRO that has Invoice created.
	* <br/>
	*/
	public listInvoiceSummary(
							   queryParams: ListInvoiceSummaryQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListInvoiceSummaryResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoice-summary'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will create the Invoice PDF and then archive to DMS and return the DMS Document Id.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>	A valid Invoice Data Header Id is input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>A new invoice will be created. 
	*/
	public archiveInvoicePdf(request: ArchiveInvoicePdfRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ArchiveInvoicePdfResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/pdf'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation will execute the Ready for Transmission rules for the invoiceDataHeaderId provided.
	* <br/>
	* <br/>The rules that are executed are:
	* <br/>- Check for pending corrections.
	* <br/>- Check for pending SRN corrections.
	* <br/>- Shipment is not final deliverered but a set number of days have elapsed since the iate of the shipment.
	* <br/>- If the DR attachment was required but not scanned and a set number of days have elapsed since the pickup date of the shipment.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. A valid invoiceDataHeaderId is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the invoice transmit status code is updated.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public executeReadyForTransmissionRules(request: ExecuteReadyForTransmissionRulesRqst,
							   pathParams: ExecuteReadyForTransmissionRulesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ExecuteReadyForTransmissionRulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/{invoiceDataHeaderId}/transmissionrules'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Deletes an invoice and its relations by the shipment instance ID.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Existing invoice(s) and its relations.
	* <br/>
	* <br/>Post-conditions:
	* <br/>Invoice(s) and its relations are deleted 
	*/
	public deleteInvoice(
							   pathParams: DeleteInvoicePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/{shipmentInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This service will identify the invoices that are not yet ready for transmission OR the invoices that are ready for transmission, but haven't been sent to the customer.  It recreates the invoices so they use the latest invoice preferences.
	* <br/>Pre-conditions:
	* <br/> Either sentToPartyCisNbr or sentToPartyCisMadCd is input and there are invoices that are either not yet transmitted or not ready for transmission.
	* <br/>
	* <br/>Post-conditions:
	* <br/>A new invoice will be created. 
	*/
	public executePrefChangeCreateInvoice(request: ExecutePrefChangeCreateInvoiceRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ExecutePrefChangeCreateInvoiceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/execute-pref-change'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will retrieve the most recent version of Invoice detail for a given PRO Number. In case of Both bill Shipments the response will have both Ppd and Coll Invoices for the input Pro.
	* <br/>Pre-conditions:
	* <br/> ProNumber is input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>Retrieve the Invoice data for the latest Invoice for the input criteria
	* <br/>
	*/
	public listInvoiceDetailByPro(
							   pathParams: ListInvoiceDetailByProPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListInvoiceDetailByProResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/pro/{proNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will retrieve the most recent version of Invoice detail for a given Shipment Inst Id and type(optional). If type value is 'clob' then only the Invoice detail data is returned. If type is not specified then Invoice details from INV tables are returned.
	* <br/>Pre-conditions:
	* <br/> Shipment Inst Id is input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>Retrieve the Invoice data for the latest Invoice for the input criteria
	* <br/>
	*/
	public getInvoiceDetail(
							   pathParams: GetInvoiceDetailPath,
							   queryParams: GetInvoiceDetailQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetInvoiceDetailResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/{shipmentInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service operation will return a list of Invoices that were previously transmitted (INV_DATA_HEADER.TRANSMIT_STAT_CD = 'T') and the Invoice Delivery sent Ind = Y.
	* <br/>		
	* <br/>Pre-conditions:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>None
	*/
	public listInvoiceTransmissionHistory(
							   pathParams: ListInvoiceTransmissionHistoryPath,
							   queryParams: ListInvoiceTransmissionHistoryQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListInvoiceTransmissionHistoryResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/{proNbr}/transmission-history'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will be called from Invoice UI where the Invoicing specialist will select 1 or more Invoices (shipment Id or Invoice header ids) to be released from hold. The service will update the INV_HOLD_INVOICING table and set the release date time and release by id. Also the service will execute the normal Ready for Transmit rules to determine if the Invoice is ready to be transmitted if all other ready for transmission rules are satisfied. It will then update the transmitStatusCd and scheduledTransmitDate in the INV_INVOICE_DATA_HEADER table.
	* <br/>	Pre-conditions:
	* <br/>	Atleast 1 shipment id or invoice header id is input along with  the Id of the user who is releasing the Invoices from hold.
	* <br/>
	* <br/>Post-conditions:
	* <br/>Updates the Invoice Transmit Status code based on the applicable rules.
	* <br/>
	*/
	public releaseHeldInvoices(request: ReleaseHeldInvoicesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ReleaseHeldInvoicesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/releasehold'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This service will return a list of Invoices along with their shipment information that are put on hold for an input SendToPartyCISMadCd.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Mandatory input: Send to Party Cis mad cd is input and there are invoices on hold for that customer.
	* <br/>
	* <br/>Post-conditions:
	* <br/>List of Invoices that are on Hold are returned.
	*/
	public listOnHoldInvoicesByCustomer(
							   pathParams: ListOnHoldInvoicesByCustomerPath,
							   queryParams: ListOnHoldInvoicesByCustomerQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListOnHoldInvoicesByCustomerResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/{sentToPartyCisMadCd}/onhold'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will resend invoices for the shipment instance IDs provided.  The invoices will be resent based on the optional data provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid shipmentInstId.  All other fields are optional.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. New invoice(s) will be resent.
	* <br/>2. If the shipmentInstIds are not found, then the appropriate error message will be returned.
	*/
	public resendInvoices(request: ResendInvoicesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ResendInvoicesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/resend'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will submit the request to Resend Invoices. Once the request is submitted then the downstream processes will create new invoices and sends based on the Preferences provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid shipmentInstId.  All other fields are optional.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. New invoice(s) will be resent.
	* <br/>2. If the shipmentInstIds are not found, then the appropriate error message will be returned.
	*/
	public submitResendInvoices(request: SubmitResendInvoicesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SubmitResendInvoicesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/invoices/resend/submit'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(InvoiceApiService.InvoiceApiEndpoint);
	}
}
