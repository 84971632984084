/**
 * ElasticSearch API
 * Version: 1.0
 * Build: 1.0.0.20
 */


import {
	XrtIndex
} from './xrt-index';
 
export class UpsertElasticSearchRecordRqst {
	/**
	 * ElasticSearch Index Name
	 * Required: true
	 * Array: false
	 */
	index:string = undefined;
	/**
	 * ElasticSearch Type name
	 * Required: true
	 * Array: false
	 */
	recordType:string = undefined;
	/**
	 * identifier
	 * Required: true
	 * Array: false
	 */
	id:string = undefined;
	/**
	 * Content details to be inserted into ElasticSearch
	 * Required: true
	 * Array: false
	 */
	content:object = undefined;
}
export class UpsertElasticSearchRecordResp {
	/**
	 * Required: false
	 * Array: false
	 */
	index:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	id:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	version:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shards:Shard = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	created:boolean = undefined;
}
export class DeleteElasticSearchRecordRqst {
	/**
	 * ElasticSearch Index Name
	 * Required: true
	 * Array: false
	 */
	index:string = undefined;
	/**
	 * The elastic search record type name
	 * Required: false
	 * Array: false
	 */
	recordType:string = undefined;
	/**
	 * The unique id for this elastic search index and type record.
	 * Required: false
	 * Array: false
	 */
	id:string = undefined;
}
export class DeleteElasticSearchRecordResp {
	/**
	 * Required: false
	 * Array: false
	 */
	content:string = undefined;
}
export class PurgeElasticSearchRecordByDateResp {
	/**
	 * Required: false
	 * Array: false
	 */
	total:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	deleted:number = undefined;
}
export class PurgeElasticSearchRecordByDateRqst {
	/**
	 * ElasticSearch Index Name
	 * Required: true
	 * Array: false
	 */
	index:string = undefined;
	/**
	 * Timestamp value to use to delete the records for given Index
	 * Required: false
	 * Array: false
	 */
	timestamp:string = undefined;
	/**
	 * pickupDate value to use to delete the records for given index
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
}
export class GetElasticSearchRecordRqst {
	/**
	 * ElasticSearch Index Name
	 * Required: true
	 * Array: false
	 */
	index:string = undefined;
	/**
	 * ElasticSearch Type name
	 * Required: true
	 * Array: false
	 */
	recordType:string = undefined;
	/**
	 * identifier
	 * Required: true
	 * Array: false
	 */
	id:string = undefined;
}
export class GetElasticSearchRecordResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:ElasticSearchResultHeader = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	record:object = undefined;
}
export class SearchElasticSearchRecordRqst {
	/**
	 * ElasticSearch Index Name
	 * Required: true
	 * Array: false
	 */
	index:string = undefined;
	/**
	 * Query DSL for searching
	 * Required: true
	 * Array: false
	 */
	query:object = undefined;
}
export class SearchElasticSearchRecordResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:ElasticSearchResultHeader = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalHits:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	result:object = undefined;
}
export class XrtSearchRqst {
	/**
	 * Query DSL for searching
	 * Required: true
	 * Array: false
	 */
	query:XrtQuery = undefined;
}
export class XrtSearchResp {
	/**
	 * Maximum number of total rows
	 * Required: false
	 * Array: false
	 */
	maxTotalRows:number = undefined;
	/**
	 * Current page number of result set
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Number of rows contained in a page
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Number of results
	 * Required: false
	 * Array: false
	 */
	resultCount:number = undefined;
	/**
	 * Details of sort used
	 * Required: false
	 * Array: false
	 */
	sortExpressions:XrtSortExpression = undefined;
	/**
	 * Summary of results
	 * Required: false
	 * Array: false
	 */
	summary:string = undefined;
	/**
	 * Total number of pages in this result
	 * Required: false
	 * Array: false
	 */
	totalPages:number = undefined;
	/**
	 * An identifier for the request
	 * Required: false
	 * Array: false
	 */
	requestId:string = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:object[] = undefined;
}
export class XrtBolSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtBolSearchFilter = undefined;
}
export class XrtBolSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:ShipmentSearchRecord[] = undefined;
}
export class XrtClaimSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtClaimSearchFilter = undefined;
}
export class XrtClaimSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:ClaimSearchRecord[] = undefined;
}
export class XrtClaimExternalSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtClaimExternalSearchFilter = undefined;
}
export class XrtClaimExternalSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:ClaimExternalSearchRecord[] = undefined;
}
export class XrtClaimPartySearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtClaimPartySearchFilter = undefined;
}
export class XrtClaimPartySearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:ClaimPartySearchRecord[] = undefined;
}
export class XrtCustomerSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtCustomerSearchFilter = undefined;
}
export class XrtCustomerSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:CustomerSearchRecord[] = undefined;
}
export class XrtDisputeSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtDisputeSearchFilter = undefined;
}
export class XrtDisputeSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:DisputeSearchRecord[] = undefined;
}
export class XrtDisputePartySearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtDisputePartySearchFilter = undefined;
}
export class XrtDisputePartySearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:DisputePartySearchRecord[] = undefined;
}
export class XrtImageViewerSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtImageViewerSearchFilter = undefined;
}
export class XrtImageViewerSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:ImageViewerSearchRecord[] = undefined;
}
export class XrtShipmentSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtShipmentSearchFilter = undefined;
}
export class XrtShipmentSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:ShipmentSearchRecord[] = undefined;
}
export class XrtNmfcItemSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtNmfcItemSearchFilter = undefined;
}
export class XrtNmfcItemSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:NmfcItemSearchRecord[] = undefined;
}
export class XrtOrderCaptureAuditSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtOrderCaptureAuditSearchFilter = undefined;
}
export class XrtOrderCaptureAuditSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:OrderCaptureAuditSearchRecord[] = undefined;
}
export class XrtHazmatRefSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtHazmatRefSearchFilter = undefined;
}
export class XrtHazmatRefSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:HazmatRefSearchRecord[] = undefined;
}
export class XrtOverageImageSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtOverageImageSearchFilter = undefined;
}
export class XrtOverageImageSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:OverageImageSearchRecord[] = undefined;
}
export class XrtPackagingBulkCdSearchRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:XrtPackagingBulkCdSearchFilter = undefined;
}
export class XrtPackagingBulkCdSearchResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:PackagingBulkCdSearchRecord[] = undefined;
}
export class XrtQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	page:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	type:XrtIndex = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	Filter_Q:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_lstUpdtTmst:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_acctPartyRole:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_countryCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_postalCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_addressLine:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_acctInstId:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_cityName:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_acctMadCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_usZip4:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_stateCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_name1:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_name2:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_seqNbr:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_consigneeCustInstID:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_consigneePostalCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_bolStatusCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_billToCustInstID:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_proNbr:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_shipperCustMadCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_bolInstID:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_destTrmnlSIC:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_shipperPostalCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_shipmentInstID:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_origTrmnlSIC:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_billToCustMadCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_shipperCustInstID:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_pkupDate:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_billToPostalCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_consigneeCustMadCd:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_suppRefNbrs:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_totalPiecesCount:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_totalWeight:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_processedDate:string[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	Filter_id:string[] = undefined;
	public toJSON = function(this: XrtQuery) {
		return {
			"page" : this.page,
			"sortExpressions" : this.sortExpressions,
			"pageSize" : this.pageSize,
			"type" : this.type,
			"Filter.Q" : this.Filter_Q,
			"Filter.lstUpdtTmst" : this.Filter_lstUpdtTmst,
			"Filter.acctPartyRole" : this.Filter_acctPartyRole,
			"Filter.countryCd" : this.Filter_countryCd,
			"Filter.postalCd" : this.Filter_postalCd,
			"Filter.addressLine" : this.Filter_addressLine,
			"Filter.acctInstId" : this.Filter_acctInstId,
			"Filter.cityName" : this.Filter_cityName,
			"Filter.acctMadCd" : this.Filter_acctMadCd,
			"Filter.usZip4" : this.Filter_usZip4,
			"Filter.stateCd" : this.Filter_stateCd,
			"Filter.name1" : this.Filter_name1,
			"Filter.name2" : this.Filter_name2,
			"Filter.seqNbr" : this.Filter_seqNbr,
			"Filter.consigneeCustInstID" : this.Filter_consigneeCustInstID,
			"Filter.consigneePostalCd" : this.Filter_consigneePostalCd,
			"Filter.bolStatusCd" : this.Filter_bolStatusCd,
			"Filter.billToCustInstID" : this.Filter_billToCustInstID,
			"Filter.proNbr" : this.Filter_proNbr,
			"Filter.shipperCustMadCd" : this.Filter_shipperCustMadCd,
			"Filter.bolInstID" : this.Filter_bolInstID,
			"Filter.destTrmnlSIC" : this.Filter_destTrmnlSIC,
			"Filter.shipperPostalCd" : this.Filter_shipperPostalCd,
			"Filter.shipmentInstID" : this.Filter_shipmentInstID,
			"Filter.origTrmnlSIC" : this.Filter_origTrmnlSIC,
			"Filter.billToCustMadCd" : this.Filter_billToCustMadCd,
			"Filter.shipperCustInstID" : this.Filter_shipperCustInstID,
			"Filter.pkupDate" : this.Filter_pkupDate,
			"Filter.billToPostalCd" : this.Filter_billToPostalCd,
			"Filter.consigneeCustMadCd" : this.Filter_consigneeCustMadCd,
			"Filter.suppRefNbrs" : this.Filter_suppRefNbrs,
			"Filter.totalPiecesCount" : this.Filter_totalPiecesCount,
			"Filter.totalWeight" : this.Filter_totalWeight,
			"Filter.processedDate" : this.Filter_processedDate,
			"Filter.id" : this.Filter_id		}
	}
}
export class XrtSearchRespHeader {
	/**
	 * Maximum number of total rows
	 * Required: false
	 * Array: false
	 */
	maxTotalRows:number = undefined;
	/**
	 * Current page number of result set
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Number of rows contained in a page
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Number of results
	 * Required: false
	 * Array: false
	 */
	resultCount:number = undefined;
	/**
	 * Details of sort used
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Summary of results
	 * Required: false
	 * Array: false
	 */
	summary:string = undefined;
	/**
	 * Total number of pages in this result
	 * Required: false
	 * Array: false
	 */
	totalPages:number = undefined;
	/**
	 * An identifier for the request
	 * Required: false
	 * Array: false
	 */
	requestId:string = undefined;
}
export class XrtAttributeFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	equals:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	min:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	max:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	startsWith:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	query:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	Location:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	Radius:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	Exclude:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	Lat:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	Lon:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	Mode:string = undefined;
}
export class XrtBooleanFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	query:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	fields:string[] = undefined;
}
export class XrtBolSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bolStatusCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bolInstID:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	origTrmnlSIC:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destTrmnlSIC:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pkupDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToPostalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToCustInstID:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToCustMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeCustInstID:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneePostalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeCustMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperPostalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperCustInstID:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperCustMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstID:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalWeight:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	processedDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suppRefNbrs:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	id:XrtAttributeFilter = undefined;
}
export class XrtClaimSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	filingDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimantRefNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimantName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimedAmount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	approvedAmount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	checkNbr:XrtAttributeFilter[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	filedBy:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	examinedBy:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalPaymentAmount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	internalStatusCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	assigneeEmployeeName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	typeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	filedByWebRegisteredUserid:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	externalStatusCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	rebuttalInd:XrtAttributeFilter = undefined;
}
export class XrtClaimExternalSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:XrtBooleanFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	examinedBy:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	externalStatusCd:XrtAttributeFilter = undefined;
}
export class XrtClaimPartySearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimPartyId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	addr1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	addr2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	postalCd:XrtAttributeFilter[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	postalCdExt:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	countrySubDivNm:XrtAttributeFilter = undefined;
}
export class XrtCustomerSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	acctInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	acctMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	seqNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	acctPartyRole:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	addressLine:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	usZip4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	name2:XrtAttributeFilter = undefined;
}
export class XrtDisputeSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	disputeId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimantName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	onBehalfOfName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	disputeCreateDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	disputeReceivedDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	disputeLastUpdateDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currencyCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalProCount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	proNbrs:string[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	mrraCustomerMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	mrraCustomerName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	wniInspectorName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	wniInspectorSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	wniInspectorArea:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	drpCategoryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	drpAeNaeName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	potentialDuplicateInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ineligibleInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	manualRerateInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalUSDInvoiceAmountAtDisputeCreation:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalUSDRequestedAdjustmentAmount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalUSDMrraWhatIfAmount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalUSDApprovedAmount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalCADInvoiceAmountAtDisputeCreation:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalCADRequestedAdjustmentAmount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalCADMrraWhatIfAmount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalCADApprovedAmount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentDebtorMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentDebtorName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	newDebtorMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	newDebtorName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerRefNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	disputeTypeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	workflowQueuInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	workflowQueueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	workflowItemInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	workflowItemStatus:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	workflowItemAssignedToEmployeeId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	workflowItemAssignedToEmployeeName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	workflowItemRoleId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	authorizedApproverName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	filedByEmployeeName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	migratedInd:XrtAttributeFilter = undefined;
}
export class XrtDisputePartySearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	disputePartyId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	addr1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	addr2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	postalCdExt:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	countrySubDivNm:XrtAttributeFilter = undefined;
}
export class XrtImageViewerSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:XrtBooleanFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadOriginSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadDestSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	imageTakenFromDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	imageTakenToDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shift:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	closedByEmployeeId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadedByEmployeeId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentType:XrtAttributeFilter = undefined;
}
export class XrtShipmentSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstID:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	origTrmnlSIC:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destTrmnlSIC:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pkupDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToPostalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToCustInstID:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToCustMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeCustInstID:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneePostalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeCustMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperPostalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperCustInstID:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperCustMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalWeight:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	processedDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suppRefNbrs:XrtAttributeFilter = undefined;
}
export class XrtNmfcItemSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	id:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hazmatInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	description:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	classType:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expiredInd:XrtAttributeFilter = undefined;
}
export class XrtOrderCaptureAuditSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	origTrmnlSIC:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	processedDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	docClass:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	captureLoc:XrtAttributeFilter = undefined;
}
export class XrtHazmatRefSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	unnaCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	description:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	properShipName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	baseDescription:XrtAttributeFilter = undefined;
}
export class XrtOverageImageSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:XrtBooleanFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	reportingSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	overPairedWithShortInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	brand:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	description:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	color:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lengthNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	widthNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	heightNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	dmsUrl:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	packageCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	piecesCount:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originalProNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	comments:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	label:XrtAttributeFilter[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	weightLbs:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	referenceNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	selectedTags:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	partialPairedWithShortInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdById:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdTimestamp:XrtAttributeFilter = undefined;
}
export class XrtPackagingBulkCdSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	Q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	packageCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	packageNm:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	bulkCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	description:XrtAttributeFilter = undefined;
}
export class XrtSearchQueryHeader {
	/**
	 * Required: false
	 * Array: false
	 */
	page:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
}
export class XrtSortExpression {
	/**
	 * Required: true
	 * Array: false
	 */
	IsDescendingSort:boolean = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	Column:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	direction:string = undefined;
}
export class Shard {
	/**
	 * Required: false
	 * Array: false
	 */
	total:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	successful:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	failed:number = undefined;
}
export class ElasticSearchResultHeader {
	/**
	 * Required: false
	 * Array: false
	 */
	index:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	version:string = undefined;
}
export class CustomerSearchResult {
	/**
	 * Required: false
	 * Array: true
	 */
	jsonElement:CustomerSearchRecord[] = undefined;
}
export class ShipmentSearchResult {
	/**
	 * Required: false
	 * Array: true
	 */
	jsonElement:ShipmentSearchRecord[] = undefined;
}
export class OrderCaptureAuditSearchResult {
	/**
	 * Required: false
	 * Array: true
	 */
	jsonElement:OrderCaptureAuditSearchRecord[] = undefined;
}
export class ClaimSearchRecord {
	/**
	 * Unique ID assigned by system to a claim when it is entered
	 * Required: false
	 * Array: false
	 */
	claimId:string = undefined;
	/**
	 * PRO# from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Date when shipment was picked up. This comes from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * The date when the claim was actually submitted for review. 
	 * The claim can be filed directly by the claiment or by a CSR when a claimant sends an email to XPO and employee files the claim.
	 * Required: false
	 * Array: false
	 */
	filingDate:string = undefined;
	/**
	 * This is Customer Reference Number for the claim.
	 * Required: false
	 * Array: false
	 */
	claimantRefNbr:string = undefined;
	/**
	 * This is name of the party filing the claim.
	 * Required: false
	 * Array: false
	 */
	claimantName:string = undefined;
	/**
	 * Amount claimed by the claiment against a claim on a shipment.   
	 * The amount is the sum total of all claims made on the line item per commodity.
	 * Required: false
	 * Array: false
	 */
	claimedAmount:number = undefined;
	/**
	 * Amount that was approved for payment
	 * Required: false
	 * Array: false
	 */
	approvedAmount:number = undefined;
	/**
	 * Check Number. This is assigned by A/P application (OFSP)
	 * Required: false
	 * Array: true
	 */
	checkNbr:string[] = undefined;
	/**
	 * Records the name of an employee filed the claim after receiving the request from the claimant.
	 * Required: false
	 * Array: false
	 */
	filedBy:string = undefined;
	/**
	 * Name of the examiner.
	 * Required: false
	 * Array: false
	 */
	examinedBy:string = undefined;
	/**
	 * Total amount payed for the claim
	 * Required: false
	 * Array: false
	 */
	totalPaymentAmount:number = undefined;
	/**
	 * Claim status describes the status of the overall claim and where it is within its lifecycle.  
	 * Internal status is shown to XPO users (Claims, CSRs, Sales, etc.).
	 * List of valid Values:
	 * UNSUBMITTED
	 * SUBMITTED
	 * UNDER_REVIEW
	 * DECLINED
	 * APPROVED
	 * Required: false
	 * Array: false
	 */
	internalStatusCd:string = undefined;
	/**
	 * This is the timestamp when this row was inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * This is the name of the employee assigned to the claim.
	 * Required: false
	 * Array: false
	 */
	assigneeEmployeeName:string = undefined;
	/**
	 * This is The type of claim that has been made by the claimant.  
	 * List of valid values:
	 * SHORTAGE  - A claim for a shipment that is partially or totally missing
	 * DAMAGE -  A claim for a shipment that is partially or completely damaged
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * The Web User's internal ID if the claim is filed by XPO LTL Web registered user
	 * .
	 * Required: false
	 * Array: false
	 */
	filedByWebRegisteredUserid:number = undefined;
	/**
	 * Claim status that is displayed to external customers that describes the status of the overall claim and where it is within its lifecycle.
	 * Required: false
	 * Array: false
	 */
	externalStatusCd:string = undefined;
	/**
	 * Indicates that the Claim is in a Rebuttal activity.
	 * Required: false
	 * Array: false
	 */
	rebuttalInd:boolean = undefined;
}
export class ClaimExternalSearchRecord {
	/**
	 * Unique ID assigned by system to a claim when it is entered
	 * Required: false
	 * Array: false
	 */
	claimId:string = undefined;
	/**
	 * PRO# from SHM_SHIPMENT
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Name of the examiner.
	 * Required: false
	 * Array: false
	 */
	examinedBy:string = undefined;
	/**
	 * Claim status that is displayed to external customers that describes the status of the overall claim and where it is within its lifecycle.
	 * Required: false
	 * Array: false
	 */
	externalStatusCd:string = undefined;
}
export class ClaimPartySearchRecord {
	/**
	 * Each party in the Claim PArty master is assigned a system generated unique ID
	 * Required: false
	 * Array: false
	 */
	claimPartyId:number = undefined;
	/**
	 * Company Name of the party.
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * The Attention line of the Company name as specified when the claim was sent to XPO
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Company Address line 1 of the party.
	 * Required: false
	 * Array: false
	 */
	addr1:string = undefined;
	/**
	 * Company Address line 1 of the party.
	 * Required: false
	 * Array: false
	 */
	addr2:string = undefined;
	/**
	 * Company city name of the party.
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * Company postal state code  of the party.
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * Country Code
	 * Valid Values
	 * US
	 * CA
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Zip code. 5 digits for US and 6 chars for CA.
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * zip code extension.
	 * Required: false
	 * Array: false
	 */
	postalCdExt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * This is the timestamp when this row was inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * Expanded name to represent State for countries that do not have states.
	 * Required: false
	 * Array: false
	 */
	countrySubDivNm:string = undefined;
}
export class DisputeSearchRecord {
	/**
	 * Unique ID assigned by system to a dispute when it is entered
	 * Required: false
	 * Array: false
	 */
	disputeId:string = undefined;
	/**
	 * This is name of the party filing the dispute.
	 * Required: false
	 * Array: false
	 */
	claimantName:string = undefined;
	/**
	 * This is name of the party the dispute is being filed for.
	 * Required: false
	 * Array: false
	 */
	onBehalfOfName:string = undefined;
	/**
	 * Date when dispute was created
	 * Required: false
	 * Array: false
	 */
	disputeCreateDate:string = undefined;
	/**
	 * Date when dispute was received.
	 * Required: false
	 * Array: false
	 */
	disputeReceivedDate:string = undefined;
	/**
	 * Date when dispute was last updated
	 * Required: false
	 * Array: false
	 */
	disputeLastUpdateDate:string = undefined;
	/**
	 * The currency code for the total invoice amount, total requested adjustment amount, total approved amount and total mrra what if amount.
	 * Required: false
	 * Array: false
	 */
	currencyCd:string = undefined;
	/**
	 * The total number of PROs covered by the dispute.
	 * Required: false
	 * Array: false
	 */
	totalProCount:number = undefined;
	/**
	 * PRO numbers for the shipments that are covered by the dispute.
	 * Required: false
	 * Array: true
	 */
	proNbrs:string[] = undefined;
	/**
	 * The mad code for the customer associated with the MRRA dispute.
	 * Required: false
	 * Array: false
	 */
	mrraCustomerMadCd:string = undefined;
	/**
	 * The name of the customer associated with the MRRA dispute.
	 * Required: false
	 * Array: false
	 */
	mrraCustomerName:string = undefined;
	/**
	 * The name of the WNI Inspector that is associated with the dispute.
	 * Required: false
	 * Array: false
	 */
	wniInspectorName:string = undefined;
	/**
	 * The SIC of the WNI Inspector that is associated with the dispute.
	 * Required: false
	 * Array: false
	 */
	wniInspectorSicCd:string = undefined;
	/**
	 * The Area of the WNI Inspector that is associated with the dispute.
	 * Required: false
	 * Array: false
	 */
	wniInspectorArea:string = undefined;
	/**
	 * Category code for the DRP
	 * Required: false
	 * Array: false
	 */
	drpCategoryCd:string = undefined;
	/**
	 * The Sales AE or NAE name that is associated with the dispute.
	 * Required: false
	 * Array: false
	 */
	drpAeNaeName:string = undefined;
	/**
	 * Indicates that the Dispute may be a potential duplicate of an already existing dispute.
	 * Required: false
	 * Array: false
	 */
	potentialDuplicateInd:boolean = undefined;
	/**
	 * Indicates that the Dispute is ineligible for payment.
	 * Required: false
	 * Array: false
	 */
	ineligibleInd:boolean = undefined;
	/**
	 * Indicates that the Dispute must be manually re-rated.
	 * Required: false
	 * Array: false
	 */
	manualRerateInd:boolean = undefined;
	/**
	 * Total of the invoice amounts at time of dispute creation for all of the PROs on the dispute.
	 * Required: false
	 * Array: false
	 */
	totalUSDInvoiceAmountAtDisputeCreation:number = undefined;
	/**
	 * Total of the invoice amounts at time of dispute creation for all of the PROs on the dispute.
	 * Required: false
	 * Array: false
	 */
	totalCADInvoiceAmountAtDisputeCreation:number = undefined;
	/**
	 * Total of the requested adjustment amounts for all of the PROs on the dispute.
	 * Required: false
	 * Array: false
	 */
	totalUSDRequestedAdjustmentAmount:number = undefined;
	/**
	 * Total of the requested adjustment amounts for all of the PROs on the dispute.
	 * Required: false
	 * Array: false
	 */
	totalCADRequestedAdjustmentAmount:number = undefined;
	/**
	 * Derived amount based on the totalInvoiceAmount at time of dispute creation plus the totalRequestedAdjustmentAmount .
	 * Required: false
	 * Array: false
	 */
	totalUSDApprovedAmount:number = undefined;
	/**
	 * Derived amount based on the totalInvoiceAmount at time of dispute creation plus the totalRequestedAdjustmentAmount .
	 * Required: false
	 * Array: false
	 */
	totalCADApprovedAmount:number = undefined;
	/**
	 * Total of the MRRA what if amounts for all of the PROs on the dispute.
	 * Required: false
	 * Array: false
	 */
	totalUSDMrraWhatIfAmount:number = undefined;
	/**
	 * Total of the MRRA what if amounts for all of the PROs on the dispute.
	 * Required: false
	 * Array: false
	 */
	totalCADMrraWhatIfAmount:number = undefined;
	/**
	 * Mad code for the current debtor customer on the dispute
	 * Required: false
	 * Array: false
	 */
	currentDebtorMadCd:string = undefined;
	/**
	 * Name of the current debtor customer on the dispute
	 * Required: false
	 * Array: false
	 */
	currentDebtorName:string = undefined;
	/**
	 * Mad code for the new debtor customer on the dispute
	 * Required: false
	 * Array: false
	 */
	newDebtorMadCd:string = undefined;
	/**
	 * Name of the new debtor customer on the dispute
	 * Required: false
	 * Array: false
	 */
	newDebtorName:string = undefined;
	/**
	 * This field records the reference number in the customer records. This information is provided by the customer while sending the dispute request.
	 * Required: false
	 * Array: false
	 */
	customerRefNbr:string = undefined;
	/**
	 * This field gives us the Type of Dispute.
	 * List of values:
	 * WNI
	 * DRP
	 * OCC
	 * MRRA
	 * Required: false
	 * Array: false
	 */
	disputeTypeCd:string = undefined;
	/**
	 * The instance id of the dispute workflow queue that the dispute workflow item is assigned to.
	 * Required: false
	 * Array: false
	 */
	workflowQueueInstId:number = undefined;
	/**
	 * The name of the dispute workflow queue that the dispute workflow item is assigned to.
	 * Required: false
	 * Array: false
	 */
	workflowQueueName:string = undefined;
	/**
	 * The instance id of the dispute workflow item that corresponds to the dispute.
	 * Required: false
	 * Array: false
	 */
	workflowItemInstId:number = undefined;
	/**
	 * The status of the dispute workflow item which represents a dispute record that needs to be processed.
	 * Required: false
	 * Array: false
	 */
	workflowItemStatus:string = undefined;
	/**
	 * The employee id for the employee that is currently assigned to the dispute workflow item.
	 * Required: false
	 * Array: false
	 */
	workflowItemAssignedToEmployeeId:string = undefined;
	/**
	 * The name of an employee that is currently assigned to the dispute workflow item.
	 * Required: false
	 * Array: false
	 */
	workflowItemAssignedToEmployeeName:string = undefined;
	/**
	 * The role id to which the workflow item can be assigned.
	 * Required: false
	 * Array: false
	 */
	workflowItemRoleId:string = undefined;
	/**
	 * The name of the authorized approver for the dispute.
	 * Required: false
	 * Array: false
	 */
	authorizedApproverName:string = undefined;
	/**
	 * The name of the user who filed the dispute request in the system
	 * Required: false
	 * Array: false
	 */
	filedByEmployeeName:string = undefined;
	/**
	 * Indicates that the Dispute was migrated from OMS.
	 * Required: false
	 * Array: false
	 */
	migratedInd:boolean = undefined;
}
export class DisputePartySearchRecord {
	/**
	 * Each party in the Dispute Party master is assigned a system generated unique ID
	 * Required: false
	 * Array: false
	 */
	disputePartyId:number = undefined;
	/**
	 * Company Name of the party.
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * The Attention line of the Company name as specified when the dispute was sent to XPO
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Company Address line 1 of the party.
	 * Required: false
	 * Array: false
	 */
	addr1:string = undefined;
	/**
	 * Company Address line 1 of the party.
	 * Required: false
	 * Array: false
	 */
	addr2:string = undefined;
	/**
	 * Company city name of the party.
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * Company postal state code  of the party.
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * Country Code
	 * Valid Values
	 * US
	 * CA
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Zip code. 5 digits for US and 6 chars for CA.
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * zip code extension.
	 * Required: false
	 * Array: false
	 */
	postalCdExt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * This is the timestamp when this row was inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * Expanded name to represent State for countries that do not have states.
	 * Required: false
	 * Array: false
	 */
	countrySubDivNm:string = undefined;
}
export class CustomerSearchRecord {
	/**
	 * Required: false
	 * Array: false
	 */
	acctInstId:string = undefined;
	/**
	 * A sequentially created surrogate identifier for
	 * the customer name and address info. A customer can have multiple
	 * name/address (aliases) in addition to the main customer
	 * name/address.
	 * Required: false
	 * Array: false
	 */
	seqNbr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	acctMadCd:string = undefined;
	/**
	 * roles for this account. p2D, billTo, corp,
	 * supplier, pay agent
	 * Required: false
	 * Array: false
	 */
	acctPartyRole:string = undefined;
	/**
	 * Primary name
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * Applies to mailing address of a person, where
	 * the
	 * address is actually in another entity's name.
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Address line that includes Street name,
	 * direction
	 * and optionally building number, unit# etc.
	 * e.g. 1000 SW Broadway st
	 * Required: false
	 * Array: false
	 */
	addressLine:string = undefined;
	/**
	 * City Name part of the address.
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * Standard Postal abbreviation for state name.
	 * Example: OR for Oregon.
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * standard Postal abbreviation for a country.
	 * Example: CA for Canada.
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Postal Code are aka Zip code in USA. US and
	 * Mexico use 5 digit postal codes. Canada uses 6 char postal
	 * codes.
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * This is a 4 digit extension to US 5 digit zip
	 * code.
	 * Required: false
	 * Array: false
	 */
	usZip4:string = undefined;
	/**
	 * This is the timestamp when this row is being
	 * inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
}
export class ImageViewerSearchRecord {
	/**
	 * Business identifier for the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * Service center where the trailer was loaded
	 * Required: false
	 * Array: false
	 */
	loadOriginSic:string = undefined;
	/**
	 * Service centere where the trailer load is destined
	 * Required: false
	 * Array: false
	 */
	loadDestSic:string = undefined;
	/**
	 * The percentage utilization for the trailer (cube)
	 * Required: false
	 * Array: false
	 */
	utilizationPct:number = undefined;
	/**
	 * The weight of the trailer in Lbs.
	 * Required: false
	 * Array: false
	 */
	weightLbs:number = undefined;
	/**
	 * Date range start when the image was taken.
	 * Required: false
	 * Array: false
	 */
	imageTakenFromDate:string = undefined;
	/**
	 * Date range end when the image was taken.
	 * Required: false
	 * Array: false
	 */
	imageTakenToDate:string = undefined;
	/**
	 * Identifier for the shift during which the trailer was loaded
	 * Required: false
	 * Array: false
	 */
	shift:string = undefined;
	/**
	 * Employeee that closed the trailer
	 * Required: false
	 * Array: false
	 */
	closedByEmployeeId:string = undefined;
	/**
	 * Employee that loaded the trailer
	 * Required: false
	 * Array: false
	 */
	loadedByEmployeeId:string = undefined;
	/**
	 * Door number at the service center where the trailer was loaded
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * DMS document ID for the image
	 * Required: false
	 * Array: false
	 */
	dmsDocumentId:string = undefined;
	/**
	 * DMS Thumbnail image for the image
	 * Required: false
	 * Array: false
	 */
	dmsThumbnailImage:string = undefined;
}
export class ShipmentSearchRecord {
	/**
	 * Original terminal SIC
	 * 
	 * ORIG_TRMNL_SIC_CD
	 * 
	 * Desc : SIC where the Shipment originates.
	 * Required: false
	 * Array: false
	 */
	origTrmnlSIC:string = undefined;
	/**
	 * Expanded Name : Total pieces count
	 * 
	 * Desc : Total pieces from all commodities in the shipment.
	 * 
	 * This can be derived from the individual commodities, but besides
	 * performance, there is a timing issue as well. SMART needs the
	 * information when freight is loaded on the trailer (TCON) and it
	 * may happen before the bill has been entered into the system.
	 * 
	 * Source : MBF: root: MBTOTPCS
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * Expanded Name : Total weight (in pounds)
	 * 
	 * Desc : Total weight of all the freight being moved as a unit.  If this is a  Mother  bill then this is the total of all of the  Daughter  bills. 
	 * 
	 * Any rating weight allowances or variations (AW, DW, RW, NW) are not included.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTWT
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Pickup Date
	 * 
	 * PKUP_DT
	 * 
	 * Desc : The date that the Shipment was picked up from the customer.
	 * 
	 * Source : E-commerce; SHM_SHIPMENT.PKUP_DT
	 * Required: false
	 * Array: false
	 */
	pkupDate:Date = undefined;
	/**
	 * Identifies whether the BOL information sent
	 * from
	 * the customer is still to be used (i.e. Active). If the customer
	 * has indicated that this BOL request has been cancelled we will
	 * retain the information but will indicate that it should no
	 * longer
	 * be used (i.e. This comes through as a Cancel in the EDI data)
	 * 
	 * Values:
	 * Active
	 * ancelled
	 * Required: false
	 * Array: false
	 */
	bolStatusCd:string = undefined;
	/**
	 * Pro Number Text
	 * 
	 * PRO_NBR_TXT
	 * 
	 * Desc: Business identifier to the shipment on the BOL
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * This is the timestamp when this row is being
	 * inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * identifier for the bill of lading
	 * Required: false
	 * Array: false
	 */
	bolInstID:string = undefined;
	/**
	 * Destination terminal SIC
	 * 
	 * DEST_TRMNL_SIC_CD
	 * 
	 * Desc : SIC that is scheduled to deliver the Shipment.
	 * Required: false
	 * Array: false
	 */
	destTrmnlSIC:string = undefined;
	/**
	 * Shipment Instance ID
	 * 
	 * SHP_INST_ID
	 * 
	 * Desc: This is the unique ltl.xpo Shipment Identifier of the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentInstID:string = undefined;
	/**
	 * An internal identifier to the Shipper customer
	 * that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	shipperCustInstID:string = undefined;
	/**
	 * A business key that identifies the Shipper.
	 * Required: false
	 * Array: false
	 */
	shipperCustMadCd:string = undefined;
	/**
	 * The name of the Shipper.
	 * Required: false
	 * Array: false
	 */
	shipperName:string = undefined;
	/**
	 * Shipper Postal Code are aka Zip code in USA. US
	 * and Mexico use 5 digit postal codes. Canada uses 6 char postal
	 * codes.
	 * Required: false
	 * Array: false
	 */
	shipperPostalCd:string = undefined;
	/**
	 * An internal identifier to the Consignee
	 * customer
	 * that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	consigneeCustInstID:string = undefined;
	/**
	 * A business key that identifies the Consignee.
	 * Required: false
	 * Array: false
	 */
	consigneeCustMadCd:string = undefined;
	/**
	 * The name of the COnsignee.
	 * Required: false
	 * Array: false
	 */
	consigneeName:string = undefined;
	/**
	 * Consignee Postal Code are aka Zip code in USA.
	 * US
	 * and Mexico use 5 digit postal codes. Canada uses 6 char postal
	 * codes.
	 * Required: false
	 * Array: false
	 */
	consigneePostalCd:string = undefined;
	/**
	 * An internal identifier to the Bill-To customer
	 * that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	billToCustInstID:string = undefined;
	/**
	 * A business key that identifies the Bill-To.
	 * Required: false
	 * Array: false
	 */
	billToCustMadCd:string = undefined;
	/**
	 * The name of the Bill-to.
	 * Required: false
	 * Array: false
	 */
	billToName:string = undefined;
	/**
	 * Bill-To Postal Code are aka Zip code in USA. US
	 * and Mexico use 5 digit postal codes. Canada uses 6 char postal
	 * codes.
	 * Required: false
	 * Array: false
	 */
	billToPostalCd:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	suppRefNbrs:string[] = undefined;
	/**
	 * Line 1 of the shipper name
	 * Required: false
	 * Array: false
	 */
	shipperName1:string = undefined;
	/**
	 * Line 1 of the consignee name
	 * Required: false
	 * Array: false
	 */
	consigneeName1:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	dimensions:object[] = undefined;
	/**
	 * Identifies the source of the eBol
	 * 
	 * Values:
	 * X - XML BOL
	 * B - Online BOL
	 * T - BOL Template
	 * E - from EDI
	 * Required: false
	 * Array: false
	 */
	bolSourceCd:string = undefined;
}
export class OrderCaptureAuditSearchRecord {
	/**
	 * Original terminal SIC
	 * ORIG_TRMNL_SIC_CD
	 * Desc : SIC where the Shipment originates.
	 * Required: false
	 * Array: false
	 */
	origTrmnlSIC:string = undefined;
	/**
	 * Pro Number Text
	 * PRO_NBR_TXT
	 * Desc: Business identifier to the shipment on the BOL
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Processed Date
	 * Desc : The date that the order was processed.
	 * Required: false
	 * Array: false
	 */
	processedDate:Date = undefined;
	/**
	 * Doc Class
	 * Desc : The document class from DMS
	 * Required: false
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * Capture Loc
	 * Desc : The location where the PRO was scanned
	 * Required: false
	 * Array: false
	 */
	captureLoc:string = undefined;
	/**
	 * Archive Date Time
	 * Desc : Date and time when the DMS record was created
	 * Required: false
	 * Array: false
	 */
	archiveDateTime:Date = undefined;
	/**
	 * Capture Loc Date Time
	 * Desc : LocalDate and time when the PRO was scanned
	 * Required: false
	 * Array: false
	 */
	captureLocDateTime:Date = undefined;
	/**
	 * Search Key
	 * Desc : The search key sent to EXL
	 * Required: false
	 * Array: false
	 */
	searchKey:string = undefined;
	/**
	 * CSV record
	 * Desc : The full CSV record sent to EXL
	 * Required: false
	 * Array: false
	 */
	csvRecord:string = undefined;
	/**
	 * This is the timestamp when this row was inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
}
export class NmfcItemSearchResult {
	/**
	 * Required: false
	 * Array: true
	 */
	jsonElement:NmfcItemSearchRecord[] = undefined;
}
export class NmfcItemSearchRecord {
	/**
	 * Derived field that is a concatenation of ITEM_NBR + - + ITEM_SUB
	 * Required: false
	 * Array: false
	 */
	id:string = undefined;
	/**
	 * Identifies a particular commodity(s) and may include allowable packaging.
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * A numeric ranking (class) assigned to the commodity(s) described based on its transportation characteristics. There are 18 classes: 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500. (Also see item/rule 110 and 420) When 0 is shown in the Class field, it means the commodity(s) is subject to regulations of the individual carrier. When Exempt is shown in the Class field, it means the commodity(s) is not subject to economic regulation. When NotTaken is shown in the Class field, it means the commodity(s) will not be accepted for shipment.
	 * Required: false
	 * Array: false
	 */
	classType:string = undefined;
	/**
	 * Indicating commodity(s) described in this record may be subject to special federal regulations concerning the shipment of hazardous materials.
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * This is the timestamp when this row is being inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * NMFC Item number
	 * Required: false
	 * Array: false
	 */
	itemNbr:string = undefined;
	/**
	 * NMFC Item Sub number
	 * Required: false
	 * Array: false
	 */
	itemSubNbr:string = undefined;
	/**
	 * Issue date for the last revision to this record.
	 * Required: false
	 * Array: false
	 */
	issueDate:Date = undefined;
	/**
	 * Effective date for the last revision to thisrecord.
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * Identifies the item number this item record is subordinate to
	 * Required: false
	 * Array: false
	 */
	refItemNbr:string = undefined;
	/**
	 * Identifies the sub number this sub record is subordinate to.
	 * Required: false
	 * Array: false
	 */
	refItemSubNbr:string = undefined;
	/**
	 * Indicated whether the NMFC code has been expired.
	 * Required: false
	 * Array: false
	 */
	expiredInd:boolean = undefined;
	/**
	 * If the NMFC code has expired, the date on which it expired.
	 * Required: false
	 * Array: false
	 */
	expiredDate:Date = undefined;
}
/**
 * Desc: United Nations (UN) Numbers are four-digit numbers used world-wide in international commerce and transportation to identify hazardous chemicals or classes of hazardous materials. These numbers generally range between 0000 and 3500 and are ideally preceded by the letters 'UN' (for example, 'UN1005') to avoid confusion with other number codes.
 * North American (NA) Numbers are identical to UN numbers. If a material does not have a UN number, it may be assigned an NA number; these are usually 4-digit numbers starting with 8 or 9 such as 9037 (or ideally, NA9037), the NA number for hexachloroethane.
 * UN/NA numbers are required for the shipment of hazardous materials.
 * 01/24/2018  Cathy Doucet Added as per Saravanan for SpeedyG 
 */
export class HazmatRefSearchRecord {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unnaInstId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unnaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	properShipName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageGroup:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classDivision:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classLabel:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	levelCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zoneCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	restrictedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	techNameRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	explosiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	baseDescription:string = undefined;
	/**
	 * This is the timestamp when this row was inserted/updated in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
}
export class OverageImageSearchRecord {
	/**
	 * PK of the SHM_OVRG_IMG_HDR table.
	 * Required: false
	 * Array: false
	 */
	instId:number = undefined;
	/**
	 * Thumbnail of the actual image (could be PNG, BMP, etc.)
	 * Required: false
	 * Array: false
	 */
	thumbnailImage:string = undefined;
	/**
	 * If available, Pro number on the found shipment.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * SIC code of the service center reporting the overage.
	 * Required: false
	 * Array: false
	 */
	reportingSicCd:string = undefined;
	/**
	 * Boolean value that identifies whether Psrt Short/All Short has been paired/matched to a reported Over Shipment.
	 * Required: false
	 * Array: false
	 */
	overPairedWithShortInd:boolean = undefined;
	/**
	 * If available, the Brand of the found shipment.
	 * Required: false
	 * Array: false
	 */
	brand:string = undefined;
	/**
	 * Description of the found shipment.
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * The color of the found shipment.
	 * Required: false
	 * Array: false
	 */
	color:string = undefined;
	/**
	 * The length of the found shipments dimensions.
	 * Required: false
	 * Array: false
	 */
	lengthNbr:number = undefined;
	/**
	 * The width of the found shipments dimensions.
	 * Required: false
	 * Array: false
	 */
	widthNbr:number = undefined;
	/**
	 * The height of the found shipments dimensions.
	 * Required: false
	 * Array: false
	 */
	heightNbr:number = undefined;
	/**
	 * Identifier we get back from the DMS system.
	 * Required: false
	 * Array: false
	 */
	dmsUrl:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageCd:string = undefined;
	/**
	 * Number of pieces
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * If available, the original Pro number on the found shipment.
	 * Required: false
	 * Array: false
	 */
	originalProNbr:string = undefined;
	/**
	 * Comments
	 * Required: false
	 * Array: false
	 */
	comments:string = undefined;
	/**
	 * This is the timestamp when this row was inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * A list of labels associated with this image
	 * Required: false
	 * Array: true
	 */
	label:string[] = undefined;
	/**
	 * Weight in lbs
	 * Required: false
	 * Array: false
	 */
	weightLbs:number = undefined;
	/**
	 * Desc: This field stores ant reference number or product marking for the freight. This can be Model #, Serial #, UPC or Item #.  01/09/2019 Added by Cathy
	 * Required: false
	 * Array: false
	 */
	referenceNbr:string = undefined;
	/**
	 * Desc: This field contains the list of all tags that were selected by the user from the list of suggested tags shown to the user while uploading photos.  01/09/2019 Added by Cathy
	 * Required: false
	 * Array: false
	 */
	selectedTags:string = undefined;
	/**
	 * Desc: Boolean value that identifies whether Part Short/All Short has been paired/matched to part of a reported Over Shipment (partially matched).   01/09/2019  Added by Cathy
	 * Required: false
	 * Array: false
	 */
	partialPairedWithShortInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdTimestamp:Date = undefined;
}
/**
 * Desc: The set of Commodity Package codes and their corresponding designation of whether they can be shipped in Bulk or Non-Bulk.
 */
export class PackagingBulkCdSearchRecord {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	internalId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageNm:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkCd:string = undefined;
	/**
	 * This is the timestamp when this row was inserted/updated in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
}


