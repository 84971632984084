/**
 * Location API
 * Version: 2.0
 * Build: 2.0.0.12
 */

import {
	ListInfo,
	Address,
	LatLong,
	AuditInfo,
	LocationDetailCd,
	PolygonTypeCd
} from '@xpo-ltl/sdk-common';

/**
 * Request to delete a location feature.
 */
export class DeleteLocationFeaturePath {
	/**
	 * The code that represents the location feature to be deleted.
	 * Required: true
	 * Array: false
	 */
	featureCd:string = undefined;
}
/**
 * Request to return the geo area for a location.
 */
export class GetGeoAreasForLocationPath {
	/**
	 * Unique identifier for the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Defines what the polygon coordinates represent.  Possible values are: Area (A), Boundary (B), Fence (F), and Service Center (S).
	 * Required: true
	 * Array: false
	 */
	polygonTypeCd:string = undefined;
}
/**
 * Request to return the geo area for a location.
 */
export class GetGeoAreasForLocationQuery {
	/**
	 * The date used to validate active satellite sics.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Indicates whether satellite SICs for the parent should be included in the response.
	 * Required: false
	 * Array: false
	 */
	satelliteInd:boolean = undefined;
	/**
	 * Indicates whether geoArea shape data should be included in the response.
	 * Required: false
	 * Array: false
	 */
	geoAreaShapeInd:boolean = undefined;
}
/**
 * Response to the request to return the geo area for a location.
 */
export class GetGeoAreasForLocationResp {
	/**
	 * Details about a geo area.
	 * Required: false
	 * Array: true
	 */
	geoArea:GeoArea[] = undefined;
}
/**
 * Path parameter used to return operation service center profitability details for the sicCd provided.
 */
export class GetLocOperationsServiceCenterProfitabilityBySicPath {
	/**
	 * The unique identifier for the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to return operation service center profitability details for the sicCd provided.
 */
export class GetLocOperationsServiceCenterProfitabilityBySicResp {
	/**
	 * The operation service center profitability details for a location.
	 * Required: false
	 * Array: false
	 */
	locOperationsServiceCentersProfitability:LocOperationsSvccProfitability = undefined;
}
/**
 * Request to return the sector by the door number provided.
 */
export class GetSectorByDoorNumberPath {
	/**
	 * The location for which the sector is to be retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The door number for which the sector is to be retrieved.
	 * Required: true
	 * Array: false
	 */
	doorNumber:number = undefined;
}
/**
 * Response to the request to return the sector by the door number provided.
 */
export class GetSectorByDoorNumberResp {
	/**
	 * The sector number associated with the door number.
	 * Required: false
	 * Array: false
	 */
	sectorNumber:number = undefined;
	/**
	 * The location for which the sector is to be retrieved.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The door number for which the sector is to be retrieved.
	 * Required: false
	 * Array: false
	 */
	doorNumber:number = undefined;
}
/**
 * Request to retrieve all active doors by SIC.
 */
export class ListAllActiveDoorsBySicPath {
	/**
	 * The code associated with the service center for which all active doors will be retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query parameters used to return the doors for a location.
 */
export class ListAllActiveDoorsBySicQuery {
	/**
	 * The area inst id for which the door is to be retrieved.
	 * Required: true
	 * Array: false
	 */
	areaInstId:number = undefined;
}
/**
 * Response to the request to list all active doors by a service center.
 */
export class ListAllActiveDoorsBySicResp {
	/**
	 * The list of active doors that are associated with the SIC provided.
	 * Required: false
	 * Array: true
	 */
	activeDoors:Door[] = undefined;
}
/**
 * Request to list all available and unrestricted location features.
 */
export class ListAvailableLocationFeaturesRqst {
}
/**
 * Response to the request to list all available and unrestricted location features.
 */
export class ListAvailableLocationFeaturesResp {
}
/**
 * Request to list a location feature by employee id.
 */
export class ListLocationFeatureByEmployeePath {
	/**
	 * The unique identifier for the employee.
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Response to the request to list a location feature by employee id.
 */
export class ListLocationFeatureByEmployeeResp {
	/**
	 * The location features accessible to the employee.
	 * Required: false
	 * Array: true
	 */
	locationFeatures:FeatureSetting[] = undefined;
}
/**
 * Query to list locations.
 */
export class ListLocationsQuery {
	/**
	 * Indicates if only active locations should be included.
	 * Required: false
	 * Array: false
	 */
	activeInd:boolean = undefined;
	/**
	 * Indicates if zone locations should be included.
	 * Required: false
	 * Array: false
	 */
	zoneDesiredInd:boolean = undefined;
	/**
	 * Indicates if satellite locations should be included.
	 * Required: false
	 * Array: false
	 */
	satelliteDesiredInd:boolean = undefined;
	/**
	 * Indicates if meet and turn SICs should be returned.
	 * Required: false
	 * Array: false
	 */
	meetAndTurnDesiredInd:boolean = undefined;
}
/**
 * Request to list locations
 */
export class ListLocationsRqst {
	/**
	 * List of SIC codes desired. If not supplied, list all locations based on the other criteria.
	 * Required: false
	 * Array: true
	 */
	refSicCd:string[] = undefined;
	/**
	 * A list of group categories to include.
	 * Required: false
	 * Array: true
	 */
	groupCategoryCd:string[] = undefined;
}
/**
 * Response to list locations and their zones and satellites, including its region, city, state, and if they are a FAC or OB.
 */
export class ListLocationsResp {
	/**
	 * A list of locations that match the input criteria.
	 * Required: false
	 * Array: true
	 */
	locationSic:LocationSic[] = undefined;
}
/**
 * Request to list operational service centers.
 */
export class ListOperationalServiceCentersQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list operational service centers.
 */
export class ListOperationalServiceCentersResp {
	/**
	 * A list of operational service centers.
	 * Required: false
	 * Array: true
	 */
	serviceCenters:ServiceCenter[] = undefined;
}
/**
 * Request to insert or update a location feature.
 */
export class UpsertLocationFeatureRqst {
	/**
	 * The location feature which will be created or update the existing etry.
	 * Required: true
	 * Array: false
	 */
	locationFeature:FeatureSetting = undefined;
}
/**
 * Response to the request to insert or update a location feature.
 */
export class UpsertLocationFeatureResp {
	/**
	 * The location feature that was created or updated the existing entry.
	 * Required: false
	 * Array: false
	 */
	locationFeature:FeatureSetting = undefined;
}
/**
 * Request to return the host SIC for a zone.
 */
export class GetHostSicDetailsPath {
	/**
	 * Unique identifier for the zone service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to return the host SIC for a zone.
 */
export class GetHostSicDetailsResp {
	/**
	 * Unique identifier for the zone service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Unique identifier for the host service center.
	 * Required: false
	 * Array: false
	 */
	hostSicCd:string = undefined;
	/**
	 * Identifies if the SIC is zone or satellite.
	 * Required: false
	 * Array: false
	 */
	sicType:string = undefined;
}
/**
 * Request to retrieve location reference details for a service center location.
 */
export class GetLocReferenceDetailsBySicPath {
	/**
	 * The code identifying a given service center
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * The query parameter for the request to get the loc reference for a SIC.
 */
export class GetLocReferenceDetailsBySicQuery {
	/**
	 * The fields that should be returned as part of the response body.
	 * Required: false
	 * Array: true
	 */
	responseFields:LocationDetailCd[] = undefined;
}
/**
 * Response that contains location reference detail information for a service center location.
 */
export class GetLocReferenceDetailsBySicResp {
	/**
	 * Required: false
	 * Array: false
	 */
	locReference:LocationReference = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	locCompanyOperations:CompanyOperations = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	locConWayCorporation:ConWayCorporation = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeToSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeToSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	areaSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	satelliteParentSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	satelliteParentSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zoneParentSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zoneParentSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	linehaulHostSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	linehaulHostSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionLocHierarchyGroup:HierarchyGroup = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	areaLocHierarchyGroup:HierarchyGroup = undefined;
}
/**
 * Request to retrieve detailed information for a service center location.
 */
export class GetServiceCenterDetailsBySicPath {
	/**
	 * The code identifying a given service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response that contains detailed information for a service center location.
 */
export class GetServiceCenterDetailsBySicResp {
	/**
	 * The code identifying a given service center
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code identifying the component for the sic
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * address of service center
	 * Required: false
	 * Array: false
	 */
	address:Address = undefined;
	/**
	 * GIS lat long
	 * Required: false
	 * Array: false
	 */
	coordinates:LatLong = undefined;
	/**
	 * The offset of the timezone.
	 * Required: false
	 * Array: false
	 */
	timezoneOffset:number = undefined;
}
/**
 * Path to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicPath {
	/**
	 * The SIC code for a service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicQuery {
	/**
	 * The true/false indicator denoting if the zone should be returned.
	 * Required: false
	 * Array: false
	 */
	zoneInd:boolean = undefined;
	/**
	 * The true/false indicator denoting if the satellite should be returned.
	 * Required: false
	 * Array: false
	 */
	satelliteInd:boolean = undefined;
}
/**
 * Response to the request to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicResp {
	/**
	 * The SIC used in the search (same as the input SIC).
	 * Required: false
	 * Array: false
	 */
	refSic:string = undefined;
	/**
	 * The region for the import SIC (host region for the sic).
	 * Required: false
	 * Array: false
	 */
	refRegionSic:string = undefined;
	/**
	 * The host of the import SIC if the import SIC was a zone.
	 * Required: false
	 * Array: false
	 */
	refZoneSic:string = undefined;
	/**
	 * The host of the import SIC if the import SIC was a satellite.
	 * Required: false
	 * Array: false
	 */
	refSatelliteSic:string = undefined;
	/**
	 * The SIC code for a service center.
	 * Required: false
	 * Array: true
	 */
	zoneSatelliteInfo:ZoneSatelliteInfo[] = undefined;
}
/**
 * Request to list all active SICs.
 */
export class ListAllActiveSicsRqst {
}
/**
 * Response to the request to list all active service service centers and their region, city, state, and if they are a FAC or OB.
 */
export class ListAllActiveSicsResp {
	/**
	 * A list of active service centers and their details.
	 * Required: false
	 * Array: true
	 */
	activeSics:ActiveSic[] = undefined;
}
/**
 * Request to retrieve all sic codes by region.
 */
export class ListAllSicCdsByRegionPath {
	/**
	 * The code identifying the region.
	 * Required: true
	 * Array: false
	 */
	locGroupCode:string = undefined;
}
/**
 * Response to the request to list sic codes by region code.
 */
export class ListAllSicCdsByRegionResp {
	/**
	 * A list of the sic codes.
	 * Required: false
	 * Array: true
	 */
	sicCds:string[] = undefined;
}
/**
 * Path parameter used to return a list of values for a location feature.
 */
export class ListLocationFeatureValuesByFeaturePath {
	/**
	 * The code that represents the feature for which details are to be retrieved.
	 * Required: true
	 * Array: false
	 */
	featureCd:string = undefined;
}
/**
 * Query parameter used to return a list of values for a location feature.
 */
export class ListLocationFeatureValuesByFeatureQuery {
	/**
	 * The value attached to the specific feature. For example - 'Y'.
	 * Required: false
	 * Array: false
	 */
	settingValue:string = undefined;
}
/**
 * Response to the request to return a list of values for a location feature.
 */
export class ListLocationFeatureValuesByFeatureResp {
	/**
	 * A list of location features associated with the feature code and optionally the feature value.
	 * Required: false
	 * Array: true
	 */
	locationFeatures:FeatureSetting[] = undefined;
}
/**
 * Query to retrieve a list location group codes.
 */
export class ListLocationGroupCodesQuery {
	/**
	 * The category code groups the SICs by linehaul region.
	 * Required: false
	 * Array: false
	 */
	groupCategoryCd:string = undefined;
	/**
	 * Used to filter the list by a specific location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to list operational service centers.
 */
export class ListLocationGroupCodesResp {
	/**
	 * A list of the regions along with the SICs that are part of that region.
	 * Required: false
	 * Array: true
	 */
	locationGroupCodes:LocationGroupCode[] = undefined;
}
/**
 * Details about an active service center.
 */
export class ActiveSic {
	/**
	 * The code associated with the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code associated with the group that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	groupCd:string = undefined;
	/**
	 * The region that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	region:string = undefined;
	/**
	 * The ciity for service center.
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * The offset of the service center.
	 * Required: false
	 * Array: false
	 */
	serviceCenterOffset:string = undefined;
	/**
	 * The state for the service center.
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * Returns 1 if SIC is a FAC.
	 * Required: false
	 * Array: false
	 */
	isFac:number = undefined;
	/**
	 * Returns 1 if SIC is a OB.
	 * Required: false
	 * Array: false
	 */
	isOb:number = undefined;
	/**
	 * A description for the location.
	 * Required: false
	 * Array: false
	 */
	sicDescription:string = undefined;
}
/**
 * Details about a geo area.
 */
export class GeoArea {
	/**
	 * The unique identifier for the geo area.
	 * Required: false
	 * Array: false
	 */
	areaInstId:string = undefined;
	/**
	 * The SIC code for the location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The name for the geo area.
	 * Required: false
	 * Array: false
	 */
	geoAreaName:string = undefined;
	/**
	 * The description for the geo area.
	 * Required: false
	 * Array: false
	 */
	geoAreaDesc:string = undefined;
	/**
	 * Defines what the polygon coordinates represent.  Possible values are: Area (A), Boundary (B), Fence (F), and Service Center (S).
	 * Required: false
	 * Array: false
	 */
	polygonTypeCd:PolygonTypeCd = undefined;
	/**
	 * The coordinates for the locations.
	 * Required: false
	 * Array: true
	 */
	polygon:LatLong[] = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Details about a linehaul region, which is a location group code.
 */
export class LocationGroupCode {
	/**
	 * The region
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * The list of sic code that belongs to that region.
	 * Required: false
	 * Array: true
	 */
	sicCds:string[] = undefined;
}
/**
 * Details about an active service center.
 */
export class LocationSic {
	/**
	 * The code associated with the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code associated with the group that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	groupCd:string = undefined;
	/**
	 * The region that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	region:string = undefined;
	/**
	 * The ciity for service center.
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * The offset of the service center.
	 * Required: false
	 * Array: false
	 */
	serviceCenterOffset:string = undefined;
	/**
	 * The state for the service center.
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * Returns 1 if SIC is a FAC.
	 * Required: false
	 * Array: false
	 */
	isFac:number = undefined;
	/**
	 * Returns 1 if SIC is a OB.
	 * Required: false
	 * Array: false
	 */
	isOb:number = undefined;
	/**
	 * A description for the location.
	 * Required: false
	 * Array: false
	 */
	sicDescription:string = undefined;
	/**
	 * The SIC of the location hosting this satellite.
	 * Required: false
	 * Array: false
	 */
	parentSatelliteSic:string = undefined;
	/**
	 * The SIC of the service center that hosts the zone.
	 * Required: false
	 * Array: false
	 */
	zoneTerminalSic:string = undefined;
	/**
	 * Indicator to identify this SIC as a satellite.
	 * Required: false
	 * Array: false
	 */
	aSatelliteSic:boolean = undefined;
	/**
	 * Indicator to identify this SIC as a zone.
	 * Required: false
	 * Array: false
	 */
	aZoneSic:boolean = undefined;
}
/**
 * Details about a service center.
 */
export class ServiceCenter {
	/**
	 * Address infomration for the service center.
	 * Required: false
	 * Array: false
	 */
	locAddress:LocationAddress = undefined;
	/**
	 * The SIC code for the location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Reference details about a service center.
	 * Required: false
	 * Array: false
	 */
	reference:LocationReference = undefined;
}
/**
 * Details about zone and satellite.
 */
export class ZoneSatelliteInfo {
	/**
	 * The SIC code for the region.
	 * Required: false
	 * Array: false
	 */
	regionSic:string = undefined;
	/**
	 * The SIC code for the location.
	 * Required: false
	 * Array: false
	 */
	locationSic:string = undefined;
	/**
	 * The parent satellite.
	 * Required: false
	 * Array: false
	 */
	satelliteParent:string = undefined;
	/**
	 * The zone location of the terminal.
	 * Required: false
	 * Array: false
	 */
	zoneTerminal:string = undefined;
}
/**
 * 
 */
export class LocationAddress {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationAddressEffDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	altAddressType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addr1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addr2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addr3:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	boxNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countrySubdivisionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countrySubdivisionName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryInstructionDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nm1Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nm2Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prmyAddressFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalExtCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pyrlCntr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pyrlCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pyrlEnvs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoCodedFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalCertifiedFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class AreaAssignment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rsnbleDisplayTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryRouteDepartTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pupOnlyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CompanyOperations {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyNameCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	menloOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rsiOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityOperationsFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	clasSzCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operations24HrFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dcmntImageFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displayToFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dpndtFreightType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dpndtSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dir:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	facFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	facHrsOfOpnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightOperationsFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightServiceAreaFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightServiceAreaType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fuelFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hstedSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	intmdlType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedToFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	liftFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lnhDayMeetFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lnhNightMeetFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rrSpurFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safetyClasSzCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	satlType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentOriginDestinationFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceSatlType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	termProdFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	termStmsLocationFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tieFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	workDoorCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	warmRoomFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	yardMeetAndTurnFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightLtlMovementFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightLtlMaintFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	maintTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	milemakerSplcNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ConWayCorporation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cnwyCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	altCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmptCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmptName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyLogoCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	glCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltCorp3CharName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sobCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltT60MajCorp:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyNameCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activeFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Door {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displaySeqNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doorNbrTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hzntlCoordNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	vrtclCoordNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sectorId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pupOnlyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dtlCapxTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replLstUpdtTmst:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlTmst:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DpndtCityOperationsLnk {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parntSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	childSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dpndtFreightServiceDayCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class FeatureSetting {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	settingValue:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	settingValueType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocFreightPostalArea {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countrySubdivisionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoPostalCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightPostalAreaEffDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activeFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dirFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expediteServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hostSicCd:string = undefined;
}
/**
 * 
 */
export class HierarchyCategory {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCategoryName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HierarchyGroup {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupRefSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltAreaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltNameA:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltNameB:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltRgnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HrchyGroupMbr {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupRefSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocOperationsSvccProfitability {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoprtPdManifestCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundAvgLoadedWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundManPowerLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundPpldLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundPpldCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prideStudyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupConfrmInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeVolumeThrshLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	laserPrtr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	linePrtr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoprtObManifestCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoprtDsrLastCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadPlanInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	smart4RolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sctrBalInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrFacInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrFacRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrInboundInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrInboundRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoptDsrTripCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mimsOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mimsRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockOperationsRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inboundArrInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inboundArrRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	avgOutboundDensity:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	avgOutboundPceVolumeCubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationReference {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpOfcFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dstFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finFuncCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finScndFuncCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsBgnDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsEndDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pdtTimeOffset:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physLocationType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prvtFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicScpType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltPrimarySequence:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltTermAbbrvName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	kronFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lseExpiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ownOrLseCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltStateAbbrv:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pdtTimeOffsetInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	slsSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timezoneName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mrcCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanLocationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanLocationFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldXrefCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mileageFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rgnSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safetyClasSzCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physSiteLatd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physSiteLngt:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpCompanyCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpProfitCenterCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpDepartmentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class GroupMember {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Sic {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scac:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	termName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	phoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
}


