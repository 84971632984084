import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	ListExpectationsResp,
	ListExpectationsQuery,
	ListExpectationsPath,
	CreateExpectationsRqst,
	CreateExpectationsResp,
	CreateExpectationsPath,
	UpdateExpectationStatusRqst,
	UpdateExpectationStatusPath,
	CreateExceptionRqst,
	CreateExceptionResp,
	CreateExceptionPath,
	SearchExceptionsRqst,
	SearchExceptionsResp,
	ListExceptionsResp,
	ListExceptionsQuery,
	CreateExpectationTypeRqst,
	CreateExpectationTypeResp,
	CreateExpectationTypePath,
	DeleteExpectationTypeResp,
	DeleteExpectationTypePath,
	ListExpectationTypesResp,
	UpdateExpectationTypeRqst,
	UpdateExpectationTypeResp,
	UpdateExpectationTypePath,
	UpsertExpectationTypeRoleRqst,
	UpsertExpectationTypeRoleResp,
	DeleteExpectationTypeRoleQuery,
	DeleteExpectationTypeRolePath,
	SearchExpectationUnfilteredRqst,
	SearchExpectationUnfilteredResp,
	ChangesFeedExpectationUnfilteredRqst,
	RegisterFilterExpectationUnfilteredRqst,
	RegisterFilterExpectationUnfilteredResp,
	UnregisterFilterExpectationUnfilteredRqst,
	DeleteElasticDocumentExpectationResp,
	DeleteElasticDocumentExpectationPath,
	GetElasticDocumentExpectationResp,
	GetElasticDocumentExpectationQuery,
	GetElasticDocumentExpectationPath,
	GetElasticDocumentExpectationCountResp,
	GetElasticDocumentExpectationCountQuery,
	UpdateElasticDocumentExpectationRqst,
	UpdateElasticDocumentExpectationResp,
	UpdateElasticDocumentExpectationPath
} from './api-exceptionmanagement';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class ExceptionManagementApiService extends BaseService {
	private static ExceptionManagementApiEndpoint = 'exceptionmanagementApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists expectations based on filters that are selected.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Optional query paramters are selected.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If there are expectations that match the filters, then a list of them is returned.
	* <br/>2. Otherwise a not found message is returned.
	* <br/>
	*/
	public listExpectations(
							   pathParams: ListExpectationsPath,
							   queryParams: ListExpectationsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExpectationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations/{expectationOwnerTypeCd}/{expectationOwnerId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Create expectations for an entity. E.g. SIC, Door, Trailer, Shipment etc.
	* <br/>        Pre-condition: A valid list of ExpectationHeader is passed in.
	* <br/>        	Required: typeCd
	* <br/>        		At least one of shiftCd, expectationSic, doorNbr, trailerId or proNbr
	* <br/>        		At least one expectationDetail
	* <br/>        Post-condition: The list of ExpectationHeaders is persisted to the database
	* <br/>        
	*/
	public createExpectations(request: CreateExpectationsRqst,
							   pathParams: CreateExpectationsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateExpectationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations/{typeCd}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Update expectation status
	* <br/>        Required: expectationInstId, expectationDetailSequenceNbr and the new status
	* <br/>        
	*/
	public updateExpectationStatus(request: UpdateExpectationStatusRqst,
							   pathParams: UpdateExpectationStatusPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations/{expectationInstId}/{expectationDetailSequenceNbr}/status'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Creates a freight exception.
	*/
	public createException(request: CreateExceptionRqst,
							   pathParams: CreateExceptionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateExceptionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations/{expectationInstId}/{expectationDetailSequenceNbr}/exceptions'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search Exceptions
	* <br/>        Pre-condition: Filter criteria is supplied
	* <br/>        Post-condition: List of exceptions that fit the supplied filter is returned
	* <br/>        
	*/
	public searchExceptions(request: SearchExceptionsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchExceptionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/searches/exceptions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* List exceptions based on the filters selected.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The following fields are optional and used for filtering: 
	* <br/>- sicCd
	* <br/>- shiftCd
	* <br/>- date
	* <br/>- regionCd
	* <br/>- exceptionType
	* <br/>- trailerNbr
	* <br/>- statusCd
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. A list of exceptions based on the provided parameters is returned.
	* <br/>2. If none are found, then a not found message is returned.
	*/
	public listExceptions(
							   queryParams: ListExceptionsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExceptionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/exceptions'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates an expectation type.
	*/
	public createExpectationType(request: CreateExpectationTypeRqst,
							   pathParams: CreateExpectationTypePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateExpectationTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-types/{typeCd}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Deletes an expectation type based on code and description.
	*/
	public deleteExpectationType(
							   pathParams: DeleteExpectationTypePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteExpectationTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-type/{typeCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Enter description
	*/
	public listExpectationTypes(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExpectationTypesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectations-types'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Enter description
	*/
	public updateExpectationType(request: UpdateExpectationTypeRqst,
							   pathParams: UpdateExpectationTypePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateExpectationTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-type/{typeCd}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Upserts an expectation type role.
	*/
	public upsertExpectationTypeRole(request: UpsertExpectationTypeRoleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertExpectationTypeRoleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-type-role'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Deletes an expectation type role based on expecation type role instance ID, employee role ID, and type code.
	*/
	public deleteExpectationTypeRole(
							   pathParams: DeleteExpectationTypeRolePath,
							   queryParams: DeleteExpectationTypeRoleQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-type-roles/{expectationTypeRoleInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation returns all shipments that need to be planned for delivery today based on the requested wave criteria.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The service center is provided and valid and there is a valid current wave defined.  No wave is provided as input.
	* <br/>2. Alternatively, a service center and a valid wave are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All shipments meeting the criteria of the service center's current, active wave are returned.
	* <br/>2. All shipments meeting the criteria of the named wave for the service center are returned.
	*/
	public searchExpectationUnfiltered(request: SearchExpectationUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchExpectationUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-unfiltered/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for expectation-unfiltered
	*/
	public changesFeedExpectationUnfiltered(request: ChangesFeedExpectationUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-unfiltered/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for ExpectationUnfiltered
	*/
	public registerFilterExpectationUnfiltered(request: RegisterFilterExpectationUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterExpectationUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-unfiltered/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for ExpectationUnfiltered
	*/
	public unregisterFilterExpectationUnfiltered(request: UnregisterFilterExpectationUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/expectation-unfiltered/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete Elastic document for Expectation
	*/
	public deleteElasticDocumentExpectation(
							   pathParams: DeleteElasticDocumentExpectationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteElasticDocumentExpectationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/expectation-unfiltered/_doc/{expectationInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Get Elastic document from Expectation index for given primary key
	*/
	public getElasticDocumentExpectation(
							   pathParams: GetElasticDocumentExpectationPath,
							   queryParams: GetElasticDocumentExpectationQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentExpectationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/expectation-unfiltered/_doc/{expectationInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get document count from Expectation index
	*/
	public getElasticDocumentExpectationCount(
							   queryParams: GetElasticDocumentExpectationCountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentExpectationCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/expectation-unfiltered/_count'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update Elastic document for Expectation
	*/
	public updateElasticDocumentExpectation(request: UpdateElasticDocumentExpectationRqst,
							   pathParams: UpdateElasticDocumentExpectationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateElasticDocumentExpectationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/expectation-unfiltered/_doc/{expectationInstId}/_update'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(ExceptionManagementApiService.ExceptionManagementApiEndpoint);
	}
}
