import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetLookerLooksResp,
	GetLookerLooksQuery,
	GetLookerLooksDetailResp,
	GetLookerLooksDetailPath,
	GetLookerSSOUrlRqst,
	GetLookerSSOUrlResp,
	GetLookerAccessTokenRqst,
	GetLookerAccessTokenResp
} from './api-analytics';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class AnalyticsApiService extends BaseService {
	private static AnalyticsApiEndpoint = 'analyticsV2ApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Execute the search look API to get the look id for a given look.  Lookid are specific to looker instances.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid search criteria (Query String parms title/space_id)
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, returns looker data / metadata.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getLookerLooks(
							   queryParams: GetLookerLooksQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLookerLooksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/looker/looks'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Execute the search look API to get the look id for a given look.  Lookid are specific to looker instances.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid search criteria (Path String parms lookId/resultFormat)
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, returns looker data / metadata.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getLookerLooksDetail(
							   pathParams: GetLookerLooksDetailPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLookerLooksDetailResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/looker/looks/{lookId}/formats/{resultFormat}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns a SSO URL that can be embedded into an application utilizing the access_token provided by the Login API.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid target_url, session_length, force_logout_login, and looker_auth must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the SSO URL is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getLookerSSOUrl(request: GetLookerSSOUrlRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLookerSSOUrlResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/looker/sso-url'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation returns an access token from Looker.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. An empty payload must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then an access token is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getLookerAccessToken(request: GetLookerAccessTokenRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLookerAccessTokenResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/looker/token'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(AnalyticsApiService.AnalyticsApiEndpoint);
	}
}
