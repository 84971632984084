/**
 * Customer API
 * Version: 2.0
 * Build: 2.0.0.21
 */

import {
	Address,
	LatLong,
	AuditInfo,
	GeoCoordinates,
	ActionCd,
	ContactRoleCd,
	CredStatCd,
	CustomerMatchResultTypeCd,
	CustomerStatusCd,
	CustomerTypeCd,
	FinalStandardAddressSourceCd
} from '@xpo-ltl/sdk-common';

export class DeleteCisCustomerAliasPath {
	/**
	 * The unique ID of the customer in the CIS system.
	 * Required: true
	 * Array: false
	 */
	customerId:string = undefined;
	/**
	 * The sequence number for identifying the tuple Name-Address.
	 * Required: true
	 * Array: false
	 */
	sequenceNbr:string = undefined;
}
export class DeleteCisCustomerAliasQuery {
	/**
	 * The code that identifies the customer type.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * The unique identifier in the old CIS system.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * The unique identifier for the CIS request.
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
}
export class DeleteCisDb2CustomerAliasPath {
	/**
	 * The unique legacy ID of the customer in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	legacyCustomerId:string = undefined;
	/**
	 * Identifies the table from which the Alias will be deleted.Specifies the type of customer the alias is for in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * The sequence number that uniquely identifies the alias for a customer in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	legacyAliasSeqNbr:number = undefined;
}
export class GetCisCustomerAliasesPath {
	/**
	 * The identifier of the customer/location.
	 * Required: true
	 * Array: false
	 */
	customerLocationFuncId:number = undefined;
}
export class GetCisCustomerAliasesQuery {
	/**
	 * The sequence number that uniquely identifies the address alias for a customer.
	 * Required: false
	 * Array: false
	 */
	customerLocationAliasSequenceNbr:number = undefined;
}
export class GetCisCustomerAliasesResp {
	/**
	 * Required: false
	 * Array: true
	 */
	cisAliases:CustomerLocationFuncAlias[] = undefined;
}
export class UpsertCisCustomerAliasPath {
	/**
	 * The unique ID of the customer in the CIS system.
	 * Required: true
	 * Array: false
	 */
	customerId:string = undefined;
}
export class UpsertCisCustomerAliasRqst {
	/**
	 * Information about the customer alias.
	 * Required: true
	 * Array: false
	 */
	customerAlias:CustomerLocationFuncAlias = undefined;
	/**
	 * The code that identifies the customer type.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * Used to manage the remarks.
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * The unique identifier for the CIS request.
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * The unique identifier for the CIS request.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * Information needed for the approval of the requested change.
	 * Required: false
	 * Array: false
	 */
	requestedChangeApproval:RequestedChangeApproval = undefined;
}
export class UpsertCisCustomerAliasResp {
	/**
	 * Information about the customer alias.
	 * Required: false
	 * Array: false
	 */
	customerAlias:CustomerLocationFuncAlias = undefined;
}
export class UpsertCisDb2CustomerAliasPath {
	/**
	 * The unique legacy ID of the customer in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	legacyCustomerId:string = undefined;
}
export class UpsertCisDb2CustomerAliasRqst {
	/**
	 * The sequence number that uniquely identifies the alias for a customer in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	legacyAliasSeqNbr:number = undefined;
	/**
	 * Specifies the type of customer the alias is for in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * Information about the customer alias.
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncAlias:CustomerLocationFuncAlias = undefined;
}
export class UpsertCisDb2CustomerAliasResp {
	/**
	 * Information about the customer party.
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncAlias:CustomerLocationFuncAlias = undefined;
}
export class DeleteCisCustomerContactPath {
	/**
	 * The unique ID of the customer in the CIS system.
	 * Required: true
	 * Array: false
	 */
	customerId:string = undefined;
	/**
	 * The unique ID of the contact.
	 * Required: true
	 * Array: false
	 */
	contactId:string = undefined;
}
export class DeleteCisCustomerContactQuery {
	/**
	 * The role that is played by the contact person for this customer location functio.The reason for contacting the person at the customer location
	 * Required: true
	 * Array: false
	 */
	contactRoleCd:ContactRoleCd = undefined;
	/**
	 * The code that identifies the customer type.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * The unique identifier for the CIS request.
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * The unique identifier in the old CIS system.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
}
export class GetCisContactDetailsPath {
	/**
	 * The NCIS generated internal identifier for the contact person.
	 * Required: true
	 * Array: false
	 */
	contactPersonId:number = undefined;
}
export class GetCisContactDetailsResp {
	/**
	 * Information about the customer relationships.
	 * Required: false
	 * Array: true
	 */
	contactCustomerRelationships:ContactCustomerRelationship[] = undefined;
	/**
	 * Information about the customer contact.
	 * Required: false
	 * Array: false
	 */
	contactPerson:ContactPerson = undefined;
}
export class UpsertCisCustomerContactPath {
	/**
	 * The unique ID of the customer in the CIS system.
	 * Required: true
	 * Array: false
	 */
	customerId:string = undefined;
}
export class UpsertCisCustomerContactRqst {
	/**
	 * The code that identifies the customer type.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * The unique identifier for the CIS request.
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * The unique identifier in the old CIS system.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * Information needed for the approval of the requested change.
	 * Required: false
	 * Array: false
	 */
	requestedChangeApproval:RequestedChangeApproval = undefined;
	/**
	 * The role that is played by the contact person for this customer location function.In other words the reason for contacting the person at the customer location.The customer location function is identified by MAD Code.
	 * Required: false
	 * Array: false
	 */
	newContactRoleCd:ContactRoleCd = undefined;
	/**
	 * Information about the customer contact.
	 * Required: false
	 * Array: false
	 */
	customerContact:CustomerContact = undefined;
}
export class UpsertCisCustomerContactResp {
	/**
	 * Information about the customer contact.
	 * Required: false
	 * Array: false
	 */
	customerContact:CustomerContact = undefined;
}
export class DeleteCisDb2ContactPath {
	/**
	 * The unique legacy ID of the customer in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	legacyCustomerId:string = undefined;
	/**
	 * Identifies the table from which the Contact will be deleted.  Specifies the type of customer the contact is for in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * The unique ID of the contact in the legacy CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	keyNbr:number = undefined;
}
export class UpsertCisDb2ContactPath {
	/**
	 * The unique legacy ID of the customer in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	legacyCustomerId:string = undefined;
}
export class UpsertCisDb2ContactRqst {
	/**
	 * Specifies the type of customer the alias is for in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * Information about the customer contact.
	 * Required: true
	 * Array: false
	 */
	contact:Contact = undefined;
}
export class UpsertCisDb2ContactResp {
	/**
	 * Information about the customer contact.
	 * Required: false
	 * Array: false
	 */
	contact:Contact = undefined;
}
/**
 * Request to create a customer for NCIS.
 */
export class CreateCisCustomerRqst {
	/**
	 * The code that identifies the customer type.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * The unique identifier for the CIS request.
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * These comments added by users (generally coding dept) when a row is added, updated. Typically it describes the reason for update.
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * Information needed for the approval of the requested change.
	 * Required: false
	 * Array: false
	 */
	requestedChangeApproval:RequestedChangeApproval = undefined;
	/**
	 * Information about the customer party or name 2 of a customer location
	 * Required: false
	 * Array: false
	 */
	cisPartyName2:CisPartyName2 = undefined;
	/**
	 * Information about the customer party or name 1 of a customer location.
	 * Required: true
	 * Array: false
	 */
	customerParty:Party = undefined;
	/**
	 * Information about the customer address.
	 * Required: true
	 * Array: false
	 */
	customerAddress:CustomerAddress = undefined;
	/**
	 * Information about the customer location function.
	 * Required: true
	 * Array: false
	 */
	customerLocationFunction:CustomerLocationFunction = undefined;
}
/**
 * Response to the request to update the geo coordinates for a customer.
 */
export class CreateCisCustomerResp {
	/**
	 * Used to manage the name 2.
	 * Required: false
	 * Array: false
	 */
	cisPartyName2:CisPartyName2 = undefined;
	/**
	 * Information about the customer party.
	 * Required: false
	 * Array: false
	 */
	customerParty:Party = undefined;
	/**
	 * Information about the customer address.
	 * Required: false
	 * Array: false
	 */
	customerAddress:CustomerAddress = undefined;
	/**
	 * Information about the customer location function.
	 * Required: false
	 * Array: false
	 */
	customerLocationFunction:CustomerLocationFunction = undefined;
}
export class GetCisCustomerDetailsPath {
	/**
	 * The ID associated with the customer in the CIS system.This can be either the legacyCisCustomerNbr or madCode depending on the customerIdType.
	 * Required: true
	 * Array: false
	 */
	id:string = undefined;
}
export class GetCisCustomerDetailsQuery {
	/**
	 * The type of identifier that is being provided for the search.It will be either legacyCisCustomerNbr or madCode.The default value is legacyCisCustomerNbr.
	 * Required: true
	 * Array: false
	 */
	customerIdType:string = undefined;
}
export class GetCisCustomerDetailsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	customerLocationFunction:CustomerLocationFunction = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerParty:Party = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cisPartyName2:CisPartyName2 = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerAddress:CustomerAddress = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerLocationFunctionChildrenCount:CustomerLocationFunctionChildrenCount = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customerAliases:CustomerLocationFuncAlias[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customerContacts:CustomerContact[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerServiceRecipient:ServiceRecipient = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerCollectGroup:CollectGroup = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerCollectorTeamAssign:CollectorTeamAssign = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerSalesTerritoryAssign:SalesTerritoryAssignment = undefined;
}
/**
 * Request to determine duplicate address groupings for the list of input Customer name and postal addresses.
 */
export class DetermineDuplicateCustomerAddressGroupingRqst {
	/**
	 * The Customer name and postal addresses for which the duplicate address grouping is being determined.
	 * Required: false
	 * Array: true
	 */
	customerAddresses:CustomerNameAndAddress[] = undefined;
}
/**
 * Response to the request to determine duplicate address groupings for the list of input Customer postal addresses.
 */
export class DetermineDuplicateCustomerAddressGroupingResp {
	/**
	 * Standardized Customer address grouping results
	 * Required: false
	 * Array: true
	 */
	duplicateCustomerAddressGroupings:CustomerAddressGrouping[] = undefined;
}
/**
 * Request to match input party to a Customer Party
 */
export class MatchCustomerRqst {
	/**
	 * The type of customer.  Valid Values: BillTo
	 * Corporate
	 * Future
	 * PickupDelivery
	 * ServiceRecipient
	 * Required: false
	 * Array: false
	 */
	customerTypeCd:CustomerTypeCd = undefined;
	/**
	 * Party to be matched.
	 * Required: false
	 * Array: false
	 */
	customerNameAndAddress:Address = undefined;
}
/**
 * Response to the request to match input customer existing Customers.
 */
export class MatchCustomerResp {
	/**
	 * Standardized address
	 * Required: false
	 * Array: false
	 */
	finalStandardAddress:Address = undefined;
	/**
	 * Source of standardized address
	 * Required: false
	 * Array: false
	 */
	finalStandardAddressSource:FinalStandardAddressSourceCd = undefined;
	/**
	 * Address from Google address validation
	 * Required: false
	 * Array: false
	 */
	googleAddress:string = undefined;
	/**
	 * Latitude number of the address
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * Longitude number of the address.
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * Number of Matched Parties returned
	 * Required: false
	 * Array: false
	 */
	numberOfMatchingResults:number = undefined;
	/**
	 * Address from Pitney Bowes address validation
	 * Required: false
	 * Array: false
	 */
	pitneyBowesAddress:string = undefined;
	/**
	 * Indicates whether record matching is against the alias (true) or customer (false).
	 * Required: false
	 * Array: false
	 */
	isAliasMatch:boolean = undefined;
	/**
	 * Indicates whether record matching is identical (true) or similar (false).
	 * Required: false
	 * Array: false
	 */
	matchInd:boolean = undefined;
	/**
	 * Matched customer results
	 * Required: false
	 * Array: true
	 */
	matchedCustomers:CustomerMatch[] = undefined;
}
export class DeleteCisDb2CustomerHeaderPath {
	/**
	 * The unique ID of the customer in the CIS system.
	 * Required: true
	 * Array: false
	 */
	customerId:number = undefined;
	/**
	 * Identifies the table from which the CustomerHeader info will be deleted.  Specifies the type of the customer in the CIS DB2 system.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
}
export class UpsertCisDb2CustomerHeaderPath {
	/**
	 * The unique ID of the customer in the CIS system.
	 * Required: true
	 * Array: false
	 */
	customerId:number = undefined;
}
export class UpsertCisDb2CustomerHeaderRqst {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billing2Location:Bil2Location = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpHrchy:CorpHrchy = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDeliveryLocation:PickupDeliveryLocation = undefined;
}
export class UpsertCisDb2CustomerHeaderResp {
	/**
	 * 
	 * Required: true
	 * Array: false
	 */
	custNumber:number = undefined;
}
export class GetCisCustomerHierarchyQuery {
	/**
	 * A meanigful code that is used by users to identify  the customer/location.
	 * Required: false
	 * Array: false
	 */
	madCd:string = undefined;
	/**
	 * The number associated with the legacy CIS customer.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * Identifies whether the query is to retrieve the offspring or the parents.
	 * Required: true
	 * Array: false
	 */
	customerHierarchyType:string = undefined;
}
export class GetCisCustomerHierarchyResp {
	/**
	 * Required: false
	 * Array: true
	 */
	customerHierarchies:CustomerHierarchy[] = undefined;
}
/**
 * 
 */
export class GetCisImmediateHierarchyQuery {
	/**
	 * The unique identifier for the customer in the old CIS system.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * The business identifier for a customer which is made up of a prefix, location, and suffix.
	 * Required: false
	 * Array: false
	 */
	madCode:string = undefined;
}
/**
 * 
 */
export class GetCisImmediateHierarchyResp {
	/**
	 * The nodes associated with the location hierarchy.
	 * Required: false
	 * Array: true
	 */
	locationNodes:LocationNode[] = undefined;
}
export class GetCisCustomerHistoryQuery {
	/**
	 * A meanigful code that is used by users to identify  the customer/location.
	 * Required: false
	 * Array: false
	 */
	madCd:string = undefined;
	/**
	 * The number associated with the legacy CIS customer.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
}
export class GetCisCustomerHistoryResp {
	/**
	 * Required: false
	 * Array: true
	 */
	customerHistoryItems:CustomerHistoryItem[] = undefined;
}
export class GetCisDb2InvoiceInstructionsQuery {
	/**
	 * The unique identifier of a customer.
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * Invoice instruction's customer type. Possible values: PickupDelivery and Bill To (P, B)
	 * Required: false
	 * Array: false
	 */
	customerType:string = undefined;
}
export class GetCisDb2InvoiceInstructionsResp {
	/**
	 * A list of information that splits the invoice requirements based on bill type (prepaid or collect) and invoice type (original, past due invoice, past due statement, balance due, and corrected).
	 * Required: false
	 * Array: true
	 */
	invoiceCriteria:InvoiceCriteria[] = undefined;
	/**
	 * A list of information that will override the defaults for invoicing rules. It can be associated with a specific CIS_PKUP_DLVR_LOC or a CIS_BIL2_LOC.  This is used to more accurately define invoice instructions for a location.
	 * Required: false
	 * Array: true
	 */
	invoiceTypes:InvoiceType[] = undefined;
}
export class UpdateCisDb2InvoiceInstructionRqst {
	/**
	 * Information that splits the invoice requirements based on bill type (prepaid or collect) and invoice type (original, past due invoice, past due statement, balance due, and corrected).
	 * Required: true
	 * Array: false
	 */
	invoiceType:InvoiceType = undefined;
}
export class UpdateCisDb2InvoiceInstructionResp {
	/**
	 * Information that will override the defaults for invoicing rules. It can be associated with a specific CIS_PKUP_DLVR_LOC or a CIS_BIL2_LOC.  This is used to more accurately define invoice instructions for a location.
	 * Required: false
	 * Array: false
	 */
	invoiceType:InvoiceType = undefined;
}
export class UpsertCisCustomerNameAndAddressPath {
	/**
	 * The unique ID of the customer in the CIS system.
	 * Required: true
	 * Array: false
	 */
	customerId:string = undefined;
}
export class UpsertCisCustomerNameAndAddressRqst {
	/**
	 * The code that identifies the customer type.
	 * Required: true
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * The status code of a customer.
	 * Required: true
	 * Array: false
	 */
	statusCd:CustomerStatusCd = undefined;
	/**
	 * The unique identifier for the CIS request.
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * The unique identifier in the old CIS system.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * Used to manage the remarks.
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * Information needed for the approval of the requested change.
	 * Required: false
	 * Array: false
	 */
	requestedChangeApproval:RequestedChangeApproval = undefined;
	/**
	 * Used to manage the name 2.
	 * Required: false
	 * Array: false
	 */
	cisPartyName2:CisPartyName2 = undefined;
	/**
	 * Information about the customer party.
	 * Required: false
	 * Array: false
	 */
	customerParty:Party = undefined;
	/**
	 * Information about the customer address.
	 * Required: false
	 * Array: false
	 */
	customerAddress:CustomerAddress = undefined;
}
export class UpsertCisCustomerNameAndAddressResp {
	/**
	 * Information about the customer party.
	 * Required: false
	 * Array: false
	 */
	cisPartyName2:CisPartyName2 = undefined;
	/**
	 * Information about the customer party.
	 * Required: false
	 * Array: false
	 */
	customerParty:Party = undefined;
	/**
	 * Information about the customer address.
	 * Required: false
	 * Array: false
	 */
	customerAddress:CustomerAddress = undefined;
}
export class GetCisPaymentTermsRqst {
}
export class GetCisPaymentTermsResp {
	/**
	 * A list of payment terms from the properties file.
	 * Required: false
	 * Array: true
	 */
	paymentTerms:PaymentTerm[] = undefined;
}
/**
 * Path parameter used to retrieve details for a customer location operations profile.
 */
export class GetCustomerLocationOperationsProfilePath {
	/**
	 * The number associated with the customer's account.
	 * Required: true
	 * Array: false
	 */
	customerAcctNbr:number = undefined;
}
/**
 * Response to the request to retrieve all details for a customer location operations profile using the customer number.
 */
export class GetCustomerLocationOperationsProfileResp {
	/**
	 * Contains information related to a place where our customer does business with us.
	 * Required: false
	 * Array: false
	 */
	acctDetail:InterfaceAcct = undefined;
	/**
	 * Details for the customer profile.
	 * Required: false
	 * Array: false
	 */
	customerProfile:CustomerProfile = undefined;
	/**
	 * Details for the customer contacts.
	 * Required: false
	 * Array: true
	 */
	customerContacts:Contact[] = undefined;
	/**
	 * Details of the customer operation including pickup and delivery options.
	 * Required: false
	 * Array: false
	 */
	customerOperation:CustomerOperation = undefined;
}
/**
 * Path parameter used to update the geo coordinates for a customer.
 */
export class UpdateGeoCoordinatesForCustPath {
	/**
	 * The unique identifier of the customer.The customer could be a Future or CIS customer.
	 * Required: true
	 * Array: false
	 */
	customerId:string = undefined;
}
/**
 * Query parameter used to update the geo coordinates for a customer.
 */
export class UpdateGeoCoordinatesForCustQuery {
	/**
	 * The unique identifier for the area associated with the customer.
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
}
/**
 * Request to update the geo coordinates for a customer.
 */
export class UpdateGeoCoordinatesForCustRqst {
	/**
	 * The geo coordinates where the customer is located.
	 * Required: true
	 * Array: false
	 */
	geoCoordinate:LatLong = undefined;
	/**
	 * Indicates if the customer is a CIS customer.
	 * Required: false
	 * Array: false
	 */
	cisCustomerInd:boolean = undefined;
}
/**
 * Response to the request to update the geo coordinates for a customer.
 */
export class UpdateGeoCoordinatesForCustResp {
	/**
	 * Indicates if the update was successful.
	 * Required: false
	 * Array: false
	 */
	successInd:boolean = undefined;
}
/**
 * Contains counters for children for a location node.
 */
export class ChildrenCounter {
	/**
	 * Corporate counter
	 * Required: false
	 * Array: false
	 */
	corporateCounter:number = undefined;
	/**
	 * Bill-to counter
	 * Required: false
	 * Array: false
	 */
	billToCounter:number = undefined;
	/**
	 * Pick up and delivery counter
	 * Required: false
	 * Array: false
	 */
	pndCounter:number = undefined;
}
export class CisPartyName2 {
	/**
	 * Null currently, for some parties, an additional name extension is used. In legacy CIS, it is stored as Name2_txt. Note in Legacy CIS, name2_txt is also used to store the name of the partner party, which is Name from PARTY2_ID in our model. Note Party2_id and Next_ext are mutually exclusive.  This is to keep legacy CIS in sync with CST. While they serve two different purposes, the legacy CIS records both of them in a single column called NAME2_TXT.
	 * Required: false
	 * Array: false
	 */
	nameExt:string = undefined;
	/**
	 * Null Party ID is a foreign key from CST_PARTY. It identifies the name of the partner party for which the customer is working. In this case the customer has to be a pay agent or a 3PL company. Note Party2_id and Next_ext are mutually exclusive. This is to keep legacy CIS in sync with CST.
	 * Required: false
	 * Array: false
	 */
	cstParty2Id:number = undefined;
	/**
	 * Name for the customer party2.
	 * Required: false
	 * Array: false
	 */
	partyName2:string = undefined;
}
export class CustomerContact {
	/**
	 * Information about the customer contact roles.
	 * Required: false
	 * Array: false
	 */
	contactCustomerRelationship:ContactCustomerRelationship = undefined;
	/**
	 * Information about the contact person.
	 * Required: false
	 * Array: false
	 */
	contactPerson:ContactPerson = undefined;
}
export class CustomerAddressGrouping {
	/**
	 * Numeric value that identifies a grouping of duplicate addresses.
	 * Required: false
	 * Array: false
	 */
	groupingId:number = undefined;
	/**
	 * Standardized address result
	 * Required: false
	 * Array: true
	 */
	groupedCustomerAddresses:CustomerNameAndAddress[] = undefined;
}
export class CustomerHierarchy {
	/**
	 * A meanigful code that is used by users to identify  the customer/location.
	 * Required: false
	 * Array: false
	 */
	madCd:string = undefined;
	/**
	 * The party name for the customer/location.
	 * Required: false
	 * Array: false
	 */
	partyName:string = undefined;
	/**
	 * The party name2 for the customer/location.
	 * Required: false
	 * Array: false
	 */
	partyName2:string = undefined;
	/**
	 * The party name2 for the customer/location.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * Identifies the type of customer.
	 * Required: false
	 * Array: false
	 */
	functionCd:CustomerTypeCd = undefined;
	/**
	 * The customer's status.
	 * Required: false
	 * Array: false
	 */
	statusCd:CustomerStatusCd = undefined;
	/**
	 * The customer's status.
	 * Required: false
	 * Array: false
	 */
	address:Address = undefined;
	/**
	 * The identifier of the customer/location.
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncId:number = undefined;
	/**
	 * The identifier for the parent customer/location.
	 * Required: false
	 * Array: false
	 */
	parentCustomerLocationFuncId:number = undefined;
	/**
	 * The sequence number that provides order to the location in the response.
	 * Required: false
	 * Array: false
	 */
	displayOrderSeq:number = undefined;
	/**
	 * The level of the tree in which the location is present.
	 * Required: false
	 * Array: false
	 */
	levelNbr:number = undefined;
}
/**
 * The history item which is used for displaying a single data change for a customer.
 */
export class CustomerHistoryItem {
	/**
	 * Category for a Customer data change e.g Location, Alias, Contact.
	 * Required: false
	 * Array: false
	 */
	dataCategory:string = undefined;
	/**
	 * Action type involved in the Customer data change e.g. Add, Update, Delete.
	 * Required: false
	 * Array: false
	 */
	actionType:ActionCd = undefined;
	/**
	 * Coder name who submitted the Customer data change.
	 * Required: false
	 * Array: false
	 */
	specialist:string = undefined;
	/**
	 * The date and time the Customer data change took place.
	 * Required: false
	 * Array: false
	 */
	changeDateTime:Date = undefined;
	/**
	 * Request id related to Customer data change.
	 * Required: false
	 * Array: false
	 */
	requestId:number = undefined;
}
export class CustomerLocationFunctionChildrenCount {
	/**
	 * Required: false
	 * Array: false
	 */
	pickupAndDeliveryCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	corporateCount:number = undefined;
}
/**
 * This is the matched customer information.
 */
export class CustomerMatch {
	/**
	 * The percentage at which the input customer matches to this matched customer.
	 * Required: false
	 * Array: false
	 */
	accuracyPercentage:number = undefined;
	/**
	 * Company Name of the party.
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Company Address line 1 of the party.
	 * Required: false
	 * Array: false
	 */
	addr1:string = undefined;
	/**
	 * Company Address line 1 of the party.
	 * Required: false
	 * Array: false
	 */
	addr2:string = undefined;
	/**
	 * Company city name of the party.
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * Company postal state code  of the party.
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * Country Code
	 * Valid Values
	 * US
	 * CA
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Zip code. 5 digits for US and 6 chars for CA.
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * zip code extension.
	 * Required: false
	 * Array: false
	 */
	postalCdExt:string = undefined;
	/**
	 * In most cases, shipper or consignee is the party on a Dispute. 
	 * This records the CIS MAD code for the party. In this case the name and address should be copied from CIS
	 * Required: false
	 * Array: false
	 */
	custMadCd:string = undefined;
	/**
	 * In most cases, shipper or consignee is the party on a Dispute. 
	 * This records the CIS Number for the party. In this case the name and address should be copied from CIS
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * The type of customer.  Valid Values: BillTo
	 * Corporate
	 * Future
	 * PickupDelivery
	 * ServiceRecipient
	 * Required: false
	 * Array: false
	 */
	customerTypeCd:CustomerTypeCd = undefined;
	/**
	 * The type of match result found for the input customer.  Valid Values:  Alias
	 * Customer
	 * Required: false
	 * Array: false
	 */
	matchResultTypeCd:CustomerMatchResultTypeCd = undefined;
}
export class CustomerNameAndAddress {
	/**
	 * Unique identifier for Customer
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * Primary name
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Applies to mailing address of a person, where the address is actually in another entity's name.
	 * Required: false
	 * Array: false
	 */
	careOfName:string = undefined;
	/**
	 * Address line that includes Street name, direction and optionally building number, unit# etc. 
	 * e.g. 1000 SW Broadway st
	 * Required: false
	 * Array: false
	 */
	addressLine1:string = undefined;
	/**
	 * Address line that includes Street name, direction and optionally building number, unit# etc. 
	 * e.g. 1000 SW Broadway st
	 * Required: false
	 * Array: false
	 */
	addressLine2:string = undefined;
	/**
	 * PO Box typically applies to a mailing address. This is the standard PO Box# of an address.
	 * Required: false
	 * Array: false
	 */
	postOfficeBox:string = undefined;
	/**
	 * City Name part of the address.
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * Standard Postal abbreviation for state name. Example: OR for Oregon.
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * standard Postal abbreviation for a country. Example: CA for Canada.
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Postal Code are aka Zip code in USA. US and Mexico use 5 digit postal codes. Canada uses 6 char postal codes.
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * This is a 4 digit extension to US 5 digit zip code.
	 * Required: false
	 * Array: false
	 */
	usZip4:string = undefined;
}
export class CustomerNameAddressAlias {
	/**
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncNameAlias:CustomerLocationFuncNameAlias = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncAddressAlias:CustomerLocationFuncAddressAlias = undefined;
}
/**
 * Details of the customer operation including pickup and delivery options.
 */
export class CustomerOperation {
	/**
	 * The number associated with the customer's account.
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * The earliest time of day that the customer will be ready to do business.
	 * Required: false
	 * Array: false
	 */
	customerOperationReadyTime:Date = undefined;
	/**
	 * The latest time of day that the customer will be available to do business.
	 * Required: false
	 * Array: false
	 */
	customerOperationCloseTime:Date = undefined;
	/**
	 * Free form comment used to indicate the limits that may exist at a customer site. E.g. the type of dock, type of equipment required, etc.
	 * Required: false
	 * Array: false
	 */
	operationRemark:string = undefined;
	/**
	 * This indicates that a pickup should be made each day and that a request will not be made.
	 * Required: false
	 * Array: false
	 */
	customerDailyStopInd:boolean = undefined;
	/**
	 * This indicates whether or not to notify customer before delivering.
	 * Required: false
	 * Array: false
	 */
	notifyCustomerOnArrivalInd:boolean = undefined;
	/**
	 * This indicates that the customer will sign a delivery manifest rather than individual delivery receipts.
	 * Required: false
	 * Array: false
	 */
	manifestInLieuOfDeliveryReceiptInd:boolean = undefined;
}
/**
 * The customer profile contains a variety of codes with values representing characteristics of the customer. These characteristics provide information related to Sales, Operations, Special Handling, Physical Location etc. Examples: 
 * Offshore location
 * Outbound Rev Potential
 * Inbound Revenue Potential
 * URL
 * Operations Info
 * Inbound Lane
 * Outbound Lanes
 * Rate Sheet
 * etc.
 * 
 */
export class CustomerProfile {
	/**
	 * Customer account instance ID
	 * Required: true
	 * Array: false
	 */
	acctInstId:string = undefined;
	/**
	 * True if customer is still a potential customer and not fully a CIS customer.
	 * Required: false
	 * Array: false
	 */
	futureCustomerInd:boolean = undefined;
	/**
	 * The list of profile codes with their values to be acted upon.
	 * Required: false
	 * Array: true
	 */
	customerProfileCodes:CustomerProfileCode[] = undefined;
	/**
	 * The network user ID of the user that is adding or updating elements of the customers profile.
	 * Required: true
	 * Array: false
	 */
	lastUpdateBy:string = undefined;
}
/**
 * The customer profile code represents a characteristic of a customer. In a profile there may be many of them, some of them have values associated unique to the customer. These characteristics provide information related to Sales, Operations, Special Handling, Physical Location etc. Examples: 
 * Offshore location
 * Outbound Rev Potential
 * Inbound Revenue Potential
 * URL
 * Operations Info
 * Inbound Lane
 * Outbound Lanes
 * Rate Sheet
 * etc.
 * 
 */
export class CustomerProfileCode {
	/**
	 * Valid values: create, update, delete.
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * A code identifying a set of related characteristics to be tracked.
	 * Required: false
	 * Array: false
	 */
	profileTypeCd:string = undefined;
	/**
	 * Type of note. Holds profile information for the customer AIM would like to track.
	 * All customer may not have all profile information and each customer may have specific value for specific profile information.
	 * Corresponds to CODE_ID in CIS_CODES_AIM table with CODE_TYP_CD representing Customer Profile.
	 * 
	 * Examples:
	 * 
	 * Customer URL
	 * In/Out Bound lanes
	 * Seasonal Shipping time
	 * Operations Info
	 * Strategy
	 * Rate Sheet Type
	 * Rotation Cycle
	 * Out/Inbound revenue potential
	 * Required: false
	 * Array: false
	 */
	profileId:string = undefined;
	/**
	 * The actual value for the profile described in the profile code ID.
	 * Required: false
	 * Array: false
	 */
	profileCdValue:string = undefined;
}
/**
 * The location node which is used for displaying the immediate hierarchy for a customer.
 */
export class LocationNode {
	/**
	 * The business identifier for a customer which is made up of a prefix, location, and suffix.
	 * Required: false
	 * Array: false
	 */
	madCode:string = undefined;
	/**
	 * The name of the party.
	 * Required: false
	 * Array: false
	 */
	partyName:string = undefined;
	/**
	 * The unique identifier for the customer in the old CIS system.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * Specifies the type of customer (PnD, bill-to, corporate).
	 * Required: false
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * The status of the location: Active, Inactive, or Expired.
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * The current location for the node.
	 * Required: false
	 * Array: false
	 */
	currentLocation:boolean = undefined;
	/**
	 * The business identifier for the parent location which is made up of a prefix, location, and suffix.
	 * Required: false
	 * Array: false
	 */
	parentMadCd:string = undefined;
	/**
	 * Contains counters for node children.
	 * Required: false
	 * Array: false
	 */
	childrenCounter:ChildrenCounter = undefined;
}
export class PaymentTerm {
	/**
	 * A meaningful label that is used by users to identify the payment term.
	 * Required: false
	 * Array: false
	 */
	paymentTermLabel:string = undefined;
	/**
	 * The value associated with the payment term.
	 * Required: false
	 * Array: false
	 */
	paymentTermValue:string = undefined;
}
/**
 * Stores information to approve the associated Requested Change.
 */
export class RequestedChangeApproval {
	/**
	 * The request location sequence number.
	 * Required: false
	 * Array: false
	 */
	rqstLocSeqNbr:number = undefined;
	/**
	 * Request contact sequence number.
	 * Required: false
	 * Array: false
	 */
	rqstContactSeqNbr:number = undefined;
	/**
	 * Request business name and alias sequence number.
	 * Required: false
	 * Array: false
	 */
	rqstBNASeqNbr:number = undefined;
	/**
	 * Request invoice sequence number.
	 * Required: false
	 * Array: false
	 */
	rqstInvcRqmtKeyNbr:number = undefined;
}
export class ServiceRecipient {
	/**
	 * Required: false
	 * Array: false
	 */
	party:Party = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	address:CustomerAddress = undefined;
}
/**
 * Stores information related to sales territory assigned to a customer and that territory is linked to a sales person.
 */
export class SalesTerritoryAssignment {
	/**
	 * Sales territory object assigned for specific sales person.
	 * Required: false
	 * Array: false
	 */
	territory:SalesTerr = undefined;
	/**
	 * Sales territory assigned for a specific customer.
	 * Required: false
	 * Array: false
	 */
	assignment:SalesAssignment = undefined;
}
/**
 * 
 */
export class Bil2Contact {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Bil2Location {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceAcctPrepaid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceAcctCol:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dbCustomerNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	standardIndlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	simpleInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceRcpntNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	directCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suppNbrReqCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	idNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditedStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzLimitAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doNotMatchInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Bil2Name {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class BusinessAliasHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histRmks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createTransactionCd:string = undefined;
}
/**
 * 
 */
export class Contact {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactLastName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactFirstName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactTitle:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactAreaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhone:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhoneExt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	email:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxAreaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxNbr:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Remarks associated with the request to upsert the contact.
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * Free form descriptive text about a CIS contact.
	 * Required: false
	 * Array: false
	 */
	notes1Txt:string = undefined;
	/**
	 * Additional free form descriptive text about a CIS contact.
	 * Required: false
	 * Array: false
	 */
	notes2Txt:string = undefined;
}
/**
 * 
 */
export class CisContactNote {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	note1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	note2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactKey:number = undefined;
}
/**
 * 
 */
export class CorpHrchy {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	natlAcctInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	govtCustomerInd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pdLocationDefaultNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditedStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	raAnalystId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	natlSalesSuppId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dbCustomerNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	standardIndlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzLimitAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesTerrCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	idNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CorpName {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ContactHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactLastName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactFirstName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactTitle:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactAreaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhone:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhoneExt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	email:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxAreaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	note1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	note2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histRmks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class FinancialInfo {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	idNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	paymentTermCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	letterSet:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	graceDaysCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
}
/**
 * 
 */
export class GroupHistory {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cisGroupInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cisCorpInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expirationDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histRmks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdTimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class GroupAssocHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cisGroupInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	memberType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	memberInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expirationDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histRmks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdTimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HierarchyContact {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class InterfaceAcct {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentAcctInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nationalAcctInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditStatusCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationTypeCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressLine1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressLine2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deletedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastCreatedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastCreatedDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoCoordinates:LatLong = undefined;
	/**
	 * Geo coordinations
	 * Required: false
	 * Array: false
	 */
	geoCoordinatesGeo:GeoCoordinates = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
}
/**
 * Holds a snapshot of InterfaceAcct. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class InterfaceAcctSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:InterfaceAcct = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:InterfaceAcct = undefined;
}
/**
 * 
 */
export class InvoiceCriteria {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceDailyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceMonInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceTueInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceWedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceThuInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceFriInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceSatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceSunInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceWeek1Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceWeek2Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceWeek3Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceWeek4Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceWeek5Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceDocCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	specialHandlingDeskCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	copiesNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	attachmentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceRemitLocationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	miscRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	email:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxAreaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histRmks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
}
/**
 * 
 */
export class InvoiceType {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeOfBillCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeOfInvoiceCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effStartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effEndDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceAddressCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histRmks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bil2CustomerNbr1:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bil2CustomerNbr2:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	holdInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PickupContact {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PickupDeliveryLocation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditedStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctClassDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerSuplyNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzLimitAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dbCustomerNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	standardIndlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	simpleInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesTerrCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesGroupNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suppNbrReqCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnCustomerMadPrefixTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnCustomerMadLocationTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnCustomerMadSuffixTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnNatlAcctInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	idNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doNotMatchInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PickupDeliveryName {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Request {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nationalInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	completedUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	completedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	priorityCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	completedEmailIn:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	submittedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	approvalStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	approverUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	approvalDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notifyDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Mad code of the corporate parent for a location on the CIS Request.
	 * Required: false
	 * Array: false
	 */
	corpParentMadCd:string = undefined;
	/**
	 * The company name for a location on the CIS Request.
	 * Required: false
	 * Array: false
	 */
	companyName:string = undefined;
	/**
	 * First and last name of the user who created the CIS Request.
	 * Required: false
	 * Array: false
	 */
	createdUsername:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	requestEmailAddress:RequestEmailAddress[] = undefined;
}
/**
 * 
 */
export class RequestBil2 {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestLocationSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToComponent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpParentMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	madCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceRecipientNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authorizationNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authorizationLimitAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedMonthlyRevenueNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RequestBusinessName {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestLocationSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerSequenceCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	massApplyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RequestContactLocation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestContactSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestLocationSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class RequestComments {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	comment:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * First and last name of the user who created the CIS Request.
	 * Required: false
	 * Array: false
	 */
	createdUsername:string = undefined;
	/**
	 * The first name of the user who submitted the comment.
	 * Required: false
	 * Array: false
	 */
	createCommentFirstName:string = undefined;
	/**
	 * The last name of the user who submitted the comment.
	 * Required: false
	 * Array: false
	 */
	createCommentLastName:string = undefined;
}
/**
 * 
 */
export class RequestContact {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessAreaCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessPhone:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessPhoneExt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	email:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxAreaCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxPhone:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	note1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	note2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	title:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RequestCorpHrch {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestLocationSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpHierarchyRequestId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpHierarchySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authorizationLimitAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authorizationNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	categoryInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	component:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpParentMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	defaultPudInvoiceLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	madCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nationalAcctInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesTerritory:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedMonthlyRevenueNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RequestEmailAddress {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emailSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emailAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RequestInvoiceCriteria {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInvoiceKeyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceDailyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceDocCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceModeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	attachmentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RequestInvoiceType {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeOfBillCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeOfInvoiceCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveStartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceAddressCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInvoiceCriteria:RequestInvoiceCriteria = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestBil2:RequestBil2 = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestPkndl:RequestPkndl = undefined;
}
/**
 * 
 */
export class RequestLocation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoicingRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notes:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lockUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lockDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RequestPkndl {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestLocationSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	accountClassInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authorizationLimitAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authorizationNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpParentMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	madCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesGroup:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesTerritory:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerReadyTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	manifestInLieuInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notifyUponArrivalInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerCloseTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dailyStopInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	directCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedMonthlyRevenueNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mergeArMadCdTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesGroupLocationCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesGroupLocationSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesGroupRequestInstId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class SalesTerr {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesTerrCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	vsamKey:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	initial:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	jobType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	homeSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesPersonCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Address can be either a street address or a PO Box address. 
 * In rare cases a street address might host more than one business (customer location).
 * PO BOX address is always in context of a customer location 
 * Therefore, PO Box address is provided only when a customer location has a single function, namely Bill-To function.
 * 
 * In theory a customer location can have both a street address and also might use a PO Box at nearby facility (Post office). However at present when po box is recorded, there is no requirement to know the street address. 
 * Note to designer: If such a requirement were to arise in the future, the current relationship between Address and cust_location 1:Many will need to change to Many:Many resulting in a new address-cust_loc relationship entity.
 * 
 * 
 */
export class CustomerAddress {
	/**
	 * System Generated Number to uniquely identify an Address. This is internal ID.
	 * Required: false
	 * Array: false
	 */
	addressId:number = undefined;
	/**
	 * Address Line 1
	 * Examples:
	 * PO BOX 182655   
	 * 150 S LOMBARD RD
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * City, town Name
	 * Example
	 * PORTLAND
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * US and Mexico have states, Canada has provinces. 
	 * The two char abbreviateions are recorded here. 
	 * Currently LTL only records US, CA and MX parties. Hence the State codes are two char.
	 * 
	 * Example
	 * OR - Oregon USA
	 * TM - Tamaulipas Mexico
	 * AB - Alberta
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * This records ZIP codes also called PIN codes etc.
	 * US and Mexico use 5 digit codes, Canada uses 6 character codes.
	 * Example
	 * T2H2K4 - Canada
	 * 97209 - USA
	 * 55540 - Mexico
	 * 
	 * There could be common zip codes numbers between US and Mexico.
	 * Required: false
	 * Array: false
	 */
	zipCd:string = undefined;
	/**
	 * USPS uses additional numbers as extension to the 5 digit zip codes
	 * Required: false
	 * Array: false
	 */
	zip4Cd:string = undefined;
	/**
	 * Country Code is 2 character Standard country codes.
	 * US -USA
	 * MX - Mexico
	 * CA - Canada
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * This is system generated ID for a record that describes geo area as defined in OPS Application.
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * Latitude of the site that is defined by the address
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * Longitude of the site that is defined by the address
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * Whether the address was validated by Postal software
	 * Valid Values
	 * Y - Address was validated and adjusted as required.
	 * N - This address is not yet validated
	 * Required: false
	 * Array: false
	 */
	postalCertifiedInd:boolean = undefined;
	/**
	 * Is this a POBOX address or a street address
	 * Y - This is a PO Box and not a streeet address
	 * N - This is not a PO Box address, but is a street address
	 * Required: false
	 * Array: false
	 */
	poBoxInd:boolean = undefined;
	/**
	 * Abbreviated address used by CIS to New CIS conversion program to identify same address with some different white space and editing chars
	 * Required: false
	 * Array: false
	 */
	addressKey:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
}
/**
 * Business Events specific to the lifecycle of a 
 * 
 * Customer, related to a specific Transactional Event.
 * 
 */
export class BusinessEvent {
	/**
	 * Unique identifier
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
	/**
	 * Used to defined a distinct business 
	 * 
	 * event within a given Transaction Event.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerLocationAddressAliasSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerLocationNameAliasSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerLocationId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	partyId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPersonId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactRoleCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	collectorTeamId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	collectorTeamSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	collectorGroupId:number = undefined;
	/**
	 * A same value is assigned to 
	 * 
	 * CORRELATION id of various rows from different tables that form a single business 
	 * 
	 * transactions. In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses 
	 * 
	 * this to version check the record to ensure another transaction has not updated the row 
	 * 
	 * after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The unique identifier in old CIS system.  Added 10/09/2019
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * Specifies the type of customer (Pand D, bill-to, corporate) Added 10/08/2019
	 * Required: false
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * Uniquely identifies the alias for a specific customer. Added 10/08/2019
	 * Required: false
	 * Array: false
	 */
	customerLocationAliasSequenceNbr:number = undefined;
}
/**
 * Collection Group is used to group multiple Customer location Functions as identified by MAD Codes.
 * The group is then assigned to a collector team, which is in turn made up of one or more collectors.
 */
export class CollectGroup {
	/**
	 * This is system generated internal ID used to uniquely identify a record.
	 * Required: false
	 * Array: false
	 */
	cstCollectGroupId:number = undefined;
	/**
	 * A collection group is also identified by a code similar to a mad code used for identifying a customer location function.
	 * Example -CAILTC01001
	 * Required: false
	 * Array: false
	 */
	collectGroupCd:string = undefined;
	/**
	 * Name of the group, usually same or similar to name of customer whose locations are grouped for collection purpose
	 * Example:
	 * HARPER ENGINEERING - RENTON, WA    
	 * Coll Group Cd is HAHBQC01001
	 * Required: false
	 * Array: false
	 */
	groupName:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
}
/**
 * This table records team of collectors. 
 */
export class CollectorTeam {
	/**
	 * System generated internal ID that uniquely identifies a record.
	 * Required: false
	 * Array: false
	 */
	cstCollectorTeamId:number = undefined;
	/**
	 * One or more collectors might be put in a team. The team is known by this name. Example - GP001.
	 * This also serves as an alternate ID
	 * Required: false
	 * Array: false
	 */
	collectTeamName:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
}
/**
 * One or more collectors might be assigned to a team. This table records individual collectors assigned to a team.
 * At this time a master data of various is collectors is not recorded separately but recorded as part of assignments.
 */
export class CollectorTeamAssign {
	/**
	 * It identifies the collector team for which this collector is assigned. This is a foreign key.
	 * Required: false
	 * Array: false
	 */
	cstCollectorTeamId:number = undefined;
	/**
	 * If multiple collectors are assigned then this further makes the particular collector assignment unique.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * First Name of collector.
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Last Name of collector.
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Internation calling country code
	 * e.g.
	 * 1 - USA (optional as most of the LTL offices are in US)
	 * 52- Mexico
	 * Required: false
	 * Array: false
	 */
	phoneCountryCd:string = undefined;
	/**
	 * Phone area code
	 * example:
	 * 503 for Portland OR
	 * Required: false
	 * Array: false
	 */
	phoneAreaCd:string = undefined;
	/**
	 * phone number excluding area code
	 * Required: false
	 * Array: false
	 */
	phoneNbr:string = undefined;
	/**
	 * Phone Extension number
	 * Required: false
	 * Array: false
	 */
	phoneExt:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * This is the ID column called SEQ_NBR of the legacy table CIS_COLL_GRP. This column is required to allow us to backsync to legacy CIS.
	 * Required: false
	 * Array: false
	 */
	legacyCisCollectGroupSequence:number = undefined;
	/**
	 * Same value for event id 
	 * 
	 * is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
}
/**
 * A Person is a contact for one or more LTL functions at customer location. This records the contact person assignment to various customerlocations. 
 * The person may be contacted for various reasons (plays roles). E.g. as a Sales contact or Accounts Payable (AP) contact etc.
 * 
 * Note legacy assignment is at the function and role level. 
 * Note to designer. 
 * Perform following data analysis:
 * 1. Is a person is used in a particular role, such as Sales contact, then is he/her always used in that role across all functions.
 * 2. Is a person used for all functions for a given customer location. e.g. if a customer location has PD location function, Corp function and Bill-to function then check is the person assigned the same role for all functions at that location.
 * Depending on the result of analysis, this table can be further normalized into Person Role, Role assignment etc.
 * 
 */
export class ContactCustomerRelationship {
	/**
	 * This is foreign key from Contact Person. This identifies that Contact person.
	 * Required: false
	 * Array: false
	 */
	contactPersonId:number = undefined;
	/**
	 * This is foreign key from Cust Location Function. This identifies the cust location function to which the Contact person is assigned.
	 * Customer location function is identified by MAD Code
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncId:number = undefined;
	/**
	 * Role that is played by the contact person for this customer location function.
	 * In other words the reason for contacting the person at customer location.
	 * Customer location function is identified by MAD Code.
	 * Valid values:
	 * Value                      Description                       CIS cd 
	 * PICKUP     PICKUP                1
	 * DELIVERY   DELIVERY                    2
	 * APACCOUNTS PAYABLE          A
	 * BALDUE BALANCE DUE                     B
	 * CLAIMSCLAIMS                               C
	 * OSD                        OS and D                             D
	 * EDIEDI                            E
	 * PAYPLAN  FREIGHT PYMNT PLAN        F
	 * INVCINVOICING                          I
	 * NOA NOA                                    N
	 * OPS OPERATIONS                      O
	 * PURCHASEPURCHASING                      P
	 * SALES SALES                                 S
	 * Required: false
	 * Array: false
	 */
	contactRoleCd:ContactRoleCd = undefined;
	/**
	 * Is this person a primary contact for this customer location function?
	 * Y - YES
	 * N - NO
	 * Required: false
	 * Array: false
	 */
	primaryInd:boolean = undefined;
	/**
	 * Is this person for inbound, outbound or both in and outbound freight?
	 * Valid values
	 * INB
	 * OUTB
	 * BOTH
	 * Required: false
	 * Array: false
	 */
	inboundOutbCd:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
}
/**
 * Notes captured for a contact. This could be additional information about the contact in a textual form
 */
export class CstContactNote {
	/**
	 * This is foreign key from Contact Person. This identifies that Contact person.
	 * Required: false
	 * Array: false
	 */
	contactPersonId:number = undefined;
	/**
	 * Uniquely identify a note for a contact, when there are multiple notes
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Text of the Note. Could be additional information such as TIM SHANAHAN--PRODUCTION PLANNERAMY RILEY--ACCOUNTS PAYABLE
	 * Required: false
	 * Array: false
	 */
	note:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * This is a person belonging to customer that XPO can contact for various activities. 
 * The contact information is recorded here.
 */
export class ContactPerson {
	/**
	 * System generated internal ID to identify the person.
	 * Required: false
	 * Array: false
	 */
	contactPersonId:number = undefined;
	/**
	 * This is internal ID from legacy CIS application. This is required to keep data synced between this database and legacy CIS tables.
	 * Required: false
	 * Array: false
	 */
	legacyContactKey:number = undefined;
	/**
	 * First Name of the Contact person
	 * John
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Last Name of the Contact person
	 * example
	 * Doe
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Title held by the person at the customer. 
	 * This is optional
	 * Example:
	 * VICE PRESIDENT MARKETING
	 * Required: false
	 * Array: false
	 */
	title:string = undefined;
	/**
	 * Internation calling country code
	 * e.g.
	 * 1 - USA (optional as most of the LTL offices are in US)
	 * 52- Mexico
	 * Required: false
	 * Array: false
	 */
	businessPhoneCountryCd:string = undefined;
	/**
	 * Business phone area code. 
	 * example
	 * 503 for Portland
	 * Required: false
	 * Array: false
	 */
	businessPhoneAreaCd:string = undefined;
	/**
	 * Business phone number excluding area code.
	 * Required: false
	 * Array: false
	 */
	businessPhoneNbr:string = undefined;
	/**
	 * Business Phone Extension
	 * Required: false
	 * Array: false
	 */
	businessPhoneExt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	altBusinessPhoneCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	altBusinessPhoneAreaCd:string = undefined;
	/**
	 * Alt. Business Phone number
	 * Legacy mapping: C IS_CNTCT_MEDIA.MEDIA_CODE_ID =2
	 * Required: false
	 * Array: false
	 */
	altBusinessPhoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	altBusinessPhoneExt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cellPhoneCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cellPhoneAreaCd:string = undefined;
	/**
	 * Cell phone number
	 * Legacy mapping: C IS_CNTCT_MEDIA.MEDIA_CODE_ID =4
	 * Required: false
	 * Array: false
	 */
	cellPhoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cellPhoneExt:string = undefined;
	/**
	 * Email ID to contact the person
	 * Required: false
	 * Array: false
	 */
	emailId:string = undefined;
	/**
	 * Fax number for the person.
	 * Required: false
	 * Array: false
	 */
	faxNbr:string = undefined;
	/**
	 * Address Line1
	 * Examples:
	 * 150 S LOMBARD RD
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * City, town Name
	 * Example
	 * PORTLAND
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * US and Mexico have states, Canada has provinces. 
	 * The two char abbreviateions are recorded here. 
	 * Currently LTL only records US, CA and MX parties. Hence the State codes are two char.
	 * 
	 * Example
	 * OR - Oregon USA
	 * TM - Tamaulipas Mexico
	 * AB - Alberta
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * This records ZIP codes also called PIN codes etc.
	 * US and Mexico use 5 digit codes, Canada uses 6 character codes.
	 * Example
	 * T2H2K4 - Canada
	 * 97209 - USA
	 * 55540 - Mexico
	 * 
	 * There could be common zip codes numbers between US and Mexico.
	 * Required: false
	 * Array: false
	 */
	zipCd:string = undefined;
	/**
	 * USPS uses additional numbers as extension to the 5 digit zip codes
	 * Required: false
	 * Array: false
	 */
	zip4Cd:string = undefined;
	/**
	 * Country Code is 2 character Standard country codes.
	 * US -USA
	 * MX - Mexico
	 * CA - Canada
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxAreaCd:string = undefined;
}
/**
 * The only purpose of this table is to allow search of Customer Location Function. For example, during bill entry, user enters might enter address for Bil-to, Shipper or Consignee. 
 * Application does a search for a match in Cust location address and also in this table. 
 * Thus this serves as an alternate address to find a match. 
 * In addiation to the alternate address, another table provides alternate Name as well. 
 * In theory a relationship to this table could have been from CST_ADDRESS, as alternate addresses or to CST_CUST_LOCATION.
 * The reason for relating to the function is because as explained above, the sole purpose of the alias, along with NAME_ALIAS is to search for a matching Location func.
 */
export class CustomerLocationFuncAddressAlias {
	/**
	 * It identifies the Cust Location Function for which this is an alternate address (alias). This is a foreign key.
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncId:number = undefined;
	/**
	 * If there are multiple alternate addresses (aliases) then this further makes the particular alias unique.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * Address Line 1
	 * Examples:
	 * PO BOX 182655   
	 * 150 S LOMBARD RD
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * City, town Name
	 * Example
	 * PORTLAND
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * US and Mexico have states, Canada has provinces. 
	 * The two char abbreviateions are recorded here. 
	 * Currently LTL only records US, CA and MX parties. Hence the State codes are two char.
	 * 
	 * Example
	 * OR - Oregon USA
	 * TM - Tamaulipas Mexico
	 * AB - Alberta
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * This records ZIP codes also called PIN codes etc.
	 * US and Mexico use 5 digit codes, Canada uses 6 character codes.
	 * Example
	 * T2H2K4 - Canada
	 * 97209 - USA
	 * 55540 - Mexico
	 * 
	 * There could be common zip codes numbers between US and Mexico.
	 * Required: false
	 * Array: false
	 */
	zipCd:string = undefined;
	/**
	 * USPS uses additional numbers as extension to the 5 digit zip codes
	 * Required: false
	 * Array: false
	 */
	zip4Cd:string = undefined;
	/**
	 * Country Code is 2 character Standard country codes.
	 * US -USA
	 * MX - Mexico
	 * CA - Canada
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Is this address postal certified?
	 * Y - Yes (only true when the alternate address is an actual address, where the customer previously was and now relocated at new address)
	 * N - No
	 * Required: false
	 * Array: false
	 */
	postalCertifiedInd:boolean = undefined;
	/**
	 * Is this a PO Box and not a street address?
	 * Y- Yes this is PO box addres
	 * N - No
	 * Required: false
	 * Array: false
	 */
	poBoxInd:boolean = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for event 
	 * 
	 * id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * The only purpose of this table is to allow search of Customer Location Function. For example, during bill entry, user enters might enter address for Bil-to, Shipper or Consignee. 
 * Application does a search for a match in CST_CUST_LOCATION_FUNCTION with related address/party table and also in this table. 
 * Thus this serves as an alternate name/address to find a match. 
 * Example:
 * 																name1                    name2
 * custABBOTT NUTRITION                                            
 * aliasABBOT NUTRITION               % ABBOTT LABS                 
 * aliasROSS LABORATORIES                                           
 * aliasROSS LABORATORIES                                           
 * aliasROSS PRODS ABBOTT LABS              
 * 
 */
export class CustomerLocationFuncAlias {
	/**
	 * It identifies the Cust Location Function for which this is an alternate address (alias). This is a foreign key.
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncId:number = undefined;
	/**
	 * If there are multiple alternate name/addresses (aliases) then this further makes the particular alias unique.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * This is seq_CD in the legacy CIS tables. Note CIS has 3 alias tables for PnD, Bil2 and Corp. Each would use seq starting with 1 for a given legacy cis_cust_nbr
	 * Required: false
	 * Array: false
	 */
	legacySequenceNbr:number = undefined;
	/**
	 * This is the alias name
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * This is mostly a comment
	 * example:
	 * SAME AS 3636 S KEDZIE AVE
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Address Line 1
	 * Examples:
	 * PO BOX 182655   
	 * 150 S LOMBARD RD
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * City, town Name
	 * Example
	 * PORTLAND
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * US and Mexico have states, Canada has provinces. 
	 * The two char abbreviateions are recorded here. 
	 * Currently LTL only records US, CA and MX parties. Hence the State codes are two char.
	 * 
	 * Example
	 * OR - Oregon USA
	 * TM - Tamaulipas Mexico
	 * AB - Alberta
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * This records ZIP codes also called PIN codes etc.
	 * US and Mexico use 5 digit codes, Canada uses 6 character codes.
	 * Example
	 * T2H2K4 - Canada
	 * 97209 - USA
	 * 55540 - Mexico
	 * 
	 * There could be common zip codes numbers between US and Mexico.
	 * Required: false
	 * Array: false
	 */
	zipCd:string = undefined;
	/**
	 * USPS uses additional numbers as extension to the 5 digit zip codes
	 * Required: false
	 * Array: false
	 */
	zip4Cd:string = undefined;
	/**
	 * Country Code is 2 character Standard country codes.
	 * US -USA
	 * MX - Mexico
	 * CA - Canada
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Is this address postal certified?
	 * Y - Yes (only true when the alternate address is an actual address, where the customer previously was and now relocated at new address)
	 * N - No
	 * Required: false
	 * Array: false
	 */
	postalCertifiedInd:boolean = undefined;
	/**
	 * Is this a PO Box and not a street address?
	 * Y- Yes this is PO box addres
	 * N - No
	 * Required: false
	 * Array: false
	 */
	poBoxInd:boolean = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Same value for event 
	 * 
	 * id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * The only purpose of this table is to allow search of Customer Location Function. For example, during bill entry, user enters might enter a name slightly different for billing party. 
 * Eg International Business Machine  instead of IBM.
 * 
 * Application does a search for a match in Cust location, which gets its name from Party and also in this table. 
 * Thus this serves as an alternate name to find a match. 
 * In addiation to the alternate name, another table provides alternate address as well. 
 * In theory a relationship to this table could have been to CST_CUST_LOCATION.
 * The reason for relating to the function is because as explained above, the sole purpose of the alias, along with ADDR_ALIAS is to search for a matching Location func.
 * 
 * Example:
 * 																name1                                    name2
 * custABBOTT NUTRITION                                            
 * aliasABBOT NUTRITION               % ABBOTT LABS                 
 * aliasROSS LABORATORIES                                           
 * aliasROSS LABORATORIES                                           
 * aliasROSS PRODS ABBOTT LABS                                      
 * 
 */
export class CustomerLocationFuncNameAlias {
	/**
	 * It identifies the Cust Location Function for which this is an alternate Name (alias). This is a foreign key.
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncId:number = undefined;
	/**
	 * If there are multiple alternate names (aliases) then this further makes the particular alias unique.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * This is the alias name
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * This is mostly a comment
	 * example:
	 * SAME AS 3636 S KEDZIE AVE
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for event 
	 * 
	 * id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * In most cases, this is a location of the given customer without any association to a partner - a stand-alone customer location. 
 * This identified by a unique combination of Party( CUST_PARTY_ID)  and Address (ADDRESS_ID: location for the customer) without any parther association (Partner_party_id is null).
 * 
 * Legacy data mapping: In this case the Party name for this cust location (via foreign key cust_party_id)  is mapped to CIS:NAME1_TXT.
 * Example:
 * Customer party: SARA LEE BAKERY GROUP, PO BOX 2502, MARYLAND HEIGHTS, MO 63043. 
 * There is no partner involved. We bill SARA LEE for SARA LEE freight, as this customer location has only one role of Bill-to. (PD location cannot have PO Box address)
 * CIS mapping:NAME1_TXT is SARA LEE BAKERY GROUP, with the above PO Box address
 * 
 * In other cases, this could be location of a customer that parners with another company, or is working on behalf of another company.  Here customer means the party that holds our debt (owes us money and is tracked under Accounts Receivable) . 
 * Example:
 * Customer Party: CASS INFORMATION SYSTEMS INC, PO BOX 67, SAINT LOUIS, MO 63166 
 * Partner Party: WALGREENS
 * This means XPO bills CASS and true debtor is CASS working for Walgreens (freight picked up or delivered to Walgreens).
 * 
 * This is presented as partner name c/o customer name with address of customer.
 * In this example:  WALGREENS c/o CASS INFORMATION SYSTEMS INC, PO BOX 67, SAINT LOUIS, MO 63166 
 * 
 * CIS Mapping:NAME1_TXT is parther location identified by PARTNER_PARTY_ID, which is for WALGREENS 
 * and NAME2_TXT is customer identified by CUST_PARTY_ID, which is c/o CASS INFORMATION SYSTEMS INC with address PO BOX 67, SAINT LOUIS, MO 63166. Address belongs to CASS.
 * 
 * CIS mapping rule:  
 * if partner_party_id is null then map Party name based on CUST_PARTY_ID as CIS.Name1_txt and leave CIS.Name2_txt null.
 * if partner_party_id is not null then 
 * 			map Party name based on CUST_PARTY_ID as CIS.Name2_txt prefix with percent sign and 
 * 			map Party name based on CUST_PARTNER_ID as CIS.Name1_txt. 
 * 
 * The confusing part in CIS might be: The mad code assigned to function in this partnership role looks like WALGEEN mad code. Walgreen is not our customer (A/R address is CASS PO Box address)
 * Address is always mapped to Address fields of CIS. It just so happens that when partner is involved, cis Address belongs to cis Name2. MAD Code is set in this fashioin to indicate that this location belonging to cass is in the same hierarchy was Walmart Pickup dlvr location (our shipper), and is so done for proper pricing.
 * 
 * 
 * 
 * 
 */
export class CustomerLocation {
	/**
	 * System generated internal ID to uniquely identify a record.
	 * In theory,
	 * Required: false
	 * Array: false
	 */
	customerLocationId:number = undefined;
	/**
	 * Currently, for some parties additional name extension is used. In legacy CIS, it is stored as Name2_txt. Note in Legacy CIS, name2_txt is also used to store the name of the partner party, which is Name from PARTY2_ID in our model.
	 * Note Party2_id and Next_ext are mutually exclusive. This is to keep legacy CIS in sync with CST.
	 * While they serve two different purpose, the legacy CIS records both of them in a single column called NAME2_TXT 
	 * 
	 * Example:
	 * Party1_name is UNITED PARCEL SERVICE
	 * Name_ext is: BLDG B (stored in legacy as name2_txt). Note this is not a name for a second party as would be if this was a location served by a 3PL or a payment agent.
	 * Required: false
	 * Array: false
	 */
	nameExt:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Some cust locations might have more than one party when there is partnership invoived. Currently we capture address for one party. 
	 * This attributes indicates the party that has the address as identified by address_id
	 * Valid values
	 * ADDR_PARTY1 - Address as identified by Address_id is for party as identified by PARTY1_ID. This is value if party2 is not present.
	 * ADDR_PARTY2 - Address as identified by Address_id is for party as identified by PARTY2_ID
	 * UNKNOWN - Two parties are present and we dont know if address is for party1 or party2
	 * Required: false
	 * Array: false
	 */
	addressIsForWhichParty:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nameExtKey:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
}
/**
 * There are three functions that can happen at a customer liocation. 
 * P - Freight is either picked up or delivered. AKA Pkup_dlvr
 * B - Invoices are sent to this location. AKA Bill-to
 * C - Corporate function done at this location (e.g. pricing negotiation, Sales related). AKA Corp
 */
export class CustomerLocationFunction {
	/**
	 * The numbers are 10digits or longer. 
	 * Reason: Other applications have data that refer to legacy CIS number, which are 9 digit random number.
	 * As the legacy apps are rewritten/modified to use this new CIS application, they will start storing new CIS number namely CUST_LOCATION_FUNC_ID.
	 * At some point, legacy CIS will cease to exist.
	 * As other applications will have historic data, in order for them to clearly differentiate between CIS and new CIS IDS, we start numbering this ID with 10 digit numbers.
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncId:number = undefined;
	/**
	 * MAD code is meanigful code that is used by users to identify a cust location function.
	 * Required: false
	 * Array: false
	 */
	madCd:string = undefined;
	/**
	 * This is a unique ID in legacy CIS database. It identifes unique function aka CIS_BILL2_LOC, CIS_PKUP_DLVR_LOC, and CIS_CUST_HRCHY.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * There are three functions that can happen at a customer liocation. 
	 * P - Freight is either picked up or delivered. AKA Pkup_dlvr
	 * B - Invoices are sent to this location. AKA Bill-to
	 * C - Corporate function done at this location (e.g. pricing negotiation, Sales related). AKA Corp
	 * Required: false
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * Valid values are:
	 * 
	 * ACTIVE, 
	 * INACTIVE (CIS do_not_match_ind) 
	 * EXPIRED  - Also see EXPR_RSN_CD
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * This indicates that the customer produces a certain amount of revenue. Valid values are A, B, C or M. The exact rules for calculating this are still being discussed. 
	 * A > $5,000 per month O/B or I/B but not combined, seasonally adj'd
	 * B > $3,000 per month O/B + I/B combined, seasonally adjusted
	 * C Potential to become a 'B' account within 6 months, but not automatically measured by system
	 * M All others
	 * Required: false
	 * Array: false
	 */
	acctClassCd:string = undefined;
	/**
	 * Date that the account class value was assigned
	 * Required: false
	 * Array: false
	 */
	acctClassDate:Date = undefined;
	/**
	 * This is a customer supplied number that they use to identify the location. The text will include a label for the field. (eg. Store 3000 or Cisco 1100)
	 * Required: false
	 * Array: false
	 */
	customerSuplyNbr:string = undefined;
	/**
	 * This is number assigned by Dun and bradstreet. It is used to access commercial data for the customer for credit rating, analytics etc.
	 * Required: false
	 * Array: false
	 */
	dbCustomerNbr:string = undefined;
	/**
	 * 4225.  Per published list, this stands for : Standard Industrial Code
	 * Required: false
	 * Array: false
	 */
	standardIndlCd:string = undefined;
	/**
	 * Credit status is used to determine, if customer needs to pay upfront at the time freight is picked up / delivered.
	 * 
	 * VVOLUNTARY           
	 * $CASH                
	 * CCREDIT              
	 * BBANKRUPT            
	 * NNO CREDIT
	 * Required: false
	 * Array: false
	 */
	creditedStatusCd:CredStatCd = undefined;
	/**
	 * Is this a government customer
	 * Valid Values:
	 * Y - Yes
	 * N - No
	 * Required: false
	 * Array: false
	 */
	govtCustomerInd:string = undefined;
	/**
	 * This is the authorization number that is returned from the credit service
	 * Required: false
	 * Array: false
	 */
	authrzNbr:string = undefined;
	/**
	 * This is the credit limit given from the credit service.
	 * Example
	 * 5000 - This means customer is worthy of receiving up to $5000 credit.
	 * Required: false
	 * Array: false
	 */
	authrzLimitAmount:number = undefined;
	/**
	 * Application ensures all Billt-to
	 * Required: false
	 * Array: false
	 */
	serviceRcpntNbr:number = undefined;
	/**
	 * The date when this function stops being used for new transactions.
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * There are two reasons for Expiration. In either case, this function might be hidden from certain views to prevent it from being used or unnecessarily cluttering the display.
	 * 
	 * MERGED   - The record of this function has been merged into another function. The function into which this function is merged is recorded in the field MERGED_TO_CUST_LOC_FUNC_ID. This is done probably because there was a duplicate function record set up.
	 * INACTIVE - The function is no more in use and hence logically deleted.
	 * Required: false
	 * Array: false
	 */
	expiryReasonCd:string = undefined;
	/**
	 * This applies only to Bill-to function. When set, the charges are not printed on Deliver Receipt for the consignee. Also on the web page, user knows the charges are restricted from being shown to the consignee.
	 * Required: false
	 * Array: false
	 */
	restrictChargeInd:boolean = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * These comments added by users (generally coding dept) when a row is added, updated. Typically it describes the reason for update
	 * Required: false
	 * Array: false
	 */
	lastUpdateRemarks:string = undefined;
	/**
	 * Specifies number of days after which an invoice is due.  The due date is generally calculated by adding days associated with the payment term to Pickup Date. E.g. NET30 means 30 days after the pickup date. Added 5/22/19 by Cathy
	 * Required: false
	 * Array: false
	 */
	paymentTermCd:string = undefined;
	/**
	 * Indicates if account is national or not.  Added 5/22/2019 by Cathy
	 * Required: false
	 * Array: false
	 */
	nationalInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for event id 
	 * 
	 * is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
	/**
	 * Represents the parent of the customer for which we are looking.
	 * Required: false
	 * Array: false
	 */
	customerLocationFunctionParent:CustomerLocationFunction = undefined;
	/**
	 * Represents the top parent of the customer for which we are looking.
	 * Required: false
	 * Array: false
	 */
	customerLocationFunctionTopParent:CustomerLocationFunction = undefined;
	/**
	 * This is the estimated monthly revenue of the customer.
	 * Required: false
	 * Array: false
	 */
	estMonthlyRevNbr:number = undefined;
	/**
	 * Indicates if pricing is attached to the customer account.  If the user doesn't exist in the Pricing API, then a message will be returned stating theat the information isn't available.  If the service fails, then a message saying so will be returned.  If the user does exist for Pricing, then true or false will be returned as a string.  If this is set to true, then the customer account can't be deleted or expired.  Also, when adding a customer, the request may want to have pricing attached. Therefore, the coder needs to work with pricing to ensure there is pricing attached to the location. This indicator will help the coder know when pricing has been set up correctly.
	 * Required: false
	 * Array: false
	 */
	pricingInd:string = undefined;
}
/**
 * This table stores ranges of LEGACY_CUST_NBR that are available to be used.
 * When a new Cust Location Function is created, a LEGACY_CUST_NBR is assigned to it as follows:
 * 	
 * SELECT FIRST_AVAIL_NBR FROM THE TABLE WHERE FIRST_AVAIL_NBR<= LAST_AVAIL_NBR  AND ROWNUM<2
 * 	
 * UPDATE THE TABLE SET FIRST_AVAIL_NBR = FIRST_AVAIL_NBR+1 and COMMIT; 
 * 	
 * Commit ensures another concurrent user does not grab the same number.
 * 	
 * This table is popuated using following query on BI/Data warehouse (which has historical customer including the deleted ones from last 10+ years).
 * 
 * 
 * 	
 * select row_number() over (order by FIRST_AVAIL_NBR) CST_LEGACY_CUST_NBR_AVAIL_ID,
 * 								FIRST_AVAIL_NBR, 
 * 				NEXT_USED_NBR-1 as LAST_AVAIL_NBR 
 * from (  select cust_nbr+1 first_avail_nbr,
 * 			lead(cust_nbr) over (order by cust_nbr) next_used_nbr
 * from prd_Whseview..CUSTOMER_FREIGHT_ROLE_VW 
 * where cust_nbr between 100 and 3000000
 * ) x  
 * where  next_used_nbr - first_avail_nbr > 0 ;
 * 
 * this gives  approx 2.5 million numbers in 220K rows in this table.
 * 	in last 4 years, we created about 150K new PD, 50K bil2 and 75K corp. So total of 300k new accounts. 
 * So these ranges should cover Legacy CIS# for next 9-10 years before which legacy CIS should be retired.
 * 
 * 	
 * The output of the query can be loaded into a table CST_LEGACY_CUST_NBR_AVAIL (FIRST_AVAIL_NBR,LAST_AVAIL_NBR).
 * 
 * There is a type of cust called FUTURE_CUST, which remains with ops. So apart from populating this table, CIS_CUST_NBR_USED should be inserted with numbers covered by these ranges (cust type can be arbitrarily given any value such as F)
 * With this approach, GEN operation for the cust# generation for type CIS_FUTURE_CUST does not need to be modified. 
 * 
 * Example of the range:
 * FIRST_AVAIL_NBR,LAST_AVAIL_NBR, USED_STATUS
 * 232,441
 * 49286,49438 
 */
export class LegacyCustomerNbrAvailable {
	/**
	 * System generated number to uniquely identify a row.
	 * Required: false
	 * Array: false
	 */
	cstLegacyCustomerNbrAvailableId:number = undefined;
	/**
	 * This is the first number in the range of available legacy cis#.  See table description also.
	 * Required: false
	 * Array: false
	 */
	firstAvailableNbr:number = undefined;
	/**
	 * This is the last number in the range of available legacy cis#.  See table description also.
	 * Required: false
	 * Array: false
	 */
	lastAvailableNbr:number = undefined;
	/**
	 * Y= This row provides a range of available numbers. N= All numbers in this row have been used up and hence this row does not provide any available number.
	 * Required: false
	 * Array: false
	 */
	availableInd:boolean = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class MadCdUsed {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	madCodePrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	madCodeLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	madCodeSeg:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastNbr:number = undefined;
}
/**
 * A Party could be either 
 * 1. a customer that is stand-alone and has no partner. This is identified by unique combination of PARTY_NAME and PARTY_NAME2 if party name2 is present. Note Party_name2 in this case is a department at the same address for the company.
 * or 
 * 2. a customer that is working as a partner on whose behalf another company. This is identified by PARTY_NAME.
 * 
 * Refer to cust_location, and Address for more examples
 */
export class Party {
	/**
	 * System Generated Number to uniquely identify a Party record.
	 * Required: false
	 * Array: false
	 */
	partyId:number = undefined;
	/**
	 * Name of the Party
	 * 
	 * Example:
	 * L H INDUSTRIAL SUPPLY  - This is our Customer
	 * 
	 * Another example is where two parties with the following name are recorded and have a relationship as recorded in cust location.
	 * CASS INFORMATION SYSTEMS INC - This is our Customer that holds our debt. It is employeed by another company that is recorded in our database and has the name QUAKER OATS CO.
	 * Required: false
	 * Array: false
	 */
	partyName:string = undefined;
	/**
	 * Identifies a company as a 3PL logistic company. In legacy CIS, there was no easy way of tracking 3PL companies. 
	 * In general 3PL companies are our customer (hold our debt), but work as partner for another company (shipper or sometimes consignee).
	 * Alternatively, a large company such as FORD might negotiate pricing, but the freight is managed by a logistic company, but Ford in this instance might hold debt (A/R). In such a case, the role of 3PL and the other company (Ford in this example) might be reversed, such that, FORD is our customer and a 3PL would be the related company.
	 * Required: false
	 * Array: false
	 */
	party3PlTypeCd:string = undefined;
	/**
	 * Abbreviated name used by CIS to New CIS conversion program to identify same names with some different white space and editing chars
	 * Required: false
	 * Array: false
	 */
	nameKey:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
}
/**
 * 
 */
export class ReservedWords {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reserveWord:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * This table ties a customer to a sales territory.  It will go away after 1CRM gives us the API to provide us with this information.  Added 02/11/2020
 */
export class SalesAssignment {
	/**
	 * Identifier of the Sales Territory; it is used to group customers for the assignment of an Account Manager or National Account Executive.
	 * Required: false
	 * Array: false
	 */
	salesTerrCd:string = undefined;
	/**
	 * The unique identifier in old CIS system.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Logs database transaction level events. One 
 * 
 * database transaction might generate several business events.
 * 
 */
export class TransactionEvent {
	/**
	 * Unique identifier
	 * Required: false
	 * Array: false
	 */
	cstEventId:number = undefined;
	/**
	 * Timestamp recorded for 
	 * 
	 * the Customer transaction.
	 * Required: false
	 * Array: false
	 */
	transactionDateTime:Date = undefined;
	/**
	 * A same value is assigned to 
	 * 
	 * CORRELATION id of various rows from different tables that form a single business 
	 * 
	 * transactions. In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction..
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation 
	 * 
	 * uses this to version check the record to ensure another transaction has not updated 
	 * 
	 * the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	requestInstanceId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Stores the request that caused this event.
	 * The request can come from either legacy CIS app or New CIS. This column Indicates the Request application. 
	 * Valid values are:
	 * 	CIS
	 * 	NCIS
	 * Required: false
	 * Array: false
	 */
	requestApplCd:string = undefined;
}


